/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_MATCH_NOTES } from "../../../../../../../queries/match";
import NoteSection from "./NoteSection";
import EmptyData from "../../../../../../../components/EmptyData";
import PartialError from "../../../../../../ErrorPage/PartialError";

export default function TGNotesTab({ position, match }) {
  const [state, setState] = useState({ notes: [] });
  const [queryError, setQueryError] = useState();
  const { refetch } = useQuery(GET_MATCH_NOTES, {
    variables: {
      id: match.id,
    },
    fetchPolicy: "network-only",
    skip: true,
  });

  const fetch = async () => {
    try {
      const result = await refetch();
      if (result?.data?.match?.notes) {
        const newState = JSON.parse(JSON.stringify(state));
        const meetingNotes = result.data.match.notes.filter(
          (i) => i.type === "MEETING"
        );

        newState.notes = meetingNotes;
        setState(newState);
      }
    } catch (err) {
      setQueryError(err);
    }
  };

  useEffect(() => {
    fetch();
  }, [match.id]);

  const handleNoteEdit = (noteData) => {
    const newState = {
      ...state,
      showForm: true,
      isNew: false,
      selectedNote: noteData,
    };
    setState(newState);
  };

  const generateKey = (index) => {
    return `${index}_${Math.random()}`;
  };

  if (queryError) return <PartialError />;

  return (
    <>
      {state.notes.length === 0 && <EmptyData />}
      {state.notes.length > 0 &&
        state.notes.map((note, idx) => (
          <div
            style={{
              marginTop: "20px",
              padding: "10px 15px",
              borderRadius: "5px",
            }}
            key={generateKey(idx)}
          >
            <NoteSection
              position={position}
              key={generateKey(idx)}
              note={note}
              handleEdit={() => handleNoteEdit(note)}
              matchedUserName={match?.user?.name}
              companyName={
                note.creatorType === 2 ? position.company.name : "TalentGrid"
              }
            />
          </div>
        ))}
    </>
  );
}

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, message } from "tg-design";
import { ProfileItem, RevokeAccess } from "../styles";
import { captureErrorWithData } from "../../../utils/helper";
import { REMOVE_EMPLOYER } from "../../../queries/company";
import AppContext from "../../../contexts/AppContext";
import { EMPLOYER_TYPES } from "../../../utils/constants";
import useQuestion from "../../../hooks/useQuestion";

const RevokeAccessSection = ({ employer }) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const question = useQuestion();

  const [removeEmployer] = useMutation(REMOVE_EMPLOYER);

  const handleRemoveFromCompany = async () => {
    try {
      await removeEmployer({
        variables: {
          id: employer.id,
          companyId: appContext.company.id,
        },
      });
      history.goBack();
    } catch (err) {
      message.error(err.message);
      captureErrorWithData(err);
    }
  };

  const handleRevokeAccess = () => {
    question(
      {
        content: (
          <div>
            Are you sure you want to remove {employer.fullName} from the team?
          </div>
        ),
        title: "Revoke Access",
      },
      handleRemoveFromCompany
    );
  };

  if (appContext.employer?.permission?.role !== EMPLOYER_TYPES.ADMIN) {
    return null;
  }

  return (
    <ProfileItem>
      <span>Revoke access</span>
      <RevokeAccess>
        <Button onClick={handleRevokeAccess} outline variant="danger">
          Revoke Access
        </Button>
        <span>Removes user from organization. </span>
        <span>This action cannot be undone so proceed with caution.</span>
      </RevokeAccess>
    </ProfileItem>
  );
};

export default RevokeAccessSection;

import React, { useContext } from "react";
import { components } from "react-select";
import { Avatar, SelectField } from "tg-design";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { CHANGE_COMPANY } from "../../../queries/company";
import AppContext from "../../../contexts/AppContext";

const CompanySelector = styled.div`
  .control {
    height: 50px;
    position: relative;
    bottom: 7.25px;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const EllipsisSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AvatarWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 12px;
`;

const Control = ({ children, ...props }) => {
  const data = props?.selectProps?.value;
  return (
    <components.Control className="control" {...props}>
      {data && (
        <AvatarWrapper>
          <Avatar name={data.name} src={data.logo} />
        </AvatarWrapper>
      )}
      {children}
    </components.Control>
  );
};

const Option = ({ data, ...props }) => (
  <components.Option {...props}>
    <FlexCenter>
      <AvatarWrapper>
        <Avatar name={data.name} src={data.logo} />
      </AvatarWrapper>
      <EllipsisSpan>{data.name}</EllipsisSpan>
    </FlexCenter>
  </components.Option>
);

const CompanySelect = () => {
  const appContext = useContext(AppContext);
  const [changeCompany] = useMutation(CHANGE_COMPANY);

  const handleChangeCompany = async ({ id }) => {
    await changeCompany({
      variables: {
        companyId: id,
        employerId: appContext.employer.id,
      },
    });
    window.location.href = "/";
  };

  return (
    <CompanySelector>
      <SelectField
        components={{
          Control,
          Option,
        }}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        options={appContext?.employer?.companies}
        onChange={handleChangeCompany}
        containerStyle={{
          width: "auto",
          marginRight: 40,
          minWidth: 220,
        }}
        defaultValue={appContext?.employer?.companies.find(
          (company) => company.id === appContext?.company?.id
        )}
      />
    </CompanySelector>
  );
};

export default React.memo(CompanySelect);

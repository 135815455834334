import React, { useContext } from "react";
import styled from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Avatar, ActionMenus, ActionMenu } from "tg-design";
import { BarMenuIcon } from "../../Icon";
import Dropdown, { DropdownItem, DropdownItemLink } from "../../Form/Dropdown";
import { LOGOUT } from "../../../queries/auth";
import AppContext from "../../../contexts/AppContext";
import logoImage from "../../../images/header-logo.png";
import logoImageWithText from "../../../images/talentgrid-logo-dark.png";
import CompanySelect from "./CompanySelect";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  box-shadow: 0px 2px 4px 2px #ebebeb;
  position: fixed;
  background-color: white;
  z-index: 999;
`;

const Box = styled.div`
  max-width: 1280px;
  width: 100%;
  padding-top: 22px;
  padding-bottom: 22px;
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 1320px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const LogoLink = styled(Link)``;

const Logo = styled.img`
  max-height: 36px;
  object-fit: contain;
  object-position: center;
`;

const MenuContainer = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 9px;

  @media only screen and (max-width: 1080px) {
    display: none;
  }
`;

const Menu = styled.li`
  display: inline;
  padding: 8px 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-right: ${(props) => (props.noMargin ? "0px" : "20px")};
  color: #9ca3af;

  &.active {
    background: rgba(100, 183, 220, 0.15);
    border-radius: 6px;
    color: #161616;
  }
`;

const MenuLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #161616;
  }
`;

const ActionBox = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  @media only screen and (max-width: 1080px) {
    display: none;
  }
`;

const MobileToggle = styled.div`
  position: absolute;
  right: 16px;
  margin-top: 5px;
  display: none;

  @media only screen and (max-width: 1080px) {
    display: unset;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 50px;
  position: relative;
  bottom: 7.25px;
  background-color: #e5e5e5;
  margin: 0px 20px;
  @media only screen and (max-width: 768px) {
    margin: 0px 10px;
  }
`;

const isPositionMenu = (pathname) => {
  return pathname === "/positions" || pathname.indexOf("/positions/") === 0;
};

export default function Navbar({ dontShowMenuItems }) {
  const appContext = useContext(AppContext);
  const { company } = appContext;
  const location = useLocation();
  const [logout] = useLazyQuery(LOGOUT);
  const history = useHistory();
  const { pathname } = location;

  const handleLogout = async () => {
    await logout();
    appContext.logout();
    history.push("/login");
    localStorage.removeItem("isAuth");
  };

  const MobileMenus = () => {
    return (
      <>
        {appContext.onboarding === false && (
          <>
            <DropdownItemLink
              style={{ textAlign: "center" }}
              to="/"
              className={pathname === "/" && "active"}
            >
              Dashboard
            </DropdownItemLink>
            <DropdownItemLink
              style={{ textAlign: "center" }}
              to="/positions"
              className={isPositionMenu(pathname) && "active"}
            >
              Positions
            </DropdownItemLink>
            <DropdownItemLink
              style={{ textAlign: "center" }}
              to="/company/edit"
              className={pathname === "/company/edit" && "active"}
            >
              Company
            </DropdownItemLink>
            <DropdownItemLink
              style={{ textAlign: "center" }}
              to="/team"
              className={pathname === "/team" && "active"}
            >
              Team
            </DropdownItemLink>
            {company.isTest && (
              <DropdownItemLink
                style={{ textAlign: "center" }}
                to="/integrations"
                className={pathname === "/integrations" && "active"}
              >
                Integrations
              </DropdownItemLink>
            )}
          </>
        )}
        <DropdownItemLink
          className={pathname === "/feedback" && "active"}
          style={{ textAlign: "center" }}
          to="/feedback"
        >
          Feedback
        </DropdownItemLink>
        <DropdownItem style={{ textAlign: "center" }} onClick={handleLogout}>
          Logout
        </DropdownItem>
      </>
    );
  };

  return (
    <Container>
      <Box>
        <LogoLink to="/">
          <Logo
            src={
              appContext?.employer?.companies?.length > 1
                ? logoImage
                : logoImageWithText
            }
            alt="Talentgrid.io Logo"
          />
        </LogoLink>

        {appContext?.employer?.companies?.length > 1 && (
          <>
            <Divider />

            <CompanySelect />
          </>
        )}

        {appContext.onboarding === false && !dontShowMenuItems && (
          <MenuContainer>
            <Menu
              className={pathname === "/" && "active"}
              style={{
                marginLeft:
                  appContext?.employer?.companies?.length > 1 ? 0 : 40,
              }}
            >
              <MenuLink to="/">Dashboard</MenuLink>
            </Menu>
            <Menu className={isPositionMenu(pathname) && "active"}>
              <MenuLink to="/positions">Positions</MenuLink>
            </Menu>

            <Menu className={pathname === "/company/edit" && "active"}>
              <MenuLink to="/company/edit">Company</MenuLink>
            </Menu>
            <Menu className={pathname === "/team" && "active"}>
              <MenuLink to="/team">Team</MenuLink>
            </Menu>
            {company.isTest && (
              <Menu className={pathname === "/integrations" && "active"}>
                <MenuLink to="/integrations">Integrations</MenuLink>
              </Menu>
            )}
          </MenuContainer>
        )}

        {!dontShowMenuItems && (
          <ActionBox>
            <Menu className={pathname === "/feedback" && "active"} noMargin>
              <MenuLink to="/feedback">Feedback</MenuLink>
            </Menu>
            <ActionMenus
              variant="right"
              icon={
                <Avatar
                  name={appContext.employer?.fullName}
                  src={appContext.employer?.avatarURL}
                />
              }
              menuStyles={{
                top: 40,
              }}
            >
              <ActionMenu onClick={handleLogout}>Logout</ActionMenu>
            </ActionMenus>
          </ActionBox>
        )}

        {!dontShowMenuItems && (
          <MobileToggle>
            <Dropdown menus={<MobileMenus />}>
              <BarMenuIcon />
            </Dropdown>
          </MobileToggle>
        )}
      </Box>
    </Container>
  );
}

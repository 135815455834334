import { gql } from "@apollo/client";

export const ALL_LANGUAGES = gql`
  query AllLanguages($search: String, $limit: Int) {
    allLanguages(search: $search, limit: $limit) {
      languages {
        id
        label
        code
        alternativeTexts
      }
    }
  }
`;

export const GET_BENEFITS = gql`
  {
    allBenefits {
      id
      label
    }
  }
`;

export const GET_REJECT_REASON = gql`
  {
    allRejectReason(type: 2) {
      id
      label
    }
  }
`;

export const CREATE_REJECT_REASON = gql`
  mutation createRejectReason(
    $label: String!
    $type: Int!
    $description: String
  ) {
    createRejectReason(label: $label, type: $type, description: $description) {
      id
    }
  }
`;

export const CREATE_PIN = gql`
  mutation CreatePin($email: String!, $operation: String!) {
    createPin(email: $email, operation: $operation) {
      email
    }
  }
`;

export const VERIFY_PIN = gql`
  query VerifyPin($email: String, $digit: Int) {
    pin(email: $email, digit: $digit) {
      email
      digit
    }
  }
`;

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { POSITION_STATES } from "../../utils/constants";

dayjs.extend(relativeTime);

export const getPositionStartDate = (position) => {
  if (!position?.history) {
    return null;
  }

  const inProgressStep = position.history.find(
    (item) => item.current === POSITION_STATES.IN_PROGRESS
  );

  if (!inProgressStep) {
    return null;
  }

  return inProgressStep.createdAt;
};

export const getPositionStartDateFromNow = (position) => {
  const date = getPositionStartDate(position);
  if (!date) {
    return "-";
  }

  return dayjs(date).fromNow();
};

export const getStartedDay = (position) => {
  const startDate = getPositionStartDate(position);
  if (!startDate) {
    return "-";
  }
  const diff = dayjs().diff(dayjs(startDate), "days", true);
  return Math.ceil(diff);
};

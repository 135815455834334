/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
import { useState } from "react";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
  REMOTE_OPTIONS_FOR_DEVELOPER,
  REMOTE_OPTIONS_FOR_COMPANY,
  EDUCATION_TYPES,
  LANGUAGE_LEVEL_TYPES,
  HEAD_DATA,
} from "./constants";

export const useLocationQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/**
 * useLazyCaller
 *
 * Bu custom hook şu işe yarar; belirtilen timeout süresinin sonunda, gönderilen
 * "callback" fonksiyonunu çağırır. Ancak, timeout bitmeden bir kez daha ilgili
 * fonksiyon çağrılmaya çalışılırsa, timeout sıfırlanır ve tekrar çağrım için
 * timeout süresi kadar beklenir.
 *
 * Örneğin timeout 1000 olsun. 100ms sonra tekrar fonksiyon çağrılırsa, ilk çağrımdan
 * 1100 ms sonra callback çağrılacaktır. ANCAK, timeout bittikten sonra sadece 1
 * kez çağrılır.
 *
 * @returns Function
 */
export const useLazyCaller = () => {
  const [task, setTask] = useState(null);
  const lazyCaller = (callback, timeout) => {
    if (task) {
      clearTimeout(task);
    }

    setTask(setTimeout(callback, timeout));
  };
  return lazyCaller;
};

export const useNamedLazyCaller = () => {
  const [task, setTask] = useState({});
  const lazyCaller = (name, callback, timeout) => {
    return (...args) => {
      if (task[name]) {
        clearTimeout(task[name]);
      }

      const later = () => {
        callback.apply(this, args);
      };

      setTask({
        ...task,
        [name]: setTimeout(later, timeout),
      });
    };
  };
  return lazyCaller;
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  }

  if (typeof value === "object") {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

/**
 * Bu method, bir objecy içerisindeki değeri, veriler path üzerinde
 * bularak günceller.
 *
 * Örnek;
 * const data = {
 *    "criteria": {
 *      "location": {
 *        "remote": false
 *      }
 *    }
 * }
 *
 * Kullanım;
 *
 * setChangesByPropertyPath(data, "criteria.location.remote", true)
 *
 * @param {Object} object
 * @param {String} path
 * @param {Any} value
 * @returns {Object}
 */
export const setChangesByPropertyPath = (object, path, value) => {
  const changes = JSON.parse(JSON.stringify(object));
  let pointer = changes;
  const steps = path.split(".");

  // eslint-disable-next-line no-restricted-syntax
  for (let index = 0; index < steps.length - 1; index++) {
    pointer = pointer[steps[index]];
  }

  const lastKey = steps[steps.length - 1];
  pointer[lastKey] = value;

  return changes;
};

export const getValueByPropertyPath = (object, path) => {
  const changes = JSON.parse(JSON.stringify(object));
  let pointer = changes;
  const steps = path.split(".");

  // eslint-disable-next-line no-restricted-syntax
  for (let index = 0; index < steps.length - 1; index++) {
    pointer = pointer[steps[index]];
  }

  const lastKey = steps[steps.length - 1];

  return pointer[lastKey];
};

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const entries = urlParams.entries();
  const params = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const entry of entries) {
    // eslint-disable-next-line prefer-destructuring
    params[entry[0]] = entry[1];
  }
  return params;
};

export const useTriggerOnce = () => {
  const [status, setStatus] = useState(null);
  const caller = (callback, period) => {
    if (status === null) {
      callback();
      const timeout = setTimeout(() => {
        setStatus(null);
      }, period);
      setStatus(timeout);
    }
  };
  return caller;
};

export const getRemoteTitleForDeveloper = (value) => {
  const item = REMOTE_OPTIONS_FOR_DEVELOPER.find((i) => i.id === value);
  if (item) {
    return item.title;
  }

  return value;
};

export const getRemoteTitleForCompany = (value) => {
  const item = REMOTE_OPTIONS_FOR_COMPANY.find((i) => i.id === value);
  if (item) {
    return item.title;
  }

  return value;
};

export const getEducationTypeText = (educationType, emptyValue = "-") => {
  if (educationType === undefined || educationType === null) {
    return emptyValue;
  }
  const match = EDUCATION_TYPES.find((item) => item.value === educationType);
  if (match) {
    return match.label;
  }
  return emptyValue;
};

export const getLanguageLevelText = (level) => {
  level = LANGUAGE_LEVEL_TYPES.find((item) => item.value === level);
  return level ? level.label : "?";
};

export const toPercent = (value, total) => {
  if (total === 0) {
    return 0;
  }
  return Math.round((100 * value) / total);
};

export const handleHeadTag = (key, additionalTitle) => {
  const { title } = HEAD_DATA[key];
  const { desc } = HEAD_DATA[key];
  const meta = document.getElementsByTagName("meta");

  if (additionalTitle) {
    document.title = `${additionalTitle} ${title}`;
  } else {
    document.title = title;
  }
  meta.description.content = desc;
};

export const sleep = async (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getUnsignedUrl = (signedUrl) => {
  if (!signedUrl) {
    return signedUrl;
  }

  const { origin, pathname } = new URL(signedUrl);
  return `${origin}${pathname}`;
};

export const captureErrorWithData = (error, data) => {
  Sentry.withScope((scope) => {
    scope.setExtra("data", JSON.stringify(data));
    Sentry.captureException(error);
  });
};

export const removeWhiteSpace = (val) => {
  return val.replace(/\s+/g, "");
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const capitalize = (s) => {
  if (typeof s !== "string") return s;
  return s
    .toLowerCase()
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.substring(1))
    .join(" ");
};

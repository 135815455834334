import React, { useCallback } from "react";
import styled, { keyframes, css } from "styled-components";
import { MATCH_STATES } from "../../utils/constants";

const scrollAnimation = keyframes`
  from {
    background: #FDEEC0;
  }
  to {
    background: #f1f1f1;
  }
`;

const Container = styled.div`
  background-color: ${(props) =>
    props.columnId === MATCH_STATES.COMPANY_ASSESSMENT &&
    props.isAwaitingActionHovered
      ? "#FDEEC0"
      : "#f1f1f1"};
  margin-right: 20px;
  width: 216px;
  height: calc(100% - 5px);
  min-width: 216px;
  border-radius: 5px;

  animation: ${(props) =>
    props.columnId === MATCH_STATES.COMPANY_ASSESSMENT &&
    props.isAwaitingActionClicked &&
    css`
      ${scrollAnimation} 2s linear 1
    `};

  &.on-hover {
    border: ${(props) =>
      props.borderColor
        ? `2px dashed ${props.borderColor}`
        : "2px dashed #64b7dc"};
  }
  &.is-not-allowed {
    opacity: 0.4;
    border-color: #f1f1f1;

    &.has-substate {
      opacity: 1;
    }
  }

  &.has-substate {
    width: auto;
    min-width: unset;
  }
`;

const TgKanbanColumn = ({
  handleDragChange,
  onDrop,
  columnId,
  substate = null,
  children,
  isOnHover,
  isAllowed,
  borderColor,
  awaitingActionState,
  setAwaitingActionState,
  hasSubState = false,
}) => {
  const handleDragOver = useCallback(
    (event) => {
      if (hasSubState) {
        return event.preventDefault();
      }

      handleDragChange({
        targetColumnId: columnId,
        targetSubstateId: substate,
        clientX: event.clientX,
      });

      event.preventDefault();
    },
    // eslint-disable-next-line
    [columnId, substate, handleDragChange]
  );

  const classes = [];
  if (isOnHover) {
    classes.push("on-hover");
  }

  if (!isAllowed) {
    classes.push("is-not-allowed");
  }

  if (hasSubState) {
    classes.push("has-substate");
  }

  return (
    <Container
      onDrop={onDrop}
      onDragOver={handleDragOver}
      className={classes.join(" ")}
      borderColor={borderColor}
      isAwaitingActionHovered={awaitingActionState?.isHovered}
      isAwaitingActionClicked={awaitingActionState?.isClicked}
      onAnimationEnd={() =>
        setAwaitingActionState({ ...awaitingActionState, isClicked: false })
      }
      columnId={columnId}
    >
      {children}
    </Container>
  );
};

const SubColumnContainer = styled(Container)`
  background: #ebebeb;
  border: 2px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 5px;
  min-width: 196px;
  width: 196px;
  height: 100%;
  margin-right: 0px;
`;

const TgKanbanSubColumn = ({
  handleDragChange,
  onDrop,
  columnId,
  substate = null,
  children,
  isOnHover,
  isAllowed,
  borderColor,
}) => {
  const handleDragOver = useCallback(
    (event) => {
      handleDragChange({
        targetColumnId: columnId,
        targetSubstateId: substate,
        clientX: event.clientX,
      });

      event.preventDefault();
    },
    // eslint-disable-next-line
    [columnId, substate, handleDragChange]
  );

  const classes = [];
  if (isOnHover) {
    classes.push("on-hover");
  }

  if (!isAllowed) {
    classes.push("is-not-allowed");
  }

  return (
    <SubColumnContainer
      onDrop={(event) => {
        event.stopPropagation();
        onDrop(event);
      }}
      onDragOver={handleDragOver}
      className={classes.join(" ")}
      borderColor={borderColor}
    >
      {children}
    </SubColumnContainer>
  );
};

export { TgKanbanColumn, TgKanbanSubColumn };

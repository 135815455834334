import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { SortIcon } from "../../Icon";

const StyledSortButton = styled.button`
  background-color: white;
  border-radius: 4px;
  height: 41px;
  padding: 8px;
  border: 1px solid #c7c7c7;
`;

const StyledSortContainer = styled.div`
  background-color: white;
  position: absolute;
  margin: 125px 10px 0 0;
  width: 200px;
  border: 1px solid #c7c7c7;
  padding: 4px 0px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: auto;
`;

const StyledSortItem = styled.div`
  display: flex;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: ${(props) => (props.isActive ? "500" : "unset")};
  &:hover {
    background-color: #f9f9f9;
  }
`;

export default function SortButton({ menus, handleChange, active, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  const handleClick = (value) => {
    handleChange(value);
    setTimeout(() => {
      setIsOpen(false);
    }, 50);
  };

  return (
    <>
      <StyledSortButton {...props} onClick={() => setIsOpen(true)}>
        <SortIcon />
      </StyledSortButton>
      {isOpen && (
        <StyledSortContainer ref={wrapperRef}>
          {menus.map((menu, index) => {
            return (
              <StyledSortItem
                key={index}
                onClick={() => handleClick(menu.value)}
                isActive={active === menu.value}
              >
                {menu.title}
              </StyledSortItem>
            );
          })}
        </StyledSortContainer>
      )}
    </>
  );
}

import React from "react";
import styled from "styled-components";

const SquareContainer = styled.div`
  height: 145px;
  width: 145px;
  min-height: 145px;
  min-width: 145px;
  background: #f8f8f8;
  border: 1px dashed #c7c7c7;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  margin-right: 14px;
  margin-bottom: 14px;

  &.has-error {
    border-color: #ff7675;
  }

  &:hover {
    border: 1px dashed #64b7dc;

    & div.image {
      background-color: black;
      opacity: 0.5;
    }

    & span.actions {
      display: unset;
    }
  }

  & span.actions {
    display: none;
  }

  &.loading {
    background-color: black;
  }

  &.not-empty {
    border: none;
  }

  &.data-box {
    background-color: white;
    border: 1px solid #9ca3af;
    color: black;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    &:hover {
      border: 1px dashed #9ca3af;

      & div {
        background-color: #fafafa;
        opacity: 0.5;
      }

      & span.actions {
        display: unset;
      }
    }
  }
`;

const HoverContent = styled.span`
  position: absolute;
  margin-top: 58px;
  width: 145px;
  text-align: center;
  display: inherit;
  color: #ddd;

  @media only screen and (max-width: 768px) {
    position: unset;
    display: ${(props) => (props.display ? "block !important" : "none")};
    height: 20px;
    margin-top: 0px;
    background-color: white;
    padding: 8px 0px;
  }
`;

export default function Square({
  children,
  squareClasses,
  hoverContent,
  hasError,
  ...props
}) {
  const classes = [squareClasses, hasError ? "has-error" : ""]
    .filter((i) => i)
    .join(" ");
  return (
    <SquareContainer className={classes} {...props}>
      {children}
      <HoverContent className="actions" display={hoverContent}>
        {hoverContent}
      </HoverContent>
    </SquareContainer>
  );
}

import React from "react";
import dayjs from "dayjs";
import styled from "styled-components";

const positionStates = {
  ON_HOLD: "Paused",
  CREATED: "Drafted",
  SUBMITTED: "Submitted",
  IN_PROGRESS: "Started",
  FILLED: "Filled",
  CLOSED: "Closed",
  CANCELED: "Canceled",
};

const ListItem = styled.li`
  position: relative;
  margin: 0;
  padding-bottom: 1em;
  padding-left: 20px;

  :before {
    background-color: #e5e5e5;
    width: 2px;
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 5px;
  }
  :first-child:before {
    top: 15px;
  }
  :last-child:before {
    height: 0px;
  }
  ::after {
    content: "";
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='rgb(100,183,220)' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: 2px;
    width: 12px;
    height: 12px;
  }
`;

export default function History({ history, positionCreatedAt }) {
  return (
    <div>
      <ul style={{ listStyleType: "none", padding: "0" }}>
        <ListItem>
          <b>Position {positionStates.CREATED}</b>
          <br />
          {dayjs(positionCreatedAt).format("DD.MM.YYYY HH:mm:ss")}
        </ListItem>
        {history &&
          history.map((state, i) => (
            <ListItem key={i}>
              <b>Position {positionStates[state.current]}</b>
              <br />
              {dayjs(state.createdAt).format("DD.MM.YYYY HH:mm:ss")}
            </ListItem>
          ))}
      </ul>
    </div>
  );
}

/* eslint-disable eqeqeq */
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: white;
  margin-bottom: 10px;
  border-radius: 5px;
  height: auto;
  padding: 8px;
  cursor: grabbing;
  &.dragging {
    opacity: 0.5;
  }
`;

const ChildContainer = styled.div`
  &.is-updating {
    opacity: 0.5;
    filter: blur(1.2px);
  }
`;

const SpinnerContainer = styled.div`
  margin-top: -29px;
  text-align: center;
`;

const Spinner = styled.span`
  display: inline-block;
`;

export default function TgKanbanColumnCard({
  drag,
  setDrag,
  cardId,
  substate = null,
  columnId,
  children,
  onClick,
  updateCardId,
}) {
  const isUpdating = updateCardId && updateCardId == cardId;
  return (
    <Container
      draggable
      onDragStart={() =>
        setDrag({
          sourceColumnId: columnId,
          sourceSubstateId: substate,
          cardId,
        })
      }
      onDragEnd={() => setDrag(null)}
      className={drag && drag.cardId === cardId ? "dragging" : ""}
      onClick={(event) => onClick({ event, cardId })}
    >
      <ChildContainer className={isUpdating ? "is-updating" : ""}>
        {children}
      </ChildContainer>
      {isUpdating && (
        <SpinnerContainer>
          <Spinner className="loader">&nbsp;</Spinner>
        </SpinnerContainer>
      )}
    </Container>
  );
}

/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { message, Tooltip } from "tg-design";
import StatusChangeModal from "../../../../../components/TgKanban/StatusChangeModal/index";
import {
  TgKanbanContainer,
  TgKanbanHeader,
  TgKanbanTitle,
  TgKanbanBody,
  TgKanbanColumn,
  TgKanbanColumnBody,
  TgKanbanColumnCard,
  TgKanbanColumnFooter,
  TgKanbanColumnHeader,
  TgKanbanTrash,
  TgKanbanSubColumn,
  TgKanbanSubColumnContainer,
  MenuAction,
} from "../../../../../components/TgKanban/index";
import SearchInput from "../../../../../components/Form/SearchInput";
import SortButton from "../../../../../components/Form/SortButton";
import MatchPreview from "./MatchPreview";
import {
  GET_STATE_WITH_MATCHES,
  MATCH_STATE_CHANGE,
  ADD_CUSTOM_FLOW,
  REMOVE_CUSTOM_FLOW,
} from "../../../../../queries/match";
import {
  MATCH_STATES,
  MATCH_STATE_TITLES,
  MATCH_STATE_ALLOWANCE_LIST,
  MATCH_NOT_A_GOOD_FIT,
  POSITION_STATES,
  DEVELOPER_STATUSES,
} from "../../../../../utils/constants";
import Drawer from "../../../../../components/Drawer";
import {
  useTriggerOnce,
  debounce,
  captureErrorWithData,
  isMobile,
} from "../../../../../utils/helper";
import FailReasonsModal from "../FailReasonsModal/FailReasonsModal";
import MoveNotAllowedModal from "./MoveNotAllowedModal";
import MatchDetailDrawer from "../MatchDetailDrawer";
import PartialError from "../../../../ErrorPage/PartialError";
import {
  EditIcon,
  TrashIcon,
  AddMoreIcon,
  TimeSpentIcon,
} from "../../../../../components/Icon";
import CustomFlowEditModal from "./CustomFlowEditModal";
import { DropdownInfo } from "../../../../../components/DropdownInfo";
import useQuestion from "../../../../../hooks/useQuestion";

const RowBlock = styled.div`
  display: flex;
  height: calc(100% - 45px);
`;

const ColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const InterviewTimeContainer = styled.div`
  display: flex;
  gap: 5px;
  padding-bottom: 10px;
  align-items: center;
`;

const InterviewTime = styled.div`
  font-size: 10px;
  line-height: 14px;
`;

const CLOSED_POSITION_STATES = [
  POSITION_STATES.ON_HOLD,
  POSITION_STATES.FILLED,
  POSITION_STATES.CLOSED,
  POSITION_STATES.CANCELED,
];

const sortFields = [
  { value: "updatedAt", title: "Sort by last updated, ascending" },
  { value: "-updatedAt", title: "Sort by last updated, descending" },
];
const columns = [
  { id: MATCH_STATES.SENT, title: MATCH_STATE_TITLES[MATCH_STATES.SENT] },
  {
    id: MATCH_STATES.INTERESTED,
    title: MATCH_STATE_TITLES[MATCH_STATES.INTERESTED],
  },
  {
    id: MATCH_STATES.TG_ASSESSMENT,
    title: MATCH_STATE_TITLES[MATCH_STATES.TG_ASSESSMENT],
  },
  {
    id: MATCH_STATES.COMPANY_ASSESSMENT,
    title: MATCH_STATE_TITLES[MATCH_STATES.COMPANY_ASSESSMENT],
  },
  {
    id: MATCH_STATES.INTERVIEW,
    title: MATCH_STATE_TITLES[MATCH_STATES.INTERVIEW],
  },
  {
    id: MATCH_STATES.SENT_OFFER,
    title: MATCH_STATE_TITLES[MATCH_STATES.SENT_OFFER],
  },
  {
    id: MATCH_STATES.HIRED,
    title: MATCH_STATE_TITLES[MATCH_STATES.HIRED],
  },
  {
    id: MATCH_STATES.FAILED,
    title: MATCH_STATE_TITLES[MATCH_STATES.FAILED],
    borderColor: "#FFBBBA",
  },
];

const DEFAULT_LIMIT = 20;

const isTrashActive = (drag) => {
  if (!drag) {
    return false;
  }

  const allowedStates = [
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.SENT_OFFER,
    MATCH_STATES.HIRED,
  ];

  return allowedStates.includes(drag.sourceColumnId);
};

const getTrashState = (drag) => {
  const states = {
    [MATCH_STATES.FAILED]: [
      MATCH_STATES.COMPANY_ASSESSMENT,
      MATCH_STATES.INTERVIEW,
      MATCH_STATES.SENT_OFFER,
      MATCH_STATES.HIRED,
    ],
  };

  for (const key of Object.keys(states)) {
    if (states[key].includes(drag.sourceColumnId)) {
      if (key === "FAILED") return "FAILED";
    } else {
      return key;
    }
  }

  return null;
};

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const StyledDrawer = styled(Drawer)`
  overflow-y: hidden !important;

  @media only screen and (max-width: 768px) {
    overflow-y: scroll !important;
  }

  & div.scroll-container {
    height: 100%;
  }
`;

const getVisibleColumns = (customFlows) => {
  const visibleColumns = [];
  Object.keys(customFlows).forEach((state) => {
    const found = columns.find((i) => i.id === state);
    if (!found) {
      return;
    }

    if (customFlows[state].length === 0) {
      visibleColumns.push({
        id: state,
        state,
        substate: null,
        title: found.title,
        borderColor: found.borderColor,
        customFlows: [],
      });
    } else if (
      state === MATCH_STATES.SENT ||
      state === MATCH_STATES.INTERESTED ||
      state === MATCH_STATES.TG_ASSESSMENT
    ) {
      visibleColumns.push({
        state,
        id: state,
        substate: null,
        title: found.title,
        borderColor: found.borderColor,
        customFlows: [],
      });
    } else if (
      customFlows[state].length > 0 &&
      state === MATCH_STATES.INTERVIEW
    ) {
      visibleColumns.push({
        state,
        id: state,
        substate: null,
        title: found.title,
        borderColor: found.borderColor,
        customFlows: customFlows[state],
      });
    } else if (
      Array.isArray(customFlows[state]) &&
      state !== MATCH_STATES.INTERVIEW
    ) {
      const ordered = [...customFlows[state]].sort((a, b) => a.index - b.index);
      ordered.forEach((substate) => {
        visibleColumns.push({
          state,
          id: state,
          substate: substate.uuid,
          title: substate.title,
          index: substate.index,
          borderColor: found.borderColor,
          customFlows: [],
        });
      });
    }
  });

  return visibleColumns;
};

const getAllStatesAsQuery = (customFlows) => {
  const states = [];
  columns.forEach((column) => {
    if (customFlows[column.id] && customFlows[column.id].length > 0) {
      customFlows[column.id].forEach((substate) => {
        states.push({
          state: column.id,
          substate: substate.uuid,
          limit: DEFAULT_LIMIT,
        });
      });
    } else {
      states.push({
        state: column.id,
        limit: DEFAULT_LIMIT,
      });
    }
  });
  return states;
};

export default function MatchesTab({
  position: { customFlows },
  position,
  awaitingActionState,
  setAwaitingActionState,
  refetch,
}) {
  const question = useQuestion();
  const containerRef = useRef(null);
  const kanbanBodyRef = useRef();
  const query = useUrlQuery();
  const history = useHistory();
  const triggerOnce = useTriggerOnce();
  const [updateCardId, setUpdateCardId] = useState(null);
  const [drag, setDrag] = useState(null);
  const [dragState, setDragState] = useState({
    sourceColumnId: null,
    targetColumnId: null,
    cardId: null,
  });
  const [filters, setFilters] = useState({
    search: query.get("search"),
    sort: query.get("sort") ? query.get("sort") : "-updatedAt",
  });
  const [drawerState, setDrawerState] = useState({
    visible: query.get("drawerId") !== null,
    matchId: query.get("drawerId"),
  });
  const [isModalOpen, setIsModalOpen] = useState({
    isOpen: false,
    data: null,
  });
  const [statusChangeModal, setStatusChangeModal] = useState({
    isOpen: false,
    user: null,
    status: null,
  });
  const [isMoveNotAllowedModalOpen, setIsMoveNotAllowedModalOpen] =
    useState(false);
  const [isSearching, setSearching] = useState(false);
  const [data, setData] = useState(null);
  const [editCustomFlow, setEditCustomFlow] = useState(null);
  const [notAGoodFitState, setNotAGoodFitState] = useState({
    isClicked: false,
  });
  const [flatColumns, setFlatColumnsState] = useState(null);
  const [rightMover, setRightMover] = useState(false);
  const [rightMoverInterval, setRightMoverInterval] = useState(false);
  const [leftMover, setLeftMover] = useState(false);
  const [leftMoverInterval, setLeftMoverInterval] = useState(false);

  const [changeMatchState] = useMutation(MATCH_STATE_CHANGE);
  const [addCustomFlow] = useMutation(ADD_CUSTOM_FLOW);
  const [removeCustomFlow] = useMutation(REMOVE_CUSTOM_FLOW);
  const {
    refetch: getStateWithMatchesQuery,
    error,
    loading,
  } = useQuery(GET_STATE_WITH_MATCHES, {
    variables: {
      positionId: position.id,
      states: [],
    },
    fetchPolicy: "no-cache",
    skip: true,
  });

  const getStatesWithMatches = useCallback(async (variables) => {
    const result = await getStateWithMatchesQuery(variables);
    return result;
  });

  const refreshTopRecords = useCallback(
    async (variables) => {
      const states = getAllStatesAsQuery(customFlows);

      const response = await getStatesWithMatches({
        ...variables,
        states,
      });
      const result = response?.data?.getStatesWithMatches || [];
      return result;
    },
    // eslint-disable-next-line
    [getStatesWithMatches]
  );

  useEffect(() => {
    if (rightMover) {
      const mover = setInterval(() => {
        if (kanbanBodyRef && kanbanBodyRef.current) {
          kanbanBodyRef.current.scrollLeft += 10;
        }
      }, 10);
      setRightMoverInterval(mover);
    } else {
      clearInterval(rightMoverInterval);
    }
    // eslint-disable-next-line
  }, [rightMover]);

  useEffect(() => {
    if (leftMover) {
      const mover = setInterval(() => {
        if (
          kanbanBodyRef &&
          kanbanBodyRef.current &&
          kanbanBodyRef.current.scrollLeft > 0
        ) {
          kanbanBodyRef.current.scrollLeft -= 10;
          if (kanbanBodyRef.current.scrollLeft < 0) {
            kanbanBodyRef.current.scrollLeft = 0;
          }
        }
      }, 10);
      setLeftMoverInterval(mover);
    } else {
      clearInterval(leftMoverInterval);
    }
    // eslint-disable-next-line
  }, [leftMover]);

  useEffect(() => {
    (async () => {
      const items = await refreshTopRecords({
        search: filters.search,
        sort: filters.sort,
      });
      setData(
        items.map((item) => {
          return {
            ...item,
            isEnded: item.count < item.limit,
          };
        })
      );
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const flatColumns = [];
    Object.keys(customFlows).forEach((state) => {
      const found = columns.find((i) => i.id === state);
      if (!found) {
        return;
      }

      if (customFlows[state].length === 0) {
        flatColumns.push({
          state,
          substate: null,
          title: found.title,
          borderColor: found.borderColor,
        });
      } else if (Array.isArray(customFlows[state])) {
        const ordered = [...customFlows[state]].sort(
          (a, b) => a.index - b.index
        );
        ordered.forEach((substate) => {
          flatColumns.push({
            state,
            substate: substate.uuid,
            title: substate.title,
            index: substate.index,
            borderColor: found.borderColor,
          });
        });
      }
    });
    setFlatColumnsState(flatColumns);
  }, [customFlows]);

  if (error) return <PartialError />;
  if (loading || !data) return "loading";

  const clearMovers = () => {
    setLeftMover(false);
    setLeftMover(false);
    if (leftMoverInterval) {
      clearInterval(leftMoverInterval);
      setLeftMoverInterval(null);
    }
    if (rightMoverInterval) {
      clearInterval(rightMoverInterval);
      setRightMoverInterval(null);
    }
  };

  const onDrop = async () => {
    if (
      drag?.sourceColumnId &&
      (drag.sourceColumnId === MATCH_STATES.SENT ||
        drag.sourceColumnId === MATCH_STATES.INTERESTED ||
        drag.sourceColumnId === MATCH_STATES.TG_ASSESSMENT)
    ) {
      setIsMoveNotAllowedModalOpen(true);
    }
    // Bizim kutucuklarımızdan başka bir şey seçilim sürüklenirse işlem yapmayız.
    if (!drag) {
      return;
    }

    clearMovers();
    const {
      sourceColumnId,
      sourceSubstateId,
      targetColumnId,
      targetSubstateId,
      cardId,
    } = drag;

    if (
      (sourceColumnId === targetColumnId &&
        sourceSubstateId === targetSubstateId) ||
      (sourceColumnId !== targetColumnId &&
        !isAllowed(targetColumnId, targetSubstateId))
    ) {
      setDrag(null);
      setUpdateCardId(null);
      return;
    }
    const column = data.find(
      (i) => i.state === sourceColumnId && i.substate === sourceSubstateId
    );
    const card = column.matches.find((i) => i.id === cardId);

    if (
      targetColumnId === MATCH_STATES.FAILED &&
      targetColumnId !== sourceColumnId
    ) {
      return setIsModalOpen({
        isOpen: true,
        data: {
          cardId,
          sourceColumnId,
          sourceSubstateId,
          targetColumnId,
          targetSubstateId,
          type: MATCH_STATES.FAILED,
        },
      });
    }

    setDragState({
      sourceColumnId,
      sourceSubstateId,
      targetColumnId,
      targetSubstateId,
      cardId,
    });

    const targetColumn = flatColumns.find(
      (i) => i.state === targetColumnId && i.substate === targetSubstateId
    );

    setStatusChangeModal({
      isOpen: true,
      user: {
        name: card.user.name,
        role: card.user.role?.title || "Missing Role",
      },
      status: targetColumn?.title || "New Stage",
    });
  };

  const getStateData = (data, state, substate = null) => {
    if (
      state === MATCH_STATES.SENT ||
      state === MATCH_STATES.INTERESTED ||
      state === MATCH_STATES.TG_ASSESSMENT
    ) {
      const item = data.filter((i) => i.state === state);

      const matches = item
        .reduce(
          (previousValue, currentValue) => [
            ...previousValue,
            ...currentValue.matches,
          ],
          []
        )
        .flat();

      const count = item.reduce(function (accumulator, curValue) {
        return accumulator + curValue.count;
      }, 0);

      const limit = item.reduce(function (accumulator, curValue) {
        return accumulator + curValue.limit;
      }, 0);

      item.matches = matches;
      item.count = count;
      item.limit = limit;
      item.isEnded = count < limit;
      return item;
    }
    const item = data.find((i) => i.state === state && i.substate === substate);
    return item || null;
  };

  const getMatchesByStates = (data, state, substate = null) => {
    data.forEach((item) => {
      const filteredMatches = item.matches.filter(
        (i) => i.user.status !== DEVELOPER_STATUSES.PRE_LEAD
      );

      const preLeadMatches = item.matches.filter(
        (i) => i.user.status === DEVELOPER_STATUSES.PRE_LEAD
      );

      item.matches = filteredMatches;
      item.count -= preLeadMatches.length;
    });
    const item = getStateData(data, state, substate);
    if (item) {
      return item?.matches || null;
    }

    return [];
  };

  const moveCardInState = ({
    newState,
    sourceColumnId,
    sourceSubstateId,
    targetColumnId,
    targetSubstateId,
    cardId,
  }) => {
    const card = getMatchesByStates(
      newState,
      sourceColumnId,
      sourceSubstateId
    ).find((item) => item.id === cardId);

    const targetArray = newState.find(
      (i) => i.state === targetColumnId && i.substate === targetSubstateId
    );

    const result = newState.map((item) => {
      if (item.state === sourceColumnId && item.substate === sourceSubstateId) {
        return {
          ...item,
          matches: item.matches.filter((match) => match.id !== cardId),
          count: item.count - 1,
        };
      }

      if (item.state === targetColumnId && item.substate === targetSubstateId) {
        return {
          ...item,
          matches: [
            { ...card, state: targetColumnId, substate: targetSubstateId },
            ...targetArray.matches,
          ],
          count: item.count + 1,
        };
      }
      return {
        ...item,
      };
    });

    return result;
  };

  const handleAcceptChange = async (params) => {
    const {
      sourceColumnId,
      sourceSubstateId,
      targetColumnId,
      targetSubstateId,
      cardId,
    } = params;
    if (
      (sourceColumnId === targetColumnId &&
        sourceSubstateId === targetSubstateId) ||
      (sourceColumnId !== targetColumnId &&
        !isAllowed(targetColumnId, targetSubstateId))
    ) {
      setDragState(null);
      return;
    }

    let newState = [...data];

    try {
      handleStatusChangeModalClose();
      newState = moveCardInState({
        ...params,
        newState,
      });
      setData(newState);
      setDrag(null);
      setUpdateCardId(cardId);
      await changeMatchState({
        variables: {
          id: cardId,
          state: targetColumnId,
          substate: targetSubstateId,
        },
      });
      setUpdateCardId(null);
    } catch (error) {
      // flip-flop. Eğer başarısız olursa, match card'ı taşıdığımız
      // yerde tutmaya devam edemeyiz.
      newState = moveCardInState({
        ...params,
        newState,
        sourceColumnId: targetColumnId,
        sourceSubstateId: targetSubstateId,
        targetColumnId: sourceColumnId,
        targetSubstateId: sourceSubstateId,
      });
      setData(newState);
      message.error("State update failed.");
      setUpdateCardId(null);
    }
  };

  const moveKanbanScroll = ({ clientX }) => {
    const { left } = kanbanBodyRef.current.getBoundingClientRect();
    const leftDiff = clientX - left - 225;
    if (leftDiff < 0 && !leftMover) {
      setLeftMover(true);
      return;
    }

    if (leftDiff > 0 && leftMover) {
      return setLeftMover(false);
    }

    // Sağa doğru bir hareket var mı?
    const rightDiff = kanbanBodyRef.current.offsetWidth - clientX - 115;
    if (rightDiff < 0 && !rightMover) {
      setRightMover(true);
    } else if (rightDiff > 0 && rightMover) {
      setRightMover(false);
    }
  };

  const isAllowed = (state, substateId = null) => {
    if (!drag) {
      return true;
    }
    if (drag.sourceColumnId === state && drag.sourceSubstateId !== substateId) {
      return true;
    }

    if (!MATCH_STATE_ALLOWANCE_LIST[state]) {
      return true;
    }

    return MATCH_STATE_ALLOWANCE_LIST[state].indexOf(drag.sourceColumnId) > -1;
  };

  const isOnHover = (column, substateId, substate) => {
    if (!drag) {
      return false;
    }

    if (
      column.customFlows.length === 0 &&
      !substateId &&
      drag.targetColumnId === column.id &&
      drag.targetColumnId !== drag.sourceColumnId
    ) {
      return true;
    }
    if (
      column.customFlows.length === 0 &&
      substateId &&
      drag.targetColumnId === column.id &&
      drag.targetSubstateId === substateId
    ) {
      return true;
    }

    if (
      column.customFlows.length > 0 &&
      drag.targetColumnId === column.id &&
      drag.targetColumnId === column.id &&
      drag.targetSubstateId === substate?.uuid
    ) {
      return true;
    }
  };

  const handleCardClick = ({ id, column, substate }) => {
    setDrawerState({
      ...drawerState,
      matchId: id,
      visible: true,
      column,
      substate,
    });
    query.set("drawerId", id);
    history.push({ search: query.toString() });
  };

  const onClose = async () => {
    setDrawerState({
      ...drawerState,
      matchId: null,
      visible: false,
    });
    query.delete("drawerId");
    query.delete("drawerTab");
    history.push({ search: query.toString() });
  };

  const handleDragChange = debounce((changes) => {
    // Bizim kutucuklarımızdan başka bir şey seçilim sürüklenirse işlem yapmayız.
    if (!drag) {
      return;
    }
    moveKanbanScroll(changes);

    if (
      drag.targetColumnId !== changes.targetColumnId ||
      drag.targetSubstateId !== changes.targetSubstateId
    ) {
      setDrag({
        ...drag,
        ...changes,
      });
    }
  }, 10);

  const handleSearchChange = async (search) => {
    const newFilters = {
      ...filters,
      search,
    };
    setSearching(true);
    setFilters(newFilters);
    query.set("search", search);
    history.push({ search: query.toString() });
    const result = await getStatesWithMatches(newFilters);
    setData(result.data.getStatesWithMatches);
    setSearching(false);
  };

  const handleActiveSortChange = async (sort) => {
    const newFilters = {
      ...filters,
      sort,
    };
    setFilters(newFilters);
    query.set("sort", sort);
    history.push({ search: query.toString() });
    const result = await getStatesWithMatches(newFilters);
    setData(result.data.getStatesWithMatches);
  };

  const setStateData = (state, substate = null, value) => {
    const newState = data.map((item) => {
      if (item.state === state && item.substate === substate) {
        return {
          ...item,
          ...value,
        };
      }
      return item;
    });
    setData(newState);
  };

  const handleLoadMore = ({ columnId, substate }) => {
    let sub = substate;
    let currentStateData = [];
    triggerOnce(async () => {
      if (
        columnId === MATCH_STATES.SENT ||
        columnId === MATCH_STATES.INTERESTED ||
        columnId === MATCH_STATES.TG_ASSESSMENT
      ) {
        currentStateData = data.filter((item) => item.state === columnId);

        const count = currentStateData.reduce(function (accumulator, curValue) {
          return accumulator + curValue.count;
        }, 0);

        const filteredData = currentStateData.map((i) => {
          return { limit: i.limit, isEnded: i.isEnded, substate: i.substate };
        });

        currentStateData.count = count;
        currentStateData.limit =
          filteredData.find((i) => !i.isEnded)?.limit || DEFAULT_LIMIT;
        currentStateData.isEnded =
          filteredData.filter((i) => !i.isEnded)?.length === 0;
        sub = filteredData.find((i) => !i.isEnded)?.substate;
      } else {
        currentStateData = data.find(
          (item) => item.state === columnId && item.substate === substate
        );
      }

      if (
        !currentStateData ||
        currentStateData.isLoading ||
        currentStateData.isEnded
      ) {
        return;
      }

      setStateData(columnId, sub, {
        isLoading: true,
      });

      const result = await getStatesWithMatches({
        states: [
          {
            state: columnId,
            substate: sub,
            limit: currentStateData.limit + DEFAULT_LIMIT,
          },
        ],
        search: filters.search,
        sort: filters.sort,
      });

      const [stateResult] = result?.data?.getStatesWithMatches || [null];
      setStateData(columnId, sub, {
        matches: stateResult?.matches || [],
        isLoading: false,
        isEnded: stateResult.count < stateResult.limit,
        limit: stateResult.limit,
      });
    }, 0);
  };

  const handleFailModalClose = () => {
    setIsModalOpen({
      isOpen: false,
      data: null,
    });
  };

  const handleFailModalSuccess = async (changeState) => {
    // Modal'ı kapat
    setIsModalOpen({
      isOpen: false,
      data: null,
    });
    if (changeState.type === MATCH_NOT_A_GOOD_FIT) {
      query.set("drawerTab", "conversation");
      history.push({ search: query.toString() });
      setNotAGoodFitState({ isClicked: true });
      return;
    }
    // Drawer açık olabilir, onu kapatmamız lazım
    setDrawerState({
      matchId: null,
      visible: false,
    });
    // Taşınan card'ı yeni sütuna ekle
    let newState = [...data];
    newState = moveCardInState({
      ...changeState,
      newState,
    });
    setData(newState);
  };

  const handleStatusChangeModalClose = function () {
    setStatusChangeModal({
      isOpen: false,
      user: null,
      status: null,
    });
  };

  const openFailedModal = (data) => {
    setIsModalOpen({
      isOpen: true,
      data,
    });
  };
  const handleRemoveSubcolumn = async ({ column, substate }) => {
    try {
      await removeCustomFlow({
        variables: {
          positionId: position.id,
          state: column.id,
          substate: substate.uuid,
        },
      });
      const newState = data.filter(
        (i) => i.state !== column.id || i.substate !== substate.uuid
      );

      const deletableColumn = data.find(
        (i) => i.state === column.id && i.substate === substate.uuid
      );

      // Sadece hiç sütun kalmadığında ana state'in matchlerini almamız lazım
      if (column.customFlows.length === 1) {
        newState.push(
          await getSelectedMatchesByStates(
            column.id,
            null,
            deletableColumn?.limit
          )
        );
      }
      setData(newState);
      refetch();
    } catch (error) {
      message.error(error.message);
      captureErrorWithData(error, {
        positionId: position.id,
        column,
        substate,
      });
    }
  };
  const handleDelete = ({ column, substate, substateIndex }) => {
    const deleteParameters = getDeleteParameters(column, substateIndex);
    if (deleteParameters.disabled) {
      return;
    }
    question(
      {
        title: (
          <>
            Do you want to delete the &quot;{substate.title}&quot; interview
            step?
          </>
        ),
        content: deleteParameters.content,
        acceptButton: { variant: "danger is-blocked", text: "Delete" },
        cancelButton: { variant: "outline-primary is-blocked", text: "Cancel" },
        buttonStyle: {
          display: "flex",
          gap: "15px",
          width: "100%",
          flexDirection: isMobile() && "column",
        },
      },
      () => handleRemoveSubcolumn({ column, substate })
    );
  };
  const getMatchCountByStates = (state, substate) => {
    data.forEach((item) => {
      const filteredMatches = item.matches.filter(
        (i) => i.user.status !== DEVELOPER_STATUSES.PRE_LEAD
      );
      const preLeadMatches = item.matches.filter(
        (i) => i.user.status === DEVELOPER_STATUSES.PRE_LEAD
      );
      item.matches = filteredMatches;
      item.count -= preLeadMatches.length;
    });
    if (substate === undefined) {
      const columns = data.filter((i) => i.state === state);
      return columns.reduce((previous, current) => {
        return previous + (current.count || 0);
      }, 0);
    }
    const column = data.find(
      (i) => i.state === state && i.substate === substate
    );
    return column?.count || 0;
  };

  const getTrashSubstate = (drag) => {
    const state = getTrashState(drag);
    if (customFlows[state] && customFlows[state].length > 0) {
      const [firstItem] = [...customFlows[state]].sort(
        (a, b) => a.index - b.index
      );
      return firstItem?.uuid;
    }
    return null;
  };

  const getSelectedMatchesByStates = async (
    state,
    substate = null,
    limit = DEFAULT_LIMIT
  ) => {
    const response = await getStatesWithMatches({
      search: filters.search,
      sort: filters.sort,
      states: [
        {
          state,
          substate,
          limit,
        },
      ],
    });
    return (
      (response?.data?.getStatesWithMatches &&
        response?.data?.getStatesWithMatches[0]) ||
      []
    );
  };

  const handleAddSubcolumn = async ({ column, value }) => {
    try {
      const state = column.id;
      const result = await addCustomFlow({
        variables: {
          positionId: position.id,
          title: value.title,
          state,
        },
      });

      const customFlows =
        result?.data?.addCustomFlow?.customFlows[column.id] || [];
      const substate = customFlows[customFlows.length - 1]?.uuid;

      let newState = [...data];

      // Sadece ilk sütun eklendiğinde ilk sütundaki matchleri almamız lazım
      if (customFlows.length === 1) {
        newState = newState.filter(
          (i) => !(i.state === state && i.substate === null)
        );
        const mainState = data.find((i) => i.state === state);
        newState.push(
          await getSelectedMatchesByStates(state, substate, mainState?.limit)
        );
      } else {
        // Yeni bir sütunsa sadece boş bir sütun oluşturmamız lazım.
        newState.push({
          state,
          substate,
          matches: [],
          count: 0,
          limit: DEFAULT_LIMIT,
        });
      }
      handleOnSaveCustomFlowChanges();
      setData(newState);
    } catch (error) {
      message.error(error.message);
      captureErrorWithData(error, {
        positionId: position.id,
        column,
      });
    }
  };

  const handleOpenFlowSettings = ({ column, substate }) => {
    setEditCustomFlow({
      column,
      substate,
    });
  };

  const handleOnSaveCustomFlowChanges = async () => {
    setEditCustomFlow(null);
    refetch();
  };

  const VISIBLE_COLUMNS = getVisibleColumns(customFlows);
  VISIBLE_COLUMNS.forEach((item) => {
    item.data = data.find(
      (i) => i.state === item.state && i.substate === item.substate
    );
  });

  columns.forEach((column) => {
    column.customFlows = customFlows[column.id] || [];
  });

  const getDeleteParameters = (column, index) => {
    const counts = column.customFlows.map(
      (i) => i.uuid && getMatchCountByStates(column.state, i.uuid)
    );
    if (counts[index] === 0) {
      return {
        message: "Delete interview step",
        disabled: false,
        content: null,
      };
    }
    if (column.customFlows.length === 1 && counts[index] > 0) {
      return {
        message: "Delete interview step",
        disabled: false,
        content:
          "As this is the only interview stage, matches will be back on the parent interview stage once you delete it.",
      };
    }
    if (column.customFlows.length > 1 && counts[index] > 0) {
      return {
        message: "You cannot delete an interview step that contains matches.",
        disabled: true,
        content: null,
      };
    }
  };

  return (
    <>
      {statusChangeModal?.isOpen && (
        <StatusChangeModal
          onClose={handleStatusChangeModalClose}
          onAccept={() => handleAcceptChange(dragState)}
          status={statusChangeModal.status}
          user={statusChangeModal.user}
        />
      )}
      {drawerState.visible && (
        <StyledDrawer onClose={onClose} width="50%" showAnimation>
          <MatchDetailDrawer
            closeDrawer={onClose}
            matchId={drawerState.matchId}
            handleAcceptChange={handleAcceptChange}
            setUpdateCardId={setUpdateCardId}
            openFailedModal={openFailedModal}
            position={position}
            columns={flatColumns}
            notAGoodFitState={notAGoodFitState}
            setNotAGoodFitState={setNotAGoodFitState}
          />
        </StyledDrawer>
      )}
      {isModalOpen.isOpen && (
        <FailReasonsModal
          open={isModalOpen}
          handleClose={handleFailModalClose}
          data={isModalOpen.data}
          onCompleted={handleFailModalSuccess}
        />
      )}
      {isMoveNotAllowedModalOpen && (
        <MoveNotAllowedModal
          open={isMoveNotAllowedModalOpen}
          handleClose={() => setIsMoveNotAllowedModalOpen(false)}
        />
      )}
      {editCustomFlow && (
        <CustomFlowEditModal
          positionId={position.id}
          data={editCustomFlow}
          onCancel={() => setEditCustomFlow(null)}
          onSave={handleOnSaveCustomFlowChanges}
          handleAddSubcolumn={handleAddSubcolumn}
        />
      )}
      <TgKanbanContainer refx={containerRef}>
        <TgKanbanHeader>
          <SearchInput
            type="text"
            placeholder="Search by names"
            onLazyChange={handleSearchChange}
            isSearching={isSearching}
            defaultValue={filters.search}
          />
          <SortButton
            style={{ marginRight: "10px" }}
            menus={sortFields}
            active={filters.sort}
            handleChange={handleActiveSortChange}
          />
        </TgKanbanHeader>
        <TgKanbanBody ref={kanbanBodyRef}>
          <ColumnBlock>
            <TgKanbanTitle />
            <RowBlock>
              {VISIBLE_COLUMNS.map((column, index) => (
                <TgKanbanColumn
                  key={index}
                  columnId={column.state}
                  handleDragChange={handleDragChange}
                  onDrop={onDrop}
                  isOnHover={isOnHover(column, column.substate)}
                  isAllowed={isAllowed(column.state, column.substate)}
                  borderColor={column.borderColor}
                  awaitingActionState={awaitingActionState}
                  setAwaitingActionState={setAwaitingActionState}
                  hasSubState={column.customFlows.length > 0}
                  substate={column.substate}
                >
                  <TgKanbanColumnHeader
                    key={column.title}
                    title={column.title}
                    hasSubState={column.customFlows.length > 0}
                    count={getMatchCountByStates(column.state)}
                    menus={
                      <>
                        <MenuAction
                          icon={<AddMoreIcon />}
                          onClick={() =>
                            handleOpenFlowSettings({
                              column,
                              substate: { title: null },
                            })
                          }
                        />
                      </>
                    }
                    isSubstate={false}
                    state={column.state}
                  />

                  <TgKanbanColumnBody
                    isOnHover={isOnHover(column, column.substate)}
                    columnId={column.state}
                    substate={column.substate}
                    onLoadMore={handleLoadMore}
                    hasSubState={column.customFlows.length > 0}
                  >
                    {column.customFlows.length > 0 && (
                      <TgKanbanSubColumnContainer>
                        {column.customFlows.map((substate, substateIndex) => (
                          <TgKanbanSubColumn
                            key={substateIndex}
                            columnId={column.id}
                            substate={substate.uuid}
                            handleDragChange={handleDragChange}
                            onDrop={onDrop}
                            isOnHover={isOnHover(
                              column,
                              column.substate,
                              substate
                            )}
                            isAllowed={isAllowed(
                              column.state,
                              column.substate,
                              substate
                            )}
                            borderColor={column.borderColor}
                            hasSubState={false}
                          >
                            <TgKanbanColumnHeader
                              title={substate.title}
                              isSubstate
                              count={getMatchCountByStates(
                                column.state,
                                substate.uuid
                              )}
                              menus={
                                <>
                                  <MenuAction
                                    icon={
                                      <Tooltip text="Rename interview step">
                                        <EditIcon color="#64B7DC" size={14} />
                                      </Tooltip>
                                    }
                                    onClick={() =>
                                      handleOpenFlowSettings({
                                        column,
                                        substate,
                                      })
                                    }
                                    hoverEffect
                                    column={column}
                                    substate={substate}
                                  />
                                  <MenuAction
                                    icon={
                                      <Tooltip
                                        text={
                                          getDeleteParameters(
                                            column,
                                            substateIndex
                                          )?.message
                                        }
                                      >
                                        <TrashIcon
                                          color={
                                            getDeleteParameters(
                                              column,
                                              substateIndex
                                            )?.disabled
                                              ? "gray"
                                              : "#64B7DC"
                                          }
                                          size={15}
                                        />
                                      </Tooltip>
                                    }
                                    onClick={() =>
                                      handleDelete({
                                        column,
                                        substate,
                                        substateIndex,
                                      })
                                    }
                                    hoverEffect
                                  />
                                </>
                              }
                            />
                            <TgKanbanColumnBody
                              isOnHover={isOnHover(column, column.substate)}
                              columnId={column.id}
                              onLoadMore={() =>
                                handleLoadMore({ column, substate })
                              }
                              hasSubState={column.customFlows.length > 0}
                            >
                              {getMatchesByStates(
                                data,
                                column.state,
                                substate.uuid
                              ).map((match, index) => (
                                <TgKanbanColumnCard
                                  key={match.id}
                                  cardId={match.id}
                                  columnId={column.id}
                                  substate={match.substate}
                                  setDrag={setDrag}
                                  onClick={() =>
                                    handleCardClick({
                                      id: match.id,
                                      column,
                                      substate,
                                      currentIndex: index,
                                    })
                                  }
                                  updateCardId={updateCardId}
                                  draggable={
                                    !CLOSED_POSITION_STATES.includes(
                                      position.state
                                    )
                                  }
                                >
                                  <MatchPreview match={match} />
                                </TgKanbanColumnCard>
                              ))}
                              <TgKanbanColumnFooter
                                isLoading={
                                  getStateData(data, column.id, substate.uuid)
                                    ?.isLoading
                                }
                                isEnded={
                                  getStateData(data, column.id, substate.uuid)
                                    ?.isEnded
                                }
                                count={
                                  getStateData(data, column.id, substate.uuid)
                                    ?.count
                                }
                              />
                            </TgKanbanColumnBody>
                          </TgKanbanSubColumn>
                        ))}
                      </TgKanbanSubColumnContainer>
                    )}
                    {column.customFlows.length === 0 && (
                      <>
                        {column.state === MATCH_STATES.SENT && (
                          <DropdownInfo
                            title="Why can't I see the candidates' details?"
                            info="We are mindful of our candidates' privacy so we hide sensitive candidate information until the they declare interest for the position."
                          />
                        )}
                        {column.state === MATCH_STATES.INTERESTED && (
                          <DropdownInfo
                            title="Why can’t I move the candidates?"
                            info="This section is here to show you the candidate details. You will be able to move candidates as they pass to  <b>Verified Matches</b> section."
                          />
                        )}
                        {column.state === MATCH_STATES.TG_ASSESSMENT &&
                          position?.interviewedByTgCount > 0 && (
                            <>
                              <InterviewTimeContainer>
                                <TimeSpentIcon />
                                <InterviewTime>
                                  Total time we save for you:{" "}
                                  <b>
                                    {position.interviewedByTgCount * 0.5} hours
                                  </b>
                                </InterviewTime>
                              </InterviewTimeContainer>

                              <DropdownInfo
                                title="How does the candidate assessment work?"
                                info="We have pre-screening calls with the candidates so you don't have to! TG recruiters share information on your company and role, and discuss the candidate's experiences and motivation. This will give you a better idea of the person behind the CV!"
                              />
                            </>
                          )}

                        {column.state === MATCH_STATES.COMPANY_ASSESSMENT && (
                          <DropdownInfo
                            title="Who are these candidates?"
                            info="These are the candidates that showed interest for the position. They also passed the TG Talent Partner reviews for their integrity and suitability as required by the position."
                          />
                        )}

                        {getMatchesByStates(
                          data,
                          column.state,
                          column.substate
                        ).map((match, index) => (
                          <TgKanbanColumnCard
                            key={match.id}
                            cardId={match.id}
                            columnId={column.id}
                            substate={column.substate}
                            setDrag={setDrag}
                            onClick={() =>
                              handleCardClick({
                                id: match.id,
                                column,
                                currentIndex: index,
                              })
                            }
                            updateCardId={updateCardId}
                            draggable={
                              !CLOSED_POSITION_STATES.includes(position.state)
                            }
                          >
                            <MatchPreview match={match} />
                          </TgKanbanColumnCard>
                        ))}
                      </>
                    )}
                    <TgKanbanColumnFooter
                      isLoading={
                        getStateData(data, column.state, column.substate)
                          ?.isLoading
                      }
                      isEnded={
                        getStateData(data, column.state, column.substate)
                          ?.isEnded
                      }
                      count={
                        getStateData(data, column.state, column.substate)?.count
                      }
                    />
                  </TgKanbanColumnBody>
                </TgKanbanColumn>
              ))}
            </RowBlock>
          </ColumnBlock>
        </TgKanbanBody>
        {isTrashActive(drag) && (
          <TgKanbanTrash
            columnId={getTrashState(drag)}
            substate={getTrashSubstate(drag)}
            handleDragChange={handleDragChange}
            onDrop={onDrop}
            isOnHover={
              drag &&
              drag.targetColumnId === getTrashState(drag) &&
              drag.targetColumnId !== drag.sourceColumnId
            }
          >
            Disqualified
          </TgKanbanTrash>
        )}
      </TgKanbanContainer>
    </>
  );
}

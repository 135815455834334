import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import styled, { keyframes, css } from "styled-components";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import {
  Timeline,
  TimelineItem,
  Avatar,
  RichTextEditor,
  Button,
  HorizantalAlignRightIcon,
  ThumbDownIcon,
  ReplyIcon,
  toRichTextState,
  richTextToMarkdown,
  message,
} from "tg-design";
import AppContext from "../../../../../../../contexts/AppContext";
import {
  ADD_CONVERSATION,
  UPDATE_CONVERSATION,
  DELETE_CONVERSATION,
  ADD_ACTION_TO_CONVERSATION,
  GET_USER_CONVERSATION,
  MAKE_CONVERSATIONS_SEEN,
  ADD_ACTIVITY,
} from "../../../../../../../queries/match";
import { captureErrorWithData } from "../../../../../../../utils/helper";
import PartialError from "../../../../../../ErrorPage/PartialError";
import ConversationItem from "./ConversationItem";
import HistoryItem from "./HistoryItem";
import {
  CONVERSATION_TYPES,
  MATCH_NOT_A_GOOD_FIT,
  MATCH_STATES,
  USER_TYPES,
  MATCH_ACTIVITY_TYPES,
} from "../../../../../../../utils/constants";

const CommentContainer = styled.div`
  position: relative;
`;

const scrollAnimation = keyframes`
  from {
    background: #FDEEC0;
  }
  to {
    background: #f9fafb;
  }
`;

const ConversationItemContainer = styled.div`
  & > div > div:last-child > div {
    animation: ${({ isSelected }) =>
      isSelected &&
      css`
        ${scrollAnimation} 4s linear 1
      `};
  }
`;

const HISTORY_TYPES = {
  CONVERSATION: "CONVERSATION",
  HISTORY: "HISTORY",
};

const ALLOWED_HISTORY_STATES = [
  MATCH_STATES.COMPANY_ASSESSMENT,
  MATCH_STATES.INTERVIEW,
  MATCH_STATES.SENT_OFFER,
  MATCH_STATES.HIRED,
  MATCH_STATES.PASSED,
  MATCH_STATES.FAILED,
];

const sorter = (a, b) => {
  return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1;
};

const getHistoryIcon = (history) => {
  if (history.current === MATCH_STATES.FAILED) {
    return <ThumbDownIcon />;
  }

  if (
    history.previous === MATCH_STATES.FAILED &&
    history.current !== MATCH_STATES.FAILED
  ) {
    return <ReplyIcon />;
  }

  return <HorizantalAlignRightIcon />;
};

const toMixedHistory = ({ conversation, history }) => {
  const newConversation = [...conversation];
  newConversation.sort(sorter);
  const items = [
    ...newConversation.map((item) => {
      return {
        ...item,
        itemType: HISTORY_TYPES.CONVERSATION,
      };
    }),
    ...history
      .filter((i) => ALLOWED_HISTORY_STATES.includes(i.current))
      .map((item) => {
        return {
          ...item,
          itemType: HISTORY_TYPES.HISTORY,
          icon: getHistoryIcon(item),
        };
      }),
  ];
  return items.sort(sorter);
};

export default function ConversationTab({
  match,
  onSeenAll,
  notAGoodFitState,
  setNotAGoodFitState,
}) {
  const appContext = useContext(AppContext);
  const employer = {
    id: appContext.employer.id,
    name: appContext.employer.fullName,
    avatarURL: null,
    role: "Employer",
  };
  const [state, setState] = useState({
    isLoading: true,
    note: toRichTextState(""),
    conversation: [],
    history: [],
  });
  const {
    error,
    refetch,
    loading,
    data: conversationData,
  } = useQuery(GET_USER_CONVERSATION, {
    variables: {
      id: match.id,
    },
  });

  const [addConversation] = useMutation(ADD_CONVERSATION);
  const [updateConversation] = useMutation(UPDATE_CONVERSATION);
  const [deleteConversation] = useMutation(DELETE_CONVERSATION, {
    refetchQueries: [
      {
        query: GET_USER_CONVERSATION,
        variables: { id: match.id },
      },
    ],
  });
  const [addActionToConversation] = useMutation(ADD_ACTION_TO_CONVERSATION);
  const [makeConversationsSeen] = useMutation(MAKE_CONVERSATIONS_SEEN);
  const [addActivity] = useMutation(ADD_ACTIVITY);

  const notAGoodFitRef = useRef(null);

  const refreshConversations = async () => {
    const result = await refetch({
      id: match.id,
    });
    setState({
      ...state,
      conversation: result?.data?.match?.conversation,
      history: result?.data?.match?.history,
      isLoading: false,
    });
  };

  const sendConversationSeen = async ({ conversationIds }) => {
    try {
      onSeenAll();
      await makeConversationsSeen({
        variables: {
          matchId: match.id,
          conversationIds,
        },
      });
    } catch (err) {
      captureErrorWithData(err);
      message.error("An error occurred");
    }
  };

  useEffect(() => {
    if (conversationData?.match) {
      setState({
        ...state,
        conversation: conversationData?.match?.conversation,
        history: conversationData?.match?.history,
        isLoading: false,
      });
    }
  }, [conversationData]);

  useEffect(() => {
    addActivity({
      variables: {
        matchId: match.id,
        type: MATCH_ACTIVITY_TYPES.EMPLOYER_SEEN_CONVERSATION_TAB,
      },
    });
  }, []);

  useEffect(() => {
    const unseenIds = state.conversation
      .filter((i) => i.unseen)
      .map((i) => i.id);
    if (unseenIds.length > 0) {
      sendConversationSeen({ conversationIds: unseenIds });
    }
  }, [state.conversation]);

  useLayoutEffect(() => {
    if (
      !conversationData?.match?.conversation?.some(
        (c) => c.type === MATCH_NOT_A_GOOD_FIT
      ) ||
      !notAGoodFitState.isClicked
    ) {
      return;
    }
    setTimeout(() => {
      notAGoodFitRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 0);
  }, [notAGoodFitState, conversationData, loading, state.isLoading]);

  if (state.isLoading || loading) return "loading";
  if (error) return <PartialError />;

  const CREATOR = {
    avatarURL: employer.avatarURL,
    name: employer.name,
    id: employer.id,
  };

  const handleSubmit = async () => {
    try {
      const note = richTextToMarkdown(state.note);
      const newState = JSON.parse(JSON.stringify(state.conversation));
      newState.push({
        creatorType: USER_TYPES.RECRUITER,
        creator: CREATOR,
        createdAt: new Date(),
        note,
        actions: [],
      });
      setState({ ...state, conversation: newState, note: toRichTextState("") });
      const result = await addConversation({
        variables: {
          matchId: match.id,
          note,
        },
      });
      setState({
        ...state,
        conversation: result.data.addConversation.conversation,
        note: toRichTextState(""),
      });
    } catch (err) {
      captureErrorWithData(err);
      message.error("An error occurred");
    }
  };

  const handleDelete = async ({ conversation }) => {
    try {
      await deleteConversation({
        variables: {
          matchId: match.id,
          conversationId: conversation.id,
        },
      });
      refreshConversations();
    } catch (err) {
      refreshConversations();
      captureErrorWithData(err);
      message.error("An error occurred");
    }
  };

  const handleEdit = ({ conversation }) => {
    setState({
      ...state,
      conversation: state.conversation.map((item) => {
        if (item.id === conversation.id) {
          return {
            ...item,
            isEditing: true,
          };
        }
        return item;
      }),
    });
  };

  const cancelEditing = ({ conversation }) => {
    setState({
      ...state,
      conversation: state.conversation.map((item) => {
        if (item.id === conversation.id) {
          return {
            ...item,
            isEditing: false,
          };
        }
        return item;
      }),
    });
  };

  const updateNote = async ({ conversation, editValue }) => {
    setState({
      ...state,
      conversation: state.conversation.map((item) => {
        if (item.id === conversation.id) {
          return {
            ...item,
            isEditing: false,
            note: editValue,
          };
        }
        return item;
      }),
    });
    try {
      await updateConversation({
        variables: {
          matchId: match.id,
          conversationId: conversation.id,
          note: editValue,
        },
      });
    } catch (err) {
      captureErrorWithData(err);
      message.error("An error occurred");
    }
  };

  const handleEmojiSelect = async ({ unicode, conversation }) => {
    const newState = JSON.parse(JSON.stringify(state.conversation));
    const currentConversation = newState.find((i) => i.id === conversation.id);
    const found = currentConversation.actions.find(
      (i) => i.unicode === unicode && i.creator.id === employer.id
    );
    if (found) {
      currentConversation.actions = currentConversation.actions.filter(
        (i) => i.unicode !== unicode || i.creator.id !== employer.id
      );
    } else {
      currentConversation.actions.push({
        unicode,
        creatorType: USER_TYPES.RECRUITER,
        creator: CREATOR,
      });
    }
    setState({
      ...state,
      conversation: newState,
    });
    try {
      await addActionToConversation({
        variables: {
          matchId: match.id,
          conversationId: conversation.id,
          unicode,
        },
      });
    } catch (err) {
      captureErrorWithData(err);
      message.error("An error occurred!");
    }
  };

  const MIXED_HISTORY = toMixedHistory(state);

  return (
    <Timeline>
      {MIXED_HISTORY.map((item, index) => (
        <ConversationItemContainer
          ref={
            item.type === CONVERSATION_TYPES.NOT_A_GOOD_FIT
              ? notAGoodFitRef
              : null
          }
          isSelected={
            item.type === CONVERSATION_TYPES.NOT_A_GOOD_FIT &&
            notAGoodFitState.isClicked
          }
          onAnimationEnd={() => {
            setNotAGoodFitState({ isClicked: false });
          }}
          key={index}
        >
          {item.itemType === HISTORY_TYPES.CONVERSATION && (
            <ConversationItem
              currentUserId={employer.id}
              conversation={item}
              handleEmojiSelect={handleEmojiSelect}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              cancelEditing={cancelEditing}
              updateNote={updateNote}
              match={match}
            />
          )}
          {item.itemType === HISTORY_TYPES.HISTORY && (
            <HistoryItem history={item} match={match} />
          )}
        </ConversationItemContainer>
      ))}
      <TimelineItem icon={<Avatar name={employer?.name} />}>
        <CommentContainer>
          <RichTextEditor
            state={state.note}
            onChange={(note) => setState({ ...state, note })}
          />
          <Button
            style={{ marginTop: "10px" }}
            onClick={handleSubmit}
            disabled={
              (richTextToMarkdown(state.note) || "").trim().length === 0
            }
          >
            Comment
          </Button>
        </CommentContainer>
      </TimelineItem>
    </Timeline>
  );
}

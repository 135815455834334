import React from "react";
import {
  InterviewNotesIcon,
  EditIcon,
} from "../../../../../../../components/Icon";

import Avatar from "../../../../../../../components/Avatar";

import {
  AvatarContainer,
  OwnerInformation,
  NoteHeaderContainer,
  NameContainer,
  RoleContainer,
  InterviewNotesContainer,
  IconContainer,
  Container,
  InterviewNotesHeader,
  SimpleIconContainer,
  InterviewNotes,
  ResponsiveView,
  ResponsiveInterviewNotes,
  ResponsiveInterviewNotesContainer,
  Description,
} from "./styles";

export const NoteHeader = ({
  name,
  avatarURL,
  role,
  companyName,
  avatarSize,
}) => {
  return (
    <NoteHeaderContainer>
      <AvatarContainer>
        <Avatar url={avatarURL || null} size={avatarSize} />
      </AvatarContainer>

      <OwnerInformation>
        <NameContainer>{name || "Missing Name"}</NameContainer>
        {role && <RoleContainer>{`${role}, ${companyName}`}</RoleContainer>}
      </OwnerInformation>
    </NoteHeaderContainer>
  );
};

export default function NoteSection({
  note,
  companyName,
  handleEdit,
  matchedUserName,
  position,
}) {
  return (
    <>
      <Description>
        A pre-assessment interview with <b>{matchedUserName}</b> is done by:
      </Description>
      <NoteHeader
        name={position?.assignee?.name}
        avatarURL={position?.assignee?.avatarURL}
        role={note.creator.role}
        companyName={companyName}
        avatarSize="50px"
      />
      <InterviewNotesContainer>
        <IconContainer>
          <InterviewNotesIcon />
        </IconContainer>
        <Container>
          <InterviewNotesHeader>
            <div>Notes from the interview:</div>

            {note.creatorType === 2 && (
              <SimpleIconContainer size="25px" onClick={handleEdit}>
                <EditIcon />
              </SimpleIconContainer>
            )}
          </InterviewNotesHeader>
          <InterviewNotes
            className="richText"
            dangerouslySetInnerHTML={{
              __html: note.note || "-",
            }}
          />
        </Container>
      </InterviewNotesContainer>
      <ResponsiveView>
        <ResponsiveInterviewNotesContainer>
          <IconContainer>
            <InterviewNotesIcon />
          </IconContainer>
          <InterviewNotesHeader>Notes from the interview:</InterviewNotesHeader>
        </ResponsiveInterviewNotesContainer>
        <ResponsiveInterviewNotes
          className="richText"
          dangerouslySetInnerHTML={{
            __html: note.note || "-",
          }}
        />
      </ResponsiveView>
    </>
  );
}

import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";

import { BoxTitle, BoxDescription, Row } from "./styles";
import SkillView from "./SkillView";
import OtherSkillsView from "./OtherSkillView";
import ReadMore from "../../../../../../../components/ReadMore";
import { ADD_ACTIVITY } from "../../../../../../../queries/match";
import {
  MATCH_STATES,
  MATCH_ACTIVITY_TYPES,
} from "../../../../../../../utils/constants";

export default function ProfileTab({ match }) {
  const [addActivity] = useMutation(ADD_ACTIVITY);
  const location = useLocation();
  const isPrintPage = location.pathname.includes("match-print");

  useEffect(() => {
    addActivity({
      variables: {
        matchId: match.id,
        type: MATCH_ACTIVITY_TYPES.EMPLOYER_SEEN_PROFILE_INFO_TAB,
      },
    });
  }, []);

  return (
    <>
      {match.user.bio && (
        <Row>
          <BoxTitle>About</BoxTitle>
          <BoxDescription>
            <ReadMore text={match.user.bio} limit={isPrintPage ? 9999 : 250} />
          </BoxDescription>
        </Row>
      )}

      {match?.state !== MATCH_STATES.SENT &&
        match?.state !== MATCH_STATES.INTERESTED &&
        match?.state !== MATCH_STATES.TG_ASSESSMENT && (
          <>
            <BoxTitle>Contact addresses</BoxTitle>

            {match?.user?.email && (
              <Row mt={1}>
                <BoxDescription>
                  <b>E-mail: </b>
                  {match.user.email}
                </BoxDescription>
              </Row>
            )}
            {match?.user?.phone && (
              <Row>
                <BoxDescription>
                  <b>Phone: </b>
                  {match.user.phone}
                </BoxDescription>
              </Row>
            )}

            {match?.user?.resume?.resumeURL && (
              <Row mt={3}>
                <BoxTitle>Resume</BoxTitle>
                <BoxDescription>
                  <Link
                    to={{ pathname: match?.user?.resume?.resumeURL }}
                    target="_blank"
                    rel="noopener"
                  >
                    {match?.user?.resume?.fileName || "No name"}
                  </Link>
                </BoxDescription>
              </Row>
            )}
          </>
        )}

      {match?.user?.resume?.resumeURL && !isPrintPage && (
        <Row mt={3}>
          <BoxTitle>Resume</BoxTitle>
          <BoxDescription>
            <Link
              to={{ pathname: match?.user?.resume?.resumeURL }}
              target="_blank"
              rel="noopener"
            >
              {match?.user?.resume?.fileName || "No name"}
            </Link>
          </BoxDescription>
        </Row>
      )}
      <Row mt={3}>
        <BoxTitle>Professional software development experience</BoxTitle>
        {match.user.experience === 1 && (
          <BoxDescription>{match.user.experience} year</BoxDescription>
        )}
        {match.user.experience > 1 && match.user.experience < 10 && (
          <BoxDescription>{match.user.experience} years</BoxDescription>
        )}
        {match.user.experience >= 11 && (
          <BoxDescription>{match.user.experience}+ years</BoxDescription>
        )}
      </Row>

      <Row mt={3}>
        <BoxTitle>Programming Languages & Frameworks</BoxTitle>
        <SkillView skills={match.user.skills} />
      </Row>
      {match &&
        match.user &&
        match.user.otherSkills &&
        match.user.otherSkills.length > 0 && (
          <Row mt={3}>
            <BoxTitle mb={3}>Other Skills</BoxTitle>
            <OtherSkillsView
              readMore={!isPrintPage}
              otherSkills={match.user.otherSkills}
            />
          </Row>
        )}
    </>
  );
}

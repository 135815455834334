import styled from "styled-components";

export default styled.td`
  padding: 16px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: ${(props) => props.weight || "500"};
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  text-align: ${(props) => props.align || "left"};
`;

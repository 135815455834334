import styled from "styled-components";

export const Description = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  margin-bottom: 20px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: ${({ size }) => size || "60px"};
  height: ${({ size }) => size || "60px"};
  background: #e5e5e5;
  border-radius: 5px;
`;

export const SimpleIconContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  float: right;
  justify-content: flex-end;
  align-items: center;
  width: ${({ size }) => size || "60px"};
  height: ${({ size }) => size || "60px"};
  background: ${({ background }) => (!background ? "none" : "#e5e5e5")};
  border-radius: 5px;
  cursor: pointer;
`;

export const Container = styled.div`
  margin-left: 20px;
  width: 100%;
`;

export const InterviewNotesContainer = styled.div`
  display: flex;
  margin-top: 40px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const InterviewNotes = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #9ca3af;
`;

export const ResponsiveInterviewNotes = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #9ca3af;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const InterviewNotesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-beetween;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;

export const ResponsiveInterviewNotesContainer = styled.div`
  display: none;
  margin-top: 40px;
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

export const ResponsiveView = styled.div``;

export const NoteTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 150px;
  gap: 10px;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 14px;
  background: ${({ background, active, color }) =>
    active ? color : background};
  border: 1px solid;
  border-color: ${({ background, active, color }) =>
    active ? background : color};
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: ${({ active }) => (active ? "0 1px 13px 2px #dddddd" : "none")};
`;

export const NoteTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
`;

export const AvatarContainer = styled.div`
  margin-right: 11px;
`;

export const NameContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;

export const RoleContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #9ca3af;
`;

export const NoteHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NoteTypeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  justify-content: flex-end;
`;

export const OwnerInformation = styled.div``;

import styled from "styled-components";

export default styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
  padding: ${(props) => (props.isSubstate ? "10px 10px 0px 10px" : "10px")};
  border-radius: 5px 5px 0px 0px;
  background: ${(props) => (props.background ? props.background : "none")};
  color: ${(props) => (props.color ? props.color : "#1A1A1A")};
`;

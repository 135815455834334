import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import TimeReports from "./TimeReports";
import HiringFunnelReports from "./HiringFunnelReports";
import NotInterestingReports from "./NotInterestingReports";
import { GET_POSITION_WITH_COMPANY_REPORTS } from "../../../../../queries/position";
import PartialError from "../../../../ErrorPage/PartialError";

const Container = styled.div`
  background: #f7f7f7;
  padding: 40px;
  display: flex;
  margin-top: -19px;
  margin-left: -10px;
  margin-right: -10px;

  @media only screen and (max-width: 768px) {
    display: block;
    padding: 15px;
  }
`;

const LeftSection = styled.div`
  width: 35%;
  padding-right: 30px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-right: 0px;
  }
`;

const RightSection = styled.div`
  width: 65%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Box = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 26px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export default function ReportsTab({ position }) {
  const { data, loading, error } = useQuery(GET_POSITION_WITH_COMPANY_REPORTS, {
    variables: { id: position.id },
    fetchPolicy: "no-cache",
  });

  if (loading) return "loading";
  if (error) return <PartialError />;
  return (
    <Container>
      <LeftSection>
        <Box>
          <TimeReports position={data.position} />
        </Box>
        <Box>
          <NotInterestingReports position={data.position} />
        </Box>
      </LeftSection>
      <RightSection>
        <Box>
          <HiringFunnelReports position={data.position} />
        </Box>
      </RightSection>
    </Container>
  );
}

import { gql } from "@apollo/client";

export const GET_MATCH = gql`
  query Match($id: ID) {
    match(id: $id) {
      id
      state
      substate
      createdAt
      conversation {
        id
        note
      }
      position {
        assignee {
          name
          avatarURL
        }
      }
      unseenConversationCount
      user {
        id
        name
        email
        phone
        experience
        birthyear
        avatarURL
        bio
        resume {
          fileName
          resumeURL
        }
        workHistory {
          company
          position
          startDate
          endDate
          description
          present
        }
        preferences {
          currency
        }
        role {
          id
          title
        }
        skills {
          _id
          title
          experience
          category
          categoryId
        }
        livingCity {
          city
          country
        }
        otherSkills {
          id
          title
          categoryId
        }
        education {
          type
          school {
            id
            name
          }
          branch {
            id
            label
          }
          startDate
          endDate
        }
        languages {
          language {
            id
            label
          }
          level
        }
        social {
          linkedin
          github
          stackoverflow
          youtube
          blog
          twitter
        }
      }
    }
  }
`;

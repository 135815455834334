/* eslint-disable import/prefer-default-export */
import * as Yup from "yup";
import { PRIVACY_POLICY_TYPES } from "./constants";

export const setPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().trim().min(6).max(100),
  passwordRetry: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null]),
});

export const localKvkkAcceptValidationSchema = Yup.object().shape({
  country: Yup.string()
    .oneOf([PRIVACY_POLICY_TYPES.GLOBAL, PRIVACY_POLICY_TYPES.TR])
    .required(),
  localKvkkAccept: Yup.bool().when("country", (country, schema) => {
    if (country && country === PRIVACY_POLICY_TYPES.TR) {
      return schema.required().oneOf([true]);
    }
    return schema;
  }),
});

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().trim().email().required(),
  password: Yup.string().trim().min(6).max(100).required(),
});

export const emailValidate = Yup.object().shape({
  email: Yup.string().trim().email().required(),
});

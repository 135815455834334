import { gql } from "@apollo/client";

export const GET_MATCH = gql`
  query Match($id: ID) {
    match(id: $id) {
      id
      state
      substate
      createdAt
      conversation {
        id
      }
      position {
        assignee {
          name
          avatarURL
        }
      }
      unseenConversationCount
      user {
        id
        name
        email
        phone
        experience
        birthyear
        avatarURL
        bio
        resume {
          fileName
          resumeURL
        }
        workHistory {
          company
          position
          startDate
          endDate
          description
          present
        }
        preferences {
          currency
          openToWork
        }
        role {
          id
          title
        }
        skills {
          _id
          title
          experience
          category
          categoryId
        }
        livingCity {
          city
          country
        }
        otherSkills {
          id
          title
          categoryId
        }
        education {
          type
          school {
            id
            name
          }
          branch {
            id
            label
          }
          startDate
          endDate
        }
        languages {
          language {
            id
            label
          }
          level
        }
        social {
          linkedin
          github
          stackoverflow
          youtube
          blog
          twitter
        }
      }
    }
  }
`;

export const GET_MATCH_NOTES = gql`
  query Match($id: ID) {
    match(id: $id) {
      id
      state
      conversation {
        id
        creatorType
        creator {
          id
          name
          avatarURL
          role
        }
        note
        type
        updatedAt
        createdAt
      }
    }
  }
`;

const MatchPreviewData = gql`
  fragment StateMatches on Match {
    id
    state
    substate
    user {
      id
      name
      status
      social {
        linkedin
      }
      role {
        id
        title
      }
    }
    unseenConversationCount
  }
`;

export const GET_STATE_WITH_MATCHES = gql`
  ${MatchPreviewData}
  query GetStatesWithMatches(
    $positionId: ID
    $states: [matchFilterByStateType]
    $search: String
    $sort: String
  ) {
    getStatesWithMatches(
      positionId: $positionId
      states: $states
      search: $search
      sort: $sort
    ) {
      state
      substate
      matches {
        ...StateMatches
      }
      count
      limit
    }
  }
`;

export const MATCH_STATE_CHANGE = gql`
  mutation MatchStateChange(
    $id: ID!
    $state: String!
    $substate: String
    $failReasons: FailReasonsInputType
    $otherReason: String
  ) {
    matchStateChange(
      id: $id
      state: $state
      substate: $substate
      failReasons: $failReasons
      otherReason: $otherReason
    ) {
      id
      state
      substate
    }
  }
`;

const CUSTOM_FLOW_FRAGMENT = gql`
  fragment CustomFlowFragment on CustomFlowType {
    uuid
    title
    index
    isVisibleToCompany
    isVisibleToDeveloper
  }
`;

export const POSITION_CUSTOM_FLOWS = gql`
  ${CUSTOM_FLOW_FRAGMENT}
  fragment CustomFlowsFragment on Position {
    customFlows {
      INTERVIEW {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      SENT_OFFER {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      HIRED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      PASSED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      FAILED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      CANCELED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
    }
  }
`;

export const ADD_CUSTOM_FLOW = gql`
  ${POSITION_CUSTOM_FLOWS}
  mutation addCustomFlow($positionId: ID!, $state: String!, $title: String) {
    addCustomFlow(positionId: $positionId, state: $state, title: $title) {
      id
      ... on Position {
        ...CustomFlowsFragment
      }
    }
  }
`;

export const REMOVE_CUSTOM_FLOW = gql`
  ${POSITION_CUSTOM_FLOWS}
  mutation removeCustomFlow(
    $positionId: ID!
    $state: String!
    $substate: String!
  ) {
    removeCustomFlow(
      positionId: $positionId
      state: $state
      substate: $substate
    ) {
      id
      ... on Position {
        ...CustomFlowsFragment
      }
    }
  }
`;

export const UPDATE_CUSTOM_FLOW = gql`
  mutation updateCustomFlow(
    $positionId: ID!
    $state: String!
    $substate: String!
    $title: String!
    $isVisibleToCompany: Boolean!
    $isVisibleToDeveloper: Boolean!
  ) {
    updateCustomFlow(
      positionId: $positionId
      state: $state
      substate: $substate
      title: $title
      isVisibleToCompany: $isVisibleToCompany
      isVisibleToDeveloper: $isVisibleToDeveloper
    ) {
      id
    }
  }
`;
export const UPDATE_MATCH = gql`
  mutation updateMatch(
    $id: ID!
    $interviewNotes: String
    $failReasons: FailReasonsInputType
  ) {
    updateMatch(
      id: $id
      interviewNotes: $interviewNotes
      failReasons: $failReasons
    ) {
      id
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNoteById(
    $match: ID!
    $noteId: ID!
    $note: String!
    $type: String
  ) {
    updateNoteById(match: $match, note: $note, type: $type, noteId: $noteId) {
      id
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation addNoteOnMatch($match: ID!, $note: String!, $type: String) {
    addNoteOnMatch(match: $match, note: $note, type: $type) {
      id
      note
      type
      creator {
        name
        avatarURL
        type
      }
      createdAt
    }
  }
`;

const CONVERSATION_FRAGMENT = gql`
  fragment ConversationFragment on Match {
    history {
      current
      previous
      user {
        name
        avatarURL
      }
      createdAt
      creatorType
      creator {
        id
        name
        avatarURL
      }
    }
    conversation {
      id
      type
      creatorType
      creator {
        id
        name
        avatarURL
      }
      actions {
        creatorType
        creator {
          id
          name
          avatarURL
        }
        unicode
      }
      note
      deletedAt
      createdAt
      unseen
    }
  }
`;

export const ADD_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation addConversation($matchId: ID!, $note: String!, $type: String) {
    addConversation(matchId: $matchId, note: $note, type: $type) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const UPDATE_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation updateConversation(
    $matchId: ID!
    $conversationId: ID!
    $note: String!
  ) {
    updateConversation(
      matchId: $matchId
      conversationId: $conversationId
      note: $note
    ) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const DELETE_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation removeConversation($matchId: ID!, $conversationId: ID!) {
    removeConversation(matchId: $matchId, conversationId: $conversationId) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const ADD_ACTION_TO_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation addActionToConversation(
    $matchId: ID!
    $conversationId: ID!
    $unicode: String!
  ) {
    addActionToConversation(
      matchId: $matchId
      conversationId: $conversationId
      unicode: $unicode
    ) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const GET_USER_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  query match($id: ID) {
    match(id: $id) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const MAKE_CONVERSATIONS_SEEN = gql`
  ${CONVERSATION_FRAGMENT}
  mutation makeConversationsSeen($matchId: ID!, $conversationIds: [ID]) {
    makeConversationsSeen(
      matchId: $matchId
      conversationIds: $conversationIds
    ) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const GET_PREVIOUS_CONVERSATIONS = gql`
  query getPreviousConversationsByUserId($userId: ID) {
    getPreviousConversationsByUserId(userId: $userId) {
      id
      conversation {
        id
        note
        type
        creator {
          name
        }
      }
      position {
        id
        title
        company {
          name
        }
      }
    }
  }
`;

export const ADD_ACTIVITY = gql`
  mutation addActivity($matchId: ID!, $type: Int!) {
    addActivity(matchId: $matchId, type: $type) {
      id
    }
  }
`;

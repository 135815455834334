import styled from "styled-components";

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 60px;

  @media only screen and (max-width: 768px) {
    line-height: 34px;
  }
`;

export const FormContainer = styled.div`
  max-width: 450px;
  padding-top: 70px;
  * {
    margin-left: 0;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const inputStyles = {
  marginBottom: "20px",
};

export const Description = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  max-width: 450px;
`;

export const TimerBox = styled.div`
  margin-top: 67px;
  margin-bottom: 20px;
`;

import React from "react";
import styled from "styled-components";
import { BoxTitle } from "./shared";
import { getStartedDay } from "../../../shared";

const TimeCount = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  color: #1a1a1a;
  margin: 0px;
`;

export default function TimeReports({ position }) {
  return (
    <>
      <BoxTitle>Days since start</BoxTitle>
      <TimeCount>{getStartedDay(position)}</TimeCount>
    </>
  );
}

import React, { useContext, useState } from "react";
import { Button, Tag, Avatar } from "tg-design";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import PageTitle from "../../components/PageTitle";
import Description from "../../components/Description";
import Drawer from "../../components/Drawer";
import InviteContactForm from "./InviteContactForm";
import AppContext from "../../contexts/AppContext";
import {
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TgTable,
} from "../../components/Table";
import { GET_COMPANY_CONTACTS } from "../../queries/company";
import PageLoading from "../../components/PageLoading";
import PartialError from "../ErrorPage/PartialError";
import { EMPLOYER_TYPES } from "../../utils/constants";

const Container = styled.div`
  display: flex;
  align-items: baseline;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
      margin-bottom: 40px;
    }
  }
`;

const HeaderContainer = styled.div`
  display: block;
  flex: 1;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Team = () => {
  const [isInviteDrawerOpen, setIsInviteDrawerOpen] = useState(false);
  const appContext = useContext(AppContext);
  const history = useHistory();

  const {
    loading: companyDataLoading,
    error: companyQueryError,
    data: companyData,
    refetch,
  } = useQuery(GET_COMPANY_CONTACTS, {
    variables: { id: appContext.company.id },
    fetchPolicy: "no-cache",
  });

  if (companyDataLoading) return <PageLoading />;
  if (companyQueryError) return <PartialError />;

  const { contacts } = companyData.company;

  return (
    <DashboardTemplate>
      {isInviteDrawerOpen && (
        <Drawer onClose={() => setIsInviteDrawerOpen(false)} showAnimation>
          <InviteContactForm
            companyId={appContext.company.id}
            setDrawer={setIsInviteDrawerOpen}
            refetch={refetch}
          />
        </Drawer>
      )}
      <Container>
        <HeaderContainer>
          <PageTitle mb="0px">Team</PageTitle>
          <Description>Users in TalentGrid organization</Description>
        </HeaderContainer>

        <Button
          variant="primary"
          style={{ flex: "0.5" }}
          onClick={() => setIsInviteDrawerOpen(true)}
        >
          Invite Team Member
        </Button>
      </Container>
      <TableContainer>
        <TgTable>
          <TableHeader>
            <TableHeaderColumn>Avatar</TableHeaderColumn>
            <TableHeaderColumn>Full name</TableHeaderColumn>
            <TableHeaderColumn>Email</TableHeaderColumn>
            <TableHeaderColumn>Role</TableHeaderColumn>
            <TableHeaderColumn>Permissions</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeader />
          </TableHeader>
          {contacts.map((contact) => (
            <TableRow
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`/team/${contact.email}`)}
              key={contact.id}
            >
              <TableCell>
                <Avatar name={contact.fullName} src={contact.avatarURL} />
              </TableCell>
              <TableCell weight={600}>{contact.fullName}</TableCell>
              <TableCell weight={400}>{contact.email}</TableCell>
              <TableCell weight={400}>{contact.role}</TableCell>
              <TableCell>
                {contact.permission?.role === EMPLOYER_TYPES.ADMIN ? (
                  <Tag content="Admin" active />
                ) : (
                  <Tag content="Member" />
                )}
              </TableCell>
              <TableCell weight={400}>
                {contact.status === "ACTIVE" ? (
                  <span style={{ color: "#444444" }}>Registered</span>
                ) : (
                  <span style={{ color: "#AAAAAA" }}>Invited</span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TgTable>
      </TableContainer>
    </DashboardTemplate>
  );
};

export default Team;

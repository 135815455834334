import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AppContext from "../contexts/AppContext";

const PrivateRoute = ({ component: Component, allowOnboarding, ...rest }) => {
  const appContext = useContext(AppContext);

  if (appContext.onboarding && !allowOnboarding) {
    return <Redirect to={{ pathname: "/onboarding" }} />;
  }

  if (appContext.isAuth) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  return (
    <Route
      render={() => (
        <Redirect
          to={{
            pathname: "/login",
            search: `?returnUrl=${
              rest.location.pathname + rest.location.search
            }`,
            state: { referrer: rest.location.pathname + rest.location.search },
          }}
        />
      )}
    />
  );
};

export default PrivateRoute;

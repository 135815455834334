import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { GET_TECHNOLOGIES } from "../../../queries/company";
import PartialError from "../../../pages/ErrorPage/PartialError";

const Container = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.p`
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #aaaaaa;
  margin-bottom: 10px;
`;

const ItemBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px 10px;
`;

const Item = styled.a`
  border: 1px solid #8fdeac;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 16px 3px 16px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #444444;
  transition: background-color 0.2s ease;
  &:hover {
    background: #abe6c1;
  }

  &.loading {
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    color: #aaa;
  }
`;

export default function TechnologySuggestions({
  usedTechnologyIds,
  onSelected,
}) {
  const [technologies, setTechnologies] = useState(null);
  const [loadingId, setLoadingId] = useState(null);
  const { refetch: getTechnologies, error } = useQuery(GET_TECHNOLOGIES, {
    skip: true,
  });

  useEffect(() => {
    const refresh = async () => {
      const result = await getTechnologies({
        sort: {
          usedByCompany: "DESC",
        },
        limit: 20,
        verified: true,
        exclude: usedTechnologyIds,
      });
      setTechnologies(result?.data?.allTechnology?.technologies || []);
    };
    refresh();
  }, [usedTechnologyIds]);

  if (!technologies) return "loading";
  if (error) return <PartialError />;

  const handleSelection = async (selection) => {
    setLoadingId(selection.id);
    await onSelected(selection);
    setLoadingId(null);
  };

  return (
    <Container>
      <Title>Suggestions</Title>
      <ItemBox>
        {technologies.map((item) => (
          <Item
            key={item.id}
            className={item.id === loadingId ? "loading" : ""}
            onClick={() => handleSelection(item)}
          >
            {item.title}
          </Item>
        ))}
      </ItemBox>
    </Container>
  );
}

// professional
// eslint-disable-next-line import/prefer-default-export

export const LANGUAGE_CODES = {
  en: "en-US",
  tr: "tr-TR",
};

export const HttpStatusCodes = Object.freeze({
  NOT_FOUND: 404,
});

export const proExperiences = [
  { title: "1", id: "1" },
  { title: "2", id: "2" },
  { title: "3", id: "3" },
  { title: "4", id: "4" },
  { title: "5", id: "5" },
  { title: "6", id: "6" },
  { title: "7", id: "7" },
  { title: "8", id: "8" },
  { title: "9", id: "9" },
  { title: "10", id: "10" },
  { title: "10+", id: "11" },
];

export const CURRENCIES = [
  { label: "USD", value: "USD" },
  { label: "TRY", value: "TRY" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
];

export const BUDGET_PERIODS = [
  { label: "Per year", value: "YEARLY" },
  { label: "Per month", value: "MONTHLY" },
];

export const INCOME_TYPES = [
  { label: "Gross", value: "GROSS" },
  { label: "Net", value: "NET" },
];

export const HEAD_DATA = {
  register: {
    title: "Register | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  login: {
    title: "Login | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  onboarding: {
    title: "Onboarding | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  company: {
    title: "Company Details | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  position: {
    title: "Position Details | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  "all positions": {
    title: "All Positions | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  "match detail": {
    title: "Match Details | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  feedback: {
    title: "Feedback | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  invite: {
    title: "Invite | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
  forgotPassword: {
    title: "Reset Password | Talentgrid",
    desc: "Helping companies scale their dev team, fast and seamlessly",
  },
};

export const POSITION_STATES = {
  ON_HOLD: "ON_HOLD",
  CREATED: "CREATED",
  SUBMITTED: "SUBMITTED",
  IN_PROGRESS: "IN_PROGRESS",
  FILLED: "FILLED",
  CLOSED: "CLOSED",
  CANCELED: "CANCELED",
};

export const POSITION_STATE_COLORS = {
  ON_HOLD: "#FAB1A0",
  CREATED: "#FBDB68",
  SUBMITTED: "#F39C12",
  IN_PROGRESS: "#54A0FF",
  FILLED: "#2ECC71",
  CLOSED: "#2ECC71",
  CANCELED: "#FF7675",
};

export const POSITION_STATE_TITLES = {
  ON_HOLD: "Paused",
  CREATED: "Draft",
  SUBMITTED: "Submitted",
  IN_PROGRESS: "Started",
  FILLED: "Filled",
  CLOSED: "Filled",
  CANCELED: "Canceled",
};

export const POSITION_STATE_DESCRIPTIONS = {
  [POSITION_STATES.ON_HOLD]: "The position is temporarily on hold.",
  [POSITION_STATES.CREATED]: "Please submit your open position.",
  [POSITION_STATES.SUBMITTED]:
    "We are reviewing your position and will contact you shortly.",
  [POSITION_STATES.IN_PROGRESS]:
    "It’s on! You’ll get notified when there are new verified matches.",
  [POSITION_STATES.FILLED]: "The position has successfully been filled.",
  [POSITION_STATES.CLOSED]: "The position has successfully been filled.",
  [POSITION_STATES.CANCELED]: "The position has been canceled by the employer.",
};

export const COUNTRIES = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
    language: "Pashto",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
    language: "Albanian",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
    language: "Arabic",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1 684",
    code: "AS",
    language: "English",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
    language: "Catalan",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
    language: "Portuguese",
  },
  {
    name: "Anguilla",
    dial_code: "+1 264",
    code: "AI",
    language: "English",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
    language: "English",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
    language: "Spanish",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
    language: "Armenian",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
    language: "Dutch",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
    language: "English",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
    language: "German",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
    language: "Azerbaijani",
  },
  {
    name: "Bahamas",
    dial_code: "+1 242",
    code: "BS",
    language: "English",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
    language: "Arabic",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
    language: "Bengali",
  },
  {
    name: "Barbados",
    dial_code: "+1 246",
    code: "BB",
    language: "English",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
    language: "Belarusian",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
    language: "Dutch",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
    language: "English",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
    language: "French",
  },
  {
    name: "Bermuda",
    dial_code: "+1 441",
    code: "BM",
    language: "English",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
    language: "Dzongkha",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
    language: "Bosnian",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
    language: "English",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
    language: "Portuguese",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
    language: "English",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
    language: "Bulgarian",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
    language: "French",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
    language: "French",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
    language: "Khmer",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
    language: "English",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
    language: "English",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
    language: "Portuguese",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
    language: "English",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
    language: "French",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
    language: "Arabic",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
    language: "Spanish",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
    language: "Mandarin",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
    language: "English",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
    language: "Spanish",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
    language: "Arabic",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
    language: "French",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
    language: "English",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
    language: "Spanish",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
    language: "Croatian",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
    language: "Spanish",
  },
  {
    name: "Cyprus",
    dial_code: "+537",
    code: "CY",
    language: "Greek",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
    language: "Czech",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
    language: "Danish",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
    language: "Arabic",
  },
  {
    name: "Dominica",
    dial_code: "+1 767",
    code: "DM",
    language: "English",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1 849",
    code: "DO",
    language: "Spanish",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
    language: "Spanish",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
    language: "Arabic",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
    language: "Spanish",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
    language: "French",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
    language: "Tigrinya",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
    language: "Estonian",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
    language: "Afar",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
    language: "Faroese",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
    language: "English",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
    language: "Finnish",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
    language: "French",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
    language: "French",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
    language: "French",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
    language: "French",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
    language: "English",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
    language: "Georgian",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
    language: "German",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
    language: "English",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
    language: "Gibraltar",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
    language: "Greek",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
    language: "Greenlandic",
  },
  {
    name: "Grenada",
    dial_code: "+1 473",
    code: "GD",
    language: "English",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
    language: "French",
  },
  {
    name: "Guam",
    dial_code: "+1 671",
    code: "GU",
    language: "English",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
    language: "Spanish",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
    language: "French",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
    language: "Portuguese",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
    language: "English",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
    language: "French",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
    language: "Spanish",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
    language: "Hungarian",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
    language: "Icelandic",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    language: "Hindi",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
    language: "Indonesian",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
    language: "Arabic",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
    language: "Irish",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
    language: "Hebrew",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    language: "Italian",
  },
  {
    name: "Jamaica",
    dial_code: "+1 876",
    code: "JM",
    language: "English",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
    language: "Japanese",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
    language: "Arabic",
  },
  {
    name: "Kazakhstan",
    dial_code: "+7 7",
    code: "KZ",
    language: "Kazakh",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
    language: "English",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
    language: "English",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
    language: "Arabic",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
    language: "Kyrgyz",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
    language: "Latvian",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
    language: "Arabic",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
    language: "Sotho",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
    language: "English",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
    language: "German",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
    language: "Lithuanian",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
    language: "French",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
    language: "French",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
    language: "English",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
    language: "Malay",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
    language: "Dhivehi",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
    language: "French",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
    language: "Maltese",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
    language: "English",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
    language: "French",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
    language: "Arabic",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
    language: "English",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
    language: "French",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
    language: "Spanish",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
    language: "French",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
    language: "Mongolian",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
    language: "Montenegrin",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
    language: "English",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
    language: "Arabic",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
    language: "Burmese",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
    language: "English",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
    language: "English",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
    language: "Nepali",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
    language: "Dutch",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
    language: "Dutch",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
    language: "French",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
    language: "Maori",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
    language: "Spanish",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
    language: "French",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
    language: "English",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
    language: "English",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
    language: "English",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1 670",
    code: "MP",
    language: "English",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
    language: "Norwegian",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
    language: "Arabic",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
    language: "Urdu",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
    language: "English",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
    language: "Spanish",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
    language: "English",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
    language: "Spanish",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
    language: "Spanish",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
    language: "Filipino",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
    language: "Polish",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
    language: "Portuguese",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1 939",
    code: "PR",
    language: "Spanish",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
    language: "Arabic",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
    language: "Romanian",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
    language: "English",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
    language: "English",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
    language: "Italian",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
    language: "Arabic",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
    language: "French	",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
    language: "Serbian",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
    language: "English",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
    language: "English",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
    language: "English",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
    language: "Slovak",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
    language: "Slovene",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
    language: "English",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
    language: "Afrikaans",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
    language: "English",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
    language: "Spanish",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    language: "Sinhala",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
    language: "Arabic",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
    language: "Dutch",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
    language: "English",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
    language: "Swedish",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
    language: "German",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
    language: "Tajik",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
    language: "Thai",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
    language: "French",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
    language: "English",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
    language: "English",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1 868",
    code: "TT",
    language: "English",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
    language: "Arabic",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
    language: "Turkish",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
    language: "Turkmen",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1 649",
    code: "TC",
    language: "English",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
    language: "English",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
    language: "English",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
    language: "Ukrainian",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    language: "Arabic",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    language: "English",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
    language: "English",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
    language: "Spanish",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
    language: "Uzbek",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
    language: "English",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
    language: "French",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
    language: "Arabic",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
    language: "English",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
    language: "English",
  },
  {
    name: "Aland Islands",
    dial_code: "",
    code: "AX",
    language: "Swedish",
  },
  {
    name: "Antarctica",
    dial_code: null,
    code: "AQ",
    language: "English",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
    language: "Spanish",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
    language: "Malay",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
    language: "English",
  },
  {
    name: "Congo, The Democratic Republic of the",
    dial_code: "+243",
    code: "CD",
    language: "French",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
    language: "French",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
    language: "English",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
    language: "English",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
    language: "Italian",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
    language: "English",
  },
  {
    name: "Iran, Islamic Republic of",
    dial_code: "+98",
    code: "IR",
    language: "Arabic",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
    language: "English",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
    language: "English",
  },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: "+850",
    code: "KP",
    language: "Korean",
  },
  {
    name: "Korea, Republic of",
    dial_code: "+82",
    code: "KR",
    language: "Korean",
  },
  {
    name: "Lao People's Democratic Republic",
    dial_code: "+856",
    code: "LA",
    language: "Lao",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
    language: "Arabic",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
    language: "Portuguese",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    dial_code: "+389",
    code: "MK",
    language: "Macedonian",
  },
  {
    name: "Micronesia, Federated States of",
    dial_code: "+691",
    code: "FM",
    language: "English",
  },
  {
    name: "Moldova, Republic of",
    dial_code: "+373",
    code: "MD",
    language: "Romanian",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
    language: "Portuguese",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
    language: "Arabic",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
    language: "English",
  },
  {
    name: "Réunion",
    dial_code: "+262",
    code: "RE",
    language: "French",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
    language: "Russian",
  },
  {
    name: "Saint Barthélemy",
    dial_code: "+590",
    code: "BL",
    language: "French",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
    language: "English",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1 869",
    code: "KN",
    language: "English",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1 758",
    code: "LC",
    language: "English",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
    language: "Dutch",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
    language: "French",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1 784",
    code: "VC",
    language: "English",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
    language: "Portuguese",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
    language: "Arabic",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
    language: "Norwegian",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
    language: "Arabic",
  },
  {
    name: "Taiwan, Province of China",
    dial_code: "+886",
    code: "TW",
    language: "Mandarin",
  },
  {
    name: "Tanzania, United Republic of",
    dial_code: "+255",
    code: "TZ",
    language: "English",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
    language: "Portuguese",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    dial_code: "+58",
    code: "VE",
    language: "Spanish",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
    language: "Vietnamese",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1 284",
    code: "VG",
    language: "English",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1 340",
    code: "VI",
    language: "English",
  },
];
export const EDUCATION_TYPES = [
  {
    label: "Associate's Degree",
    value: 0,
  },
  {
    label: "Bachelor's Degree",
    value: 1,
  },
  {
    label: "Master’s Degree",
    value: 2,
  },
  {
    label: "Doctorate Degree",
    value: 3,
  },
];

export const ISTANBUL_PLACE_ID = "60783e804136605b0a48bfe8";

export const ISTANBUL_REGIONS = [
  { id: "Anatolia", title: "Anatolia" },
  { id: "Europe", title: "Europe" },
];

export const TECHNOLOGY_CATEGORY_KEYS = {
  1: "language",
  2: "tool",
  3: "cloud",
  4: "versioning",
  5: "framework",
  6: "architecture",
  7: "database",
  8: "system",
  9: "methodologies",
  10: "analytics",
};

export const TECHNOLOGY_CATEGORY_LABELS = {
  language: "Programming language",
  framework: "Framework",
  tool: "Tools",
  cloud: "Cloud systems",
  versioning: "Versioning",
  architecture: "Architecture",
  database: "Database systems",
  analytics: "Analytics & reporting",
  methodologies: "Methodologies",
  cicd: "CI/CD Tools",
};

export const TECHNOLOGY_IDS = {
  language: 1,
  framework: 5,
  architecture: 6,
  database: 7,
  cloud: 3,
  versioning: 4,
  system: 8,
  methodologies: 9,
  analytics: 10,
  tool: 2,
};

export const REMOTE_OPTIONS = [
  {
    id: "half",
    title: "Hybrid",
  },
  {
    id: "none",
    title: "Onsite",
  },
  {
    id: "full",
    title: "Full Remote",
  },
];

export const RELOCATION_OPTIONS = [
  {
    id: "relocationSupported",
    title: "Yes",
    value: true,
  },
  {
    id: "relocationNotSupported",
    title: "No",
    value: false,
  },
];

export const BUDGET_VISIBILITY_OPTION = [
  {
    id: "budgetVisible",
    title: "Yes, make it visible",
    value: true,
  },
  {
    id: "budgetNotVisible",
    title: "No, hide it",
    value: false,
  },
];

export const VISA_SUPPORT_OPTIONS = [
  {
    id: "visaSupported",
    title: "Yes",
    value: true,
  },
  {
    id: "visaNotSupported",
    title: "No",
    value: false,
  },
];

export const STOCK_OPTION_PROVIDE_TYPES = [
  { title: "Yes", id: "stockOptionProvided", value: true },
  { title: "No", id: "stockOptionNotProvided", value: false },
];

export const STOCK_OPTION_VISIBILITY_TYPES = [
  { title: "Yes", id: "stockOptionVisibleToUser", value: true },
  { title: "No", id: "stockOptionNotVisibleToUser", value: false },
];

export const STOCK_OPTION_RANGE_TYPE = [
  { label: "Range", value: "RANGE" },
  { label: "Certain amount", value: "CERTAIN" },
];

export const LOCATION_TYPES = [
  {
    value: "headquarter",
    title: "Headquarter",
  },
  {
    value: "branch",
    title: "Branch",
  },
];
export const MATCH_NOT_A_GOOD_FIT = "NOT_A_GOOD_FIT";
export const MATCH_STATES = {
  SENT: "SENT",
  INTERESTED: "INTERESTED",
  TG_ASSESSMENT: "TG_ASSESSMENT",
  COMPANY_ASSESSMENT: "COMPANY_ASSESSMENT",
  INTERVIEW: "INTERVIEW",
  SENT_OFFER: "SENT_OFFER",
  HIRED: "HIRED",
  FAILED: "FAILED",
};

export const MATCH_STATE_TITLES = {
  [MATCH_STATES.SENT]: "Matched & Notified",
  [MATCH_STATES.INTERESTED]: "Interested",
  [MATCH_STATES.TG_ASSESSMENT]: "In Assessment",
  [MATCH_STATES.COMPANY_ASSESSMENT]: "Verified Match",
  [MATCH_STATES.INTERVIEW]: "Interview",
  [MATCH_STATES.SENT_OFFER]: "Offer Sent",
  [MATCH_STATES.HIRED]: "Filled",
  [MATCH_STATES.FAILED]: "Disqualified",
};

export const MATCH_STATE_BG_COLORS = {
  [MATCH_STATES.COMPANY_ASSESSMENT]: "#0C084C",
  [MATCH_STATES.INTERVIEW]: "#FBDB68",
  [MATCH_STATES.SENT_OFFER]: "#64B7DC",
  [MATCH_STATES.HIRED]: "#8FDEAC",
  [MATCH_STATES.FAILED]: "#D98787",
};

export const MATCH_STATE_COLORS = {
  [MATCH_STATES.COMPANY_ASSESSMENT]: "#FFFF",
  [MATCH_STATES.INTERVIEW]: "#444",
  [MATCH_STATES.SENT_OFFER]: "white",
  [MATCH_STATES.HIRED]: "#333",
  [MATCH_STATES.FAILED]: "white",
};

export const MATCH_STATE_ALLOWANCE_LIST = {
  [MATCH_STATES.SENT]: [],
  [MATCH_STATES.INTERESTED]: [],
  [MATCH_STATES.TG_ASSESSMENT]: [],
  [MATCH_STATES.COMPANY_ASSESSMENT]: [],
  [MATCH_STATES.INTERVIEW]: [MATCH_STATES.COMPANY_ASSESSMENT],
  [MATCH_STATES.SENT_OFFER]: [MATCH_STATES.INTERVIEW],
  [MATCH_STATES.HIRED]: [MATCH_STATES.SENT_OFFER],
  [MATCH_STATES.FAILED]: [
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.SENT_OFFER,
    MATCH_STATES.HIRED,
  ],
};

export const COMMENT_NOT_ALLOWED_STATES = [
  MATCH_STATES.SENT,
  MATCH_STATES.INTERESTED,
  MATCH_STATES.TG_ASSESSMENT,
];

export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT = { createdAt: "DESC" };

export const LANGUAGE_LEVEL_TYPES = [
  {
    label: "A1 - Beginner",
    value: 1,
  },
  {
    label: "A2 - Elementary",
    value: 2,
  },
  {
    label: "B1 - Intermediate",
    value: 3,
  },
  {
    label: "B2 - Upper Intermediate",
    value: 4,
  },
  {
    label: "C1 - Advanced",
    value: 5,
  },
  {
    label: "C2 - Proficiency",
    value: 6,
  },
  {
    label: "Native",
    value: 7,
  },
];

export const REMOTE_OPTIONS_FOR_DEVELOPER = [
  {
    id: "half",
    title: "Onsite or Remote",
  },
  {
    id: "none",
    title: "Only Onsite",
  },
  {
    id: "full",
    title: "Only Remote",
  },
];

export const REMOTE_OPTIONS_FOR_COMPANY = [
  {
    id: "half",
    title: "Hybrid",
  },
  {
    id: "none",
    title: "Onsite",
  },
  {
    id: "full",
    title: "Full Remote",
  },
];

export const NOT_REQUIRED_MESSAGE =
  "Not required. Fill only if this is an important criteria for you.";

export const REJECT_REASON_TYPES = {
  DEVELOPER: 0,
  COMPANY: 2,
};

export const USER_TYPES = {
  USER: 0,
  RECRUITER: 1,
  EMPLOYER: 2,
};

export const DEVELOPER_STATUSES = {
  PRE_LEAD: "PRE_LEAD",
  PASSIVE: "PASSIVE",
  LEAD: "LEAD",
  MEMBER: "MEMBER",
};

export const CONVERSATION_TYPES = {
  MEETING: "MEETING",
  CONVERSATION: "CONVERSATION",
  NOT_A_GOOD_FIT: "NOT_A_GOOD_FIT",
};

export const MOST_SPOKEN_LANGUAGES = [
  "English",
  "Spanish",
  "German",
  "French",
  "Romanian",
  "Mandarin",
  "Arabic",
  "Russian",
  "Ukrainian",
  "Hindi",
  "Italian",
  "Turkish",
  "Portuguese",
  "Korean",
];

export const MATCH_ACTIVITY_TYPES = {
  EMPLOYER_SEEN_PROFILE_INFO_TAB: 0,
  EMPLOYER_SEEN_WORK_EXPERIENCE_TAB: 1,
  EMPLOYER_SEEN_EDUCATION_TAB: 2,
  EMPLOYER_SEEN_CONVERSATION_TAB: 3,
};

export const EMPLOYER_TYPES = Object.freeze({
  MEMBER: 0,
  ADMIN: 1,
});

export const EMPLOYER_POSITION_AUTHORITY = Object.freeze({
  ALL: "_0",
  ADMIN: "_1",
  MEMBER: "_2",
});

export const PRIVACY_POLICY_TYPES = {
  TR: "TR",
  GLOBAL: "GLOBAL",
};

export const COMPANY_SIZES = [
  {
    id: 1,
    min: 1,
    max: 15,
    label: "1-15",
  },
  {
    id: 2,
    min: 15,
    max: 50,
    label: "15-50",
  },
  {
    id: 3,
    min: 51,
    max: 200,
    label: "51-200",
  },
  {
    id: 4,
    min: 201,
    max: 500,
    label: "201-500",
  },
  {
    id: 5,
    min: 501,
    max: 1000,
    label: "501-1000",
  },
  {
    id: 6,
    min: 1000,
    max: 9999,
    label: "100+",
  },
];

/* eslint-disable prefer-destructuring */
const breakpoints = [
  "320px",
  "375px",
  "425px",
  "768px",
  "1024px",
  "1440px",
  "2560px",
];

breakpoints.mobileS = breakpoints[0];
breakpoints.mobileM = breakpoints[1];
breakpoints.mobileL = breakpoints[2];
breakpoints.tablet = breakpoints[3];
breakpoints.laptop = breakpoints[4];
breakpoints.laptopL = breakpoints[5];
breakpoints.desktop = breakpoints[6];
breakpoints.desktopL = breakpoints[7];

// Colors

const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: {
    body: 14,
    medium: 12,
    large: 18,
    h6: {
      sm: 12,
      md: 14,
      lg: 16,
    },
    h5: {
      sm: 14,
      md: 14,
      lg: 16,
    },
    h4: {
      sm: 16,
      md: 18,
      lg: 22,
    },
    h3: {
      sm: 16,
      md: 22,
      lg: 28,
    },
    h2: {
      sm: 18,
      md: 22,
      lg: 24,
    },
    h1: {
      sm: 24,
      md: 26,
      lg: 30,
    },
  },
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  colors: {
    main: ["#0c084c", "#494679", "#8684a6", "#c2c1d2", "#e1e0e9"],
    green: ["#8fdeac", "#abe6c1", "#c7eed5", "#e3f7ea", "#f1fbf5"],
    blue: ["#72d1fb", "#95ddfc", "#b9e8fd", "#dcf4fe", "#edf9fe"],
    yellow: ["#fad156", "#fbdd80", "#fce8ab", "#fdeec0", "#fef9ea"],
    red: ["#ff7675", "#ff9898", "#ffbbba", "#ffdddc", "#ffeeee"],
    font: ["#444444", "#aaaaaa", "#cccccc", "#dddddd", "#eeeeee"],
    input: "#f5f6f8",
  },
  family: {
    medium: "Inter, sans-serif",
    demi: "Inter, sans-serif",
    regular: "Inter, sans-serif",
    bold: "Inter, sans-serif",
  },
  // https://material-ui.com/customization/breakpoints/
  breakpoints,
};

export default theme;

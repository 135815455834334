import React, { useState, useRef, useCallback } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  message,
} from "tg-design";
import { UPLOAD_IMAGE, DELETE_COMPANY_LOGO } from "../../../../queries/company";
import Uploader from "../../../../components/Uploader";
import { getUnsignedUrl } from "../../../../utils/helper";
import {
  Description,
  SecondaryDescription,
} from "../../../../components/TgKanban/StatusChangeModal/style";

const CropperContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DEFAULT_CROP_OPTIONS = {
  unit: "px",
  x: 30,
  y: 30,
  width: 110,
  height: 110,
  aspect: 1 / 1,
};

const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        blob.name = fileName;
        resolve(blob);
      },
      "image/jpeg",
      1
    );
  });
};

export default function UploadLogo({
  handleLogo,
  initialLogo,
  companyId,
  onStarted,
  onError,
  status,
}) {
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [deleteImage] = useMutation(DELETE_COMPANY_LOGO);
  const [uploadedFile, setUploadedFile] = useState(
    initialLogo
      ? {
          uuid: uuidv4(),
          isUploading: false,
          isDeleting: false,
          url: initialLogo,
        }
      : null
  );
  const [isModalOpen, setModal] = useState(false);
  const [selectImage, setSelectImage] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [targetImage, setTargetImage] = useState(null);
  const [crop, setCrop] = useState(DEFAULT_CROP_OPTIONS);
  const imgRef = useRef(null);

  const handleDelete = async (file) => {
    try {
      if (onStarted) {
        onStarted();
      }
      setUploadedFile({
        ...uploadedFile,
        isDeleting: true,
      });
      await deleteImage({
        variables: { imageUrl: file.url, company: companyId },
      });
      handleLogo(null);
      setUploadedFile(null);
    } catch (error) {
      message.error("Logo deletion failed");
      if (onError) {
        onError();
      }
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const startCropping = (selectedFiles) => {
    setSelectImage(selectedFiles);
    setModal(true);
    const reader = new FileReader();
    reader.addEventListener("load", () => setTargetImage(reader.result));
    reader.readAsDataURL(selectedFiles[0]);
  };

  const onCropComplete = async (cropImage) => {
    if (imgRef && cropImage.width && cropImage.height) {
      const croppedImage = await getCroppedImg(
        imgRef.current,
        cropImage,
        selectImage[0].name
      );
      setCompletedCrop(croppedImage);
    }
  };

  const handleCropperClose = () => {
    setModal(false);
    setSelectImage(null);
    setCompletedCrop(null);
    setTargetImage(null);
    imgRef.current = null;
    setCrop(DEFAULT_CROP_OPTIONS);
  };

  const handleCroppedImage = async (e) => {
    e.preventDefault();
    setModal(false);

    if (selectImage[0].size > 10485760) {
      return message.error("This file is larger than our 10 MB size limit.");
    }

    try {
      if (onStarted) {
        onStarted();
      }
      const file = {
        uuid: uuidv4(),
        name: selectImage[0].name,
        isUploading: true,
      };
      setUploadedFile(file);
      const response = await uploadImage({
        variables: { image: completedCrop },
      });
      setUploadedFile({
        ...file,
        isUploading: false,
        url: response.data.uploadCompanyImage.imageUrl,
      });
      handleLogo(getUnsignedUrl(response.data.uploadCompanyImage.imageUrl));
    } catch (error) {
      setUploadedFile(null);
      message.error("Logo upload failed");
      if (onError) {
        onError();
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal handleClose={handleCropperClose}>
          <ModalHeader>
            <Description>Crop the Image</Description>
            <SecondaryDescription>
              PNG, JPG and JPEG files with less than 10 MB size are supported.
            </SecondaryDescription>
          </ModalHeader>
          <ModalBody>
            <CropperContainer>
              <ReactCrop
                src={targetImage}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onImageLoaded={onLoad}
                onComplete={onCropComplete}
              />
            </CropperContainer>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCroppedImage}>Crop & Save</Button>
          </ModalFooter>
        </Modal>
      )}
      <Uploader
        accept="image/jpg, image/png, image/jpeg"
        onSelection={startCropping}
        onDelete={handleDelete}
        files={uploadedFile ? [uploadedFile] : []}
        hasError={status === "error"}
      />
    </>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import { TooltipIcon } from "../Icon";

const getStatsBackgroundColor = (props) => {
  const { showTooltip, titleText } = props;
  if (!showTooltip) {
    return "none";
  }
  if (titleText === "Awaiting Action") {
    return "#FDEEC0";
  }
  return "#e8f4fa";
};

const Container = styled.div`
  width: 180px;
`;

const StatsContainer = styled.div`
  padding: 10px;
  margin-right: 10px;

  cursor: pointer;

  &:hover {
    background: ${(props) => getStatsBackgroundColor(props)};
  }
`;

const StatsTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
`;

const StatsValue = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;

const TooltipContainer = styled.div`
  position: absolute;
  margin-top: 5px;
  z-index: 1;
`;

const TooltipIconContainer = styled.div`
  margin-left: 70px; // (ContainerWidth - StatsContainerMargin) / 2 - (svgWidth /2)
`;

const TooltipText = styled.p`
  background: #ffffff;
  box-shadow: 0 10px 13px 0 #cccccc;
  border-radius: 5px;
  width: 204px;
  padding: 10px 15px;
  margin: 0;
`;

const Tooltip = ({ text }) => {
  return (
    <TooltipContainer>
      <TooltipIconContainer>
        <TooltipIcon />
      </TooltipIconContainer>
      <TooltipText>{text}</TooltipText>
    </TooltipContainer>
  );
};

export default function Stats({
  title,
  awaitingActionState,
  setAwaitingActionState,
  showTooltip,
  children,
  tooltipText,
  handleChange,
}) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = (name) => {
    setIsHovering(true);
    if (
      name === "Awaiting Action" &&
      typeof setAwaitingActionState === "function"
    ) {
      setAwaitingActionState({ ...awaitingActionState, isHovered: true });
    }
  };

  const handleMouseOut = (name) => {
    setIsHovering(false);
    if (
      name === "Awaiting Action" &&
      typeof setAwaitingActionState === "function"
    ) {
      setAwaitingActionState({ ...awaitingActionState, isHovered: false });
    }
  };

  const handleClick = (name) => {
    if (
      name === "Awaiting Action" &&
      typeof setAwaitingActionState === "function"
    ) {
      handleChange("matches");
      setAwaitingActionState({ ...awaitingActionState, isClicked: true });
      window.scrollTo({
        top: 250,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Container>
      <StatsContainer
        onMouseOver={() => handleMouseOver(title)}
        onMouseOut={() => handleMouseOut(title)}
        onClick={() => handleClick(title)}
        titleText={title}
        showTooltip={showTooltip}
      >
        <StatsTitle>{title}</StatsTitle>
        <StatsValue>{children}</StatsValue>
      </StatsContainer>
      {isHovering && tooltipText && showTooltip && (
        <Tooltip text={tooltipText} />
      )}
    </Container>
  );
}

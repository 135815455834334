import styled from "styled-components";

export const InputExplanation = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #656565;
  margin-bottom: 5px;
`;

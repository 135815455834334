import styled from "styled-components";

export default styled.div`
  display: block;
  width: 19px;
  height: 19px;
  min-width: 19px;
  min-height: 19px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : "#1A1A1A"};
  color: ${(props) => (props.borderColor ? props.borderColor : "#1A1A1A")};
  line-height: 20px;
  padding: 2px 2px 2px 2px;
  margin-right: 5px;
  font-size: 12px;
  text-align: center;
`;

import React from "react";
import styled from "styled-components";

const Spinner = styled.span`
  display: inline-block;
`;

export default function TgKanbanColumnFooter({
  isLoading = false,
  isEnded = false,
  count = 0,
}) {
  return (
    <>
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <Spinner className="loader">&nbsp;</Spinner>
        </div>
      )}
      {isEnded && (
        <div style={{ textAlign: "center", fontSize: "12px" }}>
          {count} results shown.
        </div>
      )}
    </>
  );
}

import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { Button, FormWrapper, TextField, message } from "tg-design";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import {
  RaisedHandIcon,
  VerifiedIcon,
  FolderAddIcon,
  BlueCheckIcon,
} from "../../components/Icon";
import Card from "../../components/Form/Card";
import PageTitle from "../../components/PageTitle";
import AppContext from "../../contexts/AppContext";
import { CREATE_POSITION } from "../../queries/position";
import PageLoading from "../../components/PageLoading";
import { handleHeadTag } from "../../utils/helper";

const InlineForm = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    display: block;

    & input {
      margin-bottom: 10px;
      margin-right: 0px !important;
    }

    & button {
      width: 100%;
    }
  }
`;

const InlineInputContainer = styled.div`
  max-width: 400px;
  margin-right: 10px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
  }
`;

const IconContainer = styled.div`
  display: inline;
  margin-top: 2px;
`;

export default function Onboarding() {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [createPosition] = useMutation(CREATE_POSITION);
  const [creatingPosition, setCreatingPosition] = useState(false);

  if (!appContext.employer) return <PageLoading />;

  if (appContext.onboarding === false) {
    history.push("/");
    return "";
  }

  const handlePositionCreate = async (e, { positionTitle }) => {
    try {
      if (positionTitle === "")
        return message.error("Please enter the position title first!");
      setCreatingPosition(true);
      const {
        data: { addPosition: position },
      } = await createPosition({
        variables: {
          title: positionTitle,
          company: appContext.employer.company.id,
        },
      });
      setCreatingPosition(false);
      history.push(`/positions/${position.id}/edit`);
      await appContext.increasePositionCount();
    } catch (err) {
      setCreatingPosition(false);
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  handleHeadTag("onboarding");

  return (
    <DashboardTemplate>
      <PageTitle>
        Welcome, {appContext.employer.fullName} <RaisedHandIcon />
      </PageTitle>
      <>
        <Card
          icon={<VerifiedIcon />}
          title="First things first: Complete your talent facing company profile."
          description="Define your ideal match for this role and meet verified and pre-vetted candidates."
        >
          {appContext.isCompanyValid && (
            <Button rounded outline style={{ cursor: "default" }}>
              <IconContainer>
                <BlueCheckIcon />
              </IconContainer>
              Completed
            </Button>
          )}
          {!appContext.isCompanyValid && (
            <Button onClick={() => history.push("company/edit")}>
              Complete Company Profile
            </Button>
          )}
        </Card>
        <Card
          icon={<FolderAddIcon />}
          title="Create your first position"
          description="Define your ideal match for this role and meet verified and pre-vetted candidates."
          disabled={!appContext.isCompanyValid}
        >
          <FormWrapper onSubmit={handlePositionCreate}>
            <InlineForm>
              <InlineInputContainer>
                <TextField
                  name="positionTitle"
                  placeholder="Enter position title"
                  style={{
                    height: "50px",
                    marginRight: "10px",
                  }}
                />
              </InlineInputContainer>
              <Button
                type="submit"
                disabled={!appContext.isCompanyValid}
                loading={creatingPosition}
              >
                Create Position
              </Button>
            </InlineForm>
          </FormWrapper>
        </Card>
      </>
    </DashboardTemplate>
  );
}

import React, { useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 105px);
  padding: 13px 10px 40px 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  &.on-hover {
    filter: blur(1.2px);
  }

  &.has-substate {
    padding-bottom: unset;
  }
`;

export default function TgKanbanColumnBody({
  isOnHover,
  onLoadMore,
  columnId,
  substate = null,
  children,
  hasSubState,
}) {
  const containerRef = useRef();
  const classes = [];
  if (isOnHover) {
    classes.push("on-hover");
  }
  if (hasSubState) {
    classes.push("has-substate");
  }

  const handleScroll = () => {
    if (!onLoadMore) {
      return;
    }

    const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
    const scrollEnd = scrollHeight - clientHeight;
    const position = scrollTop / scrollEnd;
    if (position >= 0.8) {
      onLoadMore({ columnId, substate, position });
    }
  };

  return (
    <Container
      ref={containerRef}
      onScroll={handleScroll}
      className={classes.join(" ")}
    >
      {children}
    </Container>
  );
}

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
`;

const Spinner = styled.span`
  display: inline-block;
`;

export default function Loader() {
  return (
    <Container>
      <Spinner className="loader">&nbsp;</Spinner>
    </Container>
  );
}

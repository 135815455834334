import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { WorkExperienceIcon } from "../../../../../../../components/Icon";
import EmptyData from "../../../../../../../components/EmptyData";
import {
  Container,
  WorkInfoContainer,
  IconContainer,
  WorkingDates,
  CompanyName,
  WorkHistoryConatiner,
  PositionTitle,
  WorkDescription,
} from "./styles";
import { ADD_ACTIVITY } from "../../../../../../../queries/match";
import { MATCH_ACTIVITY_TYPES } from "../../../../../../../utils/constants";

export default function WorkExperiencesTab({ match }) {
  const [addActivity] = useMutation(ADD_ACTIVITY);

  useEffect(() => {
    addActivity({
      variables: {
        matchId: match.id,
        type: MATCH_ACTIVITY_TYPES.EMPLOYER_SEEN_WORK_EXPERIENCE_TAB,
      },
    });
  }, []);

  return (
    <>
      {(!match.user.workHistory || match.user.workHistory.length === 0) && (
        <EmptyData />
      )}

      {match.user.workHistory.map((workHistory, index) => {
        return (
          <Container key={index}>
            <WorkInfoContainer>
              <IconContainer>
                <WorkExperienceIcon />
              </IconContainer>
              <WorkHistoryConatiner>
                <PositionTitle>{workHistory.position}</PositionTitle>
                <CompanyName>{workHistory.company}</CompanyName>
                <WorkingDates>
                  {dayjs(workHistory.startDate).format("MMMM YYYY")} -{" "}
                  {workHistory.endDate == null
                    ? " Present"
                    : dayjs(workHistory.endDate).format("MMMM YYYY")}
                </WorkingDates>
              </WorkHistoryConatiner>
            </WorkInfoContainer>
            <WorkDescription>{workHistory.description}</WorkDescription>
          </Container>
        );
      })}
    </>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "tg-design";
import { InfoIcon } from "../../components/Icon";

const IntegrationCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  background: #ffffff;
  border: ${({ connected, hasError }) =>
    connected && !hasError ? "2px solid #c7eed5" : "1px solid #e5e5e5"};
  box-shadow: 0px 2px 10px rgba(229, 229, 229, 0.5);
  border-radius: 4px;

  /* Implemented to styled system for margin */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
`;

const IntegrationCardWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding-left: 47px;

  width: -webkit-fill-available;
  height: 50px;
  left: 0px;
  top: 0px;

  background: #fbdb68;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #161616;

  @media only screen and (max-width: 768px) {
    padding-left: 5px;
  }
`;

const IntegrationManualLink = styled.a`
  display: block;
  margin-top: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  cursor: pointer;

  color: #64b7dc;
  &:hover {
    text-decoration: underline;
    filter: brightness(105%);
  }
`;

const IntegrationCardBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  padding: 20px 40px;
  gap: 40px;

  /* Implemented to styled system for margin */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;

  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`;

const IntegrationLogo = styled.div`
  width: 280px;
  height: 155.44px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    min-height: 130px;
  }
  background: ${({ filename }) =>
    filename ? `url("../../../images/${filename}-logo.png")` : ""};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const IntegrationDescriptionColumn = styled.div`
  flex-direction: column;
  display: flex;
  row-gap: 8px;
  max-width: 600px;
`;

const IntegrationTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  color: #161616;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const IntegrationDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #1a1a1a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const IntegrationSummary = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #979797;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`;

const BottomLine = styled.hr`
  border: 1px solid #e5e5e5;
  width: 100%;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
`;

const IntegrationInputWrapper = styled.div`
  flex-direction: row;
  display: flex;
  row-gap: 8px;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  gap: 12px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const InputDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #979797;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  flex: 1;
`;

export default function IntegratonItem({
  isConnected = false,
  hasError = false,
  notIntegrated = false,
  integration = "",
  integrationManualLink,
  handleConnect,
  handleDisconnect,
  handleReConnect,
  connectLoading,
  disconnectLoading,
}) {
  const [state, setState] = useState("");
  const lowercaseIntegrationName = integration.toLowerCase();

  const handleConnectButton = () => {
    handleConnect(state);
    setState("");
  };

  const handleReConnectButton = () => {
    handleReConnect(state);
    setState("");
  };

  const handleDisconnectButton = () => {
    handleDisconnect();
  };

  return (
    <IntegrationCard connected={isConnected} hasError={hasError}>
      {hasError && isConnected && (
        <IntegrationCardWarning>
          <InfoIcon color="#161616" />
          Your Greenhouse connection has been lost.
        </IntegrationCardWarning>
      )}

      <IntegrationCardBody>
        <IntegrationLogo filename={lowercaseIntegrationName} />
        <IntegrationDescriptionColumn>
          {isConnected && !hasError && (
            <IntegrationTitle>Connected to {integration}</IntegrationTitle>
          )}
          {notIntegrated && (
            <IntegrationTitle>
              Connect to {integration} {"(Coming Soon)"}
            </IntegrationTitle>
          )}

          {!isConnected && !notIntegrated && (
            <IntegrationTitle>Connect to {integration}</IntegrationTitle>
          )}

          {isConnected && hasError && (
            <IntegrationTitle>
              Reconnect to {integration} with a new API Key
            </IntegrationTitle>
          )}

          {!isConnected && (
            <IntegrationDescription>
              Integrate your hiring workflow with {integration}. See the
              instructions to get an API Key.
            </IntegrationDescription>
          )}

          {isConnected && (
            <IntegrationDescription>
              Go to your {integration} account to track status of your
              candidates.
            </IntegrationDescription>
          )}

          {!isConnected && (
            <IntegrationSummary>
              Candidates who accept your interview requests will be
              automatically added to your {integration} account and you will be
              able to track their status there.{" "}
              {integrationManualLink && (
                <>
                  <IntegrationManualLink
                    target="_blank"
                    href={integrationManualLink}
                  >
                    Click for instructions.
                  </IntegrationManualLink>
                </>
              )}
            </IntegrationSummary>
          )}

          {isConnected && (
            <IntegrationSummary>
              Go to your {integration} account to track their status there.
              Candidates who accept your interview requests are automatically
              added to your {integration} account.
            </IntegrationSummary>
          )}

          <BottomLine />
          <IntegrationInputWrapper>
            {notIntegrated && (
              <InputDescription>
                Check back later to see if the integration is ready. We will
                also let you know via email.
              </InputDescription>
            )}

            {isConnected && !hasError && (
              <InputDescription>
                Click the Disconnect button to stop automatically adding
                candidates to your {integration} account.
              </InputDescription>
            )}
            {!isConnected && !notIntegrated && (
              <TextField
                name="apiKey"
                placeholder="Enter API Key"
                onChange={(e) => setState(e.target.value)}
                value={state}
              />
            )}

            {isConnected && hasError && (
              <TextField
                name="apiKey"
                placeholder="Re-enter the API Key"
                onChange={(e) => setState(e.target.value)}
                value={state}
              />
            )}

            {isConnected && !hasError && (
              <Button
                type="submit"
                variant="danger"
                outline
                size="sm"
                width="250px"
                loading={disconnectLoading}
                onClick={handleDisconnectButton}
              >
                Disconnect
              </Button>
            )}
            {!isConnected && !notIntegrated && (
              <Button
                type="submit"
                size="sm"
                width="250px"
                onClick={handleConnectButton}
                loading={connectLoading}
              >
                Connect
              </Button>
            )}
            {isConnected && hasError && (
              <Button
                type="submit"
                size="sm"
                width="250px"
                loading={connectLoading}
                onClick={handleReConnectButton}
              >
                Reconnect
              </Button>
            )}
          </IntegrationInputWrapper>
        </IntegrationDescriptionColumn>
      </IntegrationCardBody>
    </IntegrationCard>
  );
}

import styled from "styled-components";

export const SectionContainer = styled.div`
  margin-bottom: 50px;
`;
export const Container = styled.div`
  margin-top: 25px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 60px;
  width: 60px;
  background: #e5e5e5;
  border-radius: 5px;
`;

export const InformationConatiner = styled.div`
  margin-right: 20px;
`;

export const SectionTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1a1a1a;
`;
export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

export const SecondaryTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
`;

export const DegreeAndDateContainer = styled.div`
  margin-left: 30px;
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 0;
  }
`;
export const Degree = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
`;

export const Dates = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
`;

import styled from "styled-components";

export const RowContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-left: 10px;
`;
export const DescriptionCell = styled.div`
  width: 200px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding-left: 5px;
`;
export const ValueCell = styled.div`
  flex-basis: 70%;
  @media only screen and (max-width: 768px) {
    flex-basis: 50%;
  }
`;

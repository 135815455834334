import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  isLoading: false,
  title: "Are you sure?",
  content: null,
  acceptButton: { variant: "primary", text: "Yes" },
  cancelButton: { variant: "secondary", text: "No" },
  buttonStyle: {},
  callback: () => {},
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    open: (state, { payload }) => {
      state.isOpen = true;
      state.title = payload.title || initialState.title;
      state.content = payload.content || initialState.content;
      state.callback = payload.callback || initialState.callback;
      state.acceptButton = payload.acceptButton || initialState.acceptButton;
      state.cancelButton = payload.cancelButton || initialState.cancelButton;
      state.buttonStyle = payload.buttonStyle || initialState.buttonStyle;
    },
    close: (state) => {
      state.isOpen = false;
      state.title = initialState.title;
      state.content = initialState.content;
      state.acceptButton = initialState.acceptButton;
      state.cancelButton = initialState.cancelButton;
      state.buttonStyle = initialState.buttonStyle;
      state.callback = () => {};
    },
  },
});

export const { open, close } = questionSlice.actions;

export default questionSlice.reducer;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { MarkdownContent } from "tg-design";
import { GET_MATCH } from "./queries";
import DeveloperProfile from "../Position/View/tabs/MatchDetailDrawer/DeveloperProfile";
import {
  EducationTab,
  ProfileTab,
  WorkExperiencesTab,
} from "../Position/View/tabs/MatchDetailDrawer/tabs";
import PartialError from "../ErrorPage/PartialError";
import logoImage from "../../images/talentgrid-logo-dark.png";
import Loader from "../../components/Loader";
import { Margin, RowDescription } from "./styles";

export default function MatchPrintPage() {
  const { matchId } = useParams();
  const [match, setMatchData] = useState();

  const { loading, error } = useQuery(GET_MATCH, {
    variables: { id: matchId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data.errors) {
        setMatchData(data.match);
        const username = data.match.user.name;
        window.document.title = `${username} - TalentGrid`;
      }
    },
  });

  useEffect(() => {
    if (match) {
      setTimeout(window.print, 2000); //  beacuse of the font family not loaded. we must use the local fonts
    }
  }, [match]);

  if (loading) return <Loader />;

  if (error) return <PartialError />;

  const ConversationTab = ({ conversation }) => {
    return (
      <RowDescription style={{ marginLeft: "10px" }}>
        <MarkdownContent>{conversation.note}</MarkdownContent>
      </RowDescription>
    );
  };

  const TGBrand = () => (
    <>
      <div style={{ float: "right", marginRight: "20px" }}>
        <img style={{ width: "120px" }} src={logoImage} alt="TG Logo" />
      </div>
    </>
  );

  return (
    <>
      <TGBrand />
      {match && (
        <div style={{ pageBreakInside: "avoid" }}>
          <DeveloperProfile match={match} />
          <ProfileTab match={match} />
          <Margin mt={{ xs: 4 }} />
          <EducationTab match={match} emptyView={false} />
          <Margin mt={{ xs: 4 }} />
          {match?.user?.workHistory.length > 0 && (
            <>
              <RowDescription>Work Experiences</RowDescription>
              <Margin mt={{ xs: 4 }} />
              <WorkExperiencesTab match={match} />
            </>
          )}
          <Margin mt={{ xs: 4 }} />
          {match?.conversation && match?.conversation.length > 0 && (
            <>
              <RowDescription>TG Notes</RowDescription>
              <ConversationTab conversation={match?.conversation[0]} />
            </>
          )}
        </div>
      )}
    </>
  );
}

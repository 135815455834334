import styled from "styled-components";

export const GroupContainer = styled.div`
  width: 100%;
  background-color: #fffff;
  padding: 15px 15px 0px 15px;
  margin: 0px;
  border: 1px solid #64b7dc;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const GroupRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const GroupActionContainer = styled.div`
  text-align: left;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const GroupActionButton = styled.button`
  cursor: pointer;
  height: 37px;
  width: 37px;
  font-weight: bold;
  background: white;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const NewGroupButton = styled.button`
  display: flex;
  height: 60px;
  width: 60px;
  cursor: pointer;
  border: 1px solid #64b7dc;
  background: #ffffff;
  border-radius: 4px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  border-color: ${(props) => (props.hasError ? "#ff7675" : "#64b7dc")};
`;

export const Separator = styled.div`
  padding: 0;
  margin-top: ${({ type }) => (type === "and" ? "20px" : 0)};
  margin-left: ${({ type }) => (type === "and" ? "14px" : "8px")};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
`;

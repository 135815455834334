import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextField,
} from "tg-design";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOM_FLOW } from "../../../../../queries/match";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  @media only screen and (min-width: 601px) {
    flex-direction: row;
  }
`;

export default function CustomFlowEditModal({
  positionId,
  data,
  onCancel,
  onSave,
  handleAddSubcolumn,
}) {
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState(data.substate);
  const [error, setError] = useState(null);
  const [updateCustomFlow] = useMutation(UPDATE_CUSTOM_FLOW);

  const handleUpdate = async () => {
    setSaving(true);

    try {
      await updateCustomFlow({
        variables: {
          positionId,
          state: data.column.id,
          substate: data.substate.uuid,
          ...value,
        },
      });
      onSave();
    } catch (err) {
      setSaving(false);
    }
  };
  const handleSave = () => {
    if (!value.title || value.title.trim().length === 0) {
      setError("The value is required.");
      return;
    }
    if (data?.substate?.title) {
      handleUpdate();
    } else {
      handleAddSubcolumn({ column: data.column, value });
    }
  };

  return (
    <Modal handleClose={onCancel}>
      <ModalHeader>
        {data?.substate?.title ? "Edit Interview Step" : "Add Interview Step"}
      </ModalHeader>
      <ModalBody>
        <TextField
          autoFocus
          isRequired
          label="Title"
          defaultValue={value.title}
          error={error}
          onChange={(event) =>
            setValue({
              ...value,
              title: event.target.value,
            })
          }
          maxLength={25}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          <Button onClick={handleSave} loading={saving} block>
            Save
          </Button>
          <Button outline onClick={onCancel} block>
            Cancel
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
}

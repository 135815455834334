import React from "react";
import styled from "styled-components";
import { SearchIcon } from "../../Icon";
import { debounce } from "../../../utils/helper";

const StyledInput = styled.input`
  background-color: #fff;
  font-size: ${({ fontSize }) => fontSize || "18px"};
  border: none;
  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const Container = styled.div`
  display: flex;
  border: 1px solid #c7c7c7;
  padding: 8px 10px 8px 10px;
  border-radius: 4px;
`;

const Spinner = styled.span`
  display: inline-block;
`;

export default function SearchInput({ onLazyChange, isSearching, ...props }) {
  const lazyCaller = debounce((value) => {
    onLazyChange(value);
  }, 500);

  const handleChange = (event) => {
    lazyCaller(event.target.value);
  };

  return (
    <Container className="search-input">
      <IconContainer>
        {!isSearching && <SearchIcon />}
        {isSearching && <Spinner className="loader">&nbsp;</Spinner>}
      </IconContainer>
      <StyledInput {...props} onChange={handleChange} />
    </Container>
  );
}

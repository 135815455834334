import React from "react";
import { useQuery } from "@apollo/client";
import { TextField, BlockCheckbox } from "tg-design";
import { GET_REJECT_REASON } from "../../../../../queries/shared";
import PartialError from "../../../../ErrorPage/PartialError";

export default function OptionStep({
  failReasons,
  otherReason,
  setOtherReason,
  handleFailReasonChange,
}) {
  const { loading, error, data } = useQuery(GET_REJECT_REASON);

  if (loading) return "loading";
  if (error) return <PartialError />;

  return (
    <>
      {data.allRejectReason.map((item) => (
        <BlockCheckbox
          label={item?.label}
          key={item.id}
          id={item.id}
          name={item.id}
          icon
          value={failReasons.includes(item.id)}
          onChange={(event) => handleFailReasonChange(item.id, event)}
          checked={failReasons.includes(item.id)}
          checkboxStyles={{
            height: "14px",
            width: "14px",
            margin: "0 10px 0 0",
          }}
          containerStyles={{
            width: "unset",
            height: "unset",
          }}
        />
      ))}
      <BlockCheckbox
        label="Other"
        key="other"
        id="other"
        name="other"
        icon
        value={failReasons.includes("other")}
        onChange={(event) => handleFailReasonChange("other", event)}
        checked={failReasons.includes("other")}
        checkboxStyles={{
          height: "14px",
          width: "14px",
          margin: "0 10px 0 0",
        }}
        containerStyles={{
          width: "unset",
          height: "unset",
        }}
      />
      {failReasons.includes("other") && (
        <div style={{ marginTop: "10px" }}>
          <TextField
            value={otherReason}
            autoFocus
            onChange={(e, { value }) => setOtherReason(value)}
          />
        </div>
      )}
    </>
  );
}

import React from "react";
import styled from "styled-components";

const Container = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #111827;
  margin-bottom: ${(props) => (props.mb ? props.mb : "40px")};
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 15px;
  }
`;

const HR = styled.hr`
  border: 1px solid #e5e5e5;
`;

export default function PageTitle({ children, mb, ...props }) {
  const { hr } = props;
  return (
    <>
      <Container mb={mb} {...props}>
        {children}
      </Container>
      {hr && <HR />}
    </>
  );
}

import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import {
  PositionCard,
  Person,
  StatusBarFunnel,
  PositionStats,
  PositionActionButton,
} from "tg-design";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useHistory } from "react-router";
import {
  CheckListIcon,
  ConversationIcon,
  RocketIcon,
} from "../../components/Icon";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import AppContext from "../../contexts/AppContext";
import { GET_ALL_POSITION } from "../../queries/position";
import {
  CONVERSATION_TYPES,
  MATCH_STATES,
  POSITION_STATES,
} from "../../utils/constants";
import { getPositionState } from "../Position/Edit";
import {
  isAllPositionTabsValid,
  positionRequirementCheck,
} from "../Position/Edit/validations";

import {
  ClientPartnerContainer,
  DutyTitle,
  Row,
  RowBlock,
  TitleCotainer,
  TitleDescription,
  WelcomeMessage,
  SectionContainer,
} from "./styles";
import { GET_COMPANY } from "../../queries/company";
import PageLoading from "../../components/PageLoading";
import ErrorPage from "../ErrorPage";

dayjs.extend(relativeTime);

const getDateOfPositionState = (position) => {
  if (position.state === POSITION_STATES.CREATED) {
    return `Created ${dayjs(position.createdAt).fromNow()}`;
  }

  return `Started ${dayjs(
    position?.history[position?.history.length - 1]?.createdAt
  )?.fromNow()}`;
};

const getCardActions = (position) => {
  if (
    position.state === POSITION_STATES.IN_PROGRESS &&
    position.isUnseenConversationForUser
  ) {
    return {
      actionIcon: <ConversationIcon />,
      actionDescription:
        "Your Talent Partner has shared new candidates with you. Please <b>see</b> and <b>reply</b> to them.",
      actionUrl: `/positions/${position.id}/view?tab=matches`,
    };
  }

  if (position.state === POSITION_STATES.CREATED && position.isValidPosition) {
    return {
      actionIcon: <RocketIcon />,
      actionDescription:
        "Details are completed. Please <b>submit</b> the position to start the matching. ",
      actionUrl: `/positions/${position.id}/view?tab=detail`,
    };
  }

  if (position.state === POSITION_STATES.CREATED && !position.isValidPosition) {
    return {
      actionIcon: <CheckListIcon />,
      actionDescription:
        "There is some missing information about the position. Please <b>complete</b>.",
      actionUrl: `/positions/${position.id}/edit?tab=general`,
    };
  }
};

const validator = async (position) => {
  if (position.state === POSITION_STATES.CREATED) {
    const positionState = getPositionState(position);
    const tabValidations = await positionRequirementCheck({
      position: positionState,
    });

    const canBeSubmit = isAllPositionTabsValid(tabValidations);

    return canBeSubmit;
  }
};

export default function DashboardPage() {
  const [data, setData] = useState([]);
  const [recentPositionsData, setRecentPositionsData] = useState([]);
  const history = useHistory();
  const appContext = useContext(AppContext);

  const {
    data: allPositionData,
    loading,
    error: positionQueryError,
  } = useQuery(GET_ALL_POSITION, {
    variables: {
      unseenFilters: {
        state: { in: [MATCH_STATES.COMPANY_ASSESSMENT] },
        conversationType: CONVERSATION_TYPES.MEETING,
      },
      filters: {
        company: appContext.employer.company.id,
      },
      isAccessiblePositions: true,
    },
    fetchPolicy: "no-cache",
  });

  const {
    data: companyData,
    loading: companyLoading,
    error: companyQueryError,
  } = useQuery(GET_COMPANY, {
    variables: {
      id: appContext.employer.company.id,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (allPositionData) {
      const awaitingActionPositions = allPositionData?.allPosition?.positions
        .filter(
          (position) =>
            position.state === POSITION_STATES.CREATED ||
            (position.state === POSITION_STATES.IN_PROGRESS &&
              position.isUnseenConversationForUser)
        )
        .map(async (position) => {
          if (position.state === POSITION_STATES.IN_PROGRESS) {
            return position;
          }

          return {
            ...position,
            isValidPosition: await validator(position),
          };
        });

      // Validator Promise dönüyor, bu yüzden hepsini bekliyoruz.
      Promise.all(awaitingActionPositions).then((results) => {
        setData(results);
      });

      if (awaitingActionPositions.length === 0 || !awaitingActionPositions) {
        const recentPositions = allPositionData?.allPosition?.positions
          .filter(
            (position) =>
              position.state === POSITION_STATES.CREATED ||
              position.state === POSITION_STATES.IN_PROGRESS ||
              position.state === POSITION_STATES.ON_HOLD ||
              position.state === POSITION_STATES.SUBMITTED
          )
          .sort((a, b) => {
            return new Date(a.createdAt) - new Date(b.createdAt);
          });

        setRecentPositionsData(recentPositions);
      }
    }
  }, [allPositionData]);

  if (loading || !data || companyLoading) return <PageLoading />;
  if (positionQueryError || companyQueryError)
    return <ErrorPage error={positionQueryError || companyQueryError} />;

  return (
    <DashboardTemplate>
      <RowBlock mb={36}>
        <WelcomeMessage>
          <span>Welcome, </span>
          <span>{appContext.employer?.fullName || null}</span>
        </WelcomeMessage>
        {companyData.company.clientPartner && (
          <ClientPartnerContainer>
            <DutyTitle>
              <span>Client Partner</span>
              <span>Any questions? Mail us.</span>
            </DutyTitle>
            <Person
              name={companyData?.company?.clientPartner?.name}
              email={companyData?.company?.clientPartner?.email}
              photoURL={companyData?.company?.clientPartner?.avatarURL}
            />
          </ClientPartnerContainer>
        )}
      </RowBlock>

      <SectionContainer>
        <TitleCotainer>
          {data && data.length > 0 ? (
            <span>
              Positions That <span>Require Your Action</span>
            </span>
          ) : (
            <span>Recent Positions</span>
          )}

          <span onClick={() => history.push("positions")}>Show All</span>
        </TitleCotainer>
        <TitleDescription>
          {data && data.length > 0
            ? "  Before proceeding, we require more information on the positions below."
            : "   Here is the latest status of the positions you’re recently working on:"}
        </TitleDescription>
        {recentPositionsData && recentPositionsData.length > 0 && (
          <Row className="position-card-row">
            {recentPositionsData.map((position, index) => {
              if (index >= 3) {
                return;
              }
              return (
                <PositionCard
                  key={index}
                  cardStyle={{ flex: "1 1 0px" }}
                  handleClick={() =>
                    history.push(`/positions/${position.id}/view?tab=detail`)
                  }
                >
                  <PositionCard.Header
                    positionTitle={position.title}
                    positionCreatedAt={getDateOfPositionState(position)}
                    positionState={position.state}
                  />
                  <PositionCard.FirstBody>
                    <Person
                      title="Position Owner"
                      name={position?.mainContact?.fullName || "Not assigned"}
                      photoURL={position?.mainContact?.photoURL}
                    />
                    <Person
                      title="Talent Partner"
                      name={position?.assignee?.name || "Not assigned"}
                      photoURL={position?.assignee?.avatarURL}
                    />
                  </PositionCard.FirstBody>
                  <PositionCard.SecondBody>
                    <Row justifyContent="space-between" marginBottom="14px">
                      <PositionStats title="Matched & Notified">
                        {position.companyReports.notified === 0
                          ? "-"
                          : position.companyReports.notified}
                      </PositionStats>
                      <PositionStats title="Interested">
                        {" "}
                        {position.companyReports.interested === 0
                          ? "-"
                          : position.companyReports.interested}
                      </PositionStats>
                    </Row>
                    <Row justifyContent="space-between">
                      <PositionStats title="Verified">
                        {position.companyReports.verified === 0
                          ? "-"
                          : position.companyReports.verified}
                      </PositionStats>
                      <PositionStats title="Awaiting Action">
                        {position.companyReports.awatingAction === 0
                          ? "-"
                          : position.companyReports.awatingAction}
                      </PositionStats>
                    </Row>
                  </PositionCard.SecondBody>
                </PositionCard>
              );
            })}
          </Row>
        )}
        {data && data.length > 0 && (
          <Row className="position-card-row">
            {data.map((position, index) => {
              if (index >= 3) {
                return;
              }
              return (
                <PositionCard
                  key={index}
                  cardStyle={{ flex: "1 1 0px" }}
                  handleClick={() =>
                    history.push(getCardActions(position).actionUrl)
                  }
                >
                  <PositionCard.Header
                    positionTitle={position.title}
                    positionCreatedAt={getDateOfPositionState(position)}
                    positionState={position.state}
                  />
                  <PositionCard.FirstBody>
                    <Person
                      title="Position Owner"
                      name={position?.mainContact?.fullName || "Not assigned"}
                      photoURL={position?.mainContact?.photoURL}
                    />
                    <Person
                      title="Talent Partner"
                      name={position?.assignee?.name || "Not assigned"}
                      photoURL={position?.assignee?.avatarURL}
                    />
                  </PositionCard.FirstBody>
                  <PositionCard.SecondBody>
                    <Row justifyContent="space-between" marginBottom="14px">
                      <PositionStats title="Matched & Notified">
                        {position.companyReports.notified === 0
                          ? "-"
                          : position.companyReports.notified}
                      </PositionStats>
                      <PositionStats title="Interested">
                        {" "}
                        {position.companyReports.interested === 0
                          ? "-"
                          : position.companyReports.interested}
                      </PositionStats>
                    </Row>
                    <Row justifyContent="space-between">
                      <PositionStats title="Verified">
                        {position.companyReports.verified === 0
                          ? "-"
                          : position.companyReports.verified}
                      </PositionStats>
                      <PositionStats title="Awaiting Action">
                        {position.companyReports.awatingAction === 0
                          ? "-"
                          : position.companyReports.awatingAction}
                      </PositionStats>
                    </Row>
                  </PositionCard.SecondBody>
                  <PositionCard.Footer>
                    <PositionActionButton
                      icon={getCardActions(position).actionIcon}
                    >
                      <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                          __html: getCardActions(position).actionDescription,
                        }}
                      />
                    </PositionActionButton>
                  </PositionCard.Footer>
                </PositionCard>
              );
            })}
          </Row>
        )}
        {data.length === 0 &&
          recentPositionsData.length === 0 &&
          "There are no active positions."}
      </SectionContainer>

      {companyData?.company?.allPositionsCompanyReport?.notified !== 0 && (
        <SectionContainer maxWidth={812}>
          <TitleCotainer>
            <span>
              Your Overall Experience at <span>TalentGrid </span>
            </span>
          </TitleCotainer>
          <TitleDescription>
            You can see how many candidates we’ve reached so far for all your
            positions at TalentGrid.
          </TitleDescription>
          <StatusBarFunnel
            funnel={[
              {
                label: "Matched & notified",
                count:
                  companyData?.company?.allPositionsCompanyReport?.notified,
              },
              {
                label: "Interested in your positions",
                count:
                  companyData?.company?.allPositionsCompanyReport?.interested,
              },
              {
                label: "Passed to the pre-screening",
                count:
                  companyData?.company?.allPositionsCompanyReport
                    ?.inTGAssessment,
              },

              {
                label: "Profile shared for your review",
                count:
                  companyData?.company?.allPositionsCompanyReport
                    ?.companyAssessment,
              },
              {
                label: "Interview with you",
                count:
                  companyData?.company?.allPositionsCompanyReport
                    ?.hiringProcess,
              },
              {
                label: "Offer Sent",
                count:
                  companyData?.company?.allPositionsCompanyReport?.sentOffer,
              },
              {
                label: "Hired ",
                count: companyData?.company?.allPositionsCompanyReport?.hired,
              },
            ]}
          />
        </SectionContainer>
      )}
    </DashboardTemplate>
  );
}

/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { CommentIcon, LinkedInIcon } from "../../../../../components/Icon";
import {
  MATCH_STATES,
  COMMENT_NOT_ALLOWED_STATES,
} from "../../../../../utils/constants";

const LinkedInLink = ({ link, name }) => {
  if (link) {
    return /^https?:\/\//.test(link) ? (
      <a
        className="no-drawer"
        target="_blank"
        href={link}
        onClick={(e) => e.stopPropagation()}
      >
        <LinkedInIcon />
      </a>
    ) : (
      <a
        className="no-drawer"
        target="_blank"
        href={`https://${link}`}
        onClick={(e) => e.stopPropagation()}
      >
        <LinkedInIcon />
      </a>
    );
  }

  return (
    <a
      className="no-drawer"
      target="_blank"
      href={`https://www.linkedin.com/search/results/all/?keywords=${name}`}
      onClick={(e) => e.stopPropagation()}
    >
      <LinkedInIcon color="#999" />
    </a>
  );
};

const Badge = ({ children, count, color }) => {
  return (
    <div style={{ position: "relative" }}>
      <span
        style={{
          position: "absolute",
          top: -6,
          right: -6,
          background: color,
          borderRadius: "100%",
          color: "#fff",
          fontSize: 9,
          width: 15,
          height: 15,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {count > 9 ? "9+" : count}
      </span>
      {children}
    </div>
  );
};

export default function MatchPreview({ match }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 12, marginBottom: "5px" }}>
          {match.user.name}
        </div>
        <div style={{ fontSize: 12 }}>
          {match.user.role?.title || "Missing Role"}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "-3px",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {!COMMENT_NOT_ALLOWED_STATES.includes(match.state) &&
        match.unseenConversationCount ? (
          <Badge color="#8FDEAC" count={match.unseenConversationCount}>
            <span style={{ color: "#9CA3AF" }}>
              <CommentIcon />
            </span>
          </Badge>
        ) : (
          <div />
        )}
        <LinkedInLink
          link={
            match.state !== MATCH_STATES.SENT &&
            match.user.social &&
            match.user.social.linkedin
          }
          name={match.user.name}
        />
      </div>
    </div>
  );
}

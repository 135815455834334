import React from "react";
import { useHistory } from "react-router";
import { NotFoundError } from "tg-design";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";

export default function NotFoundPage() {
  const history = useHistory();

  const handleOnClick = () => {
    history.push("/");
  };
  return (
    <DashboardTemplate dontShowMenuItems>
      <NotFoundError onClickButton={handleOnClick} />
    </DashboardTemplate>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import { DropdownIcon } from "../Icon";

const Container = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background: #dadada;
  padding: 6px 8px;
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  width: 90%;
`;

const IconContainer = styled.div`
  width: 10%;
  margin: 0 auto;
  text-align: center;
`;

const Info = styled.div`
  background: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 8px;
`;

export const DropdownInfo = ({ title, info }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <Container>
      <TitleContainer
        onClick={() => setShowInfo(!showInfo)}
        borderRadius={showInfo ? "5px 5px 0 0" : "5px"}
      >
        <Title>{title}</Title>
        <IconContainer>
          <DropdownIcon />
        </IconContainer>
      </TitleContainer>
      {showInfo && (
        <Info
          dangerouslySetInnerHTML={{
            __html: info,
          }}
        />
      )}
    </Container>
  );
};

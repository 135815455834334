import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 7px #9ca3af;
  border-radius: 4px;
  padding: 30px 40px;
  display: flex;
  min-height: 150px;
  margin-bottom: 50px;

  &.is-disabled {
    opacity: 0.3;
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const CardLogoContainer = styled.div`
  width: 120px;
  height: 120px;
  margin-right: 46px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const CardContent = styled.div`
  width: 100%;
`;

const CardTitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #161616;
  margin: 0px;

  @media only screen and (max-width: 768px) {
    text-align: center;
    margin-bottom: 13px;
  }
`;

const CardDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  color: #161616;
  margin: 0px;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const CardChildContainer = styled.div`
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

export default function Card({ icon, title, description, children, disabled }) {
  return (
    <CardContainer className={disabled ? "is-disabled" : ""}>
      <CardLogoContainer>{icon}</CardLogoContainer>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
        <CardChildContainer>{children}</CardChildContainer>
      </CardContent>
    </CardContainer>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
`;

const LeftContainer = styled.div`
  width: 50%;
  padding: 60px 80px;

  @media only screen and (max-width: 768px) {
    padding: 32px 38px;
    width: 100%;
  }
`;

const SplashContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const SplashImage = styled.img`
  flex-shrink: 0;
  min-height: 100%;
  min-width: 100%;
`;

export default function AuthTemplate({ children }) {
  return (
    <Container>
      <LeftContainer>
        <Link to="/">
          <img src="/images/logo.png" alt="Talentgrid.io Logo" />
        </Link>
        {children}
      </LeftContainer>
      <SplashContainer>
        <SplashImage
          src="/images/register.png"
          alt="Talentgrid.io Company Registration Page"
        />
      </SplashContainer>
    </Container>
  );
}

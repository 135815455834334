import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { RightArrow } from "../../Icon";
import {
  Row,
  StatusCard,
  DeveloperCard,
  DeveloperName,
  DeveloperRole,
  Description,
  SecondaryDescription,
} from "./style";

export default function StatusChangeModal({
  onClose: closeModal,
  onAccept: acceptChange,
  user,
  status,
}) {
  return (
    <Modal handleClose={closeModal} width="575px">
      <ModalHeader>
        <Description>
          Are you sure you want to update the status of this match?
        </Description>
        <SecondaryDescription>
          The talent will be notified about this action.
        </SecondaryDescription>
      </ModalHeader>
      <ModalBody>
        <Row>
          <DeveloperCard>
            <DeveloperName>{user.name}</DeveloperName>
            <DeveloperRole style={{ justifyContent: "none" }}>
              {user.role}
            </DeveloperRole>
          </DeveloperCard>
          <RightArrow />
          <StatusCard>{status}</StatusCard>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={acceptChange}
          style={{
            width: 110,
          }}
        >
          Yes
        </Button>
        <Button
          type="button"
          outline
          onClick={closeModal}
          style={{
            width: 110,
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

import styled from "styled-components";
import { space } from "styled-system";

export const RowBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  justify-content: ${({ justify }) => justify};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }

  ${space}
`;

export const Row = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  height: 100%;
  gap: 15px;

  &.position-card-row {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;
export const ClientPartnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 398px;
  height: 76px;

  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 2px 10px rgba(229, 229, 229, 0.5));
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WelcomeMessage = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #111827;

  @media (max-width: 768px) {
    margin-bottom: 38px;
  }
`;

export const DutyTitle = styled.div`
  display: flex;
  flex-direction: column;

  & :nth-child(1) {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #111827;
  }

  & :nth-child(2) {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #979797;
  }
`;

export const TitleCotainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  margin-bottom: 12px;
  height: 36px;

  & :nth-child(1) {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #111827;
    @media (max-width: 768px) {
      & span {
        display: block;
      }
    }
  }

  & :nth-child(2) {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #64b7dc;
    cursor: pointer;
    &:hover {
      color: #373737;
    }
  }
  @media (max-width: 768px) {
    border: none;
    margin-bottom: 18px;
  }
`;

export const TitleDescription = styled.div`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #979797;
  margin-bottom: 14px;
`;

export const SectionContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
  max-width: ${({ maxWidth }) => maxWidth}px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { Button, FormWrapper, TextField, Checkbox, message } from "tg-design";
import { useLocationQuery } from "../../utils/helper";
import { VERIFY_EMPLOYER_TOKEN } from "../../queries/employer";
import { SIGNUP_AS_EMPLOYER } from "../../queries/auth";
import {
  setPasswordValidationSchema,
  localKvkkAcceptValidationSchema,
} from "../../utils/validations";
import { PRIVACY_POLICY_TYPES } from "../../utils/constants";
import AuthTemplate from "../../components/Templates/AuthTemplate";
import { DownPointingIcon } from "../../components/Icon";
import PageLoading from "../../components/PageLoading";
import AppContext from "../../contexts/AppContext";

const FormContainer = styled.div`
  max-width: 450px;
  * {
    margin-left: 0;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const IconContainer = styled.div`
  display: inline;
  margin-top: 3px;
  position: absolute;
  margin-left: 10px;
`;

const inputStyles = {
  marginBottom: "20px",
};

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 60px;

  @media only screen and (max-width: 768px) {
    line-height: 34px;
  }
`;

export default function Register() {
  const history = useHistory();
  const query = useLocationQuery();
  const appContext = useContext(AppContext);
  const [isSaving, setIsSaving] = useState(false);
  const [signupAsEmployer] = useMutation(SIGNUP_AS_EMPLOYER);

  const [clickedBefore, setClickedBefore] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    passwordLength: true,
    passwordMatch: true,
    termsOfUse: true,
    localKvkkAccept: true,
  });

  const { loading, error, data } = useQuery(VERIFY_EMPLOYER_TOKEN, {
    variables: { token: query.get("invite") },
  });

  if (loading) return <PageLoading />;
  if (error) {
    message.error("Unacceptable invite code");
    history.push("/login");
    return null;
  }

  const { verifyEmployerToken: employer } = data;

  const handleRegister = async (
    e,
    { isAccepted, password, passwordRetry, localKvkkAccept = false }
  ) => {
    setClickedBefore(true);
    const passwordData = {
      password,
      passwordRetry,
    };
    const kvkkData = {
      localKvkkAccept,
      country: window.country,
    };

    if (
      setPasswordValidationSchema.isValidSync(passwordData) &&
      localKvkkAcceptValidationSchema.isValidSync(kvkkData) &&
      isAccepted
    ) {
      try {
        setIsSaving(true);
        await signupAsEmployer({
          variables: {
            token: query.get("invite"),
            email: employer.email,
            password,
            userAgreement: isAccepted,
            localKvkkAccept,
          },
        });
        setIsSaving(false);
        appContext.refresh();
        localStorage.setItem("isAuth", true);
        history.push("/");
      } catch (err) {
        setIsSaving(false);
        // eslint-disable-next-line no-console
        console.error(err);
      }
    } else {
      setValidationErrors({
        ...validationErrors,
        passwordLength:
          setPasswordValidationSchema.fields.password.isValidSync(password),
        passwordMatch: setPasswordValidationSchema.isValidSync(passwordData),
        termsOfUse: isAccepted,
        localKvkkAccept: localKvkkAcceptValidationSchema.isValidSync({
          localKvkkAccept,
          country: window.country,
        }),
      });
    }
  };

  const handleChange = (
    e,
    { isAccepted, password, passwordRetry, localKvkkAccept }
  ) => {
    if (!clickedBefore) {
      return;
    }
    setValidationErrors({
      ...validationErrors,
      passwordLength:
        setPasswordValidationSchema.fields.password.isValidSync(password),
      passwordMatch: setPasswordValidationSchema.isValidSync({
        passwordRetry,
        password,
      }),
      termsOfUse: isAccepted,
      localKvkkAccept: localKvkkAcceptValidationSchema.isValidSync({
        localKvkkAccept,
        country: window.country,
      }),
    });
  };

  return (
    <AuthTemplate>
      <Title>
        You’re invited to create your account
        <IconContainer>
          <DownPointingIcon />
        </IconContainer>
      </Title>
      <FormWrapper onChange={handleChange} onSubmit={handleRegister}>
        <FormContainer>
          <TextField
            label="Name"
            style={inputStyles}
            value={employer.fullName}
            disabled
          />
          <TextField
            label="Email"
            style={inputStyles}
            value={employer.email}
            disabled
          />
          <TextField
            label="Company"
            style={inputStyles}
            value={employer.company.name}
            disabled
          />
          <div style={inputStyles}>
            <TextField
              name="password"
              type="password"
              label="Set a password"
              autoFocus
              error={
                validationErrors.passwordLength === false
                  ? "Password must be at least 6 characters."
                  : null
              }
            />
          </div>

          <div style={inputStyles}>
            <TextField
              name="passwordRetry"
              type="password"
              label="Retype the password"
              error={
                validationErrors.passwordMatch === false
                  ? "Passwords must match."
                  : null
              }
            />
          </div>
          <Checkbox name="isAccepted">
            <span style={{ cursor: "pointer" }}>
              I have read and accepted the{" "}
              <a
                href="https://talentgrid.io/wp-content/uploads/2023/04/TalentGrid-Terms-of-Business-.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Business{" "}
              </a>
              <span>and </span>
              <a
                href="https://talentgrid.io/en/privacy-policy-companies/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </span>
          </Checkbox>
          {!validationErrors.termsOfUse && (
            <div
              style={{
                color: "#ff7675",
                fontSize: "14px",
                fontWeight: 600,
                marginTop: "2px",
              }}
            >
              You have to accept the Terms of Business and Privacy Policy
            </div>
          )}
          {window.country === PRIVACY_POLICY_TYPES.TR && (
            <Checkbox name="localKvkkAccept">
              <span style={{ cursor: "pointer" }}>
                I have read and accepted the{" "}
                <a
                  href="https://talentgrid.io/tr/kvkk-bilgilendirme-metni/"
                  target="_blank"
                  rel="noreferrer"
                >
                  KVKK clarification text
                </a>
                .
              </span>
            </Checkbox>
          )}
          {!validationErrors.localKvkkAccept && (
            <div
              style={{
                color: "#ff7675",
                fontSize: "14px",
                fontWeight: 600,
                marginTop: "2px",
              }}
            >
              You have to accept the KVKK clarification text
            </div>
          )}
          <Button
            type="submit"
            style={{ marginTop: "20px", width: "100%" }}
            loading={isSaving}
          >
            Create My Account
          </Button>
        </FormContainer>
      </FormWrapper>
    </AuthTemplate>
  );
}

import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "tg-design";
import device from "../../styles/device";
import { Row } from "../../components/Form/BlockUi";
import ReadMore from "../../components/ReadMore";
import logoImage from "../../images/talentgrid-logo-dark.png";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ErrorHeader = styled.p`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 48px;
  text-align: center;
  line-height: 58px;
  @media ${device.mobileL} {
    font-size: 72px;
    line-height: 100px;
  }
`;

const ErrorMessageContainer = styled.div`
  background: #f2f2f2;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.25));
  margin: 30px 10px;
  border-radius: 5px;
  font-size: 14px;
  padding: 20px 10px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  padding: 14px 0;
  height: 55px;
  box-shadow: 0 4px 10px 0 #ededed;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 28px;
  object-position: center;
  @media ${device.tablet} {
    object-position: left;
  }
`;

const ErrorPageHeader = ({ returnHome }) => {
  return (
    <HeaderWrapper>
      <Container>
        <Row width="100%">
          <LogoContainer>
            <Logo src={logoImage} onClick={returnHome} />
          </LogoContainer>
        </Row>
      </Container>
    </HeaderWrapper>
  );
};

export default function ErrorPage({ error }) {
  const history = useHistory();

  const refreshPage = () => {
    window.location.reload();
  };

  const returnHome = () => {
    history.push("/");
    refreshPage();
  };

  return (
    <>
      <ErrorPageHeader returnHome={returnHome} />
      <Container>
        <ErrorHeader>Something Went Wrong</ErrorHeader>
        <ErrorMessageContainer>
          <ReadMore text={error.message} limit={250} />
        </ErrorMessageContainer>
        <ButtonContainer>
          <Button style={{ width: "280px" }} onClick={refreshPage}>
            Try Again
          </Button>
          <Button outline style={{ width: "280px" }} onClick={returnHome}>
            Home
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
}

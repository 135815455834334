import React from "react";
import Divider from "../../../../../components/Divider";

import GeneralInfoTable from "./tables/GeneralInfoTable";
import RequirementsTable from "./tables/RequirimentsTable";
import BenefitsTable from "./tables/BenefitsTable";

export default function DetailTab({ positionData }) {
  const positionItem = positionData;

  return (
    <>
      <Divider text="General Info" />
      <GeneralInfoTable positionItem={positionItem} />
      <Divider text="Requirements" />
      <RequirementsTable positionItem={positionItem} />
      <Divider text="Benefits" />
      <BenefitsTable positionItem={positionItem} />
    </>
  );
}

import * as Yup from "yup";
import clearHTMLTags from "../../../../utils/yup-extensions/clearHTMLTags";

Yup.addMethod(Yup.string, "clearHTMLTags", clearHTMLTags);

export const generalValidationRules = Yup.object().shape({
  title: Yup.string().trim().required("This field can not be empty"),
  role: Yup.string().required("This field can not be empty"),
  description: Yup.string()
    .clearHTMLTags()
    .trim()
    .required("This field can not be empty"),
  recruitmentStages: Yup.string()
    .trim()
    .required("This field can not be empty"),
});

const generalCriteriasRules = Yup.object().shape({
  positionLocation: Yup.object().shape({
    remote: Yup.string().nullable().required(),
    expected: Yup.string().when("remote", {
      is: (remote) =>
        remote?.split(",").includes("half") ||
        remote?.split(",").includes("none"),
      then: Yup.string().required().nullable(),
      otherwise: Yup.string().nullable(),
    }),
    relocation: Yup.boolean().when("remote", {
      is: (remote) =>
        remote?.split(",").includes("half") ||
        remote?.split(",").includes("none"),
      then: Yup.boolean().required().nullable(),
      otherwise: Yup.boolean().nullable(),
    }),
    visaSupport: Yup.boolean().when("remote", {
      is: (remote) =>
        remote?.split(",").includes("half") ||
        remote?.split(",").includes("none"),
      then: Yup.boolean().required().nullable(),
      otherwise: Yup.boolean().nullable(),
    }),
  }),
});

const requirementTabRules = Yup.object().shape({
  totalExperience: Yup.object().shape({
    min: Yup.number()
      .nullable()
      .test("lowerThanMax", (min, testContext) => {
        const { max } = testContext.parent;
        if (!max) {
          return false;
        }
        return min <= max;
      })
      .required(),
    max: Yup.number()
      .nullable()
      .test("greaterThanMin", (max, testContext) => {
        const { min } = testContext.parent;
        if (!min) {
          return false;
        }
        return max >= min;
      })
      .required(),
  }),
});

const benefitsTabRules = Yup.object().shape({
  budget: Yup.object().shape({
    min: Yup.number()
      .nullable()
      .test("lowerThanMax", (min, testContext) => {
        const { max } = testContext.parent;
        if (!max) {
          return false;
        }
        return min <= max;
      })
      .required(),
    max: Yup.number()
      .nullable()
      .test("greaterThanMin", (max, testContext) => {
        const { min } = testContext.parent;
        if (!min) {
          return false;
        }
        return max >= min;
      })
      .required(),
    isVisibleToUser: Yup.boolean().nullable().required(),
  }),
  stockOption: Yup.object().shape({
    isProvided: Yup.boolean().nullable().required(),
    isVisibleToUser: Yup.boolean().nullable().required(),
    isRange: Yup.string().when("isProvided", {
      is: false,
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().required(),
    }),
    max: Yup.number().when("isRange", {
      is: "RANGE",
      then: Yup.number()
        .nullable()
        .test("greaterThanMin", (max, testContext) => {
          const { isProvided, min } = testContext.parent;
          if (isProvided === false) {
            return true;
          }
          if (!min) {
            return false;
          }
          return max >= min;
        }),
      otherwise: Yup.number().nullable(),
    }),
    min: Yup.number().when("isRange", {
      is: "RANGE",
      then: Yup.number()
        .nullable()
        .test("lowerThanMax", (min, testContext) => {
          const { isProvided, max } = testContext.parent;
          if (isProvided === false) {
            return true;
          }

          if (!max) {
            return false;
          }
          return min <= max;
        }),
      otherwise: Yup.number().nullable(),
    }),
    certainValue: Yup.number().when("isRange", {
      is: "CERTAIN",
      then: Yup.number().min(0.01).nullable().required(),
      otherwise: Yup.number().nullable(),
    }),
  }),
});

const check = async ({ data, rules }) => {
  try {
    await rules.validate(data, { abortEarly: false });
    return { status: true, fields: [] };
  } catch (error) {
    return { status: false, fields: error.inner.map((item) => item.path) };
  }
};

const checkGeneralTab = async ({ data }) => {
  const basic = await check({ data, rules: generalValidationRules });

  const criterias = await check({
    data: data.criteria,
    rules: generalCriteriasRules,
  });

  basic.status = basic.status && criterias.status;
  basic.fields.push(...criterias.fields.map((item) => `criteria.${item}`));

  return basic;
};

const checkRequirementTab = async ({ data }) => {
  const result = await check({
    data,
    rules: requirementTabRules,
  });

  if (
    !data ||
    !data.technologies ||
    !data.technologies.expected ||
    data.technologies.expected.length === 0
  ) {
    result.status = false;
    result.fields.push("technologies");
  }

  result.fields = result.fields.map((item) => {
    if (item.includes(".")) {
      const [root] = item.split(".");
      return `criteria.${root}`;
    }
    return `criteria.${item}`;
  });

  return result;
};

const checkBenefitsTab = async ({ data }) => {
  const result = await check({
    data,
    rules: benefitsTabRules,
  });

  result.fields = result.fields.map((item) => {
    if (item.includes(".")) {
      const [root, second] = item.split(".");
      if (second === "isVisibleToUser" || second === "isProvided") {
        return `criteria.${root}.${second}`;
      }
      return `criteria.${root}`;
    }
    return `criteria.${item}`;
  });

  return result;
};

export default async ({ position }) => {
  if (!position) {
    return {};
  }

  return {
    general: await checkGeneralTab({ data: position }),
    requirements: await checkRequirementTab({
      data: position.criteria,
    }),
    benefits: await checkBenefitsTab({
      data: position.criteria,
    }),
  };
};

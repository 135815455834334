import React from "react";
import styled from "styled-components";
import { InputExplanation } from "../../../../../../components/Form/FormScaffold";
import {
  EDUCATION_TYPES,
  LANGUAGE_LEVEL_TYPES,
} from "../../../../../../utils/constants";
import SkillDiagram from "../../../../../../components/SkillDiagram";
import { RowContainer, DescriptionCell, ValueCell } from "./styles";

const Row = styled.span``;

const getEducationDepartment = function (criteria) {
  let educationDepartment = "-";
  if (
    criteria &&
    criteria.education &&
    criteria.education.branches.length > 0
  ) {
    educationDepartment = criteria.education.branches.map(
      (department) => department.label
    );

    return educationDepartment.join(", ");
  }

  return educationDepartment;
};

const getOtherSkills = function (criteria) {
  let niceToHaveSkills = "-";
  if (
    criteria &&
    criteria.technologies &&
    criteria.technologies.plus &&
    criteria.technologies.plus.length > 0
  ) {
    niceToHaveSkills = criteria.technologies.plus.map(
      (otherSkill) => otherSkill.title
    );

    return niceToHaveSkills.join(", ");
  }

  return niceToHaveSkills;
};

const getTotalExperience = function (criteria) {
  const experience = {
    min: "-",
    max: "-",
  };
  if (criteria && criteria.totalExperience.min) {
    experience.min = criteria.totalExperience.min;
  }
  if (criteria && criteria.totalExperience.max) {
    experience.max = criteria.totalExperience.max;
  }
  return experience;
};

const getUniversity = function (criteria) {
  let universities = "-";
  if (
    criteria &&
    criteria.education &&
    criteria.education.universities.length > 0
  ) {
    universities = criteria.education.universities.map(
      (university) => university.name
    );

    return universities.join(", ");
  }

  return universities;
};

const getLanguage = function (criteria) {
  const selectedLanguageInfo = [];

  if (criteria && criteria.languages && criteria.languages.language) {
    selectedLanguageInfo.push(criteria.languages.language.label);

    if (criteria.languages.languageLevel) {
      selectedLanguageInfo.push(
        LANGUAGE_LEVEL_TYPES[criteria.languages.languageLevel - 1].label
      );
    }

    return selectedLanguageInfo.join(", ");
  }

  return "-";
};

const getSkills = function (criteria, field) {
  if (
    criteria &&
    criteria.technologies &&
    criteria.technologies[field] &&
    criteria.technologies[field].length > 0
  ) {
    return <SkillDiagram diagramData={criteria.technologies[field]} />;
  }

  return "-";
};

const getEducationLevel = function (criteria) {
  let selectedEducationLevel = "-";
  if (
    criteria &&
    criteria.education &&
    (criteria.education.type || criteria.education.type === 0)
  ) {
    selectedEducationLevel = EDUCATION_TYPES[criteria.education.type].label;

    return selectedEducationLevel;
  }

  return selectedEducationLevel;
};

export default function RequirementsTable({ positionItem }) {
  return (
    <>
      <RowContainer>
        <DescriptionCell>Required Experience</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            <Row isInTable="true" style={{ paddingRight: "5px" }}>
              Min: {getTotalExperience(positionItem.criteria).min}
            </Row>
            <Row isInTable="true">
              Max:{getTotalExperience(positionItem.criteria).max}
            </Row>
          </InputExplanation>
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Required languages & frameworks</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getSkills(positionItem.criteria, "expected")}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Other required skills</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getSkills(positionItem.criteria, "otherExpected")}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Nice to Have skills</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getOtherSkills(positionItem.criteria)}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Education Level</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getEducationLevel(positionItem.criteria)}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>University</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getUniversity(positionItem.criteria)}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Education Department</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getEducationDepartment(positionItem.criteria)}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Language</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getLanguage(positionItem.criteria)}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
    </>
  );
}

import React from "react";
import styled from "styled-components";
import { Tooltip } from "tg-design";
import { InfoIcon } from "../Icon";

const Container = styled.div`
  display: inline-flex;
  gap: 320px;
`;

const KanbanTitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const KanbanTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  margin-right: 2px;
`;

const InfoContainer = styled.div`
  padding-top: 5px;
`;

const SeperatorContainer = styled.div`
  display: inline-flex;
  padding-bottom: 10px;
  gap: 22px;
`;

const Seperator = styled.div`
  line-height: 10px;
  border: 1px solid #dadada;
  width: ${(props) => props.width && props.width};
`;

export default function TgKanbanTitle() {
  return (
    <>
      <Container>
        <KanbanTitleContainer>
          <KanbanTitle>
            TG Talent Partners reached out talents for you
          </KanbanTitle>
          <Tooltip
            text={
              <div
                style={{
                  width: "260px",
                }}
              >
                You can only view candidate cards here, but you cannot move
                them.
              </div>
            }
          >
            <InfoContainer>
              <InfoIcon />
            </InfoContainer>
          </Tooltip>
        </KanbanTitleContainer>

        <KanbanTitleContainer>
          <KanbanTitle>
            You review and evaluate among verified talents
          </KanbanTitle>
          <Tooltip
            text={
              <div
                style={{
                  width: "260px",
                }}
              >
                This is your playground to review the candidates. You can drag
                the cards here.
              </div>
            }
            place="top"
          >
            <InfoContainer>
              <InfoIcon />
            </InfoContainer>
          </Tooltip>
        </KanbanTitleContainer>
      </Container>
      <SeperatorContainer>
        <Seperator width="685px" />
        <Seperator width="calc(100% - 730px)" />
      </SeperatorContainer>
    </>
  );
}

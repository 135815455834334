import React from "react";
import ProgressBar from "../../../../../components/ProgressBar";
import { BoxTitle, RowBreaker } from "./shared";
import { toPercent } from "../../../../../utils/helper";

export default function HiringFunnelReports({ position }) {
  const { companyReports: reports } = position;

  return (
    <>
      <BoxTitle>Hiring funnel</BoxTitle>
      <ProgressBar
        value={reports.notified}
        percent={reports.notified > 0 ? 100 : 0}
      >
        Total candidates matched and notified
      </ProgressBar>
      <ProgressBar
        value={reports.interested}
        percent={toPercent(reports.interested, reports.notified)}
      >
        Showed interest in position
      </ProgressBar>
      <ProgressBar
        value={reports.inTGAssessment}
        percent={toPercent(reports.inTGAssessment, reports.notified)}
      >
        Pre-vetted by TG team
      </ProgressBar>
      <ProgressBar
        value={reports.verified}
        percent={toPercent(reports.verified, reports.notified)}
      >
        Verified by TG team
      </ProgressBar>
      <ProgressBar
        value={reports.hiringProcess}
        percent={toPercent(reports.hiringProcess, reports.notified)}
      >
        Passed onto hiring process
      </ProgressBar>
      <RowBreaker />
      <ProgressBar
        value={reports.notResponded}
        percent={toPercent(reports.notResponded, reports.notified)}
        variant="error"
      >
        Not responded
      </ProgressBar>
      <ProgressBar
        value={reports.passed}
        percent={toPercent(reports.passed, reports.notified)}
        variant="error"
      >
        Not interested
      </ProgressBar>
      <ProgressBar
        value={reports.failed}
        percent={toPercent(reports.failed, reports.notified)}
        variant="error"
      >
        Disqualified
      </ProgressBar>
    </>
  );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { BoxTitle } from "./shared";
import {
  GET_POSITION_REPORTS,
  GET_REJECT_REASONS,
} from "../../../../../queries/position";
import { toPercent } from "../../../../../utils/helper";
import { REJECT_REASON_TYPES } from "../../../../../utils/constants";
import PartialError from "../../../../ErrorPage/PartialError";

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Bullet = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  background-color: #8fdeac;
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 3px;
`;

const DataContainer = styled.div``;

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #1a1a1a;
`;

const ValueContainer = styled.div`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
`;

const Value = styled.div`
  color: #1a1a1a;
  min-width: 30px;
`;

const Percent = styled.div`
  color: #9ca3af;
`;

const EmptyDataStyles = styled.p`
  margin: 0px;
  padding: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
`;

const BulletDataItem = ({ value, percent, children }) => {
  return (
    <Container>
      <Bullet />
      <DataContainer>
        <Text>{children}</Text>
        <ValueContainer>
          <Value>{value}</Value>
          <Percent>{percent}%</Percent>
        </ValueContainer>
      </DataContainer>
    </Container>
  );
};

const EmptyData = () => {
  return (
    <EmptyDataStyles>
      We don&#39;t have any details on user rejection feedback so far.
    </EmptyDataStyles>
  );
};

export default function NotInterestingReports({ position }) {
  const [reasons, setReasons] = useState(null);
  const { data: positionReports, error: positionError } = useQuery(
    GET_POSITION_REPORTS,
    {
      variables: { id: position.id },
    }
  );
  const { data: rejectReasons, error: rejectReasonError } = useQuery(
    GET_REJECT_REASONS,
    {
      variables: { type: REJECT_REASON_TYPES.DEVELOPER },
    }
  );

  useEffect(() => {
    if (!positionReports || !rejectReasons) {
      return;
    }

    const {
      positionReports: { rejectReasonStats },
    } = positionReports;
    const { allRejectReason } = rejectReasons;

    const totalPassCount = (
      positionReports.positionReports.rejectReasonStats || []
    )
      .map((i) => i.count)
      .reduce((a, b) => a + b, 0);

    // Ortak pas nedenleri hesaplanıyor
    const newState = allRejectReason.map((reason) => {
      let stat = rejectReasonStats.find(
        (item) => item.id.toString() === reason.id.toString()
      );

      if (!stat) {
        stat = {
          count: 0,
        };
      }
      return {
        ...reason,
        count: stat.count,
        percent: toPercent(stat.count, totalPassCount),
      };
    });

    // Özel olarak yazılmış olanlar ekleniyor.
    const commonReasonIds = allRejectReason.map((item) => item.id);
    const customReasons = rejectReasonStats
      .filter((item) => !commonReasonIds.includes(item.id))
      .map((item) => {
        return {
          ...item,
          percent: toPercent(item.count, totalPassCount),
        };
      });
    newState.push(...customReasons);
    newState.sort((a, b) => b.count - a.count);

    setReasons(newState);
  }, [positionReports, rejectReasons]);

  if (positionError || rejectReasonError) return <PartialError />;

  return (
    <>
      <BoxTitle>Reasons for not being interested</BoxTitle>
      {reasons !== null &&
        reasons
          .filter((reason) => reason.count)
          .map((reason, index) => (
            <BulletDataItem
              key={index}
              value={reason.count}
              percent={reason.percent}
            >
              {reason?.label}
            </BulletDataItem>
          ))}
      {reasons !== null && reasons.length === 0 && <EmptyData />}
    </>
  );
}

import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextField,
  FormScaffold,
  message,
} from "tg-design";
import { v4 as uuidv4 } from "uuid";
import { UPLOAD_IMAGE } from "../../../../queries/company";
import Uploader from "../../../../components/Uploader";
import { getUnsignedUrl } from "../../../../utils/helper";
import {
  Description,
  SecondaryDescription,
} from "../../../../components/TgKanban/StatusChangeModal/style";

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ImagePreview = styled.img`
  width: 100%;
`;

const converToSimpleFile = (file) => {
  return {
    uuid: uuidv4(),
    url: file.url,
    isUploading: false,
    isDeleting: false,
    description: file.description,
  };
};

const convertToModel = (file) => {
  return {
    url: getUnsignedUrl(file.url),
    description: file.description,
  };
};

export default function UploadOfficePhotos({
  handleOnChangeValue,
  officePhotos,
  onStarted,
  onError,
}) {
  const [files, setFiles] = useState(
    officePhotos.map(converToSimpleFile) || []
  );
  const [isModalOpen, setModal] = useState(false);
  const [targetImage, setTargetImage] = useState(null);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);

  const handleModalClose = () => {
    setModal(false);
  };

  const handleSelection = async (selectedFiles) => {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setTargetImage({
        file: selectedFiles[0],
        content: reader.result,
        description: "",
      })
    );
    reader.readAsDataURL(selectedFiles[0]);
    setModal(true);
  };

  const handleDescription = (event, { value }) => {
    setTargetImage({
      ...targetImage,
      description: value,
    });
  };

  const handleUpload = async () => {
    try {
      onStarted();
      setModal(false);
      const file = {
        uuid: uuidv4(),
        isUploading: true,
      };
      let newFiles = [...files, file];
      setFiles(newFiles);
      const response = await uploadImage({
        variables: { image: targetImage.file },
      });
      newFiles = newFiles.map((item) => {
        if (item.uuid === file.uuid) {
          return {
            ...item,
            isUploading: false,
            description: targetImage.description,
            url: response.data.uploadCompanyImage.imageUrl,
          };
        }
        return item;
      });
      setFiles(newFiles);
      handleOnChangeValue("officePhotos", newFiles.map(convertToModel));
      setTargetImage(null);
    } catch (error) {
      message.error(error.graphQLErrors[0].message);
      setFiles(files);

      if (onError) {
        onError();
      }
    }
  };

  const handleDelete = (file) => {
    try {
      onStarted();
      const newFiles = files.filter((item) => item.url !== file.url);
      handleOnChangeValue("officePhotos", newFiles.map(convertToModel));
      setFiles(newFiles);
    } catch (error) {
      message.error("Logo deletion failed");
      if (onError) {
        onError();
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal handleClose={handleModalClose}>
          <ModalHeader>
            <Description>Save the image</Description>
            <SecondaryDescription>
              PNG, JPG and JPEG files with less than 10 MB size are supported.
            </SecondaryDescription>
          </ModalHeader>
          <ModalBody>
            {targetImage && (
              <>
                <ModalContainer>
                  <ImagePreview src={targetImage.content} alt="Target" />
                </ModalContainer>
                <FormScaffold
                  options={{ showStatus: false }}
                  label="What is the picture about?"
                  formBodyStyles={{ padding: "0px !important" }}
                  style={{ marginBottom: "0px" }}
                >
                  <TextField
                    placeholder="Description"
                    otherStyle={{ display: "block" }}
                    style={{ width: "100%" }}
                    onChange={handleDescription}
                    defaultValue={targetImage.description}
                  />
                </FormScaffold>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleUpload}>Save the picture</Button>
          </ModalFooter>
        </Modal>
      )}
      <Uploader
        max={100}
        multiple={false}
        accept="image/jpg, image/png, image/jpeg"
        onSelection={handleSelection}
        onDelete={handleDelete}
        files={files}
      />
    </>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { RadioGroup, Checkbox, message } from "tg-design";
import AppContext from "../../../contexts/AppContext";
import {
  EMPLOYER_POSITION_AUTHORITY,
  EMPLOYER_TYPES,
} from "../../../utils/constants";
import {
  CheckboxTextWrapper,
  MemberPositionContainer,
  Permissions,
  ProfileItem,
  RadioTextWrapper,
} from "../styles";
import { captureErrorWithData } from "../../../utils/helper";
import {
  TOGGLE_POSITION_ACCESS,
  UPDATE_EMPLOYER_PERMISSION,
} from "../../../queries/employer";
import { GET_ALL_POSITION } from "../../../queries/position";
import PageLoading from "../../../components/PageLoading";
import PartialError from "../../ErrorPage/PartialError";

const radioOptionStyles = {
  containerStyles: {
    height: "unset",
    alignItems: "flex-start",
    marginBottom: 12,
    width: "unset",
  },
  radioStyles: {
    position: "relative",
    top: 4,
  },
};

const checkboxStyles = {
  width: 13,
  height: 13,
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  position: "relative",
  top: 1,
};

const RoleLabel = ({ roleName, list }) => {
  return (
    <RadioTextWrapper>
      <strong>{roleName}</strong>
      <ul>
        {list.map((l) => (
          <li>{l}</li>
        ))}
      </ul>
    </RadioTextWrapper>
  );
};

const PermissionsSection = ({ accessType, setAccessType, employer }) => {
  const [accessiblePositions, setAccessiblePositions] = useState([]);
  const appContext = useContext(AppContext);

  const {
    data: positionData,
    loading: positionsLoading,
    error: positionsError,
  } = useQuery(GET_ALL_POSITION, {
    variables: {
      limit: 100,
      filters: {
        company: appContext.company?.id,
      },
    },
    fetchPolicy: "no-cache",
  });
  const [updateEmployerPermission] = useMutation(UPDATE_EMPLOYER_PERMISSION);
  const [togglePositionAccess] = useMutation(TOGGLE_POSITION_ACCESS);

  useEffect(() => {
    if (employer) {
      setAccessiblePositions(employer.permission?.accessiblePositions);
    }
  }, [employer]);

  if (appContext.employer?.permission?.role !== EMPLOYER_TYPES.ADMIN) {
    return null;
  }

  const handleChangePermission = async (e, { value: role }) => {
    e.preventDefault();
    role = +role;
    try {
      await updateEmployerPermission({
        variables: {
          employerId: employer.id,
          companyId: appContext.company.id,
          role,
        },
      });
      setAccessType(role);
      if (appContext.employer.id === employer.id) {
        appContext.setPermission(role);
      }
      message.success("Employer access type change saved successfully");
    } catch (err) {
      message.error(err.message);
      captureErrorWithData(err);
    }
  };

  const handleTogglePositionAccess = async (e, { positionId, checked }) => {
    e.preventDefault();
    try {
      const res = await togglePositionAccess({
        variables: {
          positionId,
          employerId: employer.id,
          companyId: appContext.company.id,
          checked,
        },
      });
      const newAccessiblePositions =
        res.data.toggleAccessiblePosition?.accessiblePositions;
      setAccessiblePositions(newAccessiblePositions);
      if (appContext.employer.id === employer.id) {
        appContext.setAccessiblePositions(newAccessiblePositions);
      }
      message.success("Position access change saved successfully");
    } catch (err) {
      message.error(err.message);
      captureErrorWithData(err);
    }
  };

  if (positionsLoading) return <PageLoading />;
  if (positionsError) return <PartialError />;

  return (
    <ProfileItem>
      <span>Permissions</span>
      <Permissions>
        <RadioGroup
          name="role"
          isRing
          variant="secondary"
          onChange={handleChangePermission}
          value={accessType}
          options={[
            {
              key: EMPLOYER_TYPES.ADMIN,
              id: EMPLOYER_TYPES.ADMIN,
              value: EMPLOYER_TYPES.ADMIN,
              ...radioOptionStyles,
              children: (
                <RoleLabel
                  roleName="Admin"
                  list={[
                    "Can add or remove new users",
                    "Can view details of all positions",
                  ]}
                />
              ),
            },
            {
              key: EMPLOYER_TYPES.MEMBER,
              id: EMPLOYER_TYPES.MEMBER,
              value: EMPLOYER_TYPES.MEMBER,
              ...radioOptionStyles,
              children: (
                <RoleLabel
                  roleName="Member"
                  list={["Can view details of added positions"]}
                />
              ),
            },
          ]}
        />
        {accessType === EMPLOYER_TYPES.MEMBER && (
          <MemberPositionContainer>
            <span>Can access these positions:</span>
            {positionData?.allPosition.positions.map((position) => {
              const isOwner = position.mainContact?.id === employer.id;
              const authority = accessiblePositions?.find(
                ({ position: p }) => p === position.id
              )?.authority;
              const checked =
                authority[EMPLOYER_POSITION_AUTHORITY.MEMBER] ||
                authority[EMPLOYER_POSITION_AUTHORITY.ALL];
              return (
                <Checkbox
                  variant="secondary"
                  containerStyles={{
                    marginBottom: 8,
                  }}
                  checkboxStyles={checkboxStyles}
                  disabled={isOwner}
                  checked={checked}
                  onChange={(e) =>
                    !isOwner &&
                    handleTogglePositionAccess(e, {
                      positionId: position.id,
                      checked: !checked,
                    })
                  }
                >
                  <CheckboxTextWrapper>
                    {position.title} {isOwner && <strong>(owner)</strong>}
                  </CheckboxTextWrapper>
                </Checkbox>
              );
            })}
          </MemberPositionContainer>
        )}
      </Permissions>
    </ProfileItem>
  );
};

export default PermissionsSection;

import React from "react";
import styled from "styled-components";
import { Avatar } from "tg-design";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  margin-right: 5px;
`;

const NameContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;

const JobDescriptionContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #9ca3af;
`;

const InformationContainer = styled.div``;

export function TalentPartner({ talentPartner, jobDescription }) {
  return (
    <Container>
      <AvatarContainer>
        <Avatar name={talentPartner?.name} src={talentPartner?.avatarURL} />
      </AvatarContainer>

      <InformationContainer>
        <NameContainer>
          {(talentPartner && talentPartner.name) || "Not asssigned"}
        </NameContainer>

        {jobDescription && (
          <JobDescriptionContainer>
            Talent Partner, TalentGrid
          </JobDescriptionContainer>
        )}
      </InformationContainer>
    </Container>
  );
}

export function MainContact({ mainContact }) {
  return (
    <Container>
      <AvatarContainer>
        <Avatar name={mainContact?.fullName} src={mainContact?.avatarURL} />
      </AvatarContainer>

      <InformationContainer>
        <NameContainer>
          {mainContact?.fullName || "Not asssigned"}
        </NameContainer>
      </InformationContainer>
    </Container>
  );
}

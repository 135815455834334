import React, { useState } from "react";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  RadioGroup,
  FormWrapper,
  Textarea,
  SelectField,
  FormScaffold,
  TextField,
  CitySelectField,
  message,
} from "tg-design";
import { DrawerTitle } from "../../../../components/Drawer";
import {
  LOCATION_TYPES,
  ISTANBUL_PLACE_ID,
  ISTANBUL_REGIONS,
} from "../../../../utils/constants";
import {
  CREATE_OFFICE_LOCATION,
  UPDATE_OFFICE_LOCATION,
} from "../../../../queries/company";
import { captureErrorWithData, removeTypename } from "../../../../utils/helper";
import { GET_CITIES } from "../../../../queries/searches";

const FORM_OPTIONS = {
  showStatus: false,
};

const DEFAULT_FORM = {
  id: null,
  title: "",
  place: null,
  istanbulRegion: "",
  location: {
    coordinates: [0, 0],
  },
  fullAddress: "",
  headquarter: "headquarter",
};

const formRules = Yup.object().shape({
  title: Yup.string().trim().min(1).required(""),
  place: Yup.string().trim().required(""),
});

export default function NewLocationForm({
  companyId,
  defaultValue = DEFAULT_FORM,
  refetch,
  setDrawer,
  inputStatus = {},
  setInputStatus = () => {},
}) {
  const { refetch: searchCities } = useQuery(GET_CITIES, {
    skip: true,
  });
  const [createOfficeLocation] = useMutation(CREATE_OFFICE_LOCATION);
  const [updateOfficeLocation] = useMutation(UPDATE_OFFICE_LOCATION);
  const [isSaving, setSaving] = useState(false);
  const [state, setState] = useState(
    defaultValue
      ? {
          ...defaultValue,
          headquarter: defaultValue.headquarter ? "headquarter" : "branch",
          id: defaultValue.id,
          istanbulRegion: ISTANBUL_REGIONS.find(
            (item) => item.id === defaultValue.istanbulRegion
          ),
        }
      : DEFAULT_FORM
  );

  const setField = (field, value) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const submitData = async (variables) => {
    try {
      setSaving(true);
      if (variables.id === null) {
        await createOfficeLocation({ variables });
      } else {
        await updateOfficeLocation({ variables });
      }
      refetch();
      setSaving(false);
      setDrawer(false);
      setInputStatus({
        ...inputStatus,
        locations: "success",
      });
    } catch (error) {
      setSaving(false);
    }
  };

  const saveLocation = async () => {
    try {
      setInputStatus({
        ...inputStatus,
        locations: "loading",
      });
      const variables = removeTypename({
        ...state,
        istanbulRegion: state.istanbulRegion ? state.istanbulRegion.id : null,
        place: state.place ? state.place.id : null,
        headquarter: state.headquarter === "headquarter",
        companyId,
        id: state.id && state.id,
      });

      formRules
        .validate(variables, { abortEarly: false })
        .then(async () => {
          await submitData(variables);
        })
        .catch(() => {
          message.error("There are some required fields");
        });
    } catch (error) {
      captureErrorWithData(error);
      setSaving(false);
    }
  };

  const handleChange = (e, data) => {
    setState({ ...state, ...data });
  };

  return (
    <>
      <DrawerTitle onClose={() => setDrawer(false)}>
        Add new location
      </DrawerTitle>
      <FormWrapper onChange={handleChange} onSubmit={saveLocation}>
        <FormScaffold
          label="Location title"
          explanation="This title will be visible to you only. "
          options={FORM_OPTIONS}
          isRequired
        >
          <TextField
            type="text"
            name="title"
            defaultValue={state.title}
            placeholder="Add title"
          />
        </FormScaffold>

        <FormScaffold label="Select city" options={FORM_OPTIONS} isRequired>
          <CitySelectField
            defaultValue={state.place}
            value={state.place}
            onChange={(selection) => setField("place", selection)}
            showCountries={false}
            isMulti={false}
            searchCities={searchCities}
          />
        </FormScaffold>

        {state.place && state.place.id === ISTANBUL_PLACE_ID && (
          <FormScaffold label="Which side?" options={FORM_OPTIONS}>
            <SelectField
              defaultValue={state.istanbulRegion}
              value={state.istanbulRegion}
              onChange={(selection) => setField("istanbulRegion", selection)}
              options={ISTANBUL_REGIONS}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.id}
            />
          </FormScaffold>
        )}

        <FormScaffold label="Full address" options={FORM_OPTIONS}>
          <Textarea
            name="fullAddress"
            value={state.fullAddress}
            rows="8"
            placeholder="Add address"
          />
        </FormScaffold>

        <FormScaffold label="Type of location" options={FORM_OPTIONS}>
          <RadioGroup
            name="headquarter"
            defaultValue={state.headquarter ? "headquarter" : "branch"}
            options={LOCATION_TYPES.map(({ title, value }) => ({
              label: title,
              value,
              id: value,
              key: value,
            }))}
          />
        </FormScaffold>
        <Button type="submit" loading={isSaving} style={{ width: "100%" }}>
          {state.id ? "Update " : "Add "}
          Location
        </Button>
      </FormWrapper>
    </>
  );
}

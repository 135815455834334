import styled from "styled-components";
import { space, width } from "styled-system";

export const BoxTitle = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  margin: 0px 0px 5px 0px;
  ${space}
`;

export const BoxDescription = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
`;

export const SectionDescription = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
  display: inline-flex;
  width: 70%;
`;

export const Row = styled.div`
  ${space}
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  ${space}
  ${width}
`;

export const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${space}
  ${width}
`;

export const SkillItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justfiy-content: flex-start;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
  margin-bottom: 10px;
  min-width: 200px;
  width: 100%;
  padding-bottom: 10px;
  max-height: 30px;
`;

export const SkillIndexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #9ca3af;
  border-radius: 5px;
  color: #9ca3af;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #9ca3af;
  text-align: center;
  width: 22px;
  height: 22px;
`;

export const SkillTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  width: 50%;
`;

export const SkillExperience = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
  width: 30%;
`;

export const SectionTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  min-width: 157px;
  display: inline-block;
  margin: 0px 0px 10px 0px;
`;

export const RowBreaker = styled.hr`
  margin: 40px 0px;
  border-top: 1px solid #ebebeb;
  border-left: none;
`;

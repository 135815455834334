import React, { useState } from "react";
import { SelectField } from "tg-design";
import {
  GroupContainer,
  GroupRow,
  GroupActionContainer,
  GroupActionButton,
  NewGroupButton,
  Separator,
} from "./styles";
import { Row } from "../Form/BlockUi";
import { AddTechnologyBoxIcon, AddTechnologyIcon, DeleteIcon } from "../Icon";
import PartialError from "../../pages/ErrorPage/PartialError";

const Group = ({ technologyData, skillList, update, groupIndex }) => {
  const [skillState, setSkillState] = useState(
    skillList.length === 0 ? [{ title: "Select technology" }] : skillList
  );

  const addRow = () => {
    setSkillState([...skillState, { title: "Select technology" }]);
  };

  const handleOnChange = (skill, index) => {
    const currentState = [...skillState];
    currentState[index] = skill;
    setSkillState(currentState);
    if (currentState.length > index) {
      update({ changes: currentState, idx: groupIndex });
    }
  };

  const deleteRow = (index) => {
    const currentState = [...skillState];
    currentState.splice(index, 1);
    setSkillState(currentState);
    update({ changes: currentState, idx: groupIndex });
  };

  return (
    <GroupContainer>
      {skillState.map((skill, index) => (
        <div key={index}>
          <GroupRow>
            <SelectField
              defaultValue={{ title: skill.title, id: skill._id }}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option._id}
              options={technologyData}
              bgColor="white"
              optionStyle={{
                background: "white",
              }}
              containerStyle={{
                width: "calc(100% - 10px)",
                marginRight: "10px",
              }}
              onChange={(selectedOption) =>
                handleOnChange(
                  {
                    ...skill,
                    title: selectedOption.title,
                    _id: selectedOption.id,
                  },
                  index
                )
              }
            />
            <div onClick={() => deleteRow(index)}>
              <span style={{ cursor: "pointer", fontSize: "10px" }}>✖️</span>
            </div>
          </GroupRow>
          {skill.length === index ? "" : <Separator type="or">OR</Separator>}{" "}
        </div>
      ))}
      <GroupActionContainer>
        <GroupActionButton type="button" onClick={addRow}>
          <AddTechnologyIcon />
        </GroupActionButton>
      </GroupActionContainer>
    </GroupContainer>
  );
};

export default function OtherSkillGroup({
  technologyData,
  initialValues = [],
  update,
  status,
  error,
}) {
  const [state, setState] = useState(initialValues || []);

  const handleUpdate = (changes) => {
    // eslint-disable-next-line no-underscore-dangle
    const _initialValues = [...state];
    _initialValues[changes.idx] = changes.changes;
    setState(_initialValues);
    const newState = _initialValues.filter((arr) => arr.length !== 0);
    if (newState) {
      update(newState);
    }
  };

  const addGroup = () => {
    const currentState = [...state];
    currentState.push([]);
    setState(currentState);
  };

  const groupDelete = (index) => {
    const currentState = [...state];
    currentState.splice(index, 1);
    setState(currentState);
    update(currentState);
  };

  const generateKey = (index) => {
    return `${index}_${Math.random()}`;
  };

  if (error) return <PartialError />;

  return (
    <>
      <Row style={{ marginBottom: "5px" }} />
      {state &&
        state.map((list, index) => {
          return (
            <div
              key={generateKey(index)}
              style={{ alignItems: "left", marginBottom: "15px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Group
                  key={generateKey(index)}
                  skillList={list}
                  technologyData={technologyData}
                  groupIndex={index}
                  update={handleUpdate}
                />
                <div
                  onClick={() => groupDelete(index)}
                  style={{ cursor: "pointer", margin: "0 5px" }}
                  role="button"
                  tabIndex="0"
                >
                  <DeleteIcon color="#ff9898" />
                </div>
              </div>
              {state.length === index ? (
                ""
              ) : (
                <Separator type="and">AND</Separator>
              )}
            </div>
          );
        })}

      <Row onClick={addGroup} style={{ textAlign: "center" }}>
        <NewGroupButton type="button" hasError={status === "error"}>
          <AddTechnologyBoxIcon />
        </NewGroupButton>
      </Row>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { FormScaffold, SelectField } from "tg-design";
import styled from "styled-components";
import { useLazyCaller } from "../../../../utils/helper";
import { UPDATE_COMPANY, GET_TECHNOLOGIES } from "../../../../queries/company";
import {
  TECHNOLOGY_CATEGORY_KEYS,
  TECHNOLOGY_IDS,
} from "../../../../utils/constants";
import SearchTechStack from "../../../../components/Form/SearchTechStack";
import TechnologySuggestions from "../../../../components/Form/TechnologySuggestions";
import PartialError from "../../../ErrorPage/PartialError";

export const techStack = [
  { title: "Programming languages", name: "language", id: 1 },
  { title: "Framework & libraries", name: "framework", id: 5 },
  { title: "Architecture", name: "architecture", id: 6 },
  { title: "Database systems", name: "database", id: 7 },
  { title: "Cloud technologies", name: "cloud", id: 3 },
  { title: "Version control tools", name: "versioning", id: 4 },
  { title: "CI/CD tools", name: "system", id: 8 },
  { title: "Methodology & applications", name: "methodologies", id: 9 },
  { title: "Analytics & reporting tools", name: "analytics", id: 10 },
  { title: "Business management tools", name: "tool", id: 2 },
];

function filterData(data, filterObj) {
  return data.reduce((acc, val) => {
    const category = filterObj[val.categoryId];
    if (!acc[category]) {
      acc[category] = [val];
    } else {
      acc[category].push(val);
    }
    return acc;
  }, {});
}

const Container = styled.div`
  max-width: 600px;

  @media only screen and (max-width: 768px) {
    max-width: unset;
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #444444;
  margin: 0px;
  margin-bottom: 4px;
`;

const TitleDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #656565;
  margin: 0px;
  margin-bottom: 20px;
`;

const REQUIRED_FIELDS = [TECHNOLOGY_IDS.language, TECHNOLOGY_IDS.framework];

export default function TechStackTab({
  company,
  inputStatus,
  setInputStatus,
  getItemStatus,
  refetch,
  setLoadingState,
}) {
  const {
    loading: techLoading,
    error: techError,
    data: techData,
  } = useQuery(GET_TECHNOLOGIES, {
    variables: {
      verified: true,
    },
  });
  const [state, setState] = useState(company.technologies);
  const lazyCaller = useLazyCaller();
  const [updateCompany] = useMutation(UPDATE_COMPANY);

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleSubmit = async (newState, categoryId) => {
    try {
      setInputStatus({ ...inputStatus, [categoryId]: "loading" });
      await updateCompany({
        variables: {
          id: company.id,
          technologies: newState.map((i) => i.id),
        },
      });
      const newInputStatus = newState.some((i) => i.categoryId === categoryId)
        ? "success"
        : "pending";
      setInputStatus({ ...inputStatus, [categoryId]: newInputStatus });
      refetch();
    } catch (error) {
      setInputStatus({ ...inputStatus, [categoryId]: "error" });
      // eslint-disable-next-line no-console
      console.log("error: ", error);
    }
  };

  const handleOnChange = (categoryId, values) => {
    setInputStatus({ ...inputStatus, [categoryId]: "pending" });
    const newState = [
      ...state.filter((i) => i.categoryId !== categoryId),
      ...values,
    ];
    setState(newState);
    lazyCaller(() => handleSubmit(newState, categoryId), 500);
  };

  const handleSearchSelection = async (value) => {
    if (!value) {
      return;
    }
    const { categoryId } = value;
    setInputStatus({ ...inputStatus, [categoryId]: "pending" });
    const newState = [...state, value];
    setState(newState);
    await handleSubmit(newState, categoryId);
  };

  if (techLoading) {
    setLoadingState(true);
    return "loading...";
  }

  if (techError) return <PartialError />;

  setLoadingState(false);

  const filteredData = filterData(
    techData.allTechnology.technologies,
    TECHNOLOGY_CATEGORY_KEYS
  );

  return (
    <Container>
      <Title>Search and select the Tech Stack of your company</Title>
      <TitleDescription>We will categorize them below</TitleDescription>
      <TechnologySuggestions
        usedTechnologyIds={company.technologies.map((i) => i.id)}
        onSelected={handleSearchSelection}
      />
      <SearchTechStack
        usedTechnologyIds={company.technologies.map((i) => i.id)}
        onSelected={handleSearchSelection}
      />

      {techStack.map((item) => (
        <FormScaffold
          label={item.title}
          status={getItemStatus(item.id)}
          key={item.id}
          isRequired={REQUIRED_FIELDS.includes(item.id)}
        >
          <SelectField
            isMulti
            isClearable
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            options={filteredData[item.name]}
            placeholder={`Select ${item.title}`}
            defaultValue={company.technologies.filter(
              (i) => i.categoryId === item.id
            )}
            value={company.technologies.filter((i) => i.categoryId === item.id)}
            onChange={(e, { value }) => handleOnChange(item.id, value)}
            closeMenuOnSelect={false}
            isSearchable={false}
            status={getItemStatus(item.id)}
          />
        </FormScaffold>
      ))}
    </Container>
  );
}

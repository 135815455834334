import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { close } from "../../store/slices/question";

export default function Question() {
  const dispatch = useDispatch();
  const question = useSelector((state) => state.question);

  const handleOk = () => {
    question.callback();
    dispatch(close());
  };

  const handleCancel = () => {
    dispatch(close());
  };

  return (
    <>
      {question.isOpen && (
        <Modal handleClose={handleCancel}>
          <ModalHeader>{question.title}</ModalHeader>
          {question.content && <ModalBody>{question.content}</ModalBody>}
          <ModalFooter>
            <div style={question.buttonStyle}>
              <Button
                variant={question.acceptButton.variant}
                onClick={handleOk}
              >
                {question.acceptButton.text}
              </Button>
              <Button
                variant={question.cancelButton.variant}
                onClick={handleCancel}
              >
                {question.cancelButton.text}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

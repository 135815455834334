import { gql } from "@apollo/client";

export const SEARCH_UNIVERSITY = gql`
  query AllUniversity($search: String) {
    allUniversity(search: $search) {
      universities {
        id
        name
      }
    }
  }
`;

export const SEARCH_BRANCH = gql`
  query AllUniversityBranch($search: String) {
    allUniversityBranch(search: $search) {
      universityBranches {
        id
        label
      }
    }
  }
`;

export const GET_CITIES = gql`
  query allPlaces($search: String, $showCountries: Boolean) {
    allPlaces(search: $search, showCountries: $showCountries) {
      places {
        id
        city
        country
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

import * as Yup from "yup";
import { TECHNOLOGY_IDS } from "../../../utils/constants";

const generalRules = Yup.object().shape({
  name: Yup.string().trim().required(""),
  logo: Yup.string().url().required(""),
  slogan: Yup.string().trim().required(""),
  description: Yup.string().trim().required(""),
  teamSize: Yup.number()
    .required("")
    .test(
      "Is positive?",
      "ERROR: The number must be greater than 0!",
      (value) => value > 0
    ),
  techTeamSize: Yup.number()
    .required("")
    .test(
      "Is positive?",
      "ERROR: The number must be greater than 0!",
      (value) => value > 0
    ),
});

const yourStoryRules = Yup.object().shape({
  place: Yup.string().trim().required(""),
  culture: Yup.string().trim().required(""),
});

const linksRules = Yup.object().shape({
  website: Yup.string()
    .trim()
    .required()
    .matches(
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
      "Link geçerli gözükmüyor"
    ),
  linkedin: Yup.string()
    .trim()
    .required()
    .matches(
      /^$|((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)([^/]+\/(.)))/,
      "Link geçerli gözükmüyor"
    ),
});

const checkTechStack = ({ company }) => {
  if (!company || !company.technologies) {
    return {
      status: false,
      fields: [TECHNOLOGY_IDS.language, TECHNOLOGY_IDS.framework],
    };
  }

  const checkAnyTech = (key) => {
    return company.technologies.some((item) => item.categoryId === key);
  };

  const checkAll = (keys) => {
    let status = true;
    const fields = [];
    keys.forEach((key) => {
      const isValid = checkAnyTech(key);
      if (!isValid) {
        status = false;
        fields.push(key);
      }
    });

    return { status, fields };
  };

  return checkAll([TECHNOLOGY_IDS.language, TECHNOLOGY_IDS.framework]);
};

const check = async ({ data, rules }) => {
  try {
    await rules.validate(data, { abortEarly: false });
    return { status: true, fields: [] };
  } catch (error) {
    return { status: false, fields: error.inner.map((item) => item.path) };
  }
};

const companyRequirementChecks = async ({ company }) => {
  if (!company) {
    return {};
  }

  return {
    general: await check({ data: company, rules: generalRules }),
    "tech-stack": await checkTechStack({ company }),
    "your-story": await check({
      data: company.contents,
      rules: yourStoryRules,
    }),
    links: await check({ data: company, rules: linksRules }),
    locations: { status: true, fields: ["locations"] },
  };
};

const isAllCompanyTabsValid = (tabValidations) => {
  return Object.keys(tabValidations)
    .map((key) => {
      return tabValidations[key].status;
    })
    .reduce((previous, current) => {
      return previous && current;
    }, true);
};

const isValidCompany = async ({ company }) => {
  const tabValidations = await companyRequirementChecks({ company });
  return isAllCompanyTabsValid(tabValidations);
};

export { companyRequirementChecks, isAllCompanyTabsValid, isValidCompany };

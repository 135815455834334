import React from "react";
import { Row } from "../../../../../../components/Form/BlockUi";
import { InputExplanation } from "../../../../../../components/Form/FormScaffold";

import { RowContainer, DescriptionCell, ValueCell } from "./styles";

const getMonthlySalary = function (criteria) {
  const salary = {
    min: "-",
    max: "-",
  };

  if (criteria && criteria.budget.min) {
    salary.min = ` ${criteria.budget.currency} ${criteria.budget.min}`;
  }
  if (criteria && criteria.budget.max) {
    salary.max = ` ${criteria.budget.currency} ${criteria.budget.max}`;
  }
  return salary;
};

const getBenefits = function (criteria) {
  let benefits = "-";
  if (
    criteria &&
    criteria.benefits &&
    criteria.benefits.expected &&
    criteria.benefits.expected.length > 0
  ) {
    benefits = criteria.benefits.expected.map((benefit, index) => (
      <Row key={index} isInTable="true">
        {benefit.label}
      </Row>
    ));
    return benefits;
  }

  return "-";
};

export default function BenefitsTable({ positionItem }) {
  return (
    <>
      <RowContainer>
        <DescriptionCell>Monthly Salary</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            <Row isInTable="true">
              Min: {getMonthlySalary(positionItem.criteria).min}
            </Row>
            <Row isInTable="true">
              Max:{getMonthlySalary(positionItem.criteria).max}
            </Row>
          </InputExplanation>
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Side Benefits</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getBenefits(positionItem.criteria)}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
    </>
  );
}

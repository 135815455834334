import { gql } from "@apollo/client";

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany(
    $id: ID!
    $name: String
    $description: String
    $slogan: String
    $contacts: [ID]
    $logo: String
    $website: String
    $linkedin: String
    $otherLink: String
    $sector: [ID]
    $investStatus: String
    $foundedYear: Int
    $companySize: Int
    $teamSize: Int
    $techTeamSize: Int
    $tgNotes: String
    $officePhotos: [OfficePhotoInputType]
    $technologies: [ID]
    $contents: ContentInputType
  ) {
    updateCompany(
      id: $id
      name: $name
      description: $description
      slogan: $slogan
      contacts: $contacts
      logo: $logo
      website: $website
      linkedin: $linkedin
      otherLink: $otherLink
      sector: $sector
      investStatus: $investStatus
      foundedYear: $foundedYear
      companySize: $companySize
      teamSize: $teamSize
      techTeamSize: $techTeamSize
      tgNotes: $tgNotes
      officePhotos: $officePhotos
      technologies: $technologies
      contents: $contents
    ) {
      id
    }
  }
`;

export const GET_ALL_SECTOR = gql`
  {
    allSectors {
      id
      label
    }
  }
`;

export const GET_COMPANY = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      name
      clientPartner {
        name
        email
        avatarURL
      }
      contacts {
        id
        fullName
        email
        role
        phone
      }
      description
      slogan
      contents {
        place
        culture
      }
      website
      linkedin
      otherLink
      locations {
        id
        title
        location {
          type
          coordinates
        }
        fullAddress
        place {
          id
          city
          country
        }
        istanbulRegion
        headquarter
      }
      logo
      sector {
        id
        label
      }
      investStatus
      foundedYear
      companySize
      teamSize
      techTeamSize
      tgNotes
      officePhotos {
        url
        description
      }
      technologies {
        id
        title
        category
        categoryId
      }
      allPositionsCompanyReport {
        notified
        interested
        inTGAssessment
        companyAssessment
        hiringProcess
        sentOffer
        hired
      }
    }
  }
`;

export const GET_COMPANY_CONTACTS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      allPositionsCompanyReport {
        notified
        interested
        inTGAssessment
        companyAssessment
        hiringProcess
        sentOffer
        hired
      }
      contacts {
        id
        status
        fullName
        avatarURL
        email
        role
        permission {
          role
          accessiblePositions {
            position
            authority {
              _0
              _1
              _2
            }
          }
        }
      }
    }
  }
`;

export const CREATE_EMPLOYER_AND_INVITE = gql`
  mutation CreateEmployerAndInvite(
    $fullName: String!
    $email: String!
    $role: String!
    $phone: String
    $company: ID!
    $accessType: Int!
  ) {
    addEmployerAndInvite(
      fullName: $fullName
      email: $email
      role: $role
      phone: $phone
      company: $company
      accessType: $accessType
    ) {
      id
      fullName
      email
      role
      isSign
      phone
    }
  }
`;

export const REMOVE_EMPLOYER = gql`
  mutation removeEmployer($id: ID!, $companyId: ID!) {
    removeEmployerFromCompany(id: $id, companyId: $companyId) {
      id
    }
  }
`;

export const GET_COMPANY_WITH_LOCATIONS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      locations {
        id
        title
        place {
          id
          city
          country
        }
        istanbulRegion
      }
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadCompanyImage($image: Upload!) {
    uploadCompanyImage(image: $image) {
      imageUrl
    }
  }
`;

export const DELETE_COMPANY_LOGO = gql`
  mutation deleteCompanyLogo($imageUrl: String!, $company: ID) {
    deleteCompanyLogo(imageUrl: $imageUrl, company: $company)
  }
`;

export const GET_TECHNOLOGIES = gql`
  query allTechnology(
    $search: String
    $limit: Int
    $sort: technologySortInputType
    $verified: Boolean
    $exclude: [ID]
  ) {
    allTechnology(
      search: $search
      limit: $limit
      sort: $sort
      verified: $verified
      exclude: $exclude
    ) {
      technologies {
        id
        title
        categoryId
        category
      }
    }
  }
`;

export const CREATE_OFFICE_LOCATION = gql`
  mutation addCompanyLocation(
    $companyId: ID!
    $title: String!
    $location: PointInputType
    $headquarter: Boolean
    $fullAddress: String
    $place: String!
    $istanbulRegion: String
  ) {
    addCompanyLocation(
      companyId: $companyId
      title: $title
      location: $location
      headquarter: $headquarter
      fullAddress: $fullAddress
      place: $place
      istanbulRegion: $istanbulRegion
    ) {
      id
    }
  }
`;

export const UPDATE_OFFICE_LOCATION = gql`
  mutation updateCompanyLocation(
    $companyId: ID!
    $id: ID!
    $title: String!
    $location: PointInputType
    $fullAddress: String
    $headquarter: Boolean
    $place: String!
    $istanbulRegion: String
  ) {
    updateCompanyLocation(
      id: $id
      companyId: $companyId
      title: $title
      location: $location
      headquarter: $headquarter
      fullAddress: $fullAddress
      place: $place
      istanbulRegion: $istanbulRegion
    ) {
      id
    }
  }
`;

export const DELETE_COMPANY_LOCATION = gql`
  mutation deleteCompanyLocation($locationId: ID!) {
    deleteCompanyLocation(locationId: $locationId) {
      id
      locations {
        id
      }
    }
  }
`;

export const SEND_FEEDBACK = gql`
  mutation sendCompanyFeedback($feedback: String!) {
    sendCompanyFeedback(feedback: $feedback)
  }
`;

export const CHANGE_COMPANY = gql`
  mutation changeCompany($companyId: ID!, $employerId: ID!) {
    changeCompany(companyId: $companyId, employerId: $employerId) {
      id
    }
  }
`;

import React, { useContext, useEffect, useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { Button, message } from "tg-design";
import DashboardTemplate from "../../../components/Templates/DashboardTemplate";
import PageTitle from "../../../components/PageTitle";
import {
  TgHeaderTab,
  TgHeaderTabs,
  TgTabContent,
} from "../../../components/TgHeaderTabs";
import {
  GeneralTab,
  TechStackTab,
  YourStoryTab,
  LinksTab,
  LocationsTab,
} from "./tabs";
import { GET_COMPANY, UPDATE_COMPANY } from "../../../queries/company";
import {
  useLazyCaller,
  useLocationQuery,
  handleHeadTag,
  removeTypename,
} from "../../../utils/helper";
import { companyRequirementChecks, isAllCompanyTabsValid } from "./helpers";
import PageLoading from "../../../components/PageLoading";
import AppContext from "../../../contexts/AppContext";
import ErrorPage from "../../ErrorPage";

const validationMessages = {
  name: null,
  slogan: null,
  description: null,
  teamSize: null,
  techTeamSize: null,
  website: null,
  linkedin: null,
  place: null,
  culture: null,
};

const tabs = [
  { name: "general", title: "General Info" },
  { name: "tech-stack", title: "Tech Stack" },
  { name: "locations", title: "Locations" },
  { name: "your-story", title: "Your Story" },
  { name: "links", title: "Links" },
];

const generalValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required("This field can not be empty"),
  logo: Yup.string().url().required(""),
  slogan: Yup.string().trim().required("This field can not be empty"),
  description: Yup.string().trim().required("This field can not be empty"),
  teamSize: Yup.number()
    .moreThan(0, "This field must be greater than zero")
    .required(""),
  techTeamSize: Yup.number()
    .moreThan(0, "This field must be greater than zero")
    .required(""),
  website: Yup.string()
    .required("This field can not be empty")
    .matches(
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
      "Link is not valid"
    ),
  linkedin: Yup.string()
    .required("This field can not be empty")
    .matches(
      /^$|((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)([^/]+\/(.)))/,
      "Link is not valid"
    ),
  otherLink: Yup.string().url(),
  contents: Yup.object().shape({
    place: Yup.string().trim().required("This field can not be empty"),
    culture: Yup.string().trim().required("This field can not be empty"),
  }),
});

const TabNavigation = styled.div`
  border-top: 1px solid #e5e5e5;
  padding: 30px 36px;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    & button {
      margin: 0;
    }
  }
`;

export default function CompanyEdit() {
  const appContext = useContext(AppContext);
  const locationQuery = useLocationQuery();
  const history = useHistory();
  const [company, setCompany] = useState(null);
  const [tabValidation, setTabValidation] = useState({
    general: false,
    "tech-stack": false,
    "your-story": false,
    links: false,
    locations: false,
  });
  const [loadingState, setLoadingState] = useState(false);
  const [queryError, setQueryError] = useState();
  const [validationError, setValidationError] = useState(validationMessages);

  const [isNavigationAllowed, setIsNavigationAllowed] = useState(true);

  const lazyCaller = useLazyCaller();
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "general"
  );
  const [inputStatus, setInputStatus] = useState({});
  const { refetch } = useQuery(GET_COMPANY, {
    variables: { id: appContext.employer.company.id },
    fetchPolicy: "no-cache",
    skip: true,
  });

  const isBlocked = useRef(!isNavigationAllowed);

  const changeTab = (tab) => {
    locationQuery.set("tab", tab);
    history.push({ search: locationQuery.toString() });
    setCurrentTab(tab);
    window.scrollTo(0, 0);
  };

  const refreshCompany = async () => {
    try {
      const result = await refetch({
        id: appContext.employer.company.id,
      });
      if (result) {
        appContext.updateCompany(result.data.company);
        setCompany(result.data.company);
      }
      setValidationError(validationMessages);
      setInputStatus({});
    } catch (err) {
      setQueryError(err);
    }
  };

  const markInvalidFields = () => {
    if (
      tabValidation[currentTab] &&
      tabValidation[currentTab].status === false
    ) {
      const failedFields = {};
      tabValidation[currentTab].fields.forEach((field) => {
        failedFields[field] = "error";
      });
      setInputStatus({
        ...inputStatus,
        ...failedFields,
      });
    }
  };

  useEffect(() => {
    refreshCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const validator = async () => {
      const result = await companyRequirementChecks({ company });
      setTabValidation(result);
    };
    validator();
  }, [company]);

  isBlocked.current = !isNavigationAllowed;

  useEffect(() => {
    history.block((location) => {
      if (isBlocked.current && location.search === "")
        return "There are unsaved changes. Are you sure you want to change the page?";
    });

    return () => {
      isBlocked.current = false;
    };
  }, []);

  if (queryError) return <ErrorPage error={queryError} />;

  if (!company) return <PageLoading />;

  const handleTabChange = (tab) => {
    if (isNavigationAllowed) {
      changeTab(tab);
    }
    if (!isNavigationAllowed) {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(
        "Are you sure you want to exit? Some changes might not be saved."
      );
      if (answer) {
        changeTab(tab);
        setIsNavigationAllowed(true);
      }
    }
  };

  const handleLeft = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    if (currentIndex === tabs.length - 1) {
      return;
    }

    if (
      tabValidation[currentTab] &&
      tabValidation[currentTab].status === false
    ) {
      message.error("There are some required fields");
      return markInvalidFields();
    }

    handleTabChange(tabs[currentIndex + 1].name);
  };

  const isBackButtonClickable = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    const previousTab = tabs[currentIndex - 1];
    if (!previousTab || !tabValidation[previousTab.name]) {
      return false;
    }
    if (tabValidation[previousTab.name].status === false) {
      return false;
    }
    return true;
  };

  const handleRight = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    if (currentIndex > 0) {
      const previousTab = tabs[currentIndex - 1];
      if (tabValidation[previousTab.name].status === false) {
        return;
      }
      handleTabChange(tabs[currentIndex - 1].name);
    }
  };

  const getItemStatus = (item) => {
    if (inputStatus[item]) {
      return inputStatus[item];
    }

    if (company.technologies.some((i) => i.categoryId === item)) {
      return "success";
    }

    if (company[item]) {
      if (!Array.isArray(company[item])) {
        return "success";
      }

      return company[item].length > 0 ? "success" : "pending";
    }

    if (typeof item !== "number" && item.indexOf(".") > -1) {
      const [root, field] = item.split(".");
      if (company[root] && company[root][field]) {
        return "success";
      }
    }

    return "pending";
  };

  const handleSubmit = async (field, newState, path) => {
    try {
      setInputStatus({ ...inputStatus, [path]: "loading" });
      await updateCompany({
        variables: {
          id: newState.id,
          [field]: newState[field],
        },
      });

      if (field !== path && path) {
        const [, secondKey] = path.split(".");
        setValidationError({
          ...validationError,
          [secondKey]: null,
        });
      } else {
        setValidationError({
          ...validationError,
          [field]: null,
        });
      }
      await refetch();
      setInputStatus({ ...inputStatus, [path]: null });
    } catch (err) {
      setInputStatus({ ...inputStatus, [path]: "error" });
      throw err;
    }
  };

  const validateItem = (field, changes, rootField, handleOnValid) => {
    setInputStatus({ ...inputStatus, [rootField || field]: "pending" });

    if (!generalValidationSchema.fields[field]) {
      return handleOnValid();
    }

    let rules = generalValidationSchema.fields[field];
    let value = changes[field];

    if (rootField) {
      const [, secondKey] = rootField.split(".");
      rules = rules.fields[secondKey];
      value = value[secondKey];
    }

    rules
      .validate(value)
      .then(handleOnValid)
      .catch((error) => {
        setInputStatus({ ...inputStatus, [rootField || field]: "error" });
        if (rootField) {
          const [, secondKey] = rootField.split(".");
          setValidationError({
            ...validationError,
            [secondKey]: error.message,
          });
        } else {
          setValidationError({
            ...validationError,
            [field]: error.message,
          });
        }
      });
  };

  const applyChange = ({ field, changes, rootField }) => {
    validateItem(field, changes, rootField, () => {
      const newState = {
        ...company,
        ...changes,
      };
      setCompany(newState);
      lazyCaller(() => handleSubmit(field, newState, rootField || field), 500);
    });
  };

  const handleOnChangeValue = (field, value) => {
    applyChange({
      field,
      changes: {
        [field]: value,
      },
    });
  };

  const getChanges = (field, value) => {
    if (field.indexOf(".") === -1) {
      return {
        root: field,
        changes: value,
      };
    }
    const [root, child] = field.split(".");
    return removeTypename({
      root,
      changes: {
        ...company[root],
        [child]: value,
      },
    });
  };

  const handleOnChangeByPath = (field, value) => {
    const { root, changes } = getChanges(field, value);
    applyChange({
      field: root,
      rootField: field,
      changes: {
        [root]: changes,
      },
    });
  };

  const handleOnChangeDirectly = (field, value) => {
    const newState = {
      ...company,
      ...{
        [field]: value,
      },
    };
    setCompany(newState);
    handleSubmit(field, newState, field);
  };

  const handleDone = () => {
    history.push("/");
  };

  const getTab = () => {
    let Component = null;
    switch (currentTab) {
      case "general":
        Component = GeneralTab;
        break;
      case "tech-stack":
        Component = TechStackTab;
        break;
      case "your-story":
        Component = YourStoryTab;
        break;
      case "links":
        Component = LinksTab;
        break;
      case "locations":
        Component = LocationsTab;
        break;
      default:
        throw new Error(`Undefined tab: ${currentTab}`);
    }

    return (
      <Component
        company={company}
        refetch={refreshCompany}
        isNavigationAllowed={isNavigationAllowed}
        setIsNavigationAllowed={setIsNavigationAllowed}
        inputStatus={inputStatus}
        handleOnChangeDirectly={handleOnChangeDirectly}
        handleOnChangeByPath={handleOnChangeByPath}
        setInputStatus={setInputStatus}
        getItemStatus={getItemStatus}
        handleOnChangeValue={handleOnChangeValue}
        setLoadingState={setLoadingState}
        error={validationError}
      />
    );
  };

  const isClickable = (tabName) => {
    const currentIndex = tabs.findIndex((tab) => tab.name === tabName);
    if (currentIndex === 0) {
      return true;
    }

    const currentValidation = tabValidation[tabName];
    if (!currentValidation) {
      return false;
    }

    if (currentValidation.status) {
      return true;
    }

    const previousTab = tabs[currentIndex - 1];
    const previousValidation = tabValidation[previousTab.name];
    if (!previousValidation) {
      return false;
    }

    return previousValidation.status;
  };

  let NextButton = (
    <Button style={{ width: "250px" }} onClick={handleLeft}>
      Next
    </Button>
  );

  if (isAllCompanyTabsValid(tabValidation)) {
    NextButton = (
      <Button style={{ width: "250px" }} onClick={handleDone}>
        Done!
      </Button>
    );
  }

  handleHeadTag("company", company.name);

  return (
    <DashboardTemplate>
      {appContext.onboarding && <PageTitle>Complete Company Profile</PageTitle>}
      {!appContext.onboarding && <PageTitle>Edit Company Profile</PageTitle>}

      <TgHeaderTabs
        currentTab={currentTab}
        currentIndex={tabs.findIndex((i) => i.name === currentTab)}
        handleChange={handleTabChange}
      >
        {tabs.map((tab) => (
          <TgHeaderTab
            key={tab.name}
            name={tab.name}
            disabled={!isClickable(tab.name)}
            isValid={tabValidation[tab.name] && tabValidation[tab.name].status}
          >
            {tab.title}
          </TgHeaderTab>
        ))}
      </TgHeaderTabs>

      <TgTabContent handleLeft={handleLeft} handleRight={handleRight}>
        {getTab()}
        {!loadingState && (
          <TabNavigation>
            {currentTab !== tabs[0].name && (
              <Button
                variant="text"
                onClick={handleRight}
                disabled={!isBackButtonClickable()}
                style={{ marginRight: "15px" }}
              >
                Go Back
              </Button>
            )}
            {NextButton}
          </TabNavigation>
        )}
      </TgTabContent>
    </DashboardTemplate>
  );
}

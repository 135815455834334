import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router";
import styled from "styled-components";
import { Button, ButtonLink } from "tg-design";
import {
  PlaceIcon,
  LinkedInIcon,
  GitHubIcon,
  StackoverflowIcon,
  TwitterIcon,
  BlogIcon,
  YoutubeIcon,
} from "../../../../../components/Icon";
import defaultAvatar from "./default-avatar.png";
import {
  MATCH_STATES,
  MATCH_STATE_TITLES,
  MATCH_STATE_BG_COLORS,
  MATCH_STATE_COLORS,
  MATCH_STATE_ALLOWANCE_LIST,
  MATCH_NOT_A_GOOD_FIT,
  CONVERSATION_TYPES,
} from "../../../../../utils/constants";
import StatusChangeModal from "../../../../../components/TgKanban/StatusChangeModal";
import { GET_USER_CONVERSATION } from "../../../../../queries/match";
import PartialError from "../../../../ErrorPage/PartialError";

const Container = styled.div`
  padding: 5px;
`;

const ProfileBox = styled.div`
  display: flex;
`;

const AvatarContainer = styled.div`
  margin-right: 15px;

  @media only screen and (max-width: 768px) {
    margin-right: 10px;
  }
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const DataContainer = styled.div`
  font-family: Inter;
  font-style: normal;
`;

const DeveloperName = styled.h3`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #1a1a1a;
  margin: 0px;
`;

const DeveloperTitle = styled.p`
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
`;

const PlaceRaw = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #9ca3af;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    font-weight: normal;
  }

  & > svg {
    margin-right: 5px;
  }
`;

const Place = styled.div`
  margin-right: 15px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: auto;
  padding-top: 12px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileLabelContainer = styled.div`
  display: none;
  text-align: center;

  @media only screen and (max-width: 768px) {
    display: block;
    padding-top: 10px;
  }
`;

const Label = styled.span`
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 8px;
`;

const ActionBox = styled.div`
  padding: 25px 0px 20px 0px;
  display: flex;
  gap: 10px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 14px 0px 20px 0px;

    & button {
      width: 100%;
    }
  }
`;
const Circle = styled.span`
  width: ${(props) => (props.size ? props.size : "15px")};
  height: ${(props) => (props.size ? props.size : "15px")};
  background-color: ${(props) => (props.color ? props.color : "#cccccc")};
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s;
`;

const SocialBox = styled.div`
  padding-top: ${(props) => props.disabled && "5px"};
  & a {
    margin-right: 10px;
  }
`;

const SocialLink = styled.a`
  cursor: ${(props) => props.disabled && "not-allowed"};
  pointer-events: ${(props) => props.disabled && "none"};
`;

const getLinkWithURLScheme = (link) => {
  const regexPattern = new RegExp("https?://");

  if (regexPattern.test(link)) {
    return link;
  }
  return `//${link}`;
};

const isFailedStateShouldBeShown = (currentState) => {
  return MATCH_STATE_ALLOWANCE_LIST[MATCH_STATES.FAILED].includes(currentState);
};

const MatchStatusLabel = ({ match }) => {
  return (
    <Label
      style={{
        backgroundColor: MATCH_STATE_BG_COLORS[match.state],
        color: MATCH_STATE_COLORS[match.state],
      }}
    >
      {MATCH_STATE_TITLES[match.state] || "Unknown"}
    </Label>
  );
};

const getNextStep = (match, columns) => {
  const { state, substate } = match;
  const currentIndex = columns.findIndex(
    (i) => i.state === state && i.substate === substate
  );

  if (currentIndex >= columns.length - 1) {
    return null;
  }

  const nextColumn = columns[currentIndex + 1];
  if (nextColumn.state === MATCH_STATES.FAILED) {
    return null;
  }

  return {
    state: nextColumn.state,
    substate: nextColumn.substate,
    title: nextColumn.title,
  };
};

const getFailedSubstate = (columns) => {
  const failedColumns = columns.filter((i) => i.state === MATCH_STATES.FAILED);
  return failedColumns[0].substate;
};

export default function DeveloperProfile({
  match,
  openFailedModal,
  setUpdateCardId,
  handleAcceptChange,
  closeDrawer,
  columns,
  handleTabChange,
  setNotAGoodFitState,
}) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("match-print");

  const { error, data: conversationData } = useQuery(GET_USER_CONVERSATION, {
    variables: {
      id: match.id,
    },
  });
  const [loading, setLoading] = useState({
    changingState: false,
    makingFailed: false,
  });
  const showFailedStatedChanger = isFailedStateShouldBeShown(match.state);
  const [statusChangeModal, setStatusChangeModalOpen] = useState({
    isOpen: false,
    title: null,
  });

  const handleStateChange = async ({ state, substate, action }) => {
    setStatusChangeModalOpen({
      isOpen: false,
    });
    const data = {
      cardId: match.id,
      sourceColumnId: match.state,
      sourceSubstateId: match.substate,
      targetColumnId: state,
      targetSubstateId: substate,
      type: MATCH_STATES.FAILED,
    };

    if (state === MATCH_STATES.FAILED) {
      openFailedModal(data);
    } else {
      setLoading({
        ...loading,
        [action]: true,
      });
      await handleAcceptChange(data);
      setUpdateCardId(null);
      setLoading({
        ...loading,
        [action]: false,
      });
      closeDrawer();
    }
  };

  const handleNotAGoodFit = () => {
    if (
      conversationData?.match?.conversation?.some(
        (c) => c.type === CONVERSATION_TYPES.NOT_A_GOOD_FIT
      )
    ) {
      handleTabChange("conversation");
      setNotAGoodFitState({ isClicked: true });
      return;
    }
    openFailedModal({
      type: MATCH_NOT_A_GOOD_FIT,
    });
  };

  if (error) return <PartialError />;
  let nextStep;
  if (!isPrintPage) {
    nextStep = getNextStep(match, columns);
  }

  return (
    <Container>
      {statusChangeModal?.isOpen && (
        <StatusChangeModal
          user={{
            name: match.user.name,
            role: match.user.role?.title || "Missing Role",
          }}
          status={statusChangeModal.data.title}
          onAccept={() =>
            handleStateChange({
              ...statusChangeModal.data,
              action: "changingState",
            })
          }
          onClose={() =>
            setStatusChangeModalOpen({
              isOpen: false,
            })
          }
        />
      )}
      <ProfileBox>
        <AvatarContainer>
          <Avatar src={match.user.avatarURL || defaultAvatar} />
        </AvatarContainer>
        <DataContainer>
          <DeveloperName>{match.user.name}</DeveloperName>
          <DeveloperTitle>
            {match.user.role?.title || "Missing Role"}
          </DeveloperTitle>
          {match.user.preferences?.openToWork && (
            <DeveloperTitle>
              <Circle color="#abe6c1" size="10px" /> Actively looking
            </DeveloperTitle>
          )}

          <PlaceRaw>
            <PlaceIcon />
            <Place>{match?.user?.livingCity?.city}</Place>
          </PlaceRaw>
          <SocialBox disabled={match.state === MATCH_STATES.SENT}>
            {match.user?.social?.linkedin && (
              <SocialLink
                href={getLinkWithURLScheme(match.user?.social?.linkedin)}
                target="blank"
                disabled={match.state === MATCH_STATES.SENT}
              >
                <LinkedInIcon
                  color={
                    match.state === MATCH_STATES.SENT && "rgb(153,153,153)"
                  }
                />
              </SocialLink>
            )}
            {match.user?.social?.github && (
              <SocialLink
                href={getLinkWithURLScheme(match.user?.social?.github)}
                target="blank"
                disabled={match.state === MATCH_STATES.SENT}
              >
                <GitHubIcon
                  color={
                    match.state === MATCH_STATES.SENT && "rgb(153,153,153)"
                  }
                />
              </SocialLink>
            )}
            {match.user?.social?.stackoverflow && (
              <SocialLink
                href={getLinkWithURLScheme(match.user?.social?.stackoverflow)}
                target="blank"
                disabled={match.state === MATCH_STATES.SENT}
              >
                <StackoverflowIcon
                  color={
                    match.state === MATCH_STATES.SENT && "rgb(153,153,153)"
                  }
                />
              </SocialLink>
            )}
            {match.user?.social?.twitter && (
              <SocialLink
                href={getLinkWithURLScheme(match.user?.social?.twitter)}
                target="blank"
                disabled={match.state === MATCH_STATES.SENT}
              >
                <TwitterIcon
                  color={
                    match.state === MATCH_STATES.SENT && "rgb(153,153,153)"
                  }
                />
              </SocialLink>
            )}
            {match.user?.social?.blog && (
              <SocialLink
                href={getLinkWithURLScheme(match.user?.social?.blog)}
                target="blank"
                disabled={match.state === MATCH_STATES.SENT && true}
              >
                <BlogIcon
                  color={
                    match.state === MATCH_STATES.SENT && "rgb(153,153,153)"
                  }
                />
              </SocialLink>
            )}
            {match.user?.social?.youtube && (
              <SocialLink
                href={getLinkWithURLScheme(match.user?.social?.youtube)}
                target="blank"
                disabled={match.state === MATCH_STATES.SENT && true}
              >
                <YoutubeIcon
                  color={
                    match.state === MATCH_STATES.SENT && "rgb(153,153,153)"
                  }
                />
              </SocialLink>
            )}
          </SocialBox>
        </DataContainer>
        {!isPrintPage && (
          <LabelContainer>
            <MatchStatusLabel match={match} />
            {MATCH_STATES[match.state] && (
              <ButtonLink target="_blank" href={`/match-print/${match.id}`}>
                Export to PDF
              </ButtonLink>
            )}
          </LabelContainer>
        )}
      </ProfileBox>
      <MobileLabelContainer>
        <MatchStatusLabel match={match} />
      </MobileLabelContainer>
      {!isPrintPage && (
        <ActionBox>
          {nextStep &&
            match.state !== MATCH_STATES.SENT &&
            match.state !== MATCH_STATES.INTERESTED &&
            match.state !== MATCH_STATES.TG_ASSESSMENT && (
              <Button
                loading={loading.changingState}
                onClick={() =>
                  setStatusChangeModalOpen({
                    isOpen: true,
                    data: nextStep,
                  })
                }
              >
                {loading.changingState && "loading..."}
                {!loading.changingState && <>Proceed to {nextStep.title}</>}
              </Button>
            )}
          {showFailedStatedChanger && (
            <Button
              variant="danger"
              outline
              onClick={() =>
                handleStateChange({
                  state: MATCH_STATES.FAILED,
                  substate: getFailedSubstate(columns),
                  action: "makingFailed",
                })
              }
            >
              Reject Candidate
            </Button>
          )}
          {match.state === MATCH_STATES.COMPANY_ASSESSMENT && (
            <Button variant="danger" outline onClick={handleNotAGoodFit}>
              Not a Good Fit
            </Button>
          )}
        </ActionBox>
      )}
    </Container>
  );
}

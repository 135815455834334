import React from "react";
import styled from "styled-components";

const Figure = styled.figure`
  display: flex;
  align-items: center;
`;

const ListItem = styled.li`
  font-family: ${(props) => props.theme.family.regular};
  font-size: 12px;
  line-height: 12px;
  &:before {
    content: "•";
    color: ${(props) => props.color || "aqua"};
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 24px;
  }
`;

const colors = ["#0c084c", "#8fdeac", "#fad156", "#72D1FB", "#c2c1d2"];

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return `${text.substring(0, limit)}..`;
  }
  return text;
};

export default function TgDonut({ data }) {
  const totalCount = data
    .map((item) => item.value)
    .reduce((prev, current) => {
      return prev + current;
    }, 0);

  const unit = 100 / totalCount;

  let tempOffset = null;
  const newData = [...data];
  const newList = newData
    .sort((a, b) => b.value - a.value)
    .map((item, index) => {
      const prevItem = data[index - 1];
      let offset = 0;
      if (tempOffset) {
        offset = tempOffset - prevItem.value * unit * 0.999;
      } else {
        offset = 25;
      }
      tempOffset = offset;
      return {
        label: truncateText(item.label, 12),
        value: item.value,
        offset,
        color: colors[index],
      };
    });

  return (
    <>
      <Figure>
        <div>
          <svg
            height="194px"
            viewBox="0 0 42 42"
            aria-labelledby="list-label"
            role="img"
          >
            <circle
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="#fff"
              role="presentation"
            />
            <circle
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke="#d2d3d4"
              strokeWidth="5"
              role="presentation"
            />

            {newList.map((row, index) => (
              <circle
                cx="21"
                key={index}
                cy="21"
                r="15.91549430918954"
                fill="transparent"
                stroke={row.color}
                strokeWidth="5"
                strokeDasharray={`${row.value * unit}, ${
                  100 - row.value * unit
                }`}
                strokeDashoffset={row.offset}
                aria-labelledby={`donut-segment-1-title${index}`}
              >
                <title id={`donut-segment-1-title${index}`}>{row.label}</title>
              </circle>
            ))}
          </svg>
        </div>
        <figcaption>
          <ul aria-hidden="true" style={{ listStyle: "none" }}>
            {newList.map((item, index) => (
              <ListItem key={index} color={item.color}>
                {item.label}
              </ListItem>
            ))}
          </ul>
        </figcaption>
      </Figure>
    </>
  );
}

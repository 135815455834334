import React from "react";
import styled from "styled-components";

const Container = styled.thead`
  height: 60px;
  border-bottom: 2px solid #656565;
`;

export const Row = styled.tr``;

export default function TableHeader({ children }) {
  return (
    <Container>
      <Row>{children}</Row>
    </Container>
  );
}

import { gql } from "@apollo/client";

export const CREATE_POSITION = gql`
  mutation addPosition(
    $title: String!
    $description: String
    $role: ID
    $mainContact: ID
    $assignee: ID
    $company: ID!
  ) {
    addPosition(
      title: $title
      description: $description
      role: $role
      mainContact: $mainContact
      assignee: $assignee
      company: $company
    ) {
      id
    }
  }
`;

const CUSTOM_FLOW_FRAGMENT = gql`
  fragment CustomFlowFragment on CustomFlowType {
    uuid
    title
    index
    isVisibleToCompany
    isVisibleToDeveloper
  }
`;

const POSITION_FRAGMENT = gql`
  fragment PositionFragment on Position {
    id
    title
    state
    recruitmentStages
    greenhouseJobId
    isUnseenConversationForUser(filters: $unseenFilters)
    criteria {
      stockOption {
        isProvided
        isVisibleToUser
        min
        max
        isRange
        certainValue
      }
      budget {
        min
        max
        currency
        period
        income
        isVisibleToUser
      }
      totalExperience {
        min
        max
      }
      benefits {
        expected {
          id
          label
        }
      }
      technologies {
        expected {
          _id
          title
          experience
        }
        otherExpected {
          id
          title
        }
        plus {
          id
          title
        }
      }
      education {
        type
        universities {
          id
          name
        }
        branches {
          id
          label
        }
      }
      languages {
        language {
          id
          label
        }
        languageLevel
      }
      positionLocation {
        expected {
          id
          title
          place {
            city
          }
        }
        remote
        relocation
        visaSupport
      }
    }
    role {
      id
      title
    }
    title
    description
    createdAt
    assignee {
      id
      name
      avatarURL
    }
    mainContact {
      id
      fullName
      status
      avatarURL
    }
    companyReports {
      notified
      interested
      awatingAction
      verified
    }
    history {
      id
      recruiter {
        name
      }
      position {
        title
      }
      current
      previous
      createdAt
    }
  }
`;

export const POSITION_CUSTOM_FLOWS = gql`
  ${CUSTOM_FLOW_FRAGMENT}
  fragment CustomFlowsFragment on Position {
    customFlows {
      SENT {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      INTERESTED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      TG_ASSESSMENT {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      COMPANY_ASSESSMENT {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      INTERVIEW {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      SENT_OFFER {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      HIRED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      PASSED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      FAILED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      CANCELED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
    }
  }
`;

export const GET_POSITION = gql`
  ${POSITION_CUSTOM_FLOWS}
  query Position($id: ID) {
    position(id: $id) {
      id
      title
      state
      greenhouseJobId
      role {
        id
        title
      }
      title
      description
      recruitmentStages
      createdAt
      ... on Position {
        ...CustomFlowsFragment
      }
      interviewedByTgCount
      assignee {
        id
        name
        avatarURL
      }
      mainContact {
        id
        fullName
      }
      companyReports {
        notified
        interested
        awatingAction
        verified
        inTGAssessment
        hiringProcess
        notResponded
        passed
        failed
      }
      history {
        id
        recruiter {
          name
        }
        position {
          title
        }
        current
        previous
        createdAt
      }
      criteria {
        stockOption {
          isProvided
          isVisibleToUser
          min
          max
          isRange
          certainValue
        }
        budget {
          min
          max
          currency
          period
          income
          isVisibleToUser
        }
        totalExperience {
          min
          max
        }
        benefits {
          expected {
            id
            label
          }
        }
        technologies {
          expected {
            _id
            title
            experience
          }
          otherExpected {
            id
            title
          }
          plus {
            id
            title
          }
        }
        education {
          type
          universities {
            id
            name
          }
          branches {
            id
            label
          }
        }
        languages {
          language {
            id
            label
          }
          languageLevel
        }
        positionLocation {
          expected {
            id
            title
            place {
              city
            }
          }
          remote
          relocation
          visaSupport
        }
      }
      company {
        id
        name
        logo
        hasGreenhouseIntegration
        locations {
          id
          title
          location {
            coordinates
          }
          place {
            city
          }
          fullAddress
          headquarter
        }
      }
    }
  }
`;

export const GET_POSITION_WITH_COMPANY_REPORTS = gql`
  query Position($id: ID) {
    position(id: $id) {
      id
      history {
        current
        createdAt
      }
      companyReports {
        notified
        interested
        awatingAction
        verified
        inTGAssessment
        hiringProcess
        notResponded
        passed
        failed
      }
    }
  }
`;

export const UPDATE_POSITION = gql`
  mutation UpdatePosition(
    $id: ID!
    $title: String
    $description: String
    $recruitmentStages: String
    $role: ID
    $mainContact: ID
    $assignee: ID
  ) {
    updatePosition(
      id: $id
      title: $title
      description: $description
      recruitmentStages: $recruitmentStages
      role: $role
      mainContact: $mainContact
      assignee: $assignee
    ) {
      id
    }
  }
`;

export const GET_ALL_POSITION = gql`
  ${POSITION_FRAGMENT}
  query allPosition(
    $limit: Int
    $search: String
    $page: Int
    $sort: positionSortInputType
    $filters: positionFilterInputType
    $isAccessiblePositions: Boolean
    $unseenFilters: conversationFilterInputType
  ) {
    allPosition(
      limit: $limit
      search: $search
      page: $page
      sort: $sort
      filters: $filters
      isAccessiblePositions: $isAccessiblePositions
    ) {
      positions {
        ...PositionFragment
      }
      currentPage
      totalCount
      totalPages
    }
  }
`;

export const UPDATE_POSITION_CRITERIA = gql`
  mutation updatePositionCriteria(
    $positionId: ID!
    $totalExperience: PositionTotalExperienceInputType
    $budget: PositionBudgetInputType
    $benefits: PositionBenefitsInputType
    $technologies: PositionTechnologiesInputType
    $positionLocation: PositionLocationInputType
    $education: PositionEducationInputType
    $languages: [PositionLanguageInputType]
    $stockOption: PositionStockOptionInputType
  ) {
    updatePositionCriteria(
      positionId: $positionId
      totalExperience: $totalExperience
      budget: $budget
      benefits: $benefits
      technologies: $technologies
      positionLocation: $positionLocation
      education: $education
      languages: $languages
      stockOption: $stockOption
    ) {
      id
    }
  }
`;

export const SUBMIT_POSITON = gql`
  mutation submitPosition($id: ID!) {
    submitPosition(id: $id) {
      id
    }
  }
`;

export const GET_POSITION_REPORTS = gql`
  query positionReports($id: ID) {
    positionReports(id: $id) {
      id
      rejectReasonStats {
        id
        label
        count
      }
    }
  }
`;

export const GET_REJECT_REASONS = gql`
  {
    allRejectReason(type: 0) {
      id
      label
    }
  }
`;

export const GET_GREENHOUSE_JOBS = gql`
  {
    listGreenhouseJobs {
      id
      name
    }
  }
`;

export const SET_GREENHOUSE_JOB_ID = gql`
  mutation setGreenhouseJobId($id: ID!, $jobId: ID!) {
    setGreenhouseJobId(id: $id, jobId: $jobId) {
      id
    }
  }
`;

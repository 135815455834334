import React from "react";
import { EducationHistoryIcon } from "../../../../../../../components/Icon";
import EmptyData from "../../../../../../../components/EmptyData";
import {
  SectionContainer,
  SectionTitle,
  Container,
  InfoContainer,
  IconContainer,
  InformationConatiner,
  Title,
  DegreeAndDateContainer,
  Degree,
  Dates,
  SecondaryTitle,
} from "./styles";
import { EDUCATION_TYPES } from "../../../../../../../utils/constants";

function getDegree(type) {
  return EDUCATION_TYPES[type]?.label;
}
export default function EducationHistorySection({ match }) {
  return (
    <SectionContainer>
      <SectionTitle>Education History</SectionTitle>

      {(!match.user.education || match.user.education.length === 0) && (
        <EmptyData />
      )}

      {match.user.education.map((education, index) => {
        return (
          <Container key={index}>
            <InfoContainer>
              <IconContainer>
                <EducationHistoryIcon />
              </IconContainer>
              <InformationConatiner>
                <Title>{education?.school?.name}</Title>
                <SecondaryTitle>{education?.branch?.label}</SecondaryTitle>
              </InformationConatiner>
            </InfoContainer>
            <DegreeAndDateContainer>
              <Degree>{getDegree(education.type)}</Degree>
              <Dates>
                {education.startDate} -{" "}
                {!education.endDate ? "?" : education.endDate}
              </Dates>
            </DegreeAndDateContainer>
          </Container>
        );
      })}
    </SectionContainer>
  );
}

/* eslint-disable no-unused-expressions */
import React from "react";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";

const Container = styled.div`
  min-height: 100px;
  padding: 20px 10px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default function TgTabContent({
  handleLeft,
  handleRight,
  children,
  ...props
}) {
  const handlers = useSwipeable({
    onSwiped: (event) => {
      // Bizim modal'ımız açıksa swipe işlemine izin vermiyoruz.
      if (window.isTGModalOpen) {
        return;
      }

      if (event.dir === "Left") {
        handleLeft;
      } else if (event.dir === "Right") {
        handleRight;
      }
    },
  });

  return (
    <Container {...handlers} {...props}>
      {children}
    </Container>
  );
}

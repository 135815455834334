import React from "react";
import ReadMore from "../../../../../../../components/ReadMore";

import {
  TECHNOLOGY_CATEGORY_LABELS,
  TECHNOLOGY_CATEGORY_KEYS,
} from "../../../../../../../utils/constants";
import { Row, SectionTitle, SectionDescription } from "./styles";

const categoryLabel = [
  "language",
  "versioning",
  "cloud",
  "tool",
  "architecture",
  "database",
  "analytics",
  "methodologies",
  "cicd",
];

const generateKey = (index) => {
  return `${index}_${Math.random()}`;
};

export default function OtherSkillsView({ otherSkills, readMore = true }) {
  const regulatedData = otherSkills.reduce((acc, val) => {
    const category = TECHNOLOGY_CATEGORY_KEYS[val.categoryId];
    if (!acc[category]) {
      acc[category] = [val.title];
    } else {
      acc[category].push(val.title);
    }

    return acc;
  }, {});

  const getValue = (key) => {
    return regulatedData && regulatedData[key].join(", ");
  };

  const items = categoryLabel.map((key, index) => {
    if (regulatedData[key]) {
      return (
        <Row key={generateKey(index)} my={{ mobileS: 3, laptopL: 2 }}>
          <SectionTitle>{TECHNOLOGY_CATEGORY_LABELS[key]}:</SectionTitle>
          <SectionDescription>
            <ReadMore text={getValue(key)} limit={readMore ? 100 : 9999} />
          </SectionDescription>
        </Row>
      );
    }
    return null;
  });

  return <Row>{items}</Row>;
}

import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router";
import PageLoading from "./components/PageLoading";
import { GET_EMPLOYER_DATA } from "./queries/auth";
import AppContext from "./contexts/AppContext";
import { isValidCompany } from "./pages/Company/Edit/helpers";
import ErrorPage from "./pages/ErrorPage";

export default function AppProvider({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({
    isAuth: false,
    status: "pending",
    employer: null,
    onboarding: null,
  });
  const { refetch: getEmployerData } = useQuery(GET_EMPLOYER_DATA, {
    skip: true,
  });

  const [queryError, setQueryError] = useState();

  const getEmployer = async () => {
    try {
      const result = await getEmployerData();

      // eslint-disable-next-line
      if (!result) {
        return;
      }

      const { data } = result;
      if (data.employerData.employer === null) {
        setState({
          ...state,
          status: "ready",
        });
        return history.push("/login");
      }

      const newState = {
        ...state,
        status: "ready",
        isAuth: true,
        company: data.employerData.company,
        isCompanyValid: await isValidCompany(data.employerData),
        positionCount: data.employerData.positionCount,
        employer: data.employerData.employer,
      };

      newState.onboarding =
        newState.isCompanyValid === false || newState.positionCount === 0;

      if (process.env.NODE_ENV === "production") {
        const { default: LogRocket } = await import("logrocket");
        LogRocket.identify(newState.employer.email);
      }

      setState(newState);
    } catch (err) {
      setQueryError(err);
    }
  };

  useEffect(() => {
    const prepare = async () => {
      getEmployer();
    };

    const isAuth = localStorage.getItem("isAuth");
    if (isAuth) {
      prepare();
    } else {
      setState({
        ...state,
        status: "ready",
      });

      // Gidilmek istenilen route Public veya Private olsun yinede gidilmek istenilen yere history pushlaniyor.
      // Gidilmek istenilen route eğer Private ise PrivateRoute componenti içerisinde redirect kontrolü yapılıyor.
      history.push({
        path: location.pathname,
        state: { referrer: location.pathname },
        search: location.search,
      });
    }
  }, []);

  if (queryError) return <ErrorPage error={queryError} />;
  if (state.status === "pending") {
    return <PageLoading />;
  }

  const logout = () => {
    setState({
      ...state,
      isAuth: false,
      status: "ready",
      employer: null,
      onboarding: null,
    });
  };

  const refresh = () => {
    setState({
      ...state,
      status: "pending",
    });
    getEmployer();
  };

  const updateCompany = async (company) => {
    const newState = {
      ...state,
      company,
      isCompanyValid: await isValidCompany({ company }),
    };
    newState.onboarding =
      newState.isCompanyValid === false || newState.positionCount === 0;

    setState(newState);
  };

  const increasePositionCount = async () => {
    const newState = {
      ...state,
      positionCount: state.positionCount + 1,
    };
    newState.onboarding =
      newState.isCompanyValid === false || newState.positionCount === 0;

    setState(newState);
  };

  const setPermission = (role) => {
    const newState = {
      ...state,
      employer: {
        ...state.employer,
        permission: {
          ...state.employer.permission,
          role,
        },
      },
    };
    setState(newState);
  };

  const setAccessiblePositions = (accessiblePositions) => {
    const newState = {
      ...state,
      employer: {
        ...state.employer,
        permission: {
          ...state.employer.permission,
          accessiblePositions,
        },
      },
    };
    setState(newState);
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        updateCompany,
        increasePositionCount,
        logout,
        refresh,
        setAccessiblePositions,
        setPermission,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

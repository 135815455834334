import React from "react";
import styled from "styled-components";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { InfoIcon, CheckIcon } from "../../../../../components/Icon";
import { MATCH_STATES } from "../../../../../utils/constants";
import { isMobile } from "../../../../../utils/helper";

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const BodyTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 14px;
  margin: auto;
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AllowedDashedContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 5px;
  border: 1px dashed #000000;
  border-radius: 6px;
  padding: 6px 4px;
`;

const NotAllowedDashedContainer = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  gap: 5px;
  border: 1px dashed #000000;
  border-radius: 6px;
  padding: 6px 4px;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 30px;
  height: 48px;
  border-radius: 4px;
  margin: auto;
  background: ${(props) => (props.color ? props.color : "#e6e6e6")};
  @media only screen and (min-width: 601px) {
    width: 48px;
    height: 81px;
  }
`;

const ButtonContainer = styled.div`
  width: 180px;
  margin: auto;
  @media only screen and (min-width: 601px) {
    width: 100%;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const FooterInfo = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const notAllowedStates = [
  MATCH_STATES.SENT,
  MATCH_STATES.INTERESTED,
  MATCH_STATES.TG_ASSESSMENT,
];

const allowedStates = [
  MATCH_STATES.COMPANY_ASSESSMENT,
  MATCH_STATES.INTERVIEW,
  MATCH_STATES.SENT_OFFER,
  MATCH_STATES.HIRED,
  MATCH_STATES.FAILED,
];

export default function MoveNotAllowedModal({ handleClose }) {
  return (
    <Modal handleClose={() => handleClose()}>
      <ModalHeader>
        <Title>
          <InfoIcon />
          This candidate card cannot be moved.
        </Title>
      </ModalHeader>
      <ModalBody>
        <BodyContainer>
          <AllowedDashedContainer>
            <BodyTitle>TG Talent Partners</BodyTitle>
            <BoxContainer>
              {notAllowedStates.map((i) => i && <Box color="#e6e6e6">X</Box>)}
            </BoxContainer>
          </AllowedDashedContainer>
          <NotAllowedDashedContainer>
            <BodyTitle>Your Work Area</BodyTitle>
            <BoxContainer>
              {allowedStates.map(
                (i) =>
                  i && (
                    <Box color="#caefd8">
                      <CheckIcon fill="currentColor" />
                    </Box>
                  )
              )}
            </BoxContainer>
          </NotAllowedDashedContainer>
        </BodyContainer>
      </ModalBody>
      <ModalFooter>
        <FooterContainer>
          {isMobile() ? (
            <FooterInfo>
              As this area is dedicated for TG Talent Partners, you cannot move
              a candidate card from/to this area. Look for the Verified Matches
              section to integrate candidates into you hiring process.
            </FooterInfo>
          ) : (
            <FooterInfo>
              As this area is dedicated for TG Talent Partners, you cannot move
              a candidate card from/to this area.
            </FooterInfo>
          )}
          <ButtonContainer>
            <Button variant="primary" onClick={handleClose} block>
              OK
            </Button>
          </ButtonContainer>
        </FooterContainer>
      </ModalFooter>
    </Modal>
  );
}

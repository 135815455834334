import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import {
  DecimalField,
  FormScaffold,
  NumericField,
  SelectField,
  FlatCheckbox,
  RadioButton,
} from "tg-design";
import { InlineInputs } from "../../../../components/Form/BlockUi";
import { GET_BENEFITS } from "../../../../queries/shared";
import {
  BUDGET_PERIODS,
  BUDGET_VISIBILITY_OPTION,
  CURRENCIES,
  INCOME_TYPES,
  NOT_REQUIRED_MESSAGE,
  STOCK_OPTION_RANGE_TYPE,
  STOCK_OPTION_PROVIDE_TYPES,
  STOCK_OPTION_VISIBILITY_TYPES,
} from "../../../../utils/constants";
import { Label } from "../../../../components/Form/FormUi";
import PartialError from "../../../ErrorPage/PartialError";

const PercentageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100px;
  font-size: 20px;
  border-color: #f1f1f1;
  border-radius: 5px;
  border-style: solid;
  flex-shrink: 0;
  margin-left: 5px;
`;

export default function BenefitsTab({
  position,
  getItemStatus,
  handleOnChangeCriteria,
  error,
  refetch,
  setIsNavigationAllowed,
}) {
  const [benefitsData, setBenefitsData] = useState([]);
  const { error: queryError } = useQuery(GET_BENEFITS, {
    onCompleted: (data) => {
      setBenefitsData(data.allBenefits);
    },
  });

  const handleNumericCriteriaChange = (motherField, childField, value) => {
    const newState = {
      ...position.criteria[motherField],
      isRange: position?.criteria?.stockOption?.isRange || "RANGE",
      [childField]: value,
    };
    handleOnChangeCriteria(motherField, null, newState);
  };

  const handleSelectChange = (motherField, childField, event) => {
    const value = event ? event.value || event.id : null;
    const newState = {
      ...position.criteria[motherField],
      [childField]: value,
    };
    handleOnChangeCriteria(motherField, null, newState, 0);
  };

  const handleSearchListSelectChange = (
    motherField,
    childField,
    value,
    lazyTimeout = 0
  ) => {
    const newState = {
      ...position.criteria[motherField],
      [childField]: value,
    };
    handleOnChangeCriteria(motherField, null, newState, lazyTimeout);
  };

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const isEmpty = Object.values(error).every((x) => x === null || x === "");

  setIsNavigationAllowed(isEmpty);

  const handleBudgetOptionChange = (e, { name, value }) => {
    const newState = {
      ...position.criteria.budget,
      [name]: value,
    };
    handleOnChangeCriteria("budget", name, newState);
  };

  if (queryError) return <PartialError />;

  const handleStockOptionRangeChange = (e, { name, value }) => {
    if (value !== "RANGE") {
      const newState = {
        ...position.criteria.stockOption,
        [name]: value,
        certainValue: position?.criteria?.stockOption?.max || null,
      };
      return handleOnChangeCriteria("stockOption", name, newState);
    }
    const newState = {
      ...position.criteria.stockOption,
      [name]: value,
    };
    handleOnChangeCriteria("stockOption", name, newState);
  };

  const handleStockOptionChange = (motherField, childField, value) => {
    let newState = {
      ...position.criteria[motherField],
      [childField]: value,
    };

    if (childField === "isProvided" && value === false) {
      newState = {
        ...newState,
        isRange: "",
        min: null,
        max: null,
        certainValue: null,
        isVisibleToUser: false,
      };
    }
    handleOnChangeCriteria(motherField, childField, newState);
  };

  return (
    <div style={{ maxWidth: "600px" }}>
      <FormScaffold
        label="Salary range"
        status={getItemStatus("criteria.budget")}
        explanation="Enter the gross or net salary range that will be used for matching."
        isRequired
      >
        <InlineInputs>
          <FlatCheckbox
            onChange={handleBudgetOptionChange}
            name="income"
            defaultValue={position?.criteria?.budget?.income || "GROSS"}
            options={INCOME_TYPES}
            width="50%"
          />
          <FlatCheckbox
            onChange={handleBudgetOptionChange}
            name="period"
            defaultValue={position?.criteria?.budget?.period || "MONTHLY"}
            options={BUDGET_PERIODS}
            width="50%"
          />
        </InlineInputs>
        <InlineInputs mb={3} style={{}}>
          <NumericField
            icon={false}
            type="tel"
            name="budget-min"
            placeholder="Min"
            containerStyle={{ marginRight: 10 }}
            value={position?.criteria?.budget?.min || ""}
            onChange={(e, { value }) =>
              handleNumericCriteriaChange("budget", "min", value)
            }
            status={getItemStatus("criteria.budget")}
          />
          <NumericField
            type="tel"
            name="budget-max"
            placeholder="Max"
            value={position?.criteria?.budget?.max || ""}
            onChange={(e, { value }) =>
              handleNumericCriteriaChange("budget", "max", value)
            }
            status={getItemStatus("criteria.budget")}
          />
          <SelectField
            name="budget-currency"
            placeholder="Currency"
            defaultValue={{
              label: position?.criteria?.budget?.currency,
            }}
            options={CURRENCIES}
            containerStyle={{ minWidth: "100px", marginLeft: 5 }}
            onChange={(event) =>
              handleSelectChange("budget", "currency", event)
            }
          />
        </InlineInputs>
        {error.budget ? (
          <Label my={2} hasError={error.budget}>
            {error.budget}
          </Label>
        ) : null}
      </FormScaffold>
      <FormScaffold
        label="Would you like the salary range to be visible to candidates?"
        status={getItemStatus("criteria.budget.isVisibleToUser")}
        isRequired
      >
        {BUDGET_VISIBILITY_OPTION.map((option, index) => (
          <RadioButton
            width="310px"
            key={index}
            label={option.title}
            id={option.id}
            name={option.id}
            checked={
              option.value === position?.criteria?.budget?.isVisibleToUser
            }
            onChange={() => {
              handleBudgetOptionChange(null, {
                name: "isVisibleToUser",
                value: option.value,
              });
            }}
          />
        ))}
      </FormScaffold>
      <FormScaffold
        label="Do you provide stock options?"
        status={getItemStatus("criteria.stockOption.isProvided")}
        isRequired
      >
        {STOCK_OPTION_PROVIDE_TYPES.map((option, index) => (
          <RadioButton
            key={index}
            width="310px"
            label={option.title}
            id={option.id}
            name={option.id}
            checked={
              option.value === position?.criteria?.stockOption?.isProvided
            }
            onChange={() => {
              handleStockOptionChange(
                "stockOption",
                "isProvided",
                option.value
              );
            }}
          />
        ))}
      </FormScaffold>
      {position?.criteria?.stockOption?.isProvided === true && (
        <FormScaffold
          label="What is the stock option offered in percentage?"
          status={getItemStatus("criteria.stockOption")}
          explanation="This will be visible to candidates"
          isRequired
        >
          <InlineInputs>
            <FlatCheckbox
              onChange={handleStockOptionRangeChange}
              name="isRange"
              defaultValue={position?.criteria?.stockOption?.isRange || "RANGE"}
              options={STOCK_OPTION_RANGE_TYPE}
              width="50%"
            />
          </InlineInputs>
          <InlineInputs mb={3} style={{ alignItems: "center" }}>
            {(position?.criteria?.stockOption?.isRange === "RANGE" ||
              !position?.criteria?.stockOption?.isRange) && (
              <>
                <DecimalField
                  name="stock-option-min"
                  placeholder="Min"
                  containerStyle={{ marginRight: 10 }}
                  value={position.criteria.stockOption.min || ""}
                  onChange={(e, { value }) =>
                    handleNumericCriteriaChange("stockOption", "min", +value)
                  }
                  status={getItemStatus("criteria.stockOption")}
                />
                <DecimalField
                  name="stock-option-max"
                  placeholder="Max"
                  value={position?.criteria?.stockOption?.max || ""}
                  onChange={(e, { value }) =>
                    handleNumericCriteriaChange("stockOption", "max", +value)
                  }
                  status={getItemStatus("criteria.stockOption")}
                />
              </>
            )}
            {position?.criteria?.stockOption?.isRange === "CERTAIN" && (
              <DecimalField
                name="stock-option-certainValue"
                placeholder="Amount"
                value={position?.criteria?.stockOption?.certainValue || ""}
                onChange={(e, { value }) =>
                  handleNumericCriteriaChange(
                    "stockOption",
                    "certainValue",
                    +value
                  )
                }
                status={getItemStatus("criteria.stockOption")}
              />
            )}
            <PercentageContainer> % </PercentageContainer>
          </InlineInputs>
          {error.stockOption ? (
            <Label my={2} hasError={error.stockOption}>
              {error.stockOption}
            </Label>
          ) : null}
        </FormScaffold>
      )}
      {position?.criteria?.stockOption?.isProvided === true && (
        <FormScaffold
          label=" Would you like to display the amount to candidates?"
          status={getItemStatus("criteria.stockOption.isVisibleToUser")}
          isRequired
        >
          {STOCK_OPTION_VISIBILITY_TYPES.map((option, index) => (
            <RadioButton
              key={index}
              width="310px"
              label={option.title}
              id={option.id}
              name={option.id}
              checked={
                option.value ===
                position?.criteria?.stockOption?.isVisibleToUser
              }
              onChange={() => {
                handleStockOptionChange(
                  "stockOption",
                  "isVisibleToUser",
                  option.value
                );
              }}
            />
          ))}
        </FormScaffold>
      )}

      <FormScaffold
        label="Side benefits"
        status={getItemStatus("criteria.benefits")}
        explanation={NOT_REQUIRED_MESSAGE}
      >
        <SelectField
          name="benefits"
          isMulti
          isClearable
          defaultValue={
            position.criteria &&
            position.criteria.benefits &&
            position.criteria.benefits.expected &&
            position.criteria.benefits.expected.length > 0 &&
            position.criteria.benefits.expected.map((item) => {
              return item;
            })
          }
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          options={benefitsData && benefitsData}
          onChange={(event) =>
            handleSearchListSelectChange("benefits", "expected", event, 500)
          }
          closeMenuOnSelect={false}
        />
      </FormScaffold>
    </div>
  );
}

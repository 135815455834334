import styled from "styled-components";
import { space, width, typography, background } from "styled-system";

export const Label = styled.label`
  color: ${(props) => (props.hasError ? "#ff7675" : "#555")};
  font-size: 14px;
  font-weight: 600;
  display: block;
  ${space}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 11px 9px;
  border-style: solid;
  font-weight: 600;
  text-align: left;
  font-family: ${(props) => props.theme.family.demi};
  color: #444444;
  font-size: 14px;
  width: 100%;
  ${space}
  ${width}
  ${typography}
  ${background}
  border-color: ${(props) => (props.hasError ? "#ff7675" : "#f1f1f1")};
  outline: none;
  :focus {
    border-color: #64b7dc !important;
  }

  :disabled {
    background-color: #fafafa;
  }

  ::placeholder {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #656565;
  }
`;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
  display: flex;
  padding-right: 25px;
  padding-top: 4px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 4px;
    padding-right: 0px;
  }
`;

const TitleText = styled.div`
  margin-right: 4px;

  @media only screen and (max-width: 992px) {
    min-width: 110px;
  }
`;

const ValueText = styled.div`
  color: #1a1a1a;
`;

export default function Timestamp({ title, value }) {
  return (
    <Container>
      <TitleText>{title}</TitleText>
      <ValueText>{value}</ValueText>
    </Container>
  );
}

import styled from "styled-components";

export default styled.th`
  padding: 16px 24px;
  text-align: left;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;

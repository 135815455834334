import { gql } from "@apollo/client";

export const LOGOUT = gql`
  query LOGOUT {
    logout
  }
`;

export const SIGNUP_AS_EMPLOYER = gql`
  mutation signupAsEmployer(
    $token: String!
    $password: String!
    $email: String!
    $userAgreement: Boolean!
    $localKvkkAccept: Boolean!
  ) {
    signupAsEmployer(
      token: $token
      password: $password
      email: $email
      userAgreement: $userAgreement
      localKvkkAccept: $localKvkkAccept
    ) {
      userId
      companyId
    }
  }
`;

export const LOGIN_AS_EMPLOYER = gql`
  mutation loginAsEmployer($password: String!, $email: String!) {
    loginAsEmployer(password: $password, email: $email) {
      userId
      companyId
    }
  }
`;

export const GET_ME = gql`
  {
    employer {
      id
      fullName
      company {
        id
        name
      }
    }
  }
`;

export const GET_EMPLOYER_DATA = gql`
  {
    employerData {
      employer {
        id
        fullName
        avatarURL
        email
        company {
          id
          name
        }
        companies {
          id
          name
          logo
        }
        permission {
          role
          accessiblePositions {
            position
            authority {
              _0
              _1
              _2
            }
          }
        }
      }
      company {
        id
        name
        logo
        slogan
        description
        teamSize
        techTeamSize
        website
        linkedin
        hasGreenhouseIntegration
        greenhouseConnectionLost
        isTest
        locations {
          id
          title
        }
        contents {
          culture
          place
        }
        technologies {
          id
          title
          categoryId
        }
      }
      positionCount
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($password: String!, $email: String!, $pin: Int!) {
    updateEmployerPassword(password: $password, email: $email, pin: $pin) {
      id
    }
  }
`;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 9999;
`;

const Logo = styled.img`
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
`;

export default function PageLoading() {
  return (
    <Container>
      <Logo src="/images/logo-square.png" alt="" />
    </Container>
  );
}

/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const VERIFY_EMPLOYER_TOKEN = gql`
  query verifyEmployerToken($token: String) {
    verifyEmployerToken(token: $token) {
      id
      fullName
      email
      isSign
      role
      company {
        id
        name
      }
    }
  }
`;

export const GET_EMPLOYER_WITH_EMAIL = gql`
  query employer($email: String!) {
    employer(email: $email) {
      id
      fullName
      avatarURL
      email
      isSign
      role
      permission {
        role
        accessiblePositions {
          position
          authority {
            _0
            _1
            _2
          }
        }
      }
    }
  }
`;

export const UPDATE_EMPLOYER_PERMISSION = gql`
  mutation updateEmployerPermission(
    $companyId: ID!
    $employerId: ID!
    $role: Int!
  ) {
    updateEmployerPermission(
      companyId: $companyId
      employerId: $employerId
      role: $role
    )
  }
`;

export const TOGGLE_POSITION_ACCESS = gql`
  mutation toggleAccessiblePosition(
    $companyId: ID!
    $employerId: ID!
    $positionId: ID!
    $checked: Boolean!
  ) {
    toggleAccessiblePosition(
      companyId: $companyId
      employerId: $employerId
      positionId: $positionId
      checked: $checked
    ) {
      accessiblePositions {
        position
        authority {
          _0
          _1
          _2
        }
      }
    }
  }
`;

import React, { useContext, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import styled from "styled-components";
import { message } from "tg-design";

import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import PageTitle from "../../components/PageTitle";
import AppContext from "../../contexts/AppContext";
import IntegrationItem from "./IntegrationItem";

const PageDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 16px;

  color: #979797;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const ListView = styled.div`
  flex-direction: column;
  display: flex;
  gap: 16px;
`;

const GREENHOUSE_CONNECT = gql`
  mutation connectGreenhouse($token: ID!) {
    connectGreenhouse(token: $token) {
      appType
    }
  }
`;

const GREENHOUSE_DISCONNECT = gql`
  mutation disconnectGReenhouse {
    disconnectGreenhouse {
      appType
    }
  }
`;

const GREENHOUSE_RECONNECT = gql`
  mutation reConnectGreenhouse($token: ID!) {
    reConnectGreenhouse(token: $token) {
      appType
    }
  }
`;

export default function Integration() {
  const { company } = useContext(AppContext);
  const [connectGreenhouse, { loading: connectLoading }] =
    useMutation(GREENHOUSE_CONNECT);
  const [disconnectGreenhouse, { loading: disconnectLoading }] = useMutation(
    GREENHOUSE_DISCONNECT
  );
  const [reConnectGreenhouse, { loading: reConnectLoading }] =
    useMutation(GREENHOUSE_RECONNECT);
  const [state, setState] = useState(company);

  const handleGreenhouseConnect = async (data) => {
    try {
      await connectGreenhouse({ variables: { token: data } });
      setState({ ...state, hasGreenhouseIntegration: true });
      message.success("Your integration has beeen connected");
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleGreenhouseReConnect = async (data) => {
    try {
      await reConnectGreenhouse({ variables: { token: data } });
      setState({
        ...state,
        hasGreenhouseIntegration: true,
        greenhouseConnectionLost: false,
      });
      message.success("Your integration has beeen connected");
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleGreenhouseDisconnect = async () => {
    try {
      await disconnectGreenhouse();
      setState({ ...state, hasGreenhouseIntegration: false });
      message.success("Your integration has beeen disconnected");
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <DashboardTemplate>
      <PageTitle mb="12px" hr>
        Integrations
      </PageTitle>
      <PageDescription>
        When you integrate your ATS (Application Tracking System), candidates
        who accept your interview requests will be automatically added to your
        ATS providers.
      </PageDescription>
      <ListView>
        <IntegrationItem
          integrationManualLink="https://intercom.help/talentgrid/en/articles/6867756-greenhouse-integration"
          isConnected={state.hasGreenhouseIntegration}
          integration="Greenhouse"
          handleConnect={handleGreenhouseConnect}
          handleDisconnect={handleGreenhouseDisconnect}
          handleReConnect={handleGreenhouseReConnect}
          connectLoading={connectLoading || reConnectLoading}
          disconnectLoading={disconnectLoading}
          hasError={state.greenhouseConnectionLost}
        />
        <IntegrationItem notIntegrated integration="Lever" />
      </ListView>
    </DashboardTemplate>
  );
}

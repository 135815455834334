import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  message,
} from "tg-design";
import {
  ADD_CONVERSATION,
  GET_USER_CONVERSATION,
  MATCH_STATE_CHANGE,
} from "../../../../../queries/match";
import OptionStep from "./OptionStep";
import DescriptionStep from "./DescriptionStep";
import {
  CONVERSATION_TYPES,
  MATCH_NOT_A_GOOD_FIT,
  MATCH_STATES,
} from "../../../../../utils/constants";
import {
  Description,
  SecondaryDescription,
} from "../../../../../components/TgKanban/StatusChangeModal/style";
import { GET_REJECT_REASON } from "../../../../../queries/shared";
import { captureErrorWithData } from "../../../../../utils/helper";

const FAIL_REASONS_MODAL_VARIABLES = {
  [MATCH_STATES.FAILED]: {
    title: "Why do you want to pass on this profile?",
    description: "This will help us improve your experience",
  },
  [MATCH_NOT_A_GOOD_FIT]: {
    title: "Why do you think this candidate is not a good fit?",
    description:
      "This information is important for us to offer you better candidates",
  },
};

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .required("Required")
    .min(5, "Please provide a more detailed description"),
});

export default function FailReasonsModal({ data, handleClose, onCompleted }) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [sending, setSending] = useState(false);
  const [state, setState] = useState({
    failReasons: [],
    description: "",
    otherReason: "",
    isValid: false,
  });
  const [step, setStep] = useState("options");
  const [changeState] = useMutation(MATCH_STATE_CHANGE);
  const [addConversation] = useMutation(ADD_CONVERSATION, {
    refetchQueries: [
      {
        query: GET_USER_CONVERSATION,
        variables: { id: query.get("drawerId") },
      },
    ],
  });
  const { data: rejectReasons } = useQuery(GET_REJECT_REASON);

  useEffect(() => {
    (async () => {
      const isValid = await validationSchema.fields.description.isValid(
        state.description
      );
      setState({
        ...state,
        isValid,
      });
    })();
  }, [state.description]);

  const handleFailReasonChange = (value, event) => {
    const { checked } = event.target;
    const newState = {
      ...state,
    };

    if (checked) {
      newState.failReasons.push(value);
    } else {
      newState.failReasons = newState.failReasons.filter(
        (item) => item !== value
      );
    }
    setState(newState);
  };

  const handleSetOtherReason = (otherReason) => {
    setState({
      ...state,
      otherReason,
    });
  };

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setState({
      ...state,
      description: value,
    });
  };

  const getCurrentStep = () => {
    if (step === "options") {
      return (
        <OptionStep
          handleFailReasonChange={handleFailReasonChange}
          failReasons={state.failReasons}
          otherReason={state.otherReason}
          setOtherReason={handleSetOtherReason}
        />
      );
    }

    return <DescriptionStep onChange={handleDescriptionChange} />;
  };

  const isButtonActive = () => {
    if (step === "options") {
      return (
        state.failReasons.length > 0 &&
        (!state.failReasons.includes("other") || state.otherReason.length > 0)
      );
    }

    return state.isValid;
  };

  const submitFormForNotAGoodFit = async () => {
    const matchId = query.get("drawerId");
    const selectedFailReasonLabels = rejectReasons.allRejectReason
      ?.filter((reason) => state.failReasons.includes(reason.id))
      .map((reason) => `\n- ${reason.label}`);
    if (state.otherReason) {
      selectedFailReasonLabels.push(`\n- ${state.otherReason}`);
    }
    const note = `**This candidate might not be a good fit:**\n${selectedFailReasonLabels.join(
      ""
    )}\n\n${state.description}`;
    try {
      await addConversation({
        variables: {
          matchId,
          type: CONVERSATION_TYPES.NOT_A_GOOD_FIT,
          note,
        },
      });
      onCompleted(data);
    } catch (e) {
      message.error("Something went wrong.");
      captureErrorWithData(e, data);
    }
  };

  const submitForm = async () => {
    setSending(true);
    if (data.type === MATCH_NOT_A_GOOD_FIT) {
      submitFormForNotAGoodFit();
      return;
    }
    const variables = {
      id: data.cardId,
      state: MATCH_STATES.FAILED,
      substate: data.targetSubstateId,
      failReasons: {
        description: state.description,
        reasons: state.failReasons.filter((item) => item !== "other"),
      },
      otherReason: state.otherReason,
    };
    await changeState({
      variables,
    });
    onCompleted(data);
  };

  const handleButtonClick = async () => {
    if (step === "options") {
      return setStep("description");
    }

    submitForm();
  };

  return (
    <Modal handleClose={() => handleClose(data)}>
      <ModalHeader>
        <Description>
          {FAIL_REASONS_MODAL_VARIABLES[data.type].title}
        </Description>
        <SecondaryDescription>
          {FAIL_REASONS_MODAL_VARIABLES[data.type].description}
        </SecondaryDescription>
      </ModalHeader>
      <ModalBody>{getCurrentStep()}</ModalBody>
      <ModalFooter>
        <Button
          variant={step === "options" ? "secondary" : "primary"}
          disabled={!isButtonActive()}
          onClick={handleButtonClick}
          loading={sending}
        >
          {step === "options" && "Next"}
          {step === "description" && "Submit"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

import React, { useEffect } from "react";
import { FormScaffold, FormWrapper, Textarea } from "tg-design";
import UploadOfficePhotos from "./UploadOfficePhotos";
import { Label } from "../../../../components/Form/FormUi";

export default function YourStoryTab({
  company,
  inputStatus,
  setInputStatus,
  getItemStatus,
  handleOnChangeValue,
  handleOnChangeByPath,
  error,
  refetch,
  setIsNavigationAllowed,
}) {
  const onLogoStarted = () => {
    setInputStatus({ ...inputStatus, officePhotos: "pending" });
  };

  const onLogoError = () => {
    setInputStatus({ ...inputStatus, officePhotos: "error" });
  };

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const isErrorPresent = Object.values(error).every(
    (x) => x === null || x === ""
  );

  setIsNavigationAllowed(isErrorPresent);

  return (
    <FormWrapper style={{ maxWidth: "600px" }}>
      <FormScaffold
        label="Why do you think your company is a good place to work?"
        status={getItemStatus("contents.place")}
        isRequired
      >
        <Textarea
          name="contents.place"
          rows="8"
          placeholder="i.e. Fast growth, technologies, reputable investors... "
          value={company.contents ? company.contents.place : ""}
          status={getItemStatus("contents.place")}
          onChange={(event) =>
            handleOnChangeByPath("contents.place", event.target.value)
          }
        />
        {error.place ? (
          <Label my={2} hasError={error.place}>
            {error.place}
          </Label>
        ) : null}
      </FormScaffold>
      <FormScaffold
        label="How would you describe your company culture?"
        status={getItemStatus("contents.culture")}
        isRequired
      >
        <Textarea
          name="contents.culture"
          rows="8"
          placeholder="i.e. We value transparency within the team. We care for personal development and personal life..."
          value={company.contents ? company.contents.culture : ""}
          status={getItemStatus("contents.culture")}
          onChange={(event) =>
            handleOnChangeByPath("contents.culture", event.target.value)
          }
        />
        {error.culture ? (
          <Label my={2} hasError={error.culture}>
            {error.culture}
          </Label>
        ) : null}
      </FormScaffold>
      <FormScaffold
        label="Would you like to upload office / team photos?"
        status={getItemStatus("officePhotos")}
        explanation="Bringing the human element helps you stand out. "
      >
        <UploadOfficePhotos
          handleOnChangeValue={handleOnChangeValue}
          officePhotos={company.officePhotos}
          companyId={company.id}
          onStarted={onLogoStarted}
          onError={onLogoError}
        />
      </FormScaffold>
    </FormWrapper>
  );
}

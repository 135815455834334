import styled from "styled-components";
import {
  space,
  width,
  typography,
  color,
  fontWeight,
  fontSize,
} from "styled-system";

export const CriteriaFieldTitle = styled.div`
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 25px;
  ${space}
`;

export const Span = styled.span`
  ${typography}
  ${color}
  ${space}
  ${fontWeight}
  ${fontSize}
  ${width}
  text-overflow: ellipsis;
`;

export const InlineInputs = styled.div`
  display: flex;
  ${space};
  @media only screen and (max-width: 768px) {
    :not(.keep-inline) {
      display: block;
    }
  }
`;

export const Row = styled.div`
  display: block;
  margin: ${({ isInTable }) =>
    isInTable === "true" ? "0px 0px 0px 0px" : "18px 0px"};
`;

export const Col = styled.div`
  display: inline;
`;

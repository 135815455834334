import styled from "styled-components";
import { space } from "styled-system";

export const RowDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1a1a1a;
  margin: 0px 0px 5px 0px;
  ${space}
`;

export const Margin = styled.div`
  display: block;
  ${space}
`;

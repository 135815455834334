import React from "react";
import styled from "styled-components";
import TgKanbanColumnTitle from "./TgKanbanColumnTitle";
import TgKanbanColumnBadge from "./TgKanbanColumnBadge";
import TgKanbanColumnActions from "./TgKanbanColumnActions";
import { MATCH_STATES } from "../../utils/constants";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  &.is-substate {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
`;

const stateColors = [
  {
    state: MATCH_STATES.SENT,
    background: "#DADADA",
    color: "#1A1A1A",
  },
  {
    state: MATCH_STATES.INTERESTED,
    background: "#DADADA",
    color: "#1A1A1A",
  },
  {
    state: MATCH_STATES.TG_ASSESSMENT,
    background: "#DADADA",
    color: "#1A1A1A",
  },
  {
    state: MATCH_STATES.COMPANY_ASSESSMENT,
    background: "#64B7DC",
    color: "#FFFFFF",
  },
  {
    state: MATCH_STATES.INTERVIEW,
    background: "#64B7DC",
    color: "#FFFFFF",
  },
  {
    state: MATCH_STATES.SENT_OFFER,
    background: "#64B7DC",
    color: "#FFFFFF",
  },
  {
    state: MATCH_STATES.HIRED,
    background: "#5BD480",
    color: "#FFFFFF",
  },
  {
    state: MATCH_STATES.FAILED,
    background: "#FF5959",
    color: "#FFFFFF",
  },
];

export default function TgKanbanColumnHeader({
  title,
  hasSubState = false,
  isSubstate = false,
  count,
  menus = null,
  state,
}) {
  return (
    <TgKanbanColumnTitle
      hasSubState={hasSubState}
      isSubstate={isSubstate}
      background={stateColors.find((i) => i.state === state)?.background}
      color={stateColors.find((i) => i.state === state)?.color}
    >
      <Container>
        <TgKanbanColumnBadge
          borderColor={stateColors.find((i) => i.state === state)?.color}
        >
          {count}
        </TgKanbanColumnBadge>
        <Title className={isSubstate ? "is-substate" : ""}>{title}</Title>
      </Container>

      {menus && (
        <TgKanbanColumnActions state={state}>{menus}</TgKanbanColumnActions>
      )}
    </TgKanbanColumnTitle>
  );
}

import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { Button, ButtonLink, FormWrapper, TextField, message } from "tg-design";
import { LOGIN_AS_EMPLOYER } from "../../queries/auth";
import { loginValidationSchema } from "../../utils/validations";
import AuthTemplate from "../../components/Templates/AuthTemplate";
import { HelloIcon } from "../../components/Icon";
import AppContext from "../../contexts/AppContext";
import { handleHeadTag } from "../../utils/helper";

const FormContainer = styled.div`
  max-width: 450px;
  padding-top: 70px;
  * {
    margin-left: 0;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const IconContainer = styled.div`
  display: inline;
  margin-top: 3px;
  position: absolute;
  margin-left: 10px;
`;

const inputStyles = {
  marginBottom: "20px",
};

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 60px;

  @media only screen and (max-width: 768px) {
    line-height: 34px;
  }
`;

export default function Login() {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [loginAsEmployer] = useMutation(LOGIN_AS_EMPLOYER);
  const [loading, setLoading] = useState(false);
  const [isReadyToGo, setIsReadyToGo] = useState(false);
  const [email, setEmail] = useState("");

  const handleLogin = async (e, data) => {
    try {
      setLoading(true);
      await loginAsEmployer({
        variables: {
          ...data,
        },
      });
      setLoading(false);
      appContext.refresh();
      localStorage.setItem("isAuth", true);
      history.push(history.location.state?.referrer || "/");
    } catch (err) {
      setLoading(false);
      err.graphQLErrors.map((errorMessage) => {
        return message.error(errorMessage.message);
      });
    }
  };

  const handleChange = (e, data) => {
    setEmail(data.email);
    setIsReadyToGo(loginValidationSchema.isValidSync(data));
  };

  handleHeadTag("login");

  return (
    <AuthTemplate>
      <Title>
        Welcome back
        <IconContainer>
          <HelloIcon />
        </IconContainer>
      </Title>
      <FormWrapper onChange={handleChange} onSubmit={handleLogin}>
        <FormContainer>
          <TextField
            name="email"
            label="Email"
            value={history.location?.state?.email || ""}
            autoFocus
            style={inputStyles}
          />
          <TextField
            name="password"
            type="password"
            label="Password"
            style={inputStyles}
          />
          <Button
            type="submit"
            style={{ marginTop: "20px", width: "100%", marginBottom: "20px" }}
            disabled={!isReadyToGo}
            loading={loading}
          >
            Log In
          </Button>
          <ButtonLink
            onClick={() =>
              history.push({
                pathname: "/forgot-password",
                state: { email },
              })
            }
          >
            Forgot your password?
          </ButtonLink>
        </FormContainer>
      </FormWrapper>
    </AuthTemplate>
  );
}

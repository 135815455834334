/* eslint-disable prefer-rest-params */
export const addLogRocket = async () => {
  const { default: LogRocket } = await import("logrocket");
  LogRocket.init("ktqljq/tgcompany");
};

export const addGoogleAnalytrics = async () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "G-Y527K52416");
};

import * as Yup from "yup";

export default Yup.object().shape({
  totalExperience: Yup.object().shape({
    min: Yup.number().min(1, "Min must be greater than zero").required(),
    max: Yup.number()
      .moreThan(Yup.ref("min"), "Max must be greater than min")
      .required(),
  }),
  technologies: Yup.object()
    .shape({
      expected: Yup.array().nullable().of(Yup.object().nullable()),
      otherExpected: Yup.array().nullable().of(Yup.object().nullable()),
      plus: Yup.array().nullable().of(Yup.object().nullable()),
    })
    .test(
      "min-one-property",
      (value) => value.expected || value.otherExpected || value.plus
    ),
  education: Yup.object()
    .shape({
      type: Yup.number().nullable(),
      universities: Yup.array().nullable().of(Yup.object().nullable()),
      branches: Yup.array().nullable().of(Yup.object().nullable()),
      language: Yup.array().nullable().of(Yup.object().nullable()),
      languageLevel: Yup.number().nullable(),
    })
    .test(
      "min-one-property",
      (value) =>
        value.type ||
        value.universities ||
        value.branches ||
        value.language ||
        value.languageLevel
    ),
  benefits: Yup.object().shape({
    expected: Yup.object()
      .shape({
        id: Yup.number(),
      })
      .nullable(),
  }),
  budget: Yup.object().shape({
    min: Yup.number().min(1, "Min must be greater than zero").required(),
    max: Yup.number()
      .moreThan(Yup.ref("min"), "Max must be greater than min")
      .required(),
    isVisibleToUser: Yup.boolean().nullable(),
  }),
  positionLocation: Yup.object().shape({
    remote: Yup.string().nullable().required(),
    expected: Yup.string().nullable(),
    visaSupport: Yup.boolean().when("expected", {
      is: !null,
      then: Yup.boolean().nullable(false).required(),
      otherwise: Yup.boolean().nullable(),
    }),
    relocation: Yup.boolean().when("expected", {
      is: !null,
      then: Yup.boolean().nullable(false).required(),
      otherwise: Yup.boolean().nullable(),
    }),
  }),
  stockOption: Yup.object().shape({
    isProvided: Yup.boolean().required(),
    isRange: Yup.string().when("isProvied", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable(),
    }),
    min: Yup.number().when("isRange", {
      is: "RANGE",
      then: Yup.number()
        .min(0.01, "Min must be greater than zero")
        .nullable()
        .required("Min is a required value"),
      otherwise: Yup.number().nullable(),
    }),
    max: Yup.number().when("isRange", {
      is: "RANGE",
      then: Yup.number()
        .moreThan(Yup.ref("min"), "Max must be greater than min")
        .nullable()
        .required("Max is a required value"),
      otherwise: Yup.number().nullable(),
    }),
    certainValue: Yup.number().when("isRange", {
      is: "CERTAIN",
      then: Yup.number()
        .min(0.01, "Amount must be greater than zero")
        .nullable()
        .required("Amount is a required field"),
      otherwise: Yup.number().nullable(),
    }),
  }),
});

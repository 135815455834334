import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ArrowIcon } from "../Icon";

const StyledBreadCrumbs = styled.h3`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const BreadCrumbLink = styled(Link)`
  color: unset;
  text-decoration: none;
`;

const BreadCrumbs = ({ items }) => {
  const fullBreadCrumbs = [...items];

  const isMiddle = (index) => {
    if (fullBreadCrumbs.length === 1) {
      return false;
    }

    if (fullBreadCrumbs.length - 1 === index) {
      return false;
    }

    return true;
  };

  return (
    <StyledBreadCrumbs>
      {fullBreadCrumbs.map((item, index) => (
        <BreadCrumbLink to={{ pathname: item.link }} key={index}>
          {item.title}
          {isMiddle(index) && (
            <span style={{ marginLeft: "5px", marginRight: "5px" }}>
              <ArrowIcon />
            </span>
          )}
        </BreadCrumbLink>
      ))}
    </StyledBreadCrumbs>
  );
};

export default BreadCrumbs;

import styled from "styled-components";

export const BoxTitle = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  margin: 0px 0px 20px 0px;
`;

export const RowBreaker = styled.hr`
  margin: 40px 0px;
  border-top: 1px solid #ebebeb;
  border-left: none;
`;

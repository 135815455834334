import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { FormScaffold, Textarea, SelectField } from "tg-design";
import { GET_PREVIOUS_RECRUITMENT_STAGES } from "../../../../queries/roles";
import AppContext from "../../../../contexts/AppContext";
import { Label } from "../../../../components/Form/FormUi";
import PartialError from "../../../ErrorPage/PartialError";

export default function RecruitmentStages({
  id,
  getItemStatus,
  handleOnChangeValue,
  recruitmentStages,
  error,
}) {
  const [state, setState] = useState(recruitmentStages);
  const [previousRecruitmentStages, setPreviousRecruitmentStages] = useState(
    []
  );
  const appContext = useContext(AppContext);

  const handleChange = (value) => {
    setState(value);
    handleOnChangeValue("recruitmentStages", value);
  };

  const { error: queryError } = useQuery(GET_PREVIOUS_RECRUITMENT_STAGES, {
    variables: {
      companyId: appContext.employer.company.id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const filteredData = data.getPreviousRecruitmentStagesByCompanyId
        .filter((item) => item.recruitmentStages && item.id !== id)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setPreviousRecruitmentStages(filteredData);
    },
  });

  const getOptionLabel = (option) => {
    if (option.recruitmentStages.length > 50) {
      return `${option.title} : ${option.recruitmentStages.substring(
        0,
        50
      )}...`;
    }
    return `${option.title} : ${option.recruitmentStages.substring(0, 50)}`;
  };

  if (queryError) return <PartialError />;

  return (
    <FormScaffold
      label="Stages in your hiring process?"
      explanation="Online/face to face interviews, case studies etc."
      status={getItemStatus("recruitmentStages")}
      isRequired
    >
      <div>
        {previousRecruitmentStages.length > 0 && (
          <SelectField
            getOptionLabel={(option) => getOptionLabel(option)}
            getOptionValue={(option) => option.recruitmentStages}
            options={previousRecruitmentStages}
            isSearchable={false}
            onChange={(value) => handleChange(value.recruitmentStages)}
            placeholder="Quick fill from previous recruitment stages"
            containerStyle={{ marginBottom: "10px" }}
          />
        )}

        <Textarea
          name="recruitmentStages"
          rows="8"
          placeholder="1.&#13;2.&#13;3.&#13;..."
          value={state}
          onChange={(event) => handleChange(event.target.value)}
        />
        {error.recruitmentStages ? (
          <Label my={2} hasError={error.recruitmentStages}>
            {error.recruitmentStages}
          </Label>
        ) : null}
      </div>
    </FormScaffold>
  );
}

import React, { useEffect, useState } from "react";
import { Avatar, Tag, message } from "tg-design";
import { useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import Breadcrumbs from "../../components/Breadcrumbs";
import { GET_EMPLOYER_WITH_EMAIL } from "../../queries/employer";
import PageLoading from "../../components/PageLoading";
import PartialError from "../ErrorPage/PartialError";
import { Profile, ProfileHeader } from "./styles";
import { EMPLOYER_TYPES } from "../../utils/constants";
import {
  PermissionsSection,
  ProfileSection,
  RevokeAccessSection,
} from "./sections";

const UserPage = () => {
  const [accessType, setAccessType] = useState();

  const { email } = useParams();
  const history = useHistory();

  const { data, loading, error } = useQuery(GET_EMPLOYER_WITH_EMAIL, {
    variables: {
      email,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loading) {
      setAccessType(data.employer?.permission.role);
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
      history.push("/");
      window.location.reload();
    }
  }, [error]);

  if (loading) return <PageLoading />;
  if (error) return <PartialError />;

  const { employer } = data;

  return (
    <DashboardTemplate>
      <Breadcrumbs
        items={[
          { title: "Team", link: "/team" },
          { title: employer.fullName, link: `/team/${email}` },
        ]}
      />
      <Profile>
        <ProfileHeader>
          <Avatar src={employer.avatarURL} name={employer.fullName} />
          <span>{employer.fullName}</span>
          {accessType === EMPLOYER_TYPES.ADMIN ? (
            <Tag active content="Admin" />
          ) : (
            <Tag content="Member" />
          )}
        </ProfileHeader>
        <ProfileSection employer={employer} />
        <PermissionsSection
          employer={employer}
          accessType={accessType}
          setAccessType={setAccessType}
        />
        <RevokeAccessSection accessType={accessType} employer={employer} />
      </Profile>
    </DashboardTemplate>
  );
};

export default UserPage;

import React from "react";
import styled from "styled-components";
import Navbar from "./Navbar";

const ResponsiveContainer = styled.div`
  @media only screen and (max-width: 1320px) {
    padding: 20px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 80px;
`;

const PageContent = styled.div`
  max-width: 1280px;
  width: 100%;
  padding-top: 22px;
  padding-bottom: 100px;
`;

export default function DashboardTemplate({ children, dontShowMenuItems }) {
  return (
    <>
      <Navbar dontShowMenuItems={dontShowMenuItems} />
      <ResponsiveContainer>
        <PageContainer>
          <PageContent>{children}</PageContent>
        </PageContainer>
      </ResponsiveContainer>
    </>
  );
}

import { gql } from "@apollo/client";

export const GET_ROLES = gql`
  {
    allRole(limit: 0) {
      roles {
        id
        title
        isFeatured
        sort
      }
    }
  }
`;

export const GET_PREVIOUS_RECRUITMENT_STAGES = gql`
  query getPreviousRecruitmentStagesByCompanyId($companyId: ID) {
    getPreviousRecruitmentStagesByCompanyId(companyId: $companyId) {
      id
      title
      recruitmentStages
      updatedAt
    }
  }
`;

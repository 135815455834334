import React, { useEffect } from "react";
import { FormScaffold, TextField, FormWrapper } from "tg-design";

export default function LinksTab({
  company,
  getItemStatus,
  handleOnChangeValue,
  error,
  refetch,
  setIsNavigationAllowed,
}) {
  const handleChange = (e, data, { name, value }) => {
    handleOnChangeValue(name, value);
  };

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const isErrorPresent = Object.values(error).every(
    (x) => x === null || x === ""
  );

  setIsNavigationAllowed(isErrorPresent);
  return (
    <FormWrapper onChange={handleChange} style={{ maxWidth: "600px" }}>
      <FormScaffold
        label="What is your company website?"
        status={getItemStatus("website")}
        isRequired
      >
        <TextField
          name="website"
          value={company.website}
          error={error.website}
          status={getItemStatus("website")}
        />
      </FormScaffold>
      <FormScaffold
        label="Linkedin page?"
        status={getItemStatus("linkedin")}
        isRequired
      >
        <TextField
          name="linkedin"
          value={company.linkedin}
          error={error.linkedin}
          status={getItemStatus("linkedin")}
        />
      </FormScaffold>
      <FormScaffold
        label="Any other links?"
        status={getItemStatus("otherLink")}
      >
        <TextField name="otherLink" value={company.otherLink} />
      </FormScaffold>
    </FormWrapper>
  );
}

import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router";
import { Button, message } from "tg-design";
import DashboardTemplate from "../../../components/Templates/DashboardTemplate";
import PageTitle from "../../../components/PageTitle";
import {
  TgHeaderTab,
  TgHeaderTabs,
  TgTabContent,
} from "../../../components/TgHeaderTabs";
import { BenefitsTab, GeneralInfoTab, RequirementsTab } from "./tabs";
import {
  GET_POSITION,
  UPDATE_POSITION,
  UPDATE_POSITION_CRITERIA,
} from "../../../queries/position";
import {
  useNamedLazyCaller,
  useLocationQuery,
  removeTypename,
  handleHeadTag,
  getValueByPropertyPath,
} from "../../../utils/helper";
import {
  criteriaValidationSchema,
  positionRequirementCheck,
  isAllPositionTabsValid,
} from "./validations";
import { generalValidationRules } from "./validations/positionRequirementCheck";
import PageLoading from "../../../components/PageLoading";
import ErrorPage from "../../ErrorPage";

const validationMessages = {
  title: null,
  description: null,
  recruitmentStages: null,
  totalExperience: null,
  budget: null,
};

const tabs = [
  { name: "general", title: "General Info" },
  { name: "requirements", title: "Requirements" },
  { name: "benefits", title: "Benefits" },
];

const TabNavigation = styled.div`
  border-top: 1px solid #e5e5e5;
  padding: 30px 36px;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    & button {
      margin: 0;
    }
  }
`;

export const getPositionState = (value) => {
  return {
    ...value,
    role: value.role && value.role.id,
    criteria: {
      ...(value.criteria || {}),
      positionLocation: {
        ...((value.criteria && value.criteria.positionLocation) || {}),
        expected:
          value.criteria &&
          value.criteria.positionLocation &&
          value.criteria.positionLocation.expected &&
          value.criteria.positionLocation.expected.id,
      },
    },
  };
};

export default function PositionEditPage() {
  const { id: positionId } = useParams();
  const namedLazyCaller = useNamedLazyCaller();
  const history = useHistory();
  const locationQuery = useLocationQuery();
  const urlParams = new URLSearchParams(window.location.search);
  const [inputStatus, setInputStatus] = useState({});
  const [queryError, setQueryError] = useState();
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "general"
  );
  const [tabValidation, setTabValidation] = useState({
    general: false,
    requirements: false,
    benefits: false,
  });
  const [validationError, setValidationError] = useState(validationMessages);
  const [isNavigationAllowed, setIsNavigationAllowed] = useState(true);
  const [position, setPosition] = useState(null);

  const [updatePosition] = useMutation(UPDATE_POSITION);
  const [updatePositionCriteria] = useMutation(UPDATE_POSITION_CRITERIA);
  const { refetch } = useQuery(GET_POSITION, {
    variables: { id: positionId },
    fetchPolicy: "no-cache",
  });

  const isBlocked = useRef(!isNavigationAllowed);

  const changeTab = (tab) => {
    locationQuery.set("tab", tab);
    history.push({ search: locationQuery.toString() });
    setCurrentTab(tab);
    window.scrollTo(0, 0);
  };

  const validator = async () => {
    const result = await positionRequirementCheck({ position });
    setTabValidation(result);
  };

  const refreshPosition = useCallback(async () => {
    try {
      const result = await refetch({
        id: positionId,
      });
      if (result) {
        const { position: item } = result.data;
        if (item.state !== "CREATED") {
          history.push(`/positions/${item.id}/view`);
          message.warning("You can not change a submitted position.");
          return;
        }
        const newState = getPositionState(item);
        setPosition(newState);
        setValidationError(validationMessages);
        setInputStatus({});
      }
    } catch (error) {
      setQueryError(error);
    }
  });

  useEffect(() => {
    refreshPosition();
  }, []);

  useEffect(() => {
    validator();
  }, [position]);

  const handleSubmit = async (field, newState, path) => {
    try {
      setInputStatus({ ...inputStatus, [path]: "loading" });
      await updatePosition({
        variables: {
          id: positionId,
          [field]: newState[field],
        },
      });
      await refetch();
      setValidationError({
        ...validationError,
        [field]: null,
      });
      setInputStatus({ ...inputStatus, [path]: null });
    } catch (err) {
      setInputStatus({ ...inputStatus, [path]: "error" });
      throw err;
    }
  };

  const validateItem = (field, changes, handleOnValid) => {
    setInputStatus({ ...inputStatus, [field]: "pending" });

    if (!generalValidationRules.fields[field]) {
      return handleOnValid();
    }

    generalValidationRules.fields[field]
      .validate(changes[field])
      .then(handleOnValid)
      .catch((error) => {
        setInputStatus({ ...inputStatus, [field]: "error" });
        setValidationError({
          ...validationError,
          [field]: error.message,
        });
      });
  };

  const handleSubmitCaller = (field, newState) => {
    handleSubmit(field, newState, field);
  };

  const applyChange = ({ field, changes }) => {
    validateItem(field, changes, () => {
      const newState = {
        ...position,
        ...changes,
      };
      setPosition(newState);

      const caller = namedLazyCaller(field, handleSubmitCaller, 500);
      caller(field, newState);
    });
  };

  const handleTabChange = (tab) => {
    if (isNavigationAllowed) {
      changeTab(tab);
    }
    if (!isNavigationAllowed) {
      // eslint-disable-next-line no-alert
      const result = window.confirm(
        "Are you sure you want to exit? Some changes might not be saved."
      );
      if (result) {
        changeTab(tab);
        setIsNavigationAllowed(true);
      }
    }
  };

  const markInvalidFields = () => {
    if (
      tabValidation[currentTab] &&
      tabValidation[currentTab].status === false
    ) {
      const failedFields = {};
      tabValidation[currentTab].fields.forEach((field) => {
        failedFields[field] = "error";
      });
      setInputStatus({
        ...inputStatus,
        ...failedFields,
      });
    }
  };

  const handleLeft = () => {
    if (
      tabValidation[currentTab] &&
      tabValidation[currentTab].status === false
    ) {
      message.error("There are some required fields");
      return markInvalidFields();
    }

    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    if (currentIndex === tabs.length - 1) {
      return;
    }

    handleTabChange(tabs[currentIndex + 1].name);
  };

  const handleRight = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    if (currentIndex > 0) {
      const previousTab = tabs[currentIndex - 1];
      if (tabValidation[previousTab.name].status === false) {
        return;
      }
      handleTabChange(tabs[currentIndex - 1].name);
    }
  };

  const handleOnChangeDirectly = (field, value) => {
    const newState = {
      ...position,
      ...{
        [field]: value,
      },
    };
    setPosition(newState);
    handleSubmit(field, newState, field);
  };

  const handleOnChangeValue = (field, value) => {
    applyChange({
      field,
      changes: {
        [field]: value,
      },
    });
  };

  const validateCriteriaItem = (field, inputKey, changes, handleOnValid) => {
    setInputStatus({ ...inputStatus, [inputKey]: "pending" });

    if (!criteriaValidationSchema.fields[field]) {
      return handleOnValid();
    }

    if (
      inputKey === "criteria.budget.income" ||
      inputKey === "criteria.budget.period"
    ) {
      const { budget } = changes;
      if (budget.min === 0 && budget.max === 0) {
        return handleOnValid();
      }
    }

    if (inputKey === "criteria.budget.isVisibleToUser") {
      const { budget } = changes;
      if (budget.isVisibleToUser === true || budget.isVisibleToUser === false) {
        return handleOnValid();
      }
    }

    if (
      inputKey === "criteria.stockOption.isProvided" ||
      inputKey === "criteria.stockOption.isVisibleToUser"
    ) {
      const { stockOption } = changes;

      if (stockOption.isProvided === true || stockOption.isProvided === false) {
        return handleOnValid();
      }

      if (
        stockOption.isVisibleToUser === true ||
        stockOption.isVisibleToUser === false
      ) {
        return handleOnValid();
      }
    }

    criteriaValidationSchema.fields[field]
      .validate(changes[field])
      .then(handleOnValid)
      .catch((error) => {
        setInputStatus({ ...inputStatus, [inputKey]: "error" });
        setValidationError({
          ...validationError,
          [field]: error.message,
        });
      });
  };

  const convertCriterias = (criteria) => {
    return removeTypename({
      ...criteria,
      stockOption: {
        ...criteria.stockOption,
        min: criteria?.stockOption?.min?.toString() || null,
        max: criteria?.stockOption?.max?.toString() || null,
        certainValue: criteria?.stockOption?.certainValue?.toString() || null,
      },
      education: {
        ...criteria.education,
        universities: [
          ...((criteria.education &&
            criteria.education.universities.map((i) => i.id)) ||
            []),
        ],
        branches: [
          ...((criteria.education &&
            criteria.education.branches.map((i) => i.id)) ||
            []),
        ],
      },
      languages: [
        ...((criteria.languages &&
          criteria.languages.map((i) => ({
            language: i.language?.id || i.language,
            languageLevel: i.languageLevel,
          }))) ||
          []),
      ],
      benefits: {
        ...criteria.benefits,
        expected: criteria?.benefits?.expected
          ? criteria?.benefits?.expected.map((i) => i.id)
          : [],
      },
      technologies: {
        ...criteria.technologies,
        expected: criteria?.technologies?.expected
          ? criteria?.technologies?.expected.map((group) => {
              return group.map((row) => {
                return {
                  _id: row._id,
                  experience: row.experience,
                };
              });
            })
          : [],
        otherExpected: criteria?.technologies?.otherExpected
          ? criteria?.technologies?.otherExpected.map((group) => {
              return group.map((row) => {
                return row._id || row.id;
              });
            })
          : [],
        plus: criteria?.technologies?.plus
          ? criteria?.technologies?.plus.map((i) => i.id)
          : [],
      },
    });
  };

  const applyCriteriaChanges = async ({ inputKey, newCriteriaState }) => {
    try {
      setInputStatus({ ...inputStatus, [inputKey]: "loading" });
      await updatePositionCriteria({
        variables: {
          ...convertCriterias(newCriteriaState),
          positionId: position.id,
        },
      });
      await refetch();
      setInputStatus({ ...inputStatus, [inputKey]: null });
      const [, secondKey] = inputKey.split(".");
      setValidationError({
        ...validationError,
        [secondKey]: null,
      });
    } catch (error) {
      setInputStatus({ ...inputStatus, [inputKey]: "error" });
      message.error("An error occurred!");
      throw error;
    }
  };

  const handleOnChangeCriteria = async (
    field,
    subKey,
    changes,
    lazyTimeout = 500
  ) => {
    let filteredChanges;
    try {
      const expectedSkillChanges = changes.expected
        .map((skills) => skills.filter((s) => s._id && s.experience))
        .filter((s) => s.length > 0);
      filteredChanges = { ...changes, expected: expectedSkillChanges };
    } catch (error) {
      filteredChanges = changes;
    }

    let inputKey = `criteria.${field}.${subKey || ""}`;

    if (inputKey[inputKey.length - 1] === ".") {
      inputKey = inputKey.substr(0, inputKey.length - 1);
    }

    const newCriteriaState = {
      ...(position.criteria || {}),
      [field]: filteredChanges,
    };

    const newState = {
      ...position,
      criteria: newCriteriaState,
    };

    setPosition(newState);

    validateCriteriaItem(field, inputKey, newCriteriaState, () => {
      const caller = namedLazyCaller(
        field,
        (args) => {
          applyCriteriaChanges(args);
        },
        lazyTimeout
      );
      caller({ inputKey, newCriteriaState });
    });
  };

  const getItemStatus = (name) => {
    if (inputStatus[name]) {
      return inputStatus[name];
    }

    if (position[name]) {
      if (!Array.isArray(position[name])) {
        return "success";
      }

      return position[name].length > 0 ? "success" : "pending";
    }

    if (name.indexOf(".") > -1) {
      const value = getValueByPropertyPath(position, name);
      const rangeInputs = [
        "criteria.totalExperience",
        "criteria.budget",
        "criteria.stockOption",
      ];
      if (
        rangeInputs.includes(name) &&
        position?.criteria?.stockOption?.isRange === "RANGE" &&
        ((value.min === 0 && value.max === 0) ||
          (value.min === null && value.max === null))
      ) {
        return "pending";
      }

      if (
        rangeInputs.includes(name) &&
        position?.criteria?.stockOption?.isRange === "CERTAIN" &&
        (value.certainValue === 0 || value.certainValue === null)
      ) {
        return "pending";
      }

      if (name.includes("criteria.technologies") && value.length === 0) {
        return "pending";
      }

      if (
        name === "criteria.education" &&
        !value.type &&
        value.type !== 0 &&
        value.branches.length === 0 &&
        value.universities.length === 0
      ) {
        return "pending";
      }

      if (
        name === "criteria.languages" &&
        value.filter((lang) => lang.language).length === 0
      ) {
        return "pending";
      }

      if (name === "criteria.benefits" && value.expected.length === 0) {
        return "pending";
      }

      const visibilityInputs = [
        "criteria.budget.isVisibleToUser",
        "criteria.stockOption.isVisibleToUser",
      ];

      if (visibilityInputs.includes(name) && value?.isVisibleToUser === null) {
        return "pending";
      }

      if (
        name === "criteria.positionLocation.relocation" &&
        value?.relocation === null
      ) {
        return "pending";
      }
      if (
        name === "criteria.positionLocation.visaSupport" &&
        value?.visaSupport === null
      ) {
        return "pending";
      }
      if (
        name === "criteria.stockOption.isProvided" &&
        value?.isProvided === null
      ) {
        return "pending";
      }
      // Values might be boolean.
      if (value || value === true || value === false) {
        return "success";
      }
    }

    return "pending";
  };

  const getTab = () => {
    let Component = null;
    switch (currentTab) {
      case "general":
        Component = GeneralInfoTab;
        break;
      case "requirements":
        Component = RequirementsTab;
        break;
      case "benefits":
        Component = BenefitsTab;
        break;
      default:
        throw new Error(`Undefined tab: ${currentTab}`);
    }

    return (
      <Component
        position={position}
        refetch={refreshPosition}
        setIsNavigationAllowed={setIsNavigationAllowed}
        inputStatus={inputStatus}
        setInputStatus={setInputStatus}
        getItemStatus={getItemStatus}
        handleOnChangeDirectly={handleOnChangeDirectly}
        handleOnChangeValue={handleOnChangeValue}
        handleOnChangeCriteria={handleOnChangeCriteria}
        error={validationError}
      />
    );
  };

  const handleFinish = () => {
    refetch();
    history.push(`/positions/${positionId}/view`);
  };

  isBlocked.current = !isNavigationAllowed;

  useEffect(() => {
    history.block((location) => {
      if (isBlocked.current && location.search === "")
        return "There are unsaved changes. Are you sure you want to change the page?";
    });

    return () => {
      isBlocked.current = false;
    };
  }, []);

  if (queryError) return <ErrorPage error={queryError} />;
  if (!position) return <PageLoading />;

  const isClickable = (tabName) => {
    const currentIndex = tabs.findIndex((tab) => tab.name === tabName);
    if (currentIndex === 0) {
      return true;
    }

    const currentValidation = tabValidation[tabName];
    if (!currentValidation) {
      return false;
    }

    if (currentValidation.status) {
      return true;
    }

    const previousTab = tabs[currentIndex - 1];
    const previousValidation = tabValidation[previousTab.name];
    if (!previousValidation) {
      return false;
    }

    return previousValidation.status;
  };

  let NextButton = (
    <Button style={{ width: "250px" }} onClick={handleLeft}>
      Next
    </Button>
  );

  if (isAllPositionTabsValid(tabValidation)) {
    NextButton = (
      <Button style={{ width: "250px" }} onClick={handleFinish}>
        Finish
      </Button>
    );
  }
  handleHeadTag("position", position.title);

  return (
    <DashboardTemplate>
      <PageTitle>{position.title || "Unnamed Position"}</PageTitle>

      <TgHeaderTabs
        currentTab={currentTab}
        currentIndex={tabs.findIndex((i) => i.name === currentTab)}
        handleChange={handleTabChange}
      >
        {tabs.map((tab) => (
          <TgHeaderTab
            key={tab.name}
            name={tab.name}
            disabled={!isClickable(tab.name)}
            isValid={tabValidation[tab.name] && tabValidation[tab.name].status}
          >
            {tab.title}
          </TgHeaderTab>
        ))}
      </TgHeaderTabs>

      <TgTabContent handleLeft={handleLeft} handleRight={handleRight}>
        {getTab()}
        <TabNavigation>
          {currentTab !== tabs[0].name && (
            <Button
              variant="text"
              onClick={handleRight}
              style={{ marginRight: "15px" }}
            >
              Go Back
            </Button>
          )}
          {NextButton}
        </TabNavigation>
      </TgTabContent>
    </DashboardTemplate>
  );
}

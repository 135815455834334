import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useOutsideAlerter } from "../../../hooks";

const DropdownContainer = styled.div`
  display: inline;
  background-color: white;
  position: relative;

  @media only screen and (max-width: 768px) {
    :not(.keep-inline) {
      width: 100%;
    }
  }
`;

const DropdownTrigger = styled.div`
  cursor: pointer;
  color: #9ca3af;
  float: right;

  &:hover,
  &:active,
  &:focus {
    color: #161616;
  }
`;

const DropdownMenuContainer = styled.div`
  background-color: white;
  right: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  min-width: 150px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  margin-top: 34px;
  margin-right: -30px;
  z-index: 99999;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 40px;

    :not(.keep-inline) {
      left: 0px;
      position: fixed;
      border-radius: 0px;
    }

    & .keep-inline {
      left: unset;
      border-radius: 6px;
      position: absolute;
    }
  }
`;

export const DropdownItem = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
  cursor: pointer;
  padding: 14px 22px;

  &.active {
    background: rgba(100, 183, 220, 0.15);
    border-radius: 6px;
    color: #161616;
  }

  &:hover {
    color: #161616;
    background-color: rgba(100, 183, 220, 0.15);
  }
`;

export const DropdownItemLink = styled(Link)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
  cursor: pointer;
  padding: 14px 22px;
  text-decoration: none;
  display: block;

  &:hover {
    color: #161616;
    background-color: rgba(100, 183, 220, 0.15);
  }

  &.active {
    background-color: rgba(100, 183, 220, 0.15);
  }
`;

const isTrigger = (event) => {
  const search = "x-dropdown-trigger";
  return (
    (event.target.className &&
      event.target.className.toString().indexOf(search) > -1) ||
    (event.target.parentNode &&
      event.target.parentNode.className &&
      event.target.parentNode.className.toString().indexOf(search) > -1) ||
    (event.target.parentNode &&
      event.target.parentNode.parentNode &&
      event.target.parentNode.parentNode.className &&
      event.target.parentNode.parentNode.className.toString().indexOf(search) >
        -1)
  );
};

export default function Dropdown({
  children,
  menus,
  menuStyles,
  keepInline,
  ...props
}) {
  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (!isTrigger(event)) {
      setIsActive(false);
    }
  };
  useOutsideAlerter(wrapperRef, handleOutsideClick);

  const handleTriggerClick = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };

  return (
    <DropdownContainer className={keepInline && "keep-inline"} {...props}>
      <DropdownTrigger
        onClick={handleTriggerClick}
        className="x-dropdown-trigger"
      >
        {children}
      </DropdownTrigger>
      {isActive && (
        <DropdownMenuContainer
          style={menuStyles}
          ref={wrapperRef}
          className={keepInline && "keep-inline"}
        >
          {menus}
        </DropdownMenuContainer>
      )}
    </DropdownContainer>
  );
}

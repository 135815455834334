import React from "react";
import { InputExplanation } from "../../../../../../components/Form/FormScaffold";
import { captureErrorWithData } from "../../../../../../utils/helper";
import { RowContainer, DescriptionCell, ValueCell } from "./styles";

const getRole = function (role) {
  if (role) {
    return role.title;
  }
  return "-";
};

const getLocation = function (criteria) {
  try {
    if (
      criteria &&
      criteria.positionLocation.expected &&
      criteria.positionLocation.remote === "none"
    ) {
      if (
        criteria.positionLocation?.expected?.title &&
        criteria.positionLocation?.expected?.place?.city
      ) {
        return `${criteria.positionLocation.expected.title} - ${criteria.positionLocation.expected.place.city}`;
      }
      return `${
        criteria?.positionLocation?.expected?.title ||
        criteria?.positionLocation?.expected?.place?.city
      }`;
    }

    if (criteria && criteria.positionLocation.remote) {
      if (criteria.positionLocation.remote === "full") {
        return "%100 Remote";
      }
      if (criteria.positionLocation.remote === "half") {
        if (
          criteria.positionLocation?.expected?.title &&
          criteria.positionLocation?.expected?.place?.city
        ) {
          return `Hybrid, ${criteria.positionLocation.expected.title} - ${criteria.positionLocation.expected.place.city}`;
        }
        return `Hybrid ${
          criteria.positionLocation?.expected?.title ||
          criteria.positionLocation?.expected?.place?.city
        }`;
      }
    }
  } catch (error) {
    captureErrorWithData(error, criteria);
    return "-";
  }
};

export default function GeneralInfoTable({ positionItem }) {
  return (
    <>
      <RowContainer>
        <DescriptionCell>Position</DescriptionCell>
        <ValueCell>
          <InputExplanation>{positionItem.title}</InputExplanation>{" "}
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Role</DescriptionCell>
        <ValueCell>
          <InputExplanation>{getRole(positionItem.role)} </InputExplanation>{" "}
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Position Details</DescriptionCell>
        <ValueCell>
          <InputExplanation
            className="richText"
            dangerouslySetInnerHTML={{
              __html: positionItem.description ? positionItem.description : "-",
            }}
          />
        </ValueCell>
      </RowContainer>
      <RowContainer>
        <DescriptionCell>Location</DescriptionCell>
        <ValueCell>
          <InputExplanation>
            {getLocation(positionItem.criteria)}
          </InputExplanation>
        </ValueCell>
      </RowContainer>
    </>
  );
}

import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useLocationQuery } from "../../utils/helper";

const StyledUl = styled.ul`
  list-style: none;
  padding: 0px;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  margin: 0px;
  overflow-x: auto;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const getScrollPosition = (currentIndex, ulRef) => {
  if (currentIndex === 0) {
    return 0;
  }

  if (currentIndex) {
    let previousElementsWidth = 0;
    for (let index = 0; index <= currentIndex; index++) {
      previousElementsWidth +=
        ulRef.current.children[index].getBoundingClientRect().width;
    }

    const newPosition = parseInt(
      (ulRef.current.offsetWidth * previousElementsWidth) /
        ulRef.current.scrollWidth,
      10
    );

    return newPosition;
  }

  return ulRef.current.scrollLeft;
};

export default function TgHeaderTabs({
  currentTab,
  currentIndex,
  handleChange,
  children,
  tabKey = "tab",
  ...props
}) {
  const ulRef = useRef();
  const query = useLocationQuery();
  const history = useHistory();

  useEffect(() => {
    query.delete("search");
    query.delete("sort");
    query.set(tabKey, currentTab);
    history.push({ search: query.toString() });
  }, [currentTab]);

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { currentTab, handleChange });
  });

  if (ulRef.current) {
    ulRef.current.scrollLeft = getScrollPosition(currentIndex, ulRef);
  }

  return (
    <StyledUl ref={ulRef} {...props}>
      {childrenWithProps}
    </StyledUl>
  );
}

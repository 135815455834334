import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";

import EducationHistorySection from "./EducationHistorySection";
import LanguageSection from "./LanguageSection";
import { ADD_ACTIVITY } from "../../../../../../../queries/match";
import { MATCH_ACTIVITY_TYPES } from "../../../../../../../utils/constants";

export default function EducationTab({ match, emptyView = true }) {
  const [addActivity] = useMutation(ADD_ACTIVITY);

  useEffect(() => {
    addActivity({
      variables: {
        matchId: match.id,
        type: MATCH_ACTIVITY_TYPES.EMPLOYER_SEEN_EDUCATION_TAB,
      },
    });
  }, []);

  return (
    <>
      {!emptyView && match?.user?.education?.length > 0 && (
        <EducationHistorySection match={match} />
      )}
      {emptyView && <EducationHistorySection match={match} />}
      {!emptyView && match?.user?.languages?.length > 0 && (
        <LanguageSection match={match} />
      )}
      {emptyView && <LanguageSection match={match} />}
    </>
  );
}

import React from "react";
import { useHistory } from "react-router";
import { Button } from "tg-design";
import { Description, FormContainer } from "../styles";

export default function SuccessStep({ email }) {
  const history = useHistory();

  return (
    <>
      <Description>You have successfully updated your password.</Description>
      <FormContainer>
        <Button
          onClick={() =>
            history.push({
              pathname: "/login",
              state: { email },
            })
          }
          style={{ marginTop: "20px", width: "100%", marginBottom: "20px" }}
        >
          Go to log in
        </Button>
      </FormContainer>
    </>
  );
}

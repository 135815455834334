import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import DashboardTemplate from "../../../components/Templates/DashboardTemplate";
import {
  TgHeaderTabs,
  TgHeaderTab,
  TgTabContent,
} from "../../../components/TgHeaderTabs";
import { useLocationQuery, handleHeadTag } from "../../../utils/helper";
import DetailTab from "./tabs/DetailsTab";
import MatchesTab from "./tabs/MatchesTab";
import TimelineTab from "./tabs/TimelineTab";
import ReportsTab from "./tabs/ReportsTab";
import PageLoading from "../../../components/PageLoading";
import { GET_POSITION } from "../../../queries/position";
import PositionBox from "../../../components/PositionBox";
import ErrorPage from "../../ErrorPage";
import { HttpStatusCodes, POSITION_STATES } from "../../../utils/constants";
import NotFoundPage from "../../NotFound";

const tabs = [
  { name: "detail", title: "Position Details" },
  { name: "matches", title: "Hiring Funnel" },
  { name: "timeline", title: "Timeline" },
  { name: "reports", title: "Reports" },
];

export default function PositionView() {
  const { id: positionId } = useParams();
  const locationQuery = useLocationQuery();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);

  const [position, setPosition] = useState(null);
  const [awaitingActionState, setAwaitingActionState] = useState({
    isHovered: false,
    isClicked: false,
  });

  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "detail"
  );

  const [queryError, setQueryError] = useState();

  const { data, refetch } = useQuery(GET_POSITION, {
    variables: { id: positionId },
    fetchPolicy: "no-cache",
    onCompleted: () => {
      if (data?.position?.state === POSITION_STATES.IN_PROGRESS) {
        setCurrentTab("matches");
      }
    },
  });

  const handleTabChange = (tab) => {
    locationQuery.set("tab", tab);
    history.push({ search: locationQuery.toString() });
    setCurrentTab(tab);
    window.scrollTo(0, 0);
  };

  const refreshPosition = useCallback(async () => {
    try {
      const result = await refetch({
        id: positionId,
      });
      if (result) {
        setPosition(result.data.position);
      }
    } catch (error) {
      setQueryError(error);
    }
  });

  useEffect(() => {
    refreshPosition();

    return () => {
      locationQuery.delete("tab");
      history.push({ search: locationQuery.toString() });
    };
  }, []);

  if (queryError) {
    const isNotFoundError = queryError.graphQLErrors.find(
      (errorElement) => errorElement.statusCode === HttpStatusCodes.NOT_FOUND
    );
    if (isNotFoundError) {
      return <NotFoundPage />;
    }
    return <ErrorPage error={queryError} />;
  }
  if (!position) return <PageLoading />;
  const getTab = () => {
    switch (currentTab) {
      case "detail":
        return <DetailTab positionData={data?.position} />;

      case "matches":
        return (
          <MatchesTab
            position={data?.position}
            history={history}
            awaitingActionState={awaitingActionState}
            setAwaitingActionState={setAwaitingActionState}
            refetch={refetch}
          />
        );

      case "timeline":
        return (
          <TimelineTab
            history={data?.position.history}
            positionCreatedAt={data?.position.createdAt}
          />
        );
      case "reports":
        return <ReportsTab position={data?.position} />;
      default:
        setCurrentTab("detail");
        throw new Error(`Undefined tab: ${currentTab}`);
    }
  };

  handleHeadTag("position", data?.position?.title);

  return (
    <DashboardTemplate>
      <PositionBox
        position={data?.position}
        currentTab={currentTab}
        handleChange={handleTabChange}
        awaitingActionState={awaitingActionState}
        setAwaitingActionState={setAwaitingActionState}
      />

      <TgHeaderTabs
        currentTab={currentTab}
        currentIndex={tabs.findIndex((i) => i.name === currentTab)}
        handleChange={handleTabChange}
      >
        {tabs.map((tab) => (
          <TgHeaderTab
            key={tab.name}
            name={tab.name}
            showBullets={false}
            styles={{
              link: {
                background:
                  awaitingActionState.isHovered &&
                  currentTab !== "matches" &&
                  tab.name === "matches" &&
                  "#FDEEC0",
              },
            }}
          >
            {tab.title}
          </TgHeaderTab>
        ))}
      </TgHeaderTabs>

      <TgTabContent>{getTab()}</TgTabContent>
    </DashboardTemplate>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import { Button } from "tg-design";
import DashboardTemplate from "../../../components/Templates/DashboardTemplate";
import PageTitle from "../../../components/PageTitle";
import Drawer from "../../../components/Drawer";
import NewPositionForm from "./NewPositionForm";
import { GET_ALL_POSITION } from "../../../queries/position";
import { GET_COMPANY_CONTACTS } from "../../../queries/company";
import { getUrlParams, handleHeadTag } from "../../../utils/helper";
import { getQueries } from "../QueryHelper";
import { POSITION_STATES } from "../../../utils/constants";
import PageLoading from "../../../components/PageLoading";
import AppContext from "../../../contexts/AppContext";
import PositionBox from "../../../components/PositionBox";
import ErrorPage from "../../ErrorPage";
import EmptyData from "../../../components/EmptyData";
import SearchInput from "../../../components/Form/SearchInput";
import device from "../../../styles/device";

const ListContainer = styled.div`
  padding-top: 20px;
`;

const MobileView = styled.div`
  width: 100%;
  display: none;

  @media ${device.mobileMax} {
    display: block;
    width: 100%;
  }
`;

const RightAlignedItems = styled.div`
  display: flex;

  @media ${device.mobileMax} {
    & .search-input {
      display: none;
    }
  }
`;

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};
export default function PositionsPage() {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const query = useUrlQuery();
  const [drawer, setDrawer] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [data, setData] = useState(null);
  const [positionQueryError, setPositionQueryError] = useState(null);
  const { refetch: getAllPosition } = useQuery(GET_ALL_POSITION, {
    variables: {
      filters: {
        company: appContext.employer.company.id,
      },
    },
    fetchPolicy: "no-cache",
    skip: true,
  });

  const {
    loading: companyDataLoading,
    error: companyQueryError,
    data: companyData,
  } = useQuery(GET_COMPANY_CONTACTS, {
    variables: { id: appContext.employer.company.id },
  });

  const refetch = async () => {
    const result = await getAllPosition();
    if (result && result.data) {
      setData(result.data.allPosition);
    }
  };

  const handleSearchChange = async (search) => {
    const newSearch = {
      ...searchFilter,
      search,
    };
    setSearching(true);
    setSearchFilter(newSearch);
    query.set("title", search);
    history.push({ search: query.toString() });
    setSearching(false);
  };

  useEffect(() => {
    const params = getUrlParams();
    const {
      search,
      filters,
      page = 1,
      sort = {
        states: [
          POSITION_STATES.CREATED,
          POSITION_STATES.IN_PROGRESS,
          POSITION_STATES.SUBMITTED,
          POSITION_STATES.ON_HOLD,
          POSITION_STATES.FILLED,
          POSITION_STATES.CLOSED,
          POSITION_STATES.CANCELED,
        ],
      },
    } = getQueries(params);

    const fetch = async () => {
      try {
        const result = await getAllPosition({
          company: appContext.employer.company.id,
          limit: 100,
          page,
          search,
          filters: {
            ...filters,
            company: appContext.employer.company.id,
          },
          sort,
          isAccessiblePositions: true,
        });
        if (result && result.data) {
          setData(result.data.allPosition);
        }
      } catch (err) {
        setPositionQueryError(err);
      }
    };
    fetch();
  }, [window.history, searchFilter]);

  if (positionQueryError || companyQueryError)
    return <ErrorPage error={positionQueryError || companyQueryError} />;

  if (!data || companyDataLoading) return <PageLoading />;

  handleHeadTag("all positions");

  const activeContacts = companyData.company.contacts
    .map(
      (contact) =>
        contact.status === "ACTIVE" && {
          id: contact.id,
          title: contact.fullName,
        }
    )
    .filter((contact) => contact.id);

  return (
    <DashboardTemplate>
      {drawer && (
        <Drawer onClose={() => setDrawer(false)} showAnimation>
          <NewPositionForm
            companyId={appContext.employer.company.id}
            setDrawer={setDrawer}
            refetch={refetch}
            contacts={activeContacts}
          />
        </Drawer>
      )}

      <PageTitle>
        Positions
        <RightAlignedItems>
          <SearchInput
            onLazyChange={handleSearchChange}
            isSearching={searching}
            defaultValue={searchFilter.search}
            placeholder="Search by position titles"
          />
          <Button
            style={{
              height: "50px",
              marginLeft: "20px",
            }}
            onClick={() => setDrawer(true)}
          >
            Add New
          </Button>
        </RightAlignedItems>
      </PageTitle>

      <ListContainer>
        <MobileView>
          <SearchInput
            onLazyChange={handleSearchChange}
            isSearching={searching}
            defaultValue={searchFilter.search}
            placeholder="Search by position titles"
          />
        </MobileView>
        {data.positions.length === 0 ? (
          <EmptyData text="positions" />
        ) : (
          data.positions.map((position) => (
            <PositionBox
              key={position.id}
              position={position}
              showTooltip={false}
              onClick={() => history.push(`/positions/${position.id}/view`)}
              isListItem
            />
          ))
        )}
      </ListContainer>
    </DashboardTemplate>
  );
}

import React, { useCallback } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: 30px;
  width: calc(100% - 100px);
  left: 50px;
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  background-color: #fff9f9;
  border: 2px dashed #ee6868;
  text-align: center;
  padding: 10px;
  color: #ee6868;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  max-width: 1280px;
  margin-left: 30px;
  margin-right: 30px;
  &.on-hover {
    color: #d71818;
    border: solid;
  }
`;

export default function TgKanbanTrash({
  isOnHover,
  handleDragChange,
  onDrop,
  columnId,
  substate = null,
  children,
}) {
  const handleDragOver = useCallback((event) => {
    handleDragChange({
      targetColumnId: columnId,
      targetSubstateId: substate,
    });

    event.preventDefault();
  });

  const classes = [];
  if (isOnHover) {
    classes.push("on-hover");
  }

  return (
    <Container>
      <Box
        onDrop={onDrop}
        onDragOver={handleDragOver}
        className={classes.join(" ")}
      >
        {children}
      </Box>
    </Container>
  );
}

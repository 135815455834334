import React from "react";
import styled from "styled-components";
import { Row, Col } from "../Form/BlockUi";

const SkillContainer = styled.div`
  display: inline-flex;
  border: 1px solid #64b7dc;
  border-radius: 5000px;
  margin: 3px;
  padding: 10px;
  @media only screen and (max-width: 768px) {
    display: flex;
    align-item: center;
    justify-content: center;
  }
`;

const Skill = styled.div`
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Seperator = styled.div`
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #c7c7c7;
  padding-left: ${({ type }) => (type === "and" ? "40px" : 0)};
  @media only screen and (max-width: 768px) {
    display: block;
    padding-left: 40px;
    justify-content: center;
    padding-left: none;
  }
`;

export default function SkillDiagram({ diagramData }) {
  return diagramData
    .map((skill) => {
      const expectedSkills = skill
        .map((requiredSkill) => {
          return (
            <Col>
              <SkillContainer>
                {requiredSkill.experience ? (
                  <Skill>{`${requiredSkill.title}, ${
                    requiredSkill.experience
                  } ${
                    requiredSkill.experience === 1 ? "year" : "years"
                  }`}</Skill>
                ) : (
                  <Skill>{requiredSkill.title}</Skill>
                )}
              </SkillContainer>
            </Col>
          );
        })
        .reduce(
          (acc, x) =>
            acc === null ? (
              x
            ) : (
              <>
                {acc} <Seperator type="or">or</Seperator> {x}
              </>
            ),
          null
        );

      return <Row isInTable="true">{expectedSkills}</Row>;
    })
    .reduce(
      (acc, x) =>
        acc === null ? (
          x
        ) : (
          <>
            {acc} <Seperator type="and">and</Seperator>
            {x}
          </>
        ),
      null
    );
}

import styled from "styled-components";

const Profile = styled.div`
  border: 1px solid #ededed;
  border-radius: 5px;
`;

const ProfileHeader = styled.div`
  background: #f7f7f7;
  padding: 15px;
  display: flex;
  align-items: center;
  & > :first-child {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }
  & > span {
    font-weight: 700;
    font-size: 32px;
    color: #111827;
    margin-left: 15px;
  }
  & > :last-child {
    margin-left: 26px;
  }
`;

const ProfileItem = styled.div`
  padding: 40px 32px;
  border-bottom: 1px solid #ededed;
  display: flex;
  & > span {
    font-weight: 600;
    font-size: 16px;
    flex: 1;
  }
  & > div {
    flex: 2;
  }
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
  & > div {
    display: flex;
    align-items: center;
    & > strong {
      width: 113px;
      font-size: 14px;
      font-weight: 600;
      margin-right: 17px;
    }
    & > span {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const RevokeAccess = styled.div`
  display: flex;
  flex-direction: column;
  & > button {
    width: 170px;
    margin-bottom: 14px;
  }
  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const Permissions = styled.div`
  display: flex;
  flex-direction: column;
  & > label {
    height: inherit !important;
  }
`;

const RadioTextWrapper = styled.span`
  & > strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
  }

  & > ul {
    margin: 0;
    padding-left: 24px;
  }

  & li {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
  }
`;

const CheckboxTextWrapper = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  & > strong {
    font-weight: 700;
  }
`;

const MemberPositionContainer = styled.div`
  background-color: #f9fafb;
  margin-left: 35px;
  padding: 18px 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  border-radius: 5px;
  & > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
    margin-bottom: 10px;
  }
`;

export {
  Profile,
  ProfileHeader,
  ProfileItem,
  InfoList,
  RevokeAccess,
  Permissions,
  RadioTextWrapper,
  CheckboxTextWrapper,
  MemberPositionContainer,
};

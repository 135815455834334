import React from "react";
import { LanguageIcon } from "../../../../../../../components/Icon";
import EmptyData from "../../../../../../../components/EmptyData";
import {
  SectionContainer,
  SectionTitle,
  Container,
  InfoContainer,
  IconContainer,
  InformationConatiner,
  Title,
  SecondaryTitle,
} from "./styles";
import { LANGUAGE_LEVEL_TYPES } from "../../../../../../../utils/constants";

function getLanguageLevel(level) {
  const item = LANGUAGE_LEVEL_TYPES.find(
    (i) => i.value === parseInt(level, 10)
  );
  if (item) {
    return item?.label;
  }

  return null;
}

export default function LanguageSection({ match }) {
  return (
    <SectionContainer>
      <SectionTitle>Language</SectionTitle>

      {(!match.user.languages || match.user.languages.length === 0) && (
        <EmptyData />
      )}

      {match.user.languages.map((language, index) => {
        return (
          <Container key={index}>
            <InfoContainer>
              <IconContainer>
                <LanguageIcon />
              </IconContainer>
              <InformationConatiner>
                <Title>{language?.language?.label}</Title>
                <SecondaryTitle>
                  {getLanguageLevel(language.level)}
                </SecondaryTitle>
              </InformationConatiner>
            </InfoContainer>
          </Container>
        );
      })}
    </SectionContainer>
  );
}

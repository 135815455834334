import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Button,
  FormWrapper,
  RadioGroup,
  FormScaffold,
  TextField,
  message,
} from "tg-design";
import { CREATE_EMPLOYER_AND_INVITE } from "../../queries/company";
import { DrawerTitle } from "../../components/Drawer";
import { removeWhiteSpace } from "../../utils/helper";
import { EMPLOYER_TYPES } from "../../utils/constants";

const FORM_OPTIONS = {
  showStatus: false,
};

export default function InviteContactForm({ companyId, setDrawer, refetch }) {
  const [isLoading, setLoading] = useState(false);

  const [createAndInviteCompanyUser] = useMutation(CREATE_EMPLOYER_AND_INVITE);

  const submit = async (e, { fullName, email, role, accessType }) => {
    try {
      if (!fullName) {
        return message.error("Please enter a full name first!");
      }
      if (!email || !email.includes("@") || !email.includes(".")) {
        return message.error("Please enter a valid email first!");
      }
      if (!role) {
        return message.error("Please enter a role first!");
      }

      setLoading(true);
      const { data } = await createAndInviteCompanyUser({
        variables: {
          fullName,
          accessType: parseInt(accessType, 10),
          email: removeWhiteSpace(email),
          role,
          company: companyId,
        },
      });

      if (data) {
        refetch();
        setLoading(false);
        setDrawer(false);
      }
      message.success("Invite mail successfully sent!");
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  return (
    <>
      <DrawerTitle>Invite new team member</DrawerTitle>
      <FormWrapper onSubmit={submit}>
        <FormScaffold label="Full name" options={FORM_OPTIONS}>
          <TextField
            type="text"
            name="fullName"
            placeholder="Enter name - surname"
          />
        </FormScaffold>

        <FormScaffold label="E-mail" options={FORM_OPTIONS}>
          <TextField type="text" name="email" placeholder="Enter e-mail" />
        </FormScaffold>
        <FormScaffold label="Role in the company" options={FORM_OPTIONS}>
          <TextField type="text" name="role" placeholder="ie: HR Manager" />
        </FormScaffold>
        <FormScaffold label="Access type" options={FORM_OPTIONS}>
          <RadioGroup
            name="accessType"
            defaultValue={EMPLOYER_TYPES.MEMBER}
            options={[
              {
                label: "Admin",
                id: EMPLOYER_TYPES.ADMIN,
                value: EMPLOYER_TYPES.ADMIN,
              },
              {
                label: "Member",
                id: EMPLOYER_TYPES.MEMBER,
                value: EMPLOYER_TYPES.MEMBER,
              },
            ]}
          />
        </FormScaffold>
        <Button
          type="submit"
          loading={isLoading}
          style={{ width: "100%", marginTop: "30px" }}
        >
          Invite
        </Button>
      </FormWrapper>
    </>
  );
}

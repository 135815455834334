import React from "react";
import styled from "styled-components";

const Container = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.color ? props.color : "#979797")};
  margin-bottom: 40px;

  & button {
    float: right;
  }
`;

export default function Description({ children, color }) {
  return <Container color={color}>{children}</Container>;
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { ButtonBox, FormScaffold, message } from "tg-design";
import Drawer from "../../../../components/Drawer";
import NewLocationForm from "./NewLocationForm";
import {
  PlaceMarkIcon,
  TrashIcon,
  EditIcon,
} from "../../../../components/Icon";
import Square from "../../../../components/Square";
import { DELETE_COMPANY_LOCATION } from "../../../../queries/company";

const BoxContainer = styled.div`
  display: flex;
  max-width: 600px;
  flex-wrap: wrap;
`;

const PlaceDetail = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #1a1a1a;
  display: flex;
  justify-content: center;
`;

const PlaceIconContainer = styled.span`
  margin-right: 5px;
  margin-top: 5px;
`;

const LocationDataContainer = styled.div`
  height: 100%;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ActionBox = styled.button`
  border: none;
  background-color: transparent;
  color: #333;
  transition: all 0.1s ease-in-out;
  margin-right: 4px;

  &:hover {
    color: black;
    transform: scale(1.2);
  }

  @media only screen and (max-width: 768px) {
    color: #999;
    margin-right: 8px;

    &:hover {
      color: #888;
    }
  }
`;

const LocationData = ({ location }) => {
  return (
    <LocationDataContainer>
      <div>{location.title}</div>
      <div>
        <PlaceDetail>
          <PlaceIconContainer>
            <PlaceMarkIcon />
          </PlaceIconContainer>
          <div>{location.place && location.place.city}</div>
        </PlaceDetail>
      </div>
    </LocationDataContainer>
  );
};

const DataActions = ({ onDelete, onEdit }) => {
  return (
    <>
      <ActionBox type="button" onClick={onEdit}>
        <EditIcon />
      </ActionBox>
      <ActionBox type="button" onClick={onDelete}>
        <TrashIcon />
      </ActionBox>
    </>
  );
};

export default function LocationsTab({
  company,
  refetch,
  inputStatus,
  getItemStatus,
  setInputStatus,
}) {
  const [deleteCompanyLocation] = useMutation(DELETE_COMPANY_LOCATION);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const editLocation = (location) => {
    setSelectedLocation(location);
    setDrawer(true);
  };

  const deleteLocation = async (location) => {
    try {
      setInputStatus({
        ...inputStatus,
        locations: "loading",
      });
      await deleteCompanyLocation({
        variables: {
          locationId: location.id,
        },
      });
      setInputStatus({
        ...inputStatus,
        locations: company.locations.length > 1 ? "success" : "pending",
      });
      refetch();
    } catch (error) {
      message.error(error.message);
    }
  };

  const openNewLocationForm = () => {
    setSelectedLocation(null);
    setDrawer(true);
  };

  return (
    <>
      {drawer && (
        <Drawer onClose={() => setDrawer(false)} showAnimation>
          <NewLocationForm
            companyId={company.id}
            defaultValue={selectedLocation}
            setDrawer={setDrawer}
            refetch={refetch}
            inputStatus={inputStatus}
            setInputStatus={setInputStatus}
          />
        </Drawer>
      )}
      <div style={{ maxWidth: "600px" }}>
        <FormScaffold
          label="Add your office locations"
          status={getItemStatus("locations")}
          explanation="Leave blank if you have a fully remote working setup."
        >
          <BoxContainer>
            {company.locations &&
              company.locations.map((location, index) => (
                <Square
                  key={index}
                  squareClasses="data-box"
                  hoverContent={
                    <DataActions
                      onDelete={() => {
                        deleteLocation(location);
                      }}
                      onEdit={() => {
                        editLocation(location);
                      }}
                    />
                  }
                >
                  <LocationData location={location} />
                </Square>
              ))}
            <ButtonBox
              onClick={openNewLocationForm}
              status={getItemStatus("locations")}
            >
              Add new location
            </ButtonBox>
          </BoxContainer>
        </FormScaffold>
      </div>
    </>
  );
}

import React, { useEffect, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import { CloseIconBold, ArrowLeft } from "../Icon";
import device from "../../styles/device";

const drawerAnimation = keyframes`

  from {
    max-width: 0;
  }
  to {
    max-width: 100%;
  }

`;

const closeButtonAnimation = keyframes`

  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`;

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  right: 0px;
  width: ${(props) => (props.width ? props.width : "500px")};
  background-color: white;
  height: 100%;
  z-index: 9999;
  box-shadow: 0px 24px 40px rgba(26, 26, 26, 0.16);
  padding: 32px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  animation-name: ${drawerAnimation};
  animation-duration: ${(props) => (props.showAnimation ? "0.3s" : "0")};

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px 15px 15px 15px;
    margin-top: 52px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  & div.scroll-container {
    height: 100%;
  }
`;

const ScrollContainer = styled.div`
  @media ${device.tablet} {
    overflow-y: hidden;
    padding-bottom: 0;
  }
  padding-bottom: 50px;
`;

const DrawerTitleContainer = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #1a1a1a;
`;

const GrayBox = styled.div`
  background-color: black;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 999;
  opacity: 0.3;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const GeneralCloseBox = styled.button`
  position: fixed;
  right: ${(props) => (props.width ? props.width : "500px")};
  top: 0px;
  z-index: 99999;
  display: block;
  cursor: pointer;
  background: white;
  padding: 10px;
  border: none;
  border-right: 1px solid #f7f7f7;
  border-bottom-left-radius: 3px;

  animation-name: ${closeButtonAnimation};
  animation-duration: ${(props) => (props.showAnimation ? "0.3s" : "0")};

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileCloseBox = styled.div`
  background-color: #0c084c;
  position: fixed;
  top: 0px;
  z-index: 9999;
  width: 100%;
  margin: 0px;
  margin-left: -30px;
  height: 60px;
  color: white;
  padding: 14px;
  display: none;

  animation-name: ${closeButtonAnimation};
  animation-duration: ${(props) => (props.showAnimation ? "0.3s" : "0")};

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const MobileCloseButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 10px;
  }
`;

export const DrawerTitle = ({ children }) => {
  return <DrawerTitleContainer>{children}</DrawerTitleContainer>;
};

export default function Drawer({
  children,
  onClose,
  width,
  showAnimation = false,
  ...props
}) {
  const keyListener = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", keyListener, false);

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", keyListener, false);
    };
  }, [keyListener]);

  return (
    <>
      <GrayBox onClick={onClose} />
      <GeneralCloseBox
        type="button"
        onClick={onClose}
        width={width}
        showAnimation={showAnimation}
      >
        <CloseIconBold />
      </GeneralCloseBox>
      <MobileCloseBox showAnimation={showAnimation}>
        <MobileCloseButton type="button" onClick={onClose}>
          <ArrowLeft /> Back
        </MobileCloseButton>
      </MobileCloseBox>
      <Container width={width} showAnimation={showAnimation} {...props}>
        <ScrollContainer className="scroll-container">
          {children}
        </ScrollContainer>
      </Container>
    </>
  );
}

import React, { useState } from "react";
import { useHistory } from "react-router";
import AuthTemplate from "../../components/Templates/AuthTemplate";
import { handleHeadTag } from "../../utils/helper";

import { Title } from "./styles";

import {
  EmailVerifyStep,
  PinVerifyStep,
  SuccessStep,
  SetPasswordStep,
} from "./steps";

const steps = [
  {
    title: "Reset Password",
    key: "email-verify",
  },
  {
    title: "Verify your email",
    key: "pin-verify",
  },
  {
    title: "Enter new password",
    key: "set-password",
  },
  {
    title: "Password successfully changed",
    key: "success",
  },
];

export default function ForgotPassword() {
  const history = useHistory();
  const [state, setState] = useState({
    email: history.location?.state?.email || "",
    pin: null,
    step: steps[0],
  });

  handleHeadTag("forgotPassword");

  const handleEmailStep = (email) => {
    const currentIndex = steps.findIndex((step) => step.key === state.step.key);
    setState({ ...state, step: steps[currentIndex + 1], email });
  };

  const handlePinStep = ({ pin }) => {
    const currentIndex = steps.findIndex((step) => step.key === state.step.key);
    setState({
      ...state,
      step: steps[currentIndex + 1],
      email: pin.email,
      pin: pin.digit,
    });
  };

  const handleSetPasswordStep = () => {
    const currentIndex = steps.findIndex((step) => step.key === state.step.key);
    setState({ ...state, step: steps[currentIndex + 1] });
  };

  const getStep = () => {
    switch (state.step.key) {
      case "email-verify":
        return (
          <EmailVerifyStep email={state.email} handleStep={handleEmailStep} />
        );
      case "pin-verify":
        return <PinVerifyStep email={state.email} handleStep={handlePinStep} />;
      case "set-password":
        return (
          <SetPasswordStep
            email={state.email}
            pin={state.pin}
            handleStep={handleSetPasswordStep}
          />
        );
      case "success":
        return <SuccessStep email={state.email} />;
      default:
        break;
    }
  };

  return (
    <AuthTemplate>
      <Title>{state.step.title}</Title>
      {getStep()}
    </AuthTemplate>
  );
}

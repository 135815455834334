import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const Description = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

export const SecondaryDescription = styled.div`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #9ca3af;
`;

export const StatusCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 195px;
  background: #ffffff;
  border: 3px dashed #64b7dc;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #64b7dc;
`;

export const DeveloperCard = styled.div`
  width: 195px;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 9px 10px 10px 10px;
`;

export const DeveloperName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
`;

export const DeveloperRole = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`;

export const CancelButton = styled.button`
  width: 189px;
  height: 49px;
  width: 180px;
  height: 49.04px;
  border: 1px solid #c7c7c7;
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  &:hover {
    border-color: rgb(121, 181, 216);
    color: rgb(121, 181, 216);
  }
`;

import React from "react";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { TGToast } from "tg-design";
import "normalize.css";
import "./styles/app.css";
import { Provider } from "react-redux";
import theme from "./styles/theme";
import { useAppApolloClient } from "./config/apolloClient";
import { PrivateRoute, PublicRoute } from "./route";
import {
  LoginPage,
  RegisterPage,
  OnboardingPage,
  CompanyEditPage,
  PositionsPage,
  PositionEditPage,
  FeedbackPage,
  ForgotPassword,
  TeamPage,
  UserPage,
  DashboardPage,
  IntegrationPage,
} from "./pages";
import PositionView from "./pages/Position/View";
import AppProvider from "./AppProvider";
import NotFoundPage from "./pages/NotFound";
import { addLogRocket, addGoogleAnalytrics } from "./utils/externalScripts";
import ErrorBoundary from "./utils/ErrorBoundary";

import { store } from "./store";
import Question from "./components/QuestionModal";
import MatchPrintPage from "./pages/MatchPrint";

if (process.env.NODE_ENV === "production") {
  addLogRocket();
  addGoogleAnalytrics();
}

function App() {
  const client = useAppApolloClient();
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <ErrorBoundary>
                <AppProvider>
                  <Question />
                  <Switch>
                    <PublicRoute
                      restricted
                      allowOnboarding
                      component={LoginPage}
                      path="/login"
                      exact
                    />
                    <PublicRoute
                      restricted
                      allowOnboarding
                      component={ForgotPassword}
                      path="/forgot-password"
                      exact
                    />
                    <PublicRoute
                      restricted
                      component={RegisterPage}
                      path="/register"
                      exact
                    />
                    <PrivateRoute
                      component={PositionsPage}
                      path="/positions"
                      exact
                    />
                    <PrivateRoute
                      allowOnboarding
                      component={CompanyEditPage}
                      path="/company/edit"
                      exact
                    />
                    <PrivateRoute
                      allowOnboarding
                      component={OnboardingPage}
                      path="/onboarding"
                      exact
                    />
                    <PrivateRoute
                      component={PositionView}
                      path="/positions/:id/view"
                    />
                    <PrivateRoute
                      allowOnboarding
                      component={PositionEditPage}
                      path="/positions/:id/edit"
                    />
                    <PrivateRoute component={DashboardPage} path="/" exact />
                    <PrivateRoute
                      restricted
                      allowOnboarding
                      component={TeamPage}
                      path="/team"
                      exact
                    />
                    <PrivateRoute
                      restricted
                      allowOnboarding
                      component={UserPage}
                      path="/team/:email"
                      exact
                    />
                    <PrivateRoute
                      restricted
                      allowOnboarding
                      component={FeedbackPage}
                      path="/feedback"
                      exact
                    />
                    <PrivateRoute
                      restricted
                      allowOnboarding
                      component={MatchPrintPage}
                      path="/match-print/:matchId"
                      exact
                    />
                    <PrivateRoute
                      restricted
                      allowOnboarding
                      component={IntegrationPage}
                      path="/integrations"
                      exact
                    />
                    <Route path="*">
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </AppProvider>
              </ErrorBoundary>
            </BrowserRouter>
          </ApolloProvider>
          <TGToast />
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;

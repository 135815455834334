import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import {
  Button,
  FormScaffold,
  TextField,
  SelectField,
  FormWrapper,
  message,
} from "tg-design";
import { DrawerTitle } from "../../../components/Drawer";
import { GET_ROLES } from "../../../queries/roles";
import { CREATE_POSITION } from "../../../queries/position";

const FORM_OPTIONS = {
  showStatus: false,
};

const sortRoles = (roles) => {
  const featuredRoles = [];
  const otherRoles = [];

  roles.map((role) => {
    if (role.isFeatured === true) featuredRoles[role.sort] = role;
    else otherRoles.push(role);
    return null;
  });
  return featuredRoles.concat(otherRoles);
};

export default function NewPositionForm({
  companyId,
  setDrawer,
  refetch,
  contacts,
}) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [createPosition] = useMutation(CREATE_POSITION);
  const [roleData, setRoleData] = useState([]);

  const { error: roleError } = useQuery(GET_ROLES, {
    onCompleted: (data) => {
      const list = data.allRole.roles.filter(
        (item) => item.title !== "No expertise yet"
      );
      setRoleData(sortRoles(list));
    },
  });

  const submit = async (e, { title, role, mainContact }) => {
    try {
      if (!title) {
        return message.error("Please enter the position title first!");
      }
      if (!role) {
        return message.error("Please select a related role first!");
      }
      if (!mainContact) {
        return message.error("Please select a position owner first!");
      }

      setLoading(true);

      const { data } = await createPosition({
        variables: {
          title,
          role,
          mainContact,
          company: companyId,
        },
      });

      if (data) {
        refetch();
        setLoading(false);
        setDrawer(false);
        history.push(`/positions/${data.addPosition.id}/edit?tab=general`);
      }
    } catch (error) {
      setLoading(false);
      message.error("An error occurred!");
      throw error;
    }
  };

  return (
    <div style={{ padding: "1px" }}>
      <DrawerTitle onClose={() => setDrawer(false)}>
        Create new position
      </DrawerTitle>
      <FormWrapper onSubmit={submit}>
        <FormScaffold label="Position title" options={FORM_OPTIONS}>
          <TextField
            type="text"
            name="title"
            placeholder="i.e. Senior Backend Engineer"
          />
        </FormScaffold>

        <FormScaffold label="Related Role" options={FORM_OPTIONS}>
          <SelectField
            getOptionLabel={(option) => `${option.title}`}
            getOptionValue={(option) => `${option.id}`}
            options={roleData}
            error={roleError}
            name="role"
          />
        </FormScaffold>

        <FormScaffold label="Position Owner" options={FORM_OPTIONS}>
          <SelectField
            getOptionLabel={(option) => `${option.title}`}
            getOptionValue={(option) => `${option.id}`}
            options={contacts}
            name="mainContact"
          />
        </FormScaffold>
        <Button
          type="submit"
          loading={isLoading}
          style={{ width: "100%", marginTop: "30px" }}
        >
          Create New Position
        </Button>
      </FormWrapper>
    </div>
  );
}

import React from "react";

import TgDonut from "../../../../../../../components/TgDonut";
import {
  FlexRow,
  FlexColumn,
  SkillItem,
  SkillIndexBox,
  SkillTitle,
  SkillExperience,
} from "./styles";

const SkillListView = ({ data = [] }) => {
  if (data.length > 0) {
    data.sort((a, b) => b.value - a.value);
  }

  const getExperienceLabel = (experience) => {
    if (experience === 1) {
      return <SkillExperience>{experience} year</SkillExperience>;
    }
    if (experience > 1 && experience < 8) {
      return <SkillExperience>{experience} years</SkillExperience>;
    }
    if (experience >= 8) {
      return <SkillExperience>{experience}+ years</SkillExperience>;
    }
  };
  return data.map((item, index) => (
    <SkillItem key={index}>
      <SkillIndexBox>{index + 1}</SkillIndexBox>
      <SkillTitle>{item?.label}</SkillTitle>
      {getExperienceLabel(item.value)}
    </SkillItem>
  ));
};

export default function SkillView({ skills }) {
  const data = skills.map((skill) => {
    return { label: skill.title, value: skill.experience };
  });
  return (
    <FlexRow>
      <FlexColumn width={{ mobileS: "100%", laptopL: "50%" }} mt={4}>
        <SkillListView data={data} />
      </FlexColumn>
      <FlexColumn width={{ mobileS: "100%", laptopL: "50%" }}>
        <TgDonut data={data} />
      </FlexColumn>
    </FlexRow>
  );
}

import React, { useCallback, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  FormScaffold,
  NumericField,
  SelectField,
  AsyncSelectField,
  message,
} from "tg-design";
import {
  SkillGroup,
  OtherSkillGroup,
} from "../../../../components/TechnologyGroup";
import { InlineInputs, Row } from "../../../../components/Form/BlockUi";
import {
  EDUCATION_TYPES,
  NOT_REQUIRED_MESSAGE,
} from "../../../../utils/constants";
import { debounce } from "../../../../utils/helper";
import { SEARCH_UNIVERSITY, SEARCH_BRANCH } from "../../../../queries/searches";
import { ALL_LANGUAGES } from "../../../../queries/shared";
import { GET_TECHNOLOGIES } from "../../../../queries/company";
import { Label } from "../../../../components/Form/FormUi";
import LanguageGroup from "../../../../components/LanguageGroup";

export default function RequirementsTab({
  position,
  getItemStatus,
  handleOnChangeCriteria,
  error,
  refetch,
  setIsNavigationAllowed,
}) {
  const [technologyData, setTechnologyData] = useState([]);

  const { refetch: universityRefetch } = useQuery(SEARCH_UNIVERSITY, {
    skip: true,
  });
  const { refetch: branchRefetch } = useQuery(SEARCH_BRANCH, {
    skip: true,
  });

  const {
    data: langData,
    loading,
    error: langError,
  } = useQuery(ALL_LANGUAGES, {
    variables: { limit: 300 },
  });
  const { error: techError } = useQuery(GET_TECHNOLOGIES, {
    variables: {
      verified: true,
    },
    onCompleted: (data) => {
      setTechnologyData(data.allTechnology.technologies);
    },
  });

  const handleNumericCriteriaChange = (motherField, childField, value) => {
    const newState = {
      ...position.criteria[motherField],
      [childField]: value,
    };
    handleOnChangeCriteria(motherField, null, newState);
  };

  const handleSelectChange = (motherField, childField, event) => {
    const value = event ? event.id || event.value : null;
    const newState = {
      ...position.criteria[motherField],
      [childField]: value,
    };
    handleOnChangeCriteria(motherField, null, newState, 0);
  };

  const handleLanguageChange = (changes) => {
    const newState = [...changes];
    if (changes.length !== position.criteria.languages.length) {
      handleOnChangeCriteria("languages", null, newState, 0);
    }
  };

  const handleSearchListSelectChange = (
    motherField,
    childField,
    value,
    lazyTimeout = 0
  ) => {
    const newState = {
      ...position.criteria[motherField],
      [childField]: value,
    };
    handleOnChangeCriteria(motherField, "plus", newState, lazyTimeout);
  };

  const handleUniversitySearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await universityRefetch({
          search: val,
        });

        callback(
          data.allUniversity.universities.map((i) => {
            return { name: i.name, id: i.id };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleBranchSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await branchRefetch({
          search: val,
        });

        callback(
          data.allUniversityBranch.universityBranches.map((i) => {
            return { name: i.label, id: i.id };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleSkillGroupUpdate = (changes) => {
    if (changes[0]) {
      const change = changes.map((i) => {
        return i.map((j) => {
          return { _id: j._id, title: j.title, experience: j.experience };
        });
      });
      const newState = {
        ...position.criteria.technologies,
        expected: change,
      };
      handleOnChangeCriteria("technologies", "expected", newState, 0);
    } else {
      const newState = {
        ...position.criteria.technologies,
        expected: changes,
      };
      handleOnChangeCriteria("technologies", "expected", newState, 0);
    }
  };

  const handleOtherSkillGroupUpdate = (changes) => {
    if (changes[0]) {
      const change = changes.map((i) => {
        return i.map((j) => {
          return { _id: j._id || j.id };
        });
      });
      const newState = {
        ...position.criteria.technologies,
        otherExpected: change,
      };
      handleOnChangeCriteria("technologies", "otherExpected", newState, 0);
    } else {
      const newState = {
        ...position.criteria.technologies,
        otherExpected: changes,
      };
      handleOnChangeCriteria("technologies", "otherExpected", newState, 0);
    }
  };

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const isErrorPresent = Object.values(error).every(
    (x) => x === null || x === ""
  );

  setIsNavigationAllowed(isErrorPresent);

  if (loading) return <div>Loading</div>;

  const { languages } = langData.allLanguages;

  const selectedLanguages = [
    ...(position?.criteria?.languages || []).filter((lang) => lang.language),
    { language: { id: null, label: null }, languageLevel: null },
  ];

  return (
    <div style={{ maxWidth: "600px" }}>
      <FormScaffold
        label="Total experience"
        status={getItemStatus("criteria.totalExperience")}
        isRequired
      >
        <InlineInputs>
          <NumericField
            type="tel"
            placeholder="min"
            label="Min"
            containerStyle={{ marginRight: 10 }}
            name="experience-min"
            value={position?.criteria?.totalExperience?.min || ""}
            onChange={(e, { value }) =>
              handleNumericCriteriaChange("totalExperience", "min", value)
            }
            status={getItemStatus("criteria.totalExperience")}
          />
          <NumericField
            type="tel"
            label="Max"
            placeholder="max"
            name="experience-max"
            value={position?.criteria?.totalExperience?.max || ""}
            onChange={(e, { value }) =>
              handleNumericCriteriaChange("totalExperience", "max", value)
            }
            status={getItemStatus("criteria.totalExperience")}
          />
        </InlineInputs>
        {error.totalExperience ? (
          <Label my={2} hasError={error.totalExperience}>
            {error.totalExperience}
          </Label>
        ) : null}
      </FormScaffold>
      <FormScaffold
        label="Select required skills."
        status={getItemStatus("criteria.technologies.expected")}
        styles={{ body: { ml: 4, my: 2 } }}
        isRequired
        explanation="Use AND, OR inputs fo clearly identify your expectations."
      >
        <SkillGroup
          technologyData={technologyData}
          initialValues={
            position.criteria &&
            position.criteria.technologies &&
            position.criteria.technologies.expected
          }
          update={handleSkillGroupUpdate}
          status={getItemStatus("criteria.technologies.expected")}
          error={techError}
        />
      </FormScaffold>

      <FormScaffold
        label="Select other required skills."
        status={getItemStatus("criteria.technologies.otherExpected")}
        styles={{ body: { ml: 4, my: 2 } }}
        isRequired
        explanation="Use AND, OR inputs fo clearly identify your expectations."
      >
        <OtherSkillGroup
          technologyData={technologyData}
          initialValues={
            position.criteria &&
            position.criteria.technologies &&
            position.criteria.technologies.otherExpected
          }
          update={handleOtherSkillGroupUpdate}
          status={getItemStatus("criteria.technologies.otherExpected")}
          error={techError}
        />
      </FormScaffold>

      <FormScaffold
        label="Select nice to have skills."
        status={getItemStatus("criteria.technologies.plus")}
        styles={{ body: { ml: 4, my: 2 } }}
        isRequired
      >
        <SelectField
          name="niceToHaveSkills"
          isMulti
          isClearable
          defaultValue={
            position.criteria &&
            position.criteria.technologies &&
            position.criteria.technologies.plus &&
            position.criteria.technologies.plus.length > 0 &&
            position.criteria.technologies.plus.map((item) => {
              return item;
            })
          }
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.id}
          options={technologyData && technologyData}
          onChange={(event) =>
            handleSearchListSelectChange("technologies", "plus", event)
          }
          closeMenuOnSelect={false}
          containerStyles={{ marginBottom: "15px" }}
          error={techError}
        />
      </FormScaffold>

      <FormScaffold
        label="Education"
        status={getItemStatus("criteria.education")}
        styles={{ body: { ml: 4, my: 2 } }}
        explanation={NOT_REQUIRED_MESSAGE}
      >
        <Row>
          <SelectField
            name="education"
            label="Min education"
            isClearable
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            defaultValue={
              position.criteria &&
              EDUCATION_TYPES.find(
                (i) => i.value === position?.criteria?.education?.type
              )
            }
            options={EDUCATION_TYPES}
            onChange={(event) => handleSelectChange("education", "type", event)}
          />
        </Row>
        <Row>
          <AsyncSelectField
            isMulti
            label="University"
            placeholder="Select university"
            loadOptions={handleUniversitySearch}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No matching results" : "Search university"
            }
            onChange={(event) =>
              handleSearchListSelectChange("education", "universities", event)
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            value={
              position.criteria &&
              position.criteria?.education?.universities.map((i) => {
                return { id: i.id, name: i.name };
              })
            }
          />
        </Row>

        <Row>
          <AsyncSelectField
            isMulti
            label="Department"
            placeholder="Select department"
            loadOptions={handleBranchSearch}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No matching results" : "Search department"
            }
            onChange={(event) =>
              handleSearchListSelectChange("education", "branches", event)
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            value={
              position.criteria &&
              position.criteria?.education?.branches.map((i) => {
                return { id: i.id, name: i.name || i.label };
              })
            }
          />
        </Row>
      </FormScaffold>
      <LanguageGroup
        languageData={languages}
        getItemStatus={getItemStatus}
        selectedLanguages={selectedLanguages}
        updateLanguages={handleLanguageChange}
        error={langError}
      />
    </div>
  );
}

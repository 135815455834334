import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AppContext from "../contexts/AppContext";

const PublicRoute = ({ component: Component, ...rest }) => {
  const appContext = useContext(AppContext);

  const handleRoute = (props) => {
    if (appContext.isAuth) {
      return <Redirect to="/" />;
    }
    if (!appContext.isAuth) {
      return <Component name="login" />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={(props) => handleRoute(props)} />;
};

export default PublicRoute;

import React from "react";
import { InfoList, ProfileItem } from "../styles";

const ProfileSection = ({ employer }) => {
  return (
    <ProfileItem>
      <span>Profile</span>
      <InfoList>
        <div>
          <strong>Full Name</strong>
          <span>{employer.fullName}</span>
        </div>
        <div>
          <strong>Email</strong>
          <span>{employer.email}</span>
        </div>
        <div>
          <strong>Role</strong>
          <span>{employer.role}</span>
        </div>
      </InfoList>
    </ProfileItem>
  );
};

export default ProfileSection;

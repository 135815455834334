import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";
import relativeTime from "dayjs/plugin/relativeTime";
import { Button, message, SelectField } from "tg-design";
import { MainContact, TalentPartner } from "../Assignee";
import { PositionState, IntegrationDescription } from "../State";
import Stats from "./Stats";
import Timestamp from "./Timestamp";
import {
  POSITION_STATES,
  POSITION_STATE_DESCRIPTIONS,
} from "../../utils/constants";
import {
  positionRequirementCheck,
  isAllPositionTabsValid,
} from "../../pages/Position/Edit/validations";
import { getPositionState } from "../../pages/Position/Edit";
import {
  SUBMIT_POSITON,
  GET_GREENHOUSE_JOBS,
  SET_GREENHOUSE_JOB_ID,
} from "../../queries/position";
import AppContext from "../../contexts/AppContext";
import { getPositionStartDateFromNow } from "../../pages/Position/shared";
import useQuestion from "../../hooks/useQuestion";

dayjs.extend(relativeTime);

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px #e5e5e5;
  border-radius: 5px;
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 30px;

  &.list-item {
    transition: transform 0.2s;
    cursor: pointer;
    &:hover {
      background: #fcfcfc;
      transform: scale(1.04);
    }
  }

  @media only screen and (max-width: 992px) {
    padding: 15px;
  }
`;

const DataBox = styled.div`
  display: flex;

  @media only screen and (max-width: 992px) {
    display: block;
  }
`;

const TitleSection = styled.div`
  width: 45%;

  @media only screen and (max-width: 992px) {
    width: unset;
  }
`;

const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #111827;
  margin: 0px;
  margin-bottom: 4px;

  &.list-item {
    font-size: 18px;
    line-height: 20px;
    color: #0c084c;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 992px) {
    font-size: 18px;
    line-height: 20px;
    color: #0c084c;
    margin-bottom: 10px;
  }
`;

const TimestampBox = styled.div`
  display: flex;

  @media only screen and (max-width: 992px) {
    display: block;
  }
`;

const AssigneeSection = styled.div`
  width: 25%;
  padding-top: 6px;

  @media only screen and (max-width: 992px) {
    width: unset;
    display: flex;
    padding-top: 4px;
  }
`;

const AssigneeLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
  margin-bottom: 4px;

  @media only screen and (max-width: 992px) {
    min-width: 110px;
    margin-right: 4px;
  }
`;

const StatusSection = styled.div`
  width: 40%;

  @media only screen and (max-width: 992px) {
    width: unset;
    display: flex;
    padding-top: 30px;
    align-items: center;
  }
`;

const PositionStatusContainer = styled.div`
  display: flex;
  justify-content: right;
  cursor: pointer;

  @media only screen and (max-width: 992px) {
    justify-content: unset;
    margin-right: 20px;
  }
`;

const PositionMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #979797;
  padding-top: 2px;

  @media only screen and (max-width: 992px) {
    text-align: unset;
  }
`;

const ActionBox = styled.div`
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  @media only screen and (max-width: 992px) {
    justify-content: flex-start;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 15px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  min-width: 155px;
  @media only screen and (min-width: 425px) {
    min-width: 200px;
  }
`;

const WarningMessage = () => {
  return (
    <>
      <p>
        Once you submit, we will notify the talent team. Would you like to
        proceed?
      </p>
    </>
  );
};
export default function PositionBox({
  position,
  onClick,
  showTooltip = true,
  isListItem = false,
  handleChange,
  awaitingActionState,
  setAwaitingActionState,
}) {
  const appContext = useContext(AppContext);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [greenhouseJobs, setGreenhouseJobs] = useState([]);
  const question = useQuestion();

  const history = useHistory();
  const [submitPosition] = useMutation(SUBMIT_POSITON);
  const [setGreenhouseJobId] = useMutation(SET_GREENHOUSE_JOB_ID, {
    onCompleted: (data) => {
      if (data.setGreenhouseJobId) {
        message.success("Your position synced with Greenhouse Job");
      }
    },
  });
  const [fetchGreenhouseJobs] = useLazyQuery(GET_GREENHOUSE_JOBS, {
    onCompleted: (data) => {
      if (data.listGreenhouseJobs && data.listGreenhouseJobs.length > 0) {
        setGreenhouseJobs(data.listGreenhouseJobs);
      }
    },
  });

  useEffect(() => {
    const validator = async () => {
      if (position?.state === POSITION_STATES.CREATED) {
        const positionState = getPositionState(position);
        const tabValidations = await positionRequirementCheck({
          position: positionState,
        });
        const canBeSubmit = isAllPositionTabsValid(tabValidations);
        setSubmitStatus(canBeSubmit);
      }
    };
    validator();
  }, [position]);

  useEffect(async () => {
    if (!isListItem && position.company.hasGreenhouseIntegration) {
      await fetchGreenhouseJobs();
    }
  }, []);

  const handleSubmitPosition = async () => {
    try {
      setSubmitting(true);
      await submitPosition({
        variables: {
          id: position.id,
        },
      });
      setSubmitting(false);
      appContext.refresh();
      message.success("The position has ben submitted.");
      history.push("/positions");
    } catch (error) {
      setSubmitting(false);
      message.error(error.message);
    }
  };

  const handleClick = () => {
    if (submitStatus) {
      question(
        {
          content: <WarningMessage />,
          title: "Submit Position",
        },
        () => handleSubmitPosition()
      );
    } else {
      history.push(`/positions/${position.id}/edit`);
    }
  };

  const handleChangeJobSelect = async (values) => {
    await setGreenhouseJobId({
      variables: { id: position?.id, jobId: values.id },
    });
  };

  return (
    <Container className={isListItem && "list-item"} onClick={onClick}>
      <DataBox>
        <TitleSection>
          <Title className={isListItem && "list-item"}>{position?.title}</Title>
          <TimestampBox>
            <Timestamp
              title="Created:"
              value={dayjs(position?.createdAt).fromNow()}
            />
            <Timestamp
              title="Started:"
              value={getPositionStartDateFromNow(position)}
            />
          </TimestampBox>
        </TitleSection>
        <AssigneeSection>
          <AssigneeLabel>Position Owner</AssigneeLabel>
          <MainContact mainContact={position?.mainContact} />
        </AssigneeSection>
        <AssigneeSection>
          <AssigneeLabel>Talent Partner</AssigneeLabel>
          <TalentPartner talentPartner={position?.assignee} />
        </AssigneeSection>
        <StatusSection>
          <LabelWrapper>
            {isListItem && (
              <PositionStatusContainer>
                <IntegrationDescription
                  integration="greenhouse"
                  connected={!!position?.greenhouseJobId}
                />
              </PositionStatusContainer>
            )}
            <PositionStatusContainer>
              <PositionState state={position?.state} />
            </PositionStatusContainer>
          </LabelWrapper>

          <PositionMessage>
            {POSITION_STATE_DESCRIPTIONS[position?.state]}
          </PositionMessage>
        </StatusSection>
      </DataBox>

      {position?.state !== POSITION_STATES.CREATED && (
        <ActionBox>
          <Stats
            title="Matched & Notified"
            tooltipText="Qualified candidates
            who matched with the position but did not respond yet"
            showTooltip={showTooltip}
          >
            {position?.companyReports.notified}
          </Stats>
          <Stats
            title="Interested"
            tooltipText="Candidates who
            stated that they are interested in this position"
            showTooltip={showTooltip}
          >
            {position?.companyReports?.interested}
          </Stats>
          <Stats
            title="Verified"
            tooltipText="Candidates who have been interviewed and verified by our talent team"
            showTooltip={showTooltip}
          >
            {position?.companyReports?.verified}
          </Stats>
          <Stats
            title="Awaiting Action"
            isMarked={position?.companyReports?.awatingAction > 0}
            awaitingActionState={awaitingActionState}
            setAwaitingActionState={setAwaitingActionState}
            showTooltip={showTooltip}
            handleChange={handleChange}
          >
            {position?.companyReports?.awatingAction}
          </Stats>
          {!isListItem && greenhouseJobs.length > 0 && (
            <div style={{ flex: 1 }}>
              <SelectField
                name="greenhouseJobId"
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                options={greenhouseJobs}
                placeholder="Select the relevant Greenhouse Job"
                defaultValue={greenhouseJobs.find(
                  (i) => i.id === position?.greenhouseJobId
                )}
                onChange={(values) => handleChangeJobSelect(values)}
              />
            </div>
          )}
        </ActionBox>
      )}
      {position?.state === POSITION_STATES.CREATED && showTooltip && (
        <ActionBox>
          {submitStatus && (
            <Button
              outline
              onClick={() => history.push(`/positions/${position.id}/edit`)}
            >
              Edit
            </Button>
          )}
          <ButtonContainer>
            <Button onClick={() => handleClick()} loading={isSubmitting} block>
              {submitStatus ? "Submit" : "Complete Position"}
            </Button>
          </ButtonContainer>
        </ActionBox>
      )}
    </Container>
  );
}

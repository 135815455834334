import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { Button, FormWrapper, Textarea } from "tg-design";
import PageTitle from "../../components/PageTitle";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import Description from "../../components/Description";
import { SEND_FEEDBACK } from "../../queries/company";
import { handleHeadTag } from "../../utils/helper";

const Container = styled.div`
  & button {
    width: 250px;
  }

  @media only screen and (max-width: 768px) {
    & button {
      width: 100%;
    }
  }
`;

const MobileTitle = styled.div`
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const DesktopTitle = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const TextAreaContainer = styled.div`
  max-width: 50%;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export default function FeedbackPage() {
  const [sendFeedback] = useMutation(SEND_FEEDBACK);
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);

  function onSend(e, { feedback }) {
    sendFeedback({ variables: { feedback } });
    setFeedbackSuccess(true);
  }

  handleHeadTag("feedback");
  return (
    <DashboardTemplate>
      <Container>
        <DesktopTitle>
          <PageTitle mb="0px">Let us know what you think</PageTitle>
        </DesktopTitle>
        <MobileTitle>
          <PageTitle mb="0px">Feedback</PageTitle>
        </MobileTitle>

        <Description>
          We’re all ears to learn about your thoughts, questions or comments!{" "}
        </Description>

        {feedbackSuccess === false && (
          <FormWrapper onSubmit={onSend}>
            <TextAreaContainer>
              <Textarea
                containerStyle={{
                  marginBottom: "30px",
                }}
                rows="15"
                cols="5"
                placeholder="Your message...."
                name="feedback"
              />
            </TextAreaContainer>
            <Button type="submit">Send</Button>
          </FormWrapper>
        )}
        {feedbackSuccess && (
          <Description color="black">
            We have successfully received your feedback 👌
          </Description>
        )}
      </Container>
    </DashboardTemplate>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  FormScaffold,
  TextField,
  RadioButton,
  SelectField,
  BlockCheckbox,
  RichTextEditor,
  toRichTextState,
  richTextToMarkdown,
} from "tg-design";

import { GET_ROLES } from "../../../../queries/roles";
import {
  GET_COMPANY_CONTACTS,
  GET_COMPANY_WITH_LOCATIONS,
} from "../../../../queries/company";
import {
  RELOCATION_OPTIONS,
  REMOTE_OPTIONS,
  VISA_SUPPORT_OPTIONS,
} from "../../../../utils/constants";
import Drawer from "../../../../components/Drawer";
import NewLocationForm from "../../../Company/Edit/tabs/NewLocationForm";
import AppContext from "../../../../contexts/AppContext";
import RecruitmentStages from "./RecruitmentStages";
import { Label } from "../../../../components/Form/FormUi";
import PageLoading from "../../../../components/PageLoading";
import PartialError from "../../../ErrorPage/PartialError";

const convertLocationToSelectOptions = (locations) => {
  return [
    ...locations.map((option) => {
      return {
        ...option,
        optionTitle: `${option.title} - ${option.place.city}, ${option.place.country}`,
      };
    }),
    {
      id: "add-new-office",
      optionTitle: "Add a new office",
    },
  ];
};

export default function GeneralInfoTab({
  position,
  getItemStatus,
  handleOnChangeValue,
  handleOnChangeCriteria,
  error,
  refetch,
  setIsNavigationAllowed,
}) {
  const appContext = useContext(AppContext);
  const [positionDescription, setPositionDescription] = useState(
    toRichTextState(position.description || "")
  );
  const [roleData, setRoleData] = useState([]);
  const [locations, setLocations] = useState([]);

  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (
      richTextToMarkdown(toRichTextState(position.description)) !==
      richTextToMarkdown(positionDescription)
    ) {
      handleOnChangeValue(
        "description",
        richTextToMarkdown(positionDescription)
      );
    }
  }, [positionDescription]);

  const sortRoles = (roles) => {
    const featuredRoles = [];
    const otherRoles = [];

    roles.map((role) => {
      if (role.isFeatured === true) featuredRoles[role.sort] = role;
      else otherRoles.push(role);
      return null;
    });
    return featuredRoles.concat(otherRoles);
  };

  const { loading: roleLoading, error: roleError } = useQuery(GET_ROLES, {
    onCompleted: (data) => {
      const list = data.allRole.roles.filter(
        (item) => item.title !== "No expertise yet"
      );
      setRoleData(sortRoles(list));
    },
  });
  const {
    loading: companyDataLoading,
    error: companyQueryError,
    data: companyData,
  } = useQuery(GET_COMPANY_CONTACTS, {
    variables: { id: appContext.employer.company.id },
  });
  const { refetch: refetchCompany } = useQuery(GET_COMPANY_WITH_LOCATIONS, {
    variables: {
      id: appContext.employer.company.id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const selectOptions = convertLocationToSelectOptions(
        data.company.locations
      );
      setLocations(selectOptions);
    },
  });

  const currentRoleId =
    position.role && position.role.id ? position.role.id : position.role;

  const remoteValue = position?.criteria?.positionLocation?.remote || "";

  const relocation = position?.criteria?.positionLocation?.relocation;

  const visaSupport = position?.criteria?.positionLocation?.visaSupport;

  const selectedOffice = position?.criteria?.positionLocation?.expected;

  const mainContactId = position?.mainContact?.id;

  const handleOnChangeLocation = (event, value, field) => {
    if (field === "remote") {
      const { checked } = event.target;
      let values = [];

      if (remoteValue !== "") {
        values = remoteValue.split(",");
      }
      if (checked) {
        values.push(value);
        value = values.join(",");
      } else {
        values = values.filter((item) => item !== value);
        value = values.join(",");
      }
    }

    const newState = {
      ...((position.criteria && position.criteria.positionLocation) || {}),
      [field]: value,
    };
    handleOnChangeCriteria("positionLocation", field, newState);
  };

  const handleOnChangeLocationExpectation = (value) => {
    // Yeni bir ofis eklemek istiyoruz
    if (value === "add-new-office") {
      setDrawer(true);
      return;
    }

    const newState = {
      ...((position.criteria && position.criteria.positionLocation) || {}),
      remote: position.criteria.positionLocation.remote || "none",
      expected: value,
    };
    handleOnChangeCriteria("positionLocation", "expected", newState);
  };

  const handleNewLocationAdded = async () => {
    const { data } = await refetchCompany({
      id: appContext.employer.company.id,
    });
    const selectOptions = convertLocationToSelectOptions(
      data.company.locations
    );
    setLocations(selectOptions);
  };

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const isErrorPresent = Object.values(error).every(
    (x) => x === null || x === ""
  );

  setIsNavigationAllowed(isErrorPresent);

  if (roleLoading || companyDataLoading) return <PageLoading />;
  if (roleError || companyQueryError) return <PartialError />;

  const activeContacts = companyData.company.contacts
    .map(
      (contact) =>
        contact.status === "ACTIVE" && {
          id: contact.id,
          title: contact.fullName,
        }
    )
    .filter((contact) => contact.id);

  return (
    <div style={{ maxWidth: "600px" }}>
      {drawer && (
        <Drawer onClose={() => setDrawer(false)} showAnimation>
          <NewLocationForm
            companyId={appContext.employer.company.id}
            setDrawer={setDrawer}
            refetch={handleNewLocationAdded}
          />
        </Drawer>
      )}
      <FormScaffold
        label="Position title"
        status={getItemStatus("title")}
        isRequired
      >
        <TextField
          name="title"
          placeholder="Position title"
          value={position.title}
          onChange={(e, { name, value }) => handleOnChangeValue(name, value)}
          error={error.title}
          status={getItemStatus("title")}
        />
      </FormScaffold>
      <FormScaffold
        label="Related role"
        status={getItemStatus("role")}
        isRequired
      >
        <SelectField
          name="role"
          getOptionLabel={(option) => `${option.title}`}
          getOptionValue={(option) => `${option.id}`}
          options={roleData}
          value={roleData.find((i) => i.id === currentRoleId)}
          onChange={(value) => handleOnChangeValue("role", value.id)}
          status={getItemStatus("role")}
        />
      </FormScaffold>
      <FormScaffold
        label="Position Owner"
        status={getItemStatus("mainContact")}
        isRequired
      >
        <SelectField
          name="mainContact"
          getOptionLabel={(option) => `${option.title}`}
          getOptionValue={(option) => `${option.id}`}
          options={activeContacts}
          value={activeContacts.find((i) => i.id === mainContactId)}
          onChange={(value) => handleOnChangeValue("mainContact", value.id)}
          status={getItemStatus("mainContact")}
        />
      </FormScaffold>
      <FormScaffold
        label="Position description"
        status={getItemStatus("description")}
        isRequired
      >
        <RichTextEditor
          state={positionDescription}
          onChange={setPositionDescription}
          placeholder="i.e. Fast growth, technologies, reputable investors... "
          status={getItemStatus("description")}
        />
        {error.description ? (
          <Label my={2} hasError={error.description}>
            {error.description}
          </Label>
        ) : null}
      </FormScaffold>
      <RecruitmentStages
        id={position.id}
        getItemStatus={getItemStatus}
        handleOnChangeValue={handleOnChangeValue}
        recruitmentStages={position.recruitmentStages}
        error={error}
      />
      <FormScaffold
        label="Position work location"
        status={getItemStatus("criteria.positionLocation.remote")}
        isRequired
      >
        {REMOTE_OPTIONS.map((option, index) => (
          <BlockCheckbox
            label={option.title}
            key={index}
            id={option.id}
            name={option.id}
            onChange={(event) =>
              handleOnChangeLocation(event, option.id, "remote")
            }
            checked={remoteValue.split(",").includes(option.id)}
            icon
            checkboxStyles={{
              height: "14px",
              width: "14px",
              margin: "0 10px 0 0",
            }}
          />
        ))}
      </FormScaffold>
      {(remoteValue.split(",").includes("none") ||
        remoteValue.split(",").includes("half")) && (
        <FormScaffold
          label="Select office"
          status={getItemStatus("criteria.positionLocation.expected")}
          isRequired
        >
          <SelectField
            getOptionLabel={(option) => option.optionTitle}
            getOptionValue={(option) => option.id}
            options={locations}
            onChange={(option) => handleOnChangeLocationExpectation(option.id)}
            value={locations.find((i) => i.id === selectedOffice)}
            status={getItemStatus("criteria.positionLocation.expected")}
          />
        </FormScaffold>
      )}

      {(remoteValue?.split(",").includes("half") ||
        remoteValue?.split(",").includes("none")) &&
        selectedOffice && (
          <FormScaffold
            label="Do you support relocation?"
            status={getItemStatus("criteria.positionLocation.relocation")}
            isRequired
          >
            {RELOCATION_OPTIONS.map((option, index) => (
              <RadioButton
                key={index}
                width="310px"
                label={option.title}
                id={option.id}
                name={option.id}
                checked={option.value === relocation}
                onChange={() =>
                  handleOnChangeLocation(null, option.value, "relocation")
                }
              />
            ))}
          </FormScaffold>
        )}
      {(remoteValue?.split(",").includes("half") ||
        remoteValue?.split(",").includes("none")) && (
        <FormScaffold
          label="Do you support visa sponsorship?"
          status={getItemStatus("criteria.positionLocation.visaSupport")}
          isRequired
        >
          {VISA_SUPPORT_OPTIONS.map((option, index) => (
            <RadioButton
              key={index}
              width="310px"
              label={option.title}
              id={option.id}
              name={option.id}
              checked={option.value === visaSupport}
              onChange={() =>
                handleOnChangeLocation(null, option.value, "visaSupport")
              }
            />
          ))}
        </FormScaffold>
      )}
    </div>
  );
}

import React from "react";
import styled from "styled-components";
import { MATCH_STATES } from "../../utils/constants";

const MenuContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledMenu = styled.button`
  display: flex;
  background: transparent;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  width: 100%;
  padding: 2px 3px;

  &:hover {
    color: ${(props) => props.hoverEffect && "#333"};
    background: ${(props) => props.hoverEffect && "#f8f8f8"};
  }
`;

const MenuIconBox = styled.div`
  & svg {
    color: #9ca3af;
  }
`;

export const MenuAction = ({ icon, ...props }) => {
  return (
    <StyledMenu type="button" className="menu-action" {...props}>
      <MenuIconBox>{icon}</MenuIconBox>
    </StyledMenu>
  );
};

export default function TgKanbanColumnActions({ children, state }) {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (child.props.children) {
      return React.Children.map(child.props.children, (subchild) => {
        return React.cloneElement(subchild, {
          handleClose: (event) => {
            subchild.props.onClick(event);
          },
        });
      });
    }

    return React.cloneElement(child, {
      handleClose: (event) => {
        child.props.onClick(event);
      },
    });
  });

  if (state === MATCH_STATES.INTERVIEW || !state) {
    return <MenuContainer>{childrenWithProps}</MenuContainer>;
  }
  return null;
}

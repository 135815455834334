import React from "react";
import styled from "styled-components";

const StyledLi = styled.li`
  float: left;
  border-bottom: 2px solid #fff;
  height: 30px;
  margin-right: 10px;

  &.active {
    border-bottom: 2px solid;
    border-bottom-color: ${({ styles = {} }) =>
      styles.list?.active ? styles.list.active : "#8fdeac"};
  }
`;

const StyledLink = styled.button`
  background: ${({ styles = {} }) =>
    styles.link?.background ? styles.link?.background : "none"};
  border: none;
  padding: 0px 15px 0px 15px;
  font-weight: 600;
  font-size: 14px;
  color: ${({ styles = {} }) =>
    styles.link?.color ? styles.link?.color : "#1a1a1a"};
  cursor: pointer;
  display: flex;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;

  &.active {
    color: ${({ styles = {} }) =>
      styles.link?.active ? styles.link?.active : "#8fdeac"};
  }

  &:hover {
    color: ${({ styles = {} }) =>
      styles.link?.hover ? styles.link?.hover : "#8fdeac"};
  }

  &.is-valid {
    color: #1a1a1a;
  }

  &.is-disabled {
    color: #999;
    cursor: default;
  }
`;

const Bullet = styled.div`
  border: 2px solid #aaa;
  border-radius: 50%;
  min-width: 14px;
  width: 14px;
  height: 14px;
  margin-right: 10px;

  &.active {
    border: 2px solid #8fdeac;
  }

  &.is-valid {
    border: 2px solid #8fdeac;
    background-color: #8fdeac;
  }

  &.filled {
    border: 2px solid #8fdeac;
    background-color: #8fdeac;
  }
`;

const LinkText = styled.span`
  white-space: nowrap;
`;

const CountBox = styled.span`
  background: #8fdeac;
  padding: 3px 7px;
  border-radius: 20px;
  margin-left: 5px;
  color: white;
  font-size: 12px;
`;

export default function TgHeaderTab({
  name,
  handleChange,
  currentTab,
  children,
  classname,
  showBullets = true,
  isValid = false,
  disabled = false,
  styles,
  count = 0,
  ...props
}) {
  const activeClasses = [];

  if (currentTab === name) {
    activeClasses.push("active");
  }

  if (isValid) {
    activeClasses.push("is-valid");
  }

  if (disabled) {
    activeClasses.push("is-disabled");
  }

  return (
    <StyledLi className={classname || activeClasses.join(" ")} styles={styles}>
      <StyledLink
        styles={styles}
        className={classname || activeClasses.join(" ")}
        onClick={() => handleChange(name)}
        disabled={disabled}
        {...props}
      >
        {showBullets && (
          <Bullet className={classname || activeClasses.join(" ")} />
        )}
        <LinkText>{children}</LinkText>
        {count > 0 && <CountBox>{count}</CountBox>}
      </StyledLink>
    </StyledLi>
  );
}

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: left;
  margin-bottom: 50px;
  margin-top: 50px;
`;

const Line = styled.div`
  border-bottom: 2px solid rgba(100, 183, 220, 0.4);
  width: 100%;
  margin-bottom: 0.5%;
`;

const Text = styled.div`
  padding: 0 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #1a1a1a;
`;

export default function Divider({ text }) {
  return (
    <Container>
      <Text> {text} </Text>
      <Line />
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import {
  FormScaffold,
  TextField,
  NumericField,
  SelectField,
  FormWrapper,
  RichTextEditor,
  toRichTextState,
  richTextToMarkdown,
} from "tg-design";
import { useQuery } from "@apollo/client";
import { GET_ALL_SECTOR } from "../../../../queries/company";
import UploadLogo from "./UploadLogo";
import { Label } from "../../../../components/Form/FormUi";
import PartialError from "../../../ErrorPage/PartialError";
import { COMPANY_SIZES } from "../../../../utils/constants";

const investStatus = [
  { value: "NONE", name: "None" },
  { value: "PRE_SEED", name: "Pre Seed" },
  { value: "SEED", name: "Seed" },
  { value: "POST_SEED", name: "Post Seed" },
  { value: "SERIES_A", name: "Series A" },
  { value: "SERIES_B", name: "Series B" },
  { value: "SERIES_C", name: "Series C" },
  { value: "SERIES_D", name: "Series D" },
  { value: "SERIES_E", name: "Series E" },
  { value: "PUBLIC", name: "Public" },
  { value: "Acquired", name: "Acquired" },
];

export default function GeneralTab({
  company,
  inputStatus,
  setInputStatus,
  getItemStatus,
  handleOnChangeDirectly,
  handleOnChangeValue,
  setLoadingState,
  error,
  refetch,
  setIsNavigationAllowed,
}) {
  const [description, setDescription] = useState(
    toRichTextState(company.description)
  );

  const {
    loading: sectorLoading,
    error: sectorError,
    data: allSector,
  } = useQuery(GET_ALL_SECTOR);

  const onLogoStarted = () => {
    setInputStatus({ ...inputStatus, logo: "pending" });
  };

  const handleChange = (e, data, { name, value }) => {
    handleOnChangeValue(name, value);
  };

  useEffect(() => {
    if (
      richTextToMarkdown(toRichTextState(company.description)) !==
      richTextToMarkdown(description)
    ) {
      handleOnChangeValue("description", richTextToMarkdown(description));
    }
  }, [description]);

  useEffect(() => {
    refetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const onLogoError = () => {
    setInputStatus({ ...inputStatus, logo: "error" });
  };

  if (sectorLoading) {
    setLoadingState(true);
    return "loading...";
  }
  if (sectorError) return <PartialError />;
  setLoadingState(false);

  const isErrorPresent = Object.values(error).every(
    (x) => x === null || x === ""
  );

  setIsNavigationAllowed(isErrorPresent);

  const getCompanySectors = (allSectors, companySector) => {
    return allSectors.filter((item) => {
      return companySector.find((i) => {
        return item.id === i.id;
      });
    });
  };

  const getCompanyTeamSize = (teamSize) => {
    return COMPANY_SIZES.filter((item) => {
      return item.id === teamSize;
    });
  };

  return (
    <FormWrapper onChange={handleChange} style={{ maxWidth: "600px" }}>
      <FormScaffold
        label="Company name"
        status={getItemStatus("name")}
        isRequired
      >
        <TextField
          name="name"
          placeholder="Company name"
          value={company.name}
          error={error.name}
          status={getItemStatus("name")}
        />
      </FormScaffold>

      <FormScaffold
        label="Company logo"
        status={getItemStatus("logo")}
        explanation="We suggest a square image for best results."
        isRequired
      >
        <UploadLogo
          handleLogo={(url) => handleOnChangeDirectly("logo", url)}
          initialLogo={company.logo}
          companyId={company.id}
          onStarted={onLogoStarted}
          onError={onLogoError}
          status={getItemStatus("logo")}
        />
      </FormScaffold>

      <FormScaffold
        label="What’s your company tagline?"
        status={getItemStatus("slogan")}
        isRequired
      >
        <TextField
          name="slogan"
          placeholder="i.e. Helping companies hire the best talent"
          value={company.slogan}
          error={error.slogan}
          status={getItemStatus("slogan")}
        />
      </FormScaffold>

      <FormScaffold
        label="What does your company do?"
        status={getItemStatus("description")}
        isRequired
      >
        <RichTextEditor
          name="description"
          state={description}
          onChange={setDescription}
          status={getItemStatus("description")}
        />
        {error.description ? (
          <Label my={2} hasError={error.description}>
            {error.description}
          </Label>
        ) : null}
      </FormScaffold>

      <FormScaffold
        label="When was your company founded?"
        status={getItemStatus("foundedYear")}
      >
        <NumericField
          type="tel"
          name="foundedYear"
          value={company.foundedYear}
        />
      </FormScaffold>

      <FormScaffold label="Your industry" status={getItemStatus("sector")}>
        <SelectField
          isMulti
          closeMenuOnSelect={false}
          name="sector"
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option.id}`}
          options={allSector.allSectors}
          defaultValue={getCompanySectors(allSector.allSectors, company.sector)}
          onChange={(values) =>
            handleOnChangeValue(
              "sector",
              values.map((i) => i.id)
            )
          }
        />
      </FormScaffold>

      <FormScaffold
        label="How much fundraising does your company have?"
        status={getItemStatus("investStatus")}
      >
        <SelectField
          name="investStatus"
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option.value}`}
          options={investStatus}
          defaultValue={investStatus.find(
            (i) => i.name === company.investStatus
          )}
          onChange={(value) => handleOnChangeValue("investStatus", value.value)}
        />
      </FormScaffold>

      <FormScaffold
        label="How many people work at your company?"
        status={getItemStatus("teamSize")}
        isRequired
      >
        <SelectField
          name="teamSize"
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option.id}`}
          options={COMPANY_SIZES}
          defaultValue={getCompanyTeamSize(company.teamSize)}
          onChange={(values) => handleOnChangeValue("teamSize", values.id)}
        />
      </FormScaffold>

      <FormScaffold
        label="How large is your tech team?"
        status={getItemStatus("techTeamSize")}
        isRequired
      >
        <SelectField
          name="teamSize"
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option.id}`}
          options={COMPANY_SIZES}
          defaultValue={getCompanyTeamSize(company.techTeamSize)}
          onChange={(values) => handleOnChangeValue("techTeamSize", values.id)}
        />
      </FormScaffold>
    </FormWrapper>
  );
}

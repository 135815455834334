import React from "react";
import styled from "styled-components";
import {
  POSITION_STATE_TITLES,
  POSITION_STATE_COLORS,
} from "../../utils/constants";
import { capitalize } from "../../utils/helper";

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 2px 10px;
  display: flex;
  margin-right: -8px;
  justify-content: flex-start;
  width: fit-content;
  height: 32px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
`;

const Bullet = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 6px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;

const IntegrationStatus = styled.div`
  background: ${({ integration }) =>
    integration ? `url("../../../images/${integration}-icon.png")` : ""};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  filter: ${({ connected }) => (connected ? `grayscale(0)` : `grayscale(100)`)};
  width: 18px;
  height: 18px;
`;

const IntegrationStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-color: ${({ connected }) => (connected ? `#2ECC71` : `#b6b6b6`)};
  border-radius: 50%;
  padding: 4px;
`;

const State = ({ color = "#BFBFBF", title }) => {
  return (
    <Container>
      <Bullet color={color} />
      <div style={{ whiteSpace: "nowrap" }}>{title}</div>
    </Container>
  );
};

export const PositionState = ({ state }) => {
  return (
    <State
      title={POSITION_STATE_TITLES[state]}
      color={POSITION_STATE_COLORS[state]}
    />
  );
};

export const IntegrationDescription = ({ integration, connected = true }) => {
  return (
    <IntegrationStatusWrapper connected={connected}>
      <IntegrationStatus
        integration={integration}
        connected={connected}
        title={capitalize(integration)}
      />
    </IntegrationStatusWrapper>
  );
};

export default State;

import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { ButtonLink, message } from "tg-design";
import PinField from "../../../components/Form/PinField";
import Timer from "../../../components/Timer";
import { VERIFY_PIN, CREATE_PIN } from "../../../queries/shared";
import { FormContainer, Description, TimerBox } from "../styles";

const PIN_FIELD_SIZE = 6;

const showNetworkError = (error) => {
  error.graphQLErrors.map((errorMessage) => {
    return message.error(errorMessage.message);
  });
};

export default function PinVerifyStep({ email, handleStep }) {
  const [state, setState] = useState({ pin: null, resend: true, email });
  const [createPin] = useMutation(CREATE_PIN, { onError: showNetworkError });
  const [verifyPin] = useLazyQuery(VERIFY_PIN, {
    onError: showNetworkError,
    onCompleted: (data) => handleStep(data),
  });

  const handlePinChange = (pin) => {
    setState({ ...state, pin: Number.parseInt(pin, 10) });
  };

  const handleTimesUp = () => {
    sessionStorage.removeItem("resendPin");
    setState({ ...state, resend: true });
  };

  const resend = async () => {
    await createPin({
      variables: {
        email,
        operation: "FORGOT_PASSWORD_FOR_EMPLOYER",
      },
    });
    sessionStorage.setItem("resendPin", false);
    setState({ ...state, resend: false });
  };

  useEffect(() => {
    const pinString = state.pin ? state.pin.toString() : "";

    if (state.pin && pinString.length === PIN_FIELD_SIZE) {
      verifyPin({
        variables: {
          email: state.email,
          digit: state.pin,
        },
      });
    }
  }, [state.pin]);

  return (
    <>
      <Description>
        We’ve send a verification code to <b>{email || ""}</b>. Please enter the
        6-digit code to verify your email address.
      </Description>
      <FormContainer>
        <PinField
          fields={PIN_FIELD_SIZE}
          type="number"
          onChange={handlePinChange}
        />
        <TimerBox>
          <Timer
            initialMinute={2}
            initialSeconds={59}
            handleTimesUp={handleTimesUp}
          />
        </TimerBox>
        {state.resend && (
          <ButtonLink onClick={resend}>Did not get the code? Resend</ButtonLink>
        )}
      </FormContainer>
    </>
  );
}

import React, { useCallback } from "react";
import styled from "styled-components";
import { AsyncSelectField, message } from "tg-design";
import { useQuery } from "@apollo/client";
import { debounce } from "../../../utils/helper";
import { GET_TECHNOLOGIES } from "../../../queries/company";

const StyledAsyncSelectField = styled(AsyncSelectField)`
  margin-bottom: 60px;

  & div[class*="-control"] {
    background: #f5f6f8;
    height: 54px;
  }
`;

export default function SearchTechStack({ usedTechnologyIds, onSelected }) {
  const { refetch: searchTechnologies } = useQuery(GET_TECHNOLOGIES, {
    skip: true,
  });

  const handleSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const response = await searchTechnologies({
          search: val,
          limit: 20,
          verified: true,
          exclude: usedTechnologyIds,
        });

        callback(response.data?.allTechnology?.technologies || []);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    [usedTechnologyIds]
  );

  return (
    <StyledAsyncSelectField
      isClearable
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.id}
      loadOptions={handleSearch}
      placeholder="Search technology here"
      noOptionsMessage={({ inputValue }) =>
        inputValue ? "No matching results" : "Search technology"
      }
      onChange={(values) => onSelected(values)}
      value={null}
      closeMenuOnSelect
    />
  );
}

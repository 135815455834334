import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";

if (process.env.REACT_APP_HOST_ENV !== "development") {
  Sentry.init({
    dsn: "https://4dd90281f3f04d62864f943c1eae8469@o1064775.ingest.sentry.io/5923945",
    environment: process.env.REACT_APP_HOST_ENV,
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [/Network\sError/i],
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

import React, { useState, useCallback } from "react";
import {
  AsyncSelectField,
  SelectField,
  FormScaffold,
  message,
} from "tg-design";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Row } from "../Form/BlockUi";
import {
  LANGUAGE_LEVEL_TYPES,
  MOST_SPOKEN_LANGUAGES,
} from "../../utils/constants";
import { ALL_LANGUAGES } from "../../queries/shared";
import { captureErrorWithData, debounce } from "../../utils/helper";
import "../../styles/components/flags.css";

const FlagContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  border: 2px solid #0c084c;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  :hover {
    background: #e9d8f2;
  }
`;

const FlagBlock = styled.div`
  display: inline-table;
`;

const LanguageSuggestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const LanguageContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const getLanguageLevelType = (initialFields) => {
  if (
    initialFields.languageLevel !== null &&
    initialFields.languageLevel !== undefined
  ) {
    return LANGUAGE_LEVEL_TYPES.find(
      (level) => level.value === initialFields.languageLevel
    );
  }
  return null;
};

export default function LanguageGroup({
  languageData,
  getItemStatus,
  selectedLanguages,
  updateLanguages,
  error,
}) {
  const [state, setState] = useState(selectedLanguages);

  const { refetch: languageSearch } = useQuery(ALL_LANGUAGES);

  const handleLanguageSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await languageSearch({ search: val });
        const _languages = data.allLanguages.languages.map((language) => {
          return {
            value: language?.id,
            label: language?.label,
          };
        });
        const sortedlanguages = _languages.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        callback(sortedlanguages);
      } catch (err) {
        message.error("Something went wrong.");
        captureErrorWithData(err);
      }
    }, 500),
    []
  );

  const filterLanguages = () => {
    const mostSpokenLanguages = [];

    for (let i = 0; i < MOST_SPOKEN_LANGUAGES.length; i++) {
      languageData.forEach((language) => {
        if (MOST_SPOKEN_LANGUAGES[i] === language.label) {
          mostSpokenLanguages.push(language);
        }
      });
    }
    const selectedLanguageIds = state.map((language) => language?.language?.id);
    const filteredMostSpokenLanguages = mostSpokenLanguages.filter(
      (language) => !selectedLanguageIds.includes(language.id)
    );

    return filteredMostSpokenLanguages;
  };

  const handleOptionChange = ({ value, type, idx }) => {
    let newState = state;
    const emptyRowIndex = newState.findIndex(
      (language) => !language.language.id
    );
    const index = idx || idx === 0 ? idx : emptyRowIndex;

    if (newState.length < index + 2 && value) {
      newState = [
        ...newState,
        { language: { id: null, label: null }, languageLevel: null },
      ];
    }

    if (type === "languages") {
      if (value) {
        newState[index].language.id = value;
      }
      if (!value) {
        newState.splice(index, 1);
        if (newState.filter((language) => !language.language.id).length !== 1) {
          newState = [
            ...newState,
            { language: { id: null, label: null }, languageLevel: null },
          ];
        }
      }
    }
    if (type === "level") {
      newState[index].languageLevel = value;
    }
    setState(newState);

    const filledLanguages = [];
    newState.forEach((language) => {
      if (language.language?.id && language.languageLevel) {
        filledLanguages.push({
          language: language.language?.id,
          languageLevel: language.languageLevel,
        });
      }
    });

    updateLanguages(filledLanguages);

    filterLanguages();
  };

  const getLanguageOptions = () => {
    const stateLanguageIds = state.map((lang) => lang.language?.id);
    const filteredLanguages = languageData.filter(
      (language) => !stateLanguageIds.includes(language.id)
    );
    return filteredLanguages;
  };

  return (
    <>
      <FormScaffold
        label="Languages"
        status={getItemStatus("criteria.languages")}
        styles={{ body: { ml: 4, my: 2 } }}
        explanation="Please select all languages your ideal candidate should know"
      >
        <LanguageSuggestionContainer>
          {filterLanguages()
            .slice(0, 10)
            .map((language, index) => (
              <FlagContainer
                onClick={() =>
                  handleOptionChange({
                    value: language.id,
                    label: language.label,
                    type: "languages",
                  })
                }
                key={index}
              >
                <FlagBlock
                  style={{ marginBottom: "3px" }}
                  display={{ mobileS: "none", tablet: "inline-table" }}
                  className={
                    language?.code
                      ? `flag ${language?.code?.toLowerCase()}`
                      : `flag default`
                  }
                />
                <span>{language.label}</span>
              </FlagContainer>
            ))}
        </LanguageSuggestionContainer>
        {state.map((language, idx) => (
          <LanguageContainer key={idx}>
            <Row style={{ flex: 1 }}>
              <AsyncSelectField
                name="language"
                label="Language"
                width="100%"
                isClearable
                options={languageData && getLanguageOptions()}
                value={languageData.filter(
                  (value) => value?.id === language?.language?.id
                )}
                noOptionsMessage={({ inputValue }) =>
                  inputValue ? "No results found" : "Type something to search"
                }
                loadOptions={handleLanguageSearch}
                onChange={(selectedOption) =>
                  handleOptionChange({
                    ...selectedOption,
                    type: "languages",
                    idx,
                  })
                }
                error={error}
              />
            </Row>
            <Row style={{ flex: 1 }}>
              <SelectField
                name="languageLevel"
                label="Language level"
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                options={LANGUAGE_LEVEL_TYPES}
                value={getLanguageLevelType(language) || null}
                onChange={(selectedOption) =>
                  handleOptionChange({
                    ...selectedOption,
                    type: "level",
                    idx,
                  })
                }
              />
            </Row>
          </LanguageContainer>
        ))}
      </FormScaffold>
    </>
  );
}

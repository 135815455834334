import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { NotFoundError } from "tg-design";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { GET_MATCH } from "../../../../../queries/match";
import DeveloperProfile from "./DeveloperProfile";
import {
  TgHeaderTab,
  TgHeaderTabs,
  TgTabContent,
} from "../../../../../components/TgHeaderTabs";
import {
  EducationTab,
  ProfileTab,
  WorkExperiencesTab,
  TGNotesTab,
  ConversationTab,
} from "./tabs";
import Loader from "../../../../../components/Loader";
import { useLocationQuery, handleHeadTag } from "../../../../../utils/helper";
import PartialError from "../../../../ErrorPage/PartialError";
import {
  HttpStatusCodes,
  MATCH_STATES,
  COMMENT_NOT_ALLOWED_STATES,
} from "../../../../../utils/constants";
import { LockIcon } from "../../../../../components/Icon";

const Container = styled.div`
  overflow-y: hidden;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    overflow-y: unset;
    display: block;
    flex-direction: unset;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTabHeaders = styled(TgHeaderTabs)`
  padding-bottom: 5px;
  margin-bottom: 4px;
  overflow-y: hidden;

  @media only screen and (max-width: 768px) {
    padding-bottom: unset;
    margin-bottom: unset;
  }
`;

const StyledTabContent = styled(TgTabContent)`
  height: 100%;
  overflow-y: scroll;

  @media only screen and (max-width: 768px) {
    height: unset;
    overflow-y: unset;
  }
`;

export default function MatchDetailDrawer({
  matchId,
  setUpdateCardId,
  openFailedModal,
  closeDrawer,
  position,
  columns,
  handleAcceptChange,
  notAGoodFitState,
  setNotAGoodFitState,
}) {
  const query = useLocationQuery();
  const [currentTab, setCurrentTab] = useState(
    query.get("drawerTab") || "profile"
  );
  const [data, setData] = useState(null);
  const [queryError, setQueryError] = useState();
  const location = useLocation();

  const { loading, refetch } = useQuery(GET_MATCH, {
    variables: {
      id: matchId,
    },
    skip: true,
    fetchPolicy: "network-only",
  });

  const getMatchDetail = async () => {
    try {
      const result = await refetch({
        id: matchId,
      });
      if (result?.data?.match) {
        setData(result.data.match);
      }
    } catch (err) {
      setQueryError(err);
    }
  };

  useEffect(() => {
    setCurrentTab(query.get("drawerTab") || "profile");
  }, [location.search]);

  useEffect(() => {
    getMatchDetail();
  }, [matchId]);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const handleSeenAll = () => {
    setData({
      ...data,
      unseenConversationCount: 0,
    });
  };

  const getTab = () => {
    let Component = null;
    switch (currentTab) {
      case "profile":
        Component = ProfileTab;
        break;
      case "workExperiences":
        Component = WorkExperiencesTab;
        break;
      case "education":
        Component = EducationTab;
        break;
      case "tgNotes":
        Component = TGNotesTab;
        break;
      case "conversation":
        Component = ConversationTab;
        break;
      default:
        throw new Error(`Undefined tab: ${currentTab}`);
    }
    return (
      <Component
        match={data}
        position={position}
        onSeenAll={handleSeenAll}
        notAGoodFitState={notAGoodFitState}
        setNotAGoodFitState={setNotAGoodFitState}
      />
    );
  };

  const tabStyles = {
    link: {
      active: "#64B7DC",
      hover: "#64B7DC",
    },
    list: {
      active: "#64B7DC",
    },
  };
  if (queryError) {
    const isNotFoundError = queryError.graphQLErrors.find(
      (errorElement) => errorElement.statusCode === HttpStatusCodes.NOT_FOUND
    );
    if (isNotFoundError) {
      return (
        <NotFoundError
          onClickButton={closeDrawer}
          buttonText="Close"
          title="Match Not Found"
          message="The match you were looking for cannot be found."
        />
      );
    }
    return <PartialError />;
  }
  if (loading || !data) return <Loader />;

  handleHeadTag("match detail", data.user.name);

  const tabs = [
    { name: "profile", title: "Profile Info" },
    { name: "workExperiences", title: "Work Experience" },
    { name: "education", title: "Education" },
    {
      name: "conversation",
      title: "Interview Note",
      count:
        !COMMENT_NOT_ALLOWED_STATES.includes(data?.state) &&
        data.unseenConversationCount,
    },
  ];

  return (
    <Container>
      <DeveloperProfile
        match={data}
        handleAcceptChange={handleAcceptChange}
        closeDrawer={closeDrawer}
        setUpdateCardId={setUpdateCardId}
        openFailedModal={openFailedModal}
        columns={columns}
        handleTabChange={handleTabChange}
        notAGoodFitState={notAGoodFitState}
        setNotAGoodFitState={setNotAGoodFitState}
      />

      <StyledTabHeaders
        currentTab={currentTab}
        currentIndex={tabs.findIndex((i) => i.name === currentTab)}
        handleChange={handleTabChange}
        tabKey="drawerTab"
      >
        {tabs.map((tab) => (
          <TgHeaderTab
            showBullets={false}
            key={tab.name}
            name={tab.name}
            styles={tabStyles}
            count={tab.count}
            disabled={
              (data.state === MATCH_STATES.SENT && tab.name !== "profile") ||
              ((data.state === MATCH_STATES.INTERESTED ||
                data.state === MATCH_STATES.TG_ASSESSMENT) &&
                tab.name === "conversation")
            }
          >
            {(data.state !== MATCH_STATES.SENT &&
              data.state !== MATCH_STATES.INTERESTED &&
              data.state !== MATCH_STATES.TG_ASSESSMENT) ||
            tab.name === "profile" ||
            ((data.state === MATCH_STATES.INTERESTED ||
              data.state === MATCH_STATES.TG_ASSESSMENT) &&
              tab.name !== "conversation") ? (
              tab.title
            ) : (
              <IconContainer>
                {tab.title}
                <LockIcon fill="#999" />
              </IconContainer>
            )}
          </TgHeaderTab>
        ))}
      </StyledTabHeaders>

      <StyledTabContent>{getTab()}</StyledTabContent>
    </Container>
  );
}
